"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function binarySearch(arr, target, comparator) {
    var l = 0, h = arr.length - 1, m, comparison;
    comparator = comparator || function (a, b) {
        return (a < b ? -1 : (a > b ? 1 : 0)); /* default comparison method if one was not provided */
    };
    while (l <= h) {
        m = (l + h) >>> 1; /* equivalent to Math.floor((l + h) / 2) but faster */
        comparison = comparator(arr[m], target);
        if (comparison < 0) {
            l = m + 1;
        }
        else if (comparison > 0) {
            h = m - 1;
        }
        else {
            return m;
        }
    }
    return ~l;
}
;
function binaryInsert(arr, target, duplicate, comparator) {
    var i = binarySearch(arr, target, comparator);
    if (i >= 0) { /* if the binarySearch return value was zero or positive, a matching object was found */
        if (!duplicate) {
            return i;
        }
    }
    else { /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
        i = ~i;
    }
    arr.splice(i, 0, target);
    return i;
}
;
const BinarySearch = {
    search: binarySearch,
    insert: binaryInsert
};
exports.default = BinarySearch;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pad2 = exports.padMonth = exports.padSecond = exports.padHour = exports.padMinute = exports.padDate = void 0;
exports.padDate = pad2;
exports.padMinute = pad2;
exports.padHour = pad2;
exports.padSecond = pad2;
exports.padMonth = pad2;
function pad2(n) {
    if (n < 10)
        return "0" + n;
    return n.toString();
}
exports.pad2 = pad2;
exports.default = pad2;

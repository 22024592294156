import { shortDateDisplay } from "../mods/common";

const KEY_STORAGE_LANG = "lang";
const lang = localStorage.getItem(KEY_STORAGE_LANG);

const DateUtils = {
  Today: "TODAY",
  TickWeeks: 604800000,
  TickDays: 86400000,
  TickHours: 3600000,
  TickMinutes: 60000,

  DayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  DayNamesVN: [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
  ],

  DayShortNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

  MonthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  MonthShortNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  MonthShortNamesVN: [
    "giêng",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
    "mười",
    "mười một",
    "mười hai",
  ],

  compareDate: (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  },

  offsetDate(dateA: Date, dateB: Date) {
    const tickDate = 86400000; // 24 * 60 * 60 * 1000
    return (
      Math.floor(dateA.getTime() / tickDate) -
      Math.floor(dateB.getTime() / tickDate)
    );
  },

  calcMonth(date: Date, offsetMonth: number) {
    return (12 + (offsetMonth % 12) + date.getMonth()) % 12;
  },

  ago(date: Date) {
    let ms = Date.now() - date.getTime();
    let arrEN = [
      [DateUtils.TickWeeks, "weeks ago", "week ago"],
      [DateUtils.TickDays, "days ago", "day ago"],
      [DateUtils.TickHours, "hours ago", "hour ago"],
      [DateUtils.TickMinutes, "mins ago", "min ago"],
    ];
    let arrVN = [
      [DateUtils.TickWeeks, "tuấn trước", "tuần trước"],
      [DateUtils.TickDays, "ngày trước", "ngày trước"],
      [DateUtils.TickHours, "giờ trước", "giờ trước"],
      [DateUtils.TickMinutes, "phút trước", "phút trước"],
    ];

    let arr = lang === "vn" ? arrVN : arrEN;

    for (let i = 0; i < arr.length; i++) {
      let value = ms / Number(arr[i][0]);
      if (value > 2) return { value: Math.floor(value), text: arr[i][1] };
      if (value > 1) return { value: Math.floor(value), text: arr[i][2] };
    }
    return {
      value: Math.floor(ms / 1000),
      text: lang === "vn" ? "giây trước" : "seconds ago",
    };
  },

  getDayShortENNames: function (fromSunday: Boolean): string[] {
    const DayShortName = DateUtils.DayShortNames;
    if (fromSunday) {
      return DayShortName;
    }
    let days = DayShortName.slice(1);
    days.push(DayShortName[0]);
    return days;
  },
  
  getDayShortNames: function (fromSunday: Boolean): string[] {
    const DayShortName =
      lang === "vn" ? DateUtils.DayNamesVN : DateUtils.DayShortNames;
    if (fromSunday) {
      return DayShortName;
    }
    let days = DayShortName.slice(1);
    days.push(DayShortName[0]);
    return days;
  },

  fromESD(esd: number) {
    let year = Math.floor(esd / 10000000000);
    let month = (Math.floor(esd / 100000000) % 100) - 1;
    let date = Math.floor(esd / 1000000) % 100;
    let hours = Math.floor(esd / 10000) % 100;
    let minutes = Math.floor(esd / 100) % 100;
    let seconds = esd % 100;
    let ms = 0;
    return new Date(year, month, date, hours, minutes, seconds, ms);
  },

  formatDDMMM(date: Date) {
    return `${date.getDate().toString().padStart(2, "0")} ${
      lang === "vn"
        ? `Th ${date.getMonth() + 1}`
        : DateUtils.MonthShortNames[date.getMonth()]
    }`;
  },

  formatHHMMDDMMMYYYY(date: Date) {
    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}, ${
      lang === "vn"
        ? `Th ${date.getMonth() + 1}`
        : DateUtils.MonthShortNames[date.getMonth()]
    } ${date.getFullYear()}`;
  },

  /**
   * Convert from milliseconds to string date
   * @param date
   * @returns date to show(string)
   */
  getDateText(date: number) {
    const now = new Date();
    let vDate = new Date(date);
    vDate = new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate());
    const cDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).valueOf();
    let dateText: string | undefined =
      vDate.valueOf() === cDate ? undefined : shortDateDisplay(vDate);
    return dateText;
  },
};
export default DateUtils;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPlayer = exports.PosPlayer = exports.CompetitionMode = exports.isLiveMatchStatus = exports.GameStatusMap = exports.GameStatus = void 0;
var GameStatus;
(function (GameStatus) {
    GameStatus[GameStatus["Upcoming"] = 0] = "Upcoming";
    GameStatus[GameStatus["FirstHalf"] = 1] = "FirstHalf";
    GameStatus[GameStatus["HalfTime"] = 2] = "HalfTime";
    GameStatus[GameStatus["SecondHalf"] = 3] = "SecondHalf";
    GameStatus[GameStatus["FullTime"] = 4] = "FullTime";
    GameStatus[GameStatus["Suspended"] = 5] = "Suspended";
    GameStatus[GameStatus["Cancelled"] = 6] = "Cancelled";
    GameStatus[GameStatus["FirstExtraTime"] = 7] = "FirstExtraTime";
    GameStatus[GameStatus["HalfExtraTime"] = 8] = "HalfExtraTime";
    GameStatus[GameStatus["SecondExtraTime"] = 9] = "SecondExtraTime";
    GameStatus[GameStatus["FullTimeExtra"] = 10] = "FullTimeExtra";
    GameStatus[GameStatus["PenaltyShootout"] = 11] = "PenaltyShootout";
    GameStatus[GameStatus["FullTimePenalty"] = 12] = "FullTimePenalty";
    GameStatus[GameStatus["Postponed"] = 13] = "Postponed";
    GameStatus[GameStatus["Abandoned"] = 14] = "Abandoned";
    GameStatus[GameStatus["Undecided"] = 15] = "Undecided";
    GameStatus[GameStatus["Gold"] = 16] = "Gold";
    GameStatus[GameStatus["NotYetStarted"] = 17] = "NotYetStarted";
})(GameStatus = exports.GameStatus || (exports.GameStatus = {}));
exports.GameStatusMap = {
    0: "Upcoming",
    1: "1st Half",
    2: "Half Time",
    3: "2nd Half",
    4: "Full Time",
    5: "Suspended",
    6: "Cancelled",
    7: "1st Extra Time",
    8: "Half Extra Time",
    9: "2nd Extra Time",
    10: "Full Time Extra",
    11: "Penalty Shootout",
    12: "Full Time Penalty",
    13: "Postponed",
    14: "Abandoned",
    15: "Undecided",
    16: "Gold",
    17: "Not Yet Started"
};
const LiveStatus = [
    GameStatus.FirstHalf, GameStatus.HalfTime, GameStatus.SecondHalf,
    GameStatus.FirstExtraTime, GameStatus.HalfExtraTime, GameStatus.SecondExtraTime,
    GameStatus.PenaltyShootout
];
function isLiveMatchStatus(status) {
    return LiveStatus.indexOf(status) >= 0;
}
exports.isLiveMatchStatus = isLiveMatchStatus;
var CompetitionMode;
(function (CompetitionMode) {
    CompetitionMode["ROUND"] = "Round";
    CompetitionMode["PERIOD"] = "Period";
    CompetitionMode["GROUP"] = "Group";
    CompetitionMode["NONE"] = "None";
})(CompetitionMode = exports.CompetitionMode || (exports.CompetitionMode = {}));
var PosPlayer;
(function (PosPlayer) {
    PosPlayer[PosPlayer["Goalkeepers"] = 0] = "Goalkeepers";
    PosPlayer[PosPlayer["Defenders"] = 1] = "Defenders";
    PosPlayer[PosPlayer["Midfielders"] = 2] = "Midfielders";
    PosPlayer[PosPlayer["Forwards"] = 3] = "Forwards";
})(PosPlayer = exports.PosPlayer || (exports.PosPlayer = {}));
var StatusPlayer;
(function (StatusPlayer) {
    StatusPlayer[StatusPlayer["Substitutions"] = 0] = "Substitutions";
    StatusPlayer[StatusPlayer["Suspended"] = 1] = "Suspended";
    StatusPlayer[StatusPlayer["Injured"] = 2] = "Injured";
    StatusPlayer[StatusPlayer["MainPlayers"] = 3] = "MainPlayers";
    StatusPlayer[StatusPlayer["MissPlayers"] = 4] = "MissPlayers";
})(StatusPlayer = exports.StatusPlayer || (exports.StatusPlayer = {}));

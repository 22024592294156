import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { HOCKEY, LeagueHockeyDetail, MatchesHockey, SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import NoData from "../shared/NoData";
import TabsComponent from '../tabs/tabComponent';
import TextLinker from "../textLinker";
import CompetitionDetailTable from './competitionDetaILTable';
import MatchListItem from "./matchListItem";
import { convertToSlug } from "../../utils/ConvertSlug";
import { GlobalSpinner } from "../globalSpinner";

type IData = {
    name: string,
    path: string,
    childs?: IData[]
    active?: boolean
    hidden?: boolean
}


export default function CompetitionLayout(props: { table?: boolean, fixtures?: boolean, results?: boolean }) {
    const [league, setLeague] = useState<LeagueHockeyDetail | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const params = useParams<SportParam>();
    const nav = useNavigate();
    const link = new URL(window.location.href).searchParams.get('link');



    useEffect(() => {
        (async () => {
            if (!params.coid) {
                redirect(getNavLink())
            } else {
                setIsLoading(true)
                setLeague(undefined)
                const tLeague = await Services.getHockeyLeague(params.coid || "", link || "")
                tLeague && setLeague(tLeague)
                setIsLoading(false)
            }
        })()
    }, [params.coid, link])

    const overviewMode = !props.fixtures && !props.table && !props.results

    const data: IData[] = [
        {
            name: HOCKEY.OVERVIEW,
            path: '',
            active: !props.fixtures && !props.table && !props.results
        },
        {
            name: HOCKEY.MATCHES,
            path: 'fixtures',
            active: props.fixtures || props.results,
            childs: [
                {
                    name: HOCKEY.FIXTURES,
                    path: "fixtures",
                    active: props.fixtures
                },
                {
                    name: HOCKEY.RESULTS,
                    path: "results",
                    active: props.results
                },
            ],
        },
        {
            name: HOCKEY.TABLE,
            path: 'table',
            active: props.table,
            hidden: league && !Object.keys(league!.Table).length
        },
    ]

    const handleNavigateTo = (path: string) => {
        if (params.cid && params.coid) nav(`/${window.location.pathname.split('/')[1]}/${convertToSlug(params.cid?.toString())}/${convertToSlug(params.coid)}/${path || 'overview'}`);
    }

    document.title = `Hockey - ${league?.LeagueName}`;

    if (!league) {
        return isLoading ? <GlobalSpinner /> :  <NoData text="There is no data available" />
    }

    function renderMatchListItems(matches?: MatchesHockey[], limit?: number) {
        if (!matches?.length) {
            return <NoData text="There is no data available" />
        }
        if (limit) {
            matches = matches.slice(0, 3);
        }
        return matches.map((match: MatchesHockey, index: number) => {
            return <div className="flex flex-col gap-y-3 mb-1" key={`$match-${index}`}>
                <MatchListItem
                    navigate={nav}
                    cid={params.cid}
                    coid={params.coid}
                    matchesHockey={match} />
            </div >
        })
    }

    return <>
        <CompetitionLinker
            isButton={false}
            league={league}
            navigate={nav}
        />

        <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />

        {overviewMode && <TextLinker text="FIXTURES" className="font-bold text-sm text-primary" link={"fixtures"} navigate={nav} isTextLink={true} />}
        {(overviewMode || props.fixtures) && renderMatchListItems(league.Fixtures, overviewMode ? 3 : undefined)}


        {overviewMode && <TextLinker text="RESULTS" className="font-bold text-sm text-primary" link={"results"} navigate={nav} isTextLink={true} />}
        {(overviewMode || props.results) && renderMatchListItems(league.Results, overviewMode ? 3 : undefined)}

        {overviewMode && !!Object.keys(league.Table).length && <TextLinker text="TABLE" className="font-bold text-sm text-primary" link={"table"} navigate={nav} isTextLink={true} />}

        {overviewMode && !!Object.keys(league.Table).length && <CompetitionDetailTable data={league.Table} isOverview={!props.table} />}
        {props.table && !!Object.keys(league.Table).length && <CompetitionDetailTable data={league.Table} isOverview={!props.table} />}
    </>
}
import { CompetitionTennisMatches } from "@lib/models";
import { padDate, padMonth } from "@lib/tools";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import { LiveDate, SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import DateNavigator, { DateChangeData } from "../dateNavigator";
import NoData from "../shared/NoData";
import TennisMatchList from "./tennisMatchList";
import { GlobalSpinner } from "../globalSpinner";

export default function TennisMatchLayout(props: {
    route: string,
    limit?: number
}) {
    const [matches, setMatches] = useState<CompetitionTennisMatches[]>()
    const params = useParams<SportParam>();
    const nav = useNavigate();
    const date = params.date ? new Date(params.date) : new Date();
    const newDate = date.getUTCDate();
    const newMonth = date.getUTCMonth();
    const newYear = date.getUTCFullYear();

    useEffect(() => {
        (async () => {
            setMatches(undefined)
            const tMatches = await ((params.date === LiveDate) ? Services.getTennisLiveSchedule() : Services.getTennisSchedule(newYear, newMonth + 1, newDate))
            tMatches && setMatches(tMatches)
        })()
    }, [params.date])

    return <>
        <DateNavigator date={params.date} onDateChanged={onDateChanged} />
        {matches ? matches.length > 0
            ? renderMap(matches)
            : <NoData text="There are no games scheduled" />
            : <GlobalSpinner />
        }
    </>

    function renderMap(matches: CompetitionTennisMatches[]) {
        if (props.limit !== undefined && matches.length >= props.limit) {
            matches = matches.slice(0, props.limit);
        }

        return <Virtuoso
            useWindowScroll
            totalCount={matches.length}
            itemContent={index =>
                <TennisMatchList
                    key={`TennisMatchList${matches[index].LeagueName}`}
                    league={matches[index]}
                    navigate={nav} />
            } />
    }

    function onDateChanged(e: DateChangeData) {
        const date = e.date ? new Date(e.date) : new Date();
        const newDate = date.getDate();
        const newMonth = date.getMonth() + 1;
        const newYear = date.getFullYear();
        const newdate = newYear + "-" + padMonth(newMonth) + "-" + padDate(newDate);

        nav(getNavLink({
            cid: params.cid,
            coid: params.coid,
            date: e.isLive ? LiveDate : newdate
        }))
    }

}
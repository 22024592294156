import { useEffect, useState, useMemo } from "react";
import Services from "../../mods/services";
import { GlobalSpinner } from "../globalSpinner";
import { News, NewsPost } from "@lib/models";
import NoData from "../shared/NoData";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LIMIT_NUMBER } from "../../mods/common";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PageFetcher from "../pageFetcher";

const PAGE_SIZE = 22;
const KEY_STORAGE_LANG = "lang";

function NewsLayout() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [data, setData] = useState<News | null>();
  const [categories, setCategories] = useState<
    { id: number; name: string; slug: string }[] | null
  >();
  const navigate = useNavigate();
  const query = useLocation();
  const lang = localStorage.getItem(KEY_STORAGE_LANG) || "en";
  const usp = new URLSearchParams(query.search);
  const categoryQuery = usp.get("category") || "all";
  const page = parseInt(usp.get("page") || "1");

  const handleActiveCategory = (item: { id: number; slug: string }) => {
    navigate({
      search: `category=${item.slug}`,
    });
  };
  const onPageChanged = (pageIndex: number) => {
    navigate({
      search: `category=${categoryQuery}&page=${pageIndex + 1}`,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const categoriesPromise = await Services.getNewsCategories(lang);
        categoriesPromise && setCategories(() => [...categoriesPromise]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setDataIsLoading(true);
        if (categories && categories.length) {
          const categoryId = categories.find((item) => item.slug === categoryQuery);
          const response = categoryId?.id
            ? await Services.getNews(lang, categoryId?.id, page, PAGE_SIZE)
            : null;
          response && setData(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDataIsLoading(false);
      }
    })();
  }, [query.search, categories, localStorage.getItem(KEY_STORAGE_LANG)]);

  document.title = `ScoreUpdate | The News About Live Scores, Fixtures & Results`;

  return (
    <div className="lg:col-span-full w-full">
      {isLoading ? (
        <GlobalSpinner />
      ) : (
        <>
          <div className="horizontal-navigation-container">
            <Swiper
              spaceBetween={5}
              slidesPerView="auto"
              navigation
              modules={[Navigation]}
              pagination={{ clickable: true }}
            >
              {categories &&
                categories.map((item) => (
                  <SwiperSlide
                    onClick={() => handleActiveCategory(item)}
                    key={`NEWS_CATEGORIES:${item.id}`}
                    className={`border font-bold rounded-2xl !w-fit text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer ${
                      categoryQuery === item.slug
                        ? "bg-[#fdfdfd] text-[#111] border-[#fdfdfd] dark:text-accent dark:border-accent"
                        : "text-[#aaa] bg-[#181818] border-[#181818] dark:bg-slate-200 dark:border-zinc-300 dark:text-black"
                    }`}
                  >
                    <span>{item.name}</span>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          {dataIsLoading ? (
            <GlobalSpinner />
          ) : data && Object.keys(data.Posts).length > 0 ? (
            <div className="news-wrapper my-2 !p-0 tab-content !pb-3">
              <RenderHorizontalPosts postsArray={data.Posts.slice(0, LIMIT_NUMBER + 3)} categoryQuery={categoryQuery} />
              <hr className="max-w-screen-xl mx-auto border-primary-darker dark:border-zinc-200  mb-5" />
              <RenderVerticalPosts postsArray={data.Posts.slice(LIMIT_NUMBER + 3)} />
              <PageFetcher
                pageIndex={page - 1}
                next={data.Posts.length >= PAGE_SIZE}
                onPageChanged={onPageChanged} />
            </div>
          ) : (
            <NoData text="There are no featured news" />
          )}
        </>
      )}
    </div>
  );
}

function RenderHorizontalPosts(props: { postsArray: NewsPost[], categoryQuery: string }) {
  return <>
    <div className="featured-articles-wrapper p-2 mb-4">
      <Swiper
        loop
        spaceBetween={5}
        slidesPerView={1.2}
        breakpoints={{
          576: {
            width: 576,
            slidesPerView: 2,
          },
          768: {
            width: 768,
            slidesPerView: 2,
          },
        }}
        navigation
        autoplay={true}
        modules={[Navigation, Autoplay]}
        pagination={{ clickable: true }}
      >
        {props.postsArray.slice(0, LIMIT_NUMBER + 3).map((item: NewsPost, index: number) => {
          return (
            <SwiperSlide
              key={`NEWS_FEATURED:${index}`}
              className={`news-slider font-bold rounded-2xl text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer`}
            >
              <Link
                className="relative rounded-md overflow-hidden h-[200px] w-full"
                to={`/news/detail/${localStorage.getItem(KEY_STORAGE_LANG) || "en"
                  }/${item.Slug}`}
              >
                <span className="z-10 absolute left-2.5 top-2.5 bg-[#333]  text-[#fdfdfd] text-[10px] rounded py-0 px-1 capitalize">
                  {props.categoryQuery.replaceAll("-", " ")}
                </span>
                <LazyLoadImage
                  className="w-full h-full object-cover"
                  src={item.ImageUrl}
                  alt={item.Title}
                  effect="blur"
                />
                <span className="absolute bottom-0 px-3 py-7 z-10 whitespace-normal">
                  <p
                    className=" text-[#fdfdfd] font-bold ellipse-content text-base"
                    dangerouslySetInnerHTML={{
                      __html: item.Title,
                    }}
                  />
                  <p className="text-[#aaa] text-xs truncate">
                    {item.PostTime}
                  </p>
                </span>
                <span className="w-full h-full bottom-0 bg-[linear-gradient(180deg,hsla(0,0%,4%,0),#111)] absolute"></span>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  </>
}

function RenderVerticalPosts(props: { postsArray: NewsPost[] }) {
  return <div style={{ minHeight: '850px' }}>
    <div className="flex w-full flex-wrap flex-col md:flex-row last:border-none md:border-b border-primary-darker dark:border-zinc-200">
      {props.postsArray.map((item: NewsPost, index: number) => {
        return <RenderPost key={`Post-${index}`} post={item} />;
      })}
    </div>
  </div>
}

function RenderPost(props: { post: NewsPost }) {
  let post = props.post;

  return (
    <Link
      className="flex flex-cols w-full md:w-1/2 text-left p-2.5 border-t border-primary-darker dark:border-zinc-200 "
      to={`/news/detail/${localStorage.getItem(KEY_STORAGE_LANG) || "en"}/${
        post.Slug
      }`}
    >
      {post.ImageUrl && (
        <div className="news-img w-[130px] h-[74px]">
          <LazyLoadImage
            effect="blur"
            src={post.ImageUrl}
            alt={post.Title}
            width={130}
            height={74}
            className="object-cover rounded-md h-full min-w-[130px]"
          />
        </div>
      )}
      <div className="flex-auto pl-4 relative">
        <div
          dangerouslySetInnerHTML={{ __html: post.Title }}
          className=" text-[#fdfdfd] dark:text-black font-bold ellipse-content text-base"
        />
        <div className="grid-cols-2">
          <div>
            {post.Tags?.length &&
              post.Tags[0] !== "" &&
              post.Tags.slice(0, 3).map((tagID: string, index: number) => (
                <RenderTag key={`Tag-${index}`} id={tagID} />
              ))}
          </div>
        </div>
        <span className="absolute bottom-0 px-3 z-10 whitespace-normal right-0">
          {post.PostTime && (
            <div className="text-[#aaa] text-xs max-w-[172px] truncate dark:text-black">
              {post.PostTime}
            </div>
          )}
        </span>
      </div>
    </Link>
  );
}

function RenderTag({ id }: { id: string }) {
  return (
    <span className="bg-[#333]  text-[#fdfdfd] text-[10px] rounded py-0.5 px-1.5 capitalize mr-2 inline-block max-w-[100px] truncate dark:bg-[#ccc] dark:text-black">
      {id}
    </span>
  );
}

export default withTranslation()(NewsLayout);

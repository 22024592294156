export const basketballCompetitionList = [
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
    "CategoryName": "NBA",
    "Id": "nba",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Play-offs",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/play-offs/",
        "Id": "play-offs"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Regular season",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/regular-season/",
        "Id": "regular-season"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Pre-season",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/pre-season/",
        "Id": "pre-season"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "All-Star Game",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/all-star-game/",
        "Id": "all-star-game"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "California Classic Summer League",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/california-classic-summer-league/",
        "Id": "california-classic-summer-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Salt Lake City Summer League",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/salt-lake-city-summer-league/",
        "Id": "salt-lake-city-summer-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Las Vegas Summer League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/las-vegas-summer-league-play-off/",
        "Id": "las-vegas-summer-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Las Vegas Summer League",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/las-vegas-summer-league/",
        "Id": "las-vegas-summer-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-nba.jpg",
        "LeagueName": "Las Vegas Summer League: Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/nba/las-vegas-summer-league-placement-matches/",
        "Id": "las-vegas-summer-league-placement-matches"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-ncaa.jpg",
    "CategoryName": "NCAA",
    "Id": "ncaa",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-ncaa.jpg",
        "LeagueName": "March Madness",
        "LeagueLink": "https://www.livescore.com/en/basketball/ncaa/march-madness/",
        "Id": "march-madness"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-ncaa.jpg",
        "LeagueName": "Regular Season",
        "LeagueLink": "https://www.livescore.com/en/basketball/ncaa/ncaa-men/",
        "Id": "ncaa-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-ncaa.jpg",
        "LeagueName": "National Invitation Tournament",
        "LeagueLink": "https://www.livescore.com/en/basketball/ncaa/national-invitation-tournament/",
        "Id": "national-invitation-tournament"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-euro-league.jpg",
    "CategoryName": "EuroLeague",
    "Id": "euro-league",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-euro-league.jpg",
        "LeagueName": "EuroLeague: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/euro-league/euroleague-play-off/",
        "Id": "euroleague-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-euro-league.jpg",
        "LeagueName": "EuroLeague: Regular season",
        "LeagueLink": "https://www.livescore.com/en/basketball/euro-league/euroleague-regular-season/",
        "Id": "euroleague-regular-season"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
    "CategoryName": "EuroBasket",
    "Id": "eurobasket",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/play-off/",
        "Id": "play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/group-d/",
        "Id": "group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-a/",
        "Id": "qualification-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-b/",
        "Id": "qualification-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-c/",
        "Id": "qualification-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-d/",
        "Id": "qualification-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-e/",
        "Id": "qualification-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-f/",
        "Id": "qualification-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-g/",
        "Id": "qualification-group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket.jpg",
        "LeagueName": "Qualification: group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket/qualification-group-h/",
        "Id": "qualification-group-h"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
    "CategoryName": "EuroBasket Women",
    "Id": "eurobasket-women",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/group-d/",
        "Id": "group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-a/",
        "Id": "qualification-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-b/",
        "Id": "qualification-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-c/",
        "Id": "qualification-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-d/",
        "Id": "qualification-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-e/",
        "Id": "qualification-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-f/",
        "Id": "qualification-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-g/",
        "Id": "qualification-group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-h/",
        "Id": "qualification-group-h"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group I",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-i/",
        "Id": "qualification-group-i"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-women.jpg",
        "LeagueName": "Qualification: Group J",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-women/qualification-group-j/",
        "Id": "qualification-group-j"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
    "CategoryName": "EuroBasket U18",
    "Id": "eurobasket-u18",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-play-off/",
        "Id": "division-a-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-placement-matches/",
        "Id": "division-a-placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-group-a/",
        "Id": "division-a-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-group-b/",
        "Id": "division-a-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-group-c/",
        "Id": "division-a-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division A: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-a-group-d/",
        "Id": "division-a-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-play-off/",
        "Id": "division-b-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-placement-matches/",
        "Id": "division-b-placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-a/",
        "Id": "division-b-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-b/",
        "Id": "division-b-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-c/",
        "Id": "division-b-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-d/",
        "Id": "division-b-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-e/",
        "Id": "division-b-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division B: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-b-group-f/",
        "Id": "division-b-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division C: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-c-play-off/",
        "Id": "division-c-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division C: Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-c-placement-matches/",
        "Id": "division-c-placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division C: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-c-group-a/",
        "Id": "division-c-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Division C: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/division-c-group-b/",
        "Id": "division-c-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18.jpg",
        "LeagueName": "Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18/placement-matches/",
        "Id": "placement-matches"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
    "CategoryName": "Champions League",
    "Id": "champions-league",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Qualification Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/qualification-play-off/",
        "Id": "qualification-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group I",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-i/",
        "Id": "group-i"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group J",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-j/",
        "Id": "group-j"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group K",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-k/",
        "Id": "group-k"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group L",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-l/",
        "Id": "group-l"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-d/",
        "Id": "group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-e/",
        "Id": "group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-f/",
        "Id": "group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-g/",
        "Id": "group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/group-h/",
        "Id": "group-h"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Qualification",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/qualification-2020/",
        "Id": "qualification-2020"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-champions-league.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/champions-league/play-off/",
        "Id": "play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
    "CategoryName": "FIBA Europe Cup",
    "Id": "eurochallenge",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/play-off/",
        "Id": "play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-d/",
        "Id": "group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-e/",
        "Id": "group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-f/",
        "Id": "group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-g/",
        "Id": "group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-h/",
        "Id": "group-h"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group I",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-i/",
        "Id": "group-i"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group J",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-j-2021-2022/",
        "Id": "group-j-2021-2022"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group K",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-k/",
        "Id": "group-k"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurochallenge.jpg",
        "LeagueName": "Group L",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurochallenge/group-l/",
        "Id": "group-l"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-euro-cup.jpg",
    "CategoryName": "EuroCup",
    "Id": "euro-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-euro-cup.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/euro-cup/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-euro-cup.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/euro-cup/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-euro-cup.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/euro-cup/play-off/",
        "Id": "play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
    "CategoryName": "Asia Cup",
    "Id": "asia-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-cup/play-off/",
        "Id": "play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-cup/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-cup/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-cup/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-cup.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-cup/group-d/",
        "Id": "group-d"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-adriatic.jpg",
    "CategoryName": "Adriatic",
    "Id": "adriatic",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-adriatic.jpg",
        "LeagueName": "ABA League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/adriatic/aba-league-play-off/",
        "Id": "aba-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-adriatic.jpg",
        "LeagueName": "ABA League",
        "LeagueLink": "https://www.livescore.com/en/basketball/adriatic/aba-league/",
        "Id": "aba-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-adriatic.jpg",
        "LeagueName": "ABA League: Qualification",
        "LeagueLink": "https://www.livescore.com/en/basketball/adriatic/aba-league-qualification/",
        "Id": "aba-league-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-baltic.jpg",
    "CategoryName": "Latvian-Estonian League",
    "Id": "baltic",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-baltic.jpg",
        "LeagueName": "Regular season",
        "LeagueLink": "https://www.livescore.com/en/basketball/baltic/regular-season/",
        "Id": "regular-season"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-baltic.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/baltic/play-off/",
        "Id": "play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
    "CategoryName": "BNXT League",
    "Id": "bnxt-league",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "BNXT League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/bnxt-league-play-off/",
        "Id": "bnxt-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "BNXT League: Elite Gold",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/bnxt-league-elite-gold/",
        "Id": "bnxt-league-elite-gold"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "BNXT League: Elite Silver",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/bnxt-league-elite-silver/",
        "Id": "bnxt-league-elite-silver"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "Domestic Belgium: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/domestic-belgium-play-off/",
        "Id": "domestic-belgium-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "Domestic Belgium",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/domestic-belgium/",
        "Id": "domestic-belgium"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "Domestic Netherlands: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/domestic-netherlands-play-off/",
        "Id": "domestic-netherlands-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bnxt-league.jpg",
        "LeagueName": "Domestic Netherlands",
        "LeagueLink": "https://www.livescore.com/en/basketball/bnxt-league/domestic-netherlands/",
        "Id": "domestic-netherlands"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-wnba.jpg",
    "CategoryName": "WNBA",
    "Id": "wnba",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-wnba.jpg",
        "LeagueName": "WNBA: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/wnba/wnba-play-off/",
        "Id": "wnba-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-wnba.jpg",
        "LeagueName": "WNBA: Regular season",
        "LeagueLink": "https://www.livescore.com/en/basketball/wnba/wnba-regular-season/",
        "Id": "wnba-regular-season"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-wnba.jpg",
        "LeagueName": "All-Star Game",
        "LeagueLink": "https://www.livescore.com/en/basketball/wnba/all-star-game/",
        "Id": "all-star-game"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
    "CategoryName": "Olympics Men",
    "Id": "olympics-men",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Gold Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/gold-medal/",
        "Id": "gold-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Bronze Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/bronze-medal/",
        "Id": "bronze-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Semi-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/semi-finals/",
        "Id": "semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Quarter-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/quarter-finals/",
        "Id": "quarter-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "3x3: Gold Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/3x3-gold-medal/",
        "Id": "3x3-gold-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "3x3: Bronze Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/3x3-bronze-medal/",
        "Id": "3x3-bronze-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "3x3: Semi-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/3x3-semi-finals/",
        "Id": "3x3-semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "3x3: Quarter-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/3x3-quarter-finals/",
        "Id": "3x3-quarter-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "3x3",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/3x3/",
        "Id": "3x3"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Qualification: Canada",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/qualification-canada/",
        "Id": "qualification-canada"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Qualification: Croatia",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/qualification-croatia/",
        "Id": "qualification-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Qualification: Lithuania",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/qualification-lithuania/",
        "Id": "qualification-lithuania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Qualification: Serbia",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/qualification-serbia/",
        "Id": "qualification-serbia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-men.jpg",
        "LeagueName": "Qualification: Final Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-men/qualification-final-stage/",
        "Id": "qualification-final-stage"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
    "CategoryName": "Olympics Women",
    "Id": "olympics-women",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Semi-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/semi-finals/",
        "Id": "semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Gold Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/gold-medal/",
        "Id": "gold-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Bronze Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/bronze-medal/",
        "Id": "bronze-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Quarter-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/quarter-finals/",
        "Id": "quarter-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "3x3: Gold Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/3x3-gold-medal/",
        "Id": "3x3-gold-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "3x3: Bronze Medal",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/3x3-bronze-medal/",
        "Id": "3x3-bronze-medal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "3x3: Semi-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/3x3-semi-finals/",
        "Id": "3x3-semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "3x3: Quarter-Finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/3x3-quarter-finals/",
        "Id": "3x3-quarter-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-olympics-women.jpg",
        "LeagueName": "3x3",
        "LeagueLink": "https://www.livescore.com/en/basketball/olympics-women/3x3/",
        "Id": "3x3"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
    "CategoryName": "World Cup",
    "Id": "world-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Placement matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/placement-matches/",
        "Id": "placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 2nd Round: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-second-round-group-e/",
        "Id": "qualification-africa-second-round-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 2nd Round: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-second-round-group-f/",
        "Id": "qualification-africa-second-round-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 2nd Round: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-second-round-group-e/",
        "Id": "qualification-asia-second-round-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 2nd Round: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-second-round-group-f/",
        "Id": "qualification-asia-second-round-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 1st Round: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-2nd-round-group-e/",
        "Id": "qualification-america-2nd-round-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 2nd Round: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-second-round-group-f/",
        "Id": "qualification-america-second-round-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 2nd Round: Group I",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-second-round-group-i/",
        "Id": "qualification-europe-second-round-group-i"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 2nd Round: Group J",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-second-round-group-j/",
        "Id": "qualification-europe-second-round-group-j"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 2nd Round: Group K",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-second-round-group-k/",
        "Id": "qualification-europe-second-round-group-k"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 2nd Round: Group L",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-second-round-group-l/",
        "Id": "qualification-europe-second-round-group-l"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 1st Round: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-1st-round-group-a/",
        "Id": "qualification-africa-1st-round-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 1st Round: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-1st-round-group-b/",
        "Id": "qualification-africa-1st-round-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 1st Round: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-1st-round-group-c/",
        "Id": "qualification-africa-1st-round-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Africa: 1st Round: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-africa-1st-round-group-d/",
        "Id": "qualification-africa-1st-round-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 1st Round: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-1st-round-group-a/",
        "Id": "qualification-asia-1st-round-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 1st Round: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-1st-round-group-b/",
        "Id": "qualification-asia-1st-round-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 1st Round: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-1st-round-group-c/",
        "Id": "qualification-asia-1st-round-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Asia: 1st Round: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-asia-1st-round-group-d/",
        "Id": "qualification-asia-1st-round-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 1st Round: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-1st-round-group-a/",
        "Id": "qualification-america-1st-round-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 1st Round: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-1st-round-group-b/",
        "Id": "qualification-america-1st-round-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 1st Round: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-1st-round-group-c/",
        "Id": "qualification-america-1st-round-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: America: 1st Round: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-america-1st-round-group-d/",
        "Id": "qualification-america-1st-round-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-a/",
        "Id": "qualification-europe-1st-round-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-b/",
        "Id": "qualification-europe-1st-round-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-c/",
        "Id": "qualification-europe-1st-round-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-d/",
        "Id": "qualification-europe-1st-round-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-e/",
        "Id": "qualification-europe-1st-round-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-f/",
        "Id": "qualification-europe-1st-round-group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-g/",
        "Id": "qualification-europe-1st-round-group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Qualification: Europe: 1st Round: Group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/qualification-europe-1st-round-group-h/",
        "Id": "qualification-europe-1st-round-group-h"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-d/",
        "Id": "group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group E",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-e/",
        "Id": "group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group F",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-f/",
        "Id": "group-f"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group G",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-g/",
        "Id": "group-g"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-world-cup.jpg",
        "LeagueName": "Group H",
        "LeagueLink": "https://www.livescore.com/en/basketball/world-cup/group-h/",
        "Id": "group-h"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
    "CategoryName": "Women's World Cup",
    "Id": "womens-world-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Final",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/final/",
        "Id": "final"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Third Place Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/third-place-play-off/",
        "Id": "third-place-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Semi-finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/semi-finals/",
        "Id": "semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Quarter-finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/quarter-finals/",
        "Id": "quarter-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-womens-world-cup.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/womens-world-cup/group-b/",
        "Id": "group-b"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
    "CategoryName": "Women's EuroBasket U18",
    "Id": "eurobasket-u18-women",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18-women/play-off/",
        "Id": "play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
        "LeagueName": "Division A: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18-women/division-a-group-a/",
        "Id": "division-a-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
        "LeagueName": "Division A: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18-women/division-a-group-b/",
        "Id": "division-a-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
        "LeagueName": "Division A: Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18-women/division-a-group-c/",
        "Id": "division-a-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-eurobasket-u18-women.jpg",
        "LeagueName": "Division A: Group D",
        "LeagueLink": "https://www.livescore.com/en/basketball/eurobasket-u18-women/division-a-group-d/",
        "Id": "division-a-group-d"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
    "CategoryName": "Asia U18 Championship",
    "Id": "asia-u18-championship",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Final",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/final/",
        "Id": "final"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Third Place Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/third-place-play-off/",
        "Id": "third-place-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Semi-finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/semi-finals/",
        "Id": "semi-finals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Placement matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/placement-matches/",
        "Id": "placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Quarter-finals",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/quarterfinals/",
        "Id": "quarterfinals"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-asia-u18-championship.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/basketball/asia-u18-championship/group-c/",
        "Id": "group-c"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-argentina.jpg",
    "CategoryName": "Argentina",
    "Id": "argentina",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-argentina.jpg",
        "LeagueName": "LNB",
        "LeagueLink": "https://www.livescore.com/en/basketball/argentina/lnb/",
        "Id": "lnb"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-argentina.jpg",
        "LeagueName": "LNB: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/argentina/lnb-play-off/",
        "Id": "lnb-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-argentina.jpg",
        "LeagueName": "LNB: Relegation play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/argentina/lnb-relegation-play-off/",
        "Id": "lnb-relegation-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-australia.jpg",
    "CategoryName": "Australia",
    "Id": "australia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-australia.jpg",
        "LeagueName": "NBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/australia/nbl-play-off/",
        "Id": "nbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-australia.jpg",
        "LeagueName": "NBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/australia/nbl/",
        "Id": "nbl"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-austria.jpg",
    "CategoryName": "Austria",
    "Id": "austria",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-austria.jpg",
        "LeagueName": "Superliga: Winners Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/austria/superliga-winners-stage/",
        "Id": "superliga-winners-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-austria.jpg",
        "LeagueName": "Superliga",
        "LeagueLink": "https://www.livescore.com/en/basketball/austria/superliga/",
        "Id": "superliga"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-austria.jpg",
        "LeagueName": "Superliga: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/austria/superliga-play-off/",
        "Id": "superliga-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-austria.jpg",
        "LeagueName": "Superliga: Losers Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/austria/superliga-losers-stage/",
        "Id": "superliga-losers-stage"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-belarus.jpg",
    "CategoryName": "Belarus",
    "Id": "belarus",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belarus.jpg",
        "LeagueName": "Premier League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/belarus/premier-league-play-off/",
        "Id": "premier-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belarus.jpg",
        "LeagueName": "Premier League: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/belarus/premier-league-group-a/",
        "Id": "premier-league-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belarus.jpg",
        "LeagueName": "Premier League: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/belarus/premier-league-group-b/",
        "Id": "premier-league-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belarus.jpg",
        "LeagueName": "Premier League: Placement Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/belarus/premier-league-placement-play-off/",
        "Id": "premier-league-placement-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-belgium.jpg",
    "CategoryName": "Belgium",
    "Id": "belgium",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belgium.jpg",
        "LeagueName": "Pro Basketball League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/belgium/pro-basketball-league-play-off/",
        "Id": "pro-basketball-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-belgium.jpg",
        "LeagueName": "Pro Basketball League",
        "LeagueLink": "https://www.livescore.com/en/basketball/belgium/pro-basketball-league-2020-2021/",
        "Id": "pro-basketball-league-2020-2021"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-brazil.jpg",
    "CategoryName": "Brazil",
    "Id": "brazil",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-brazil.jpg",
        "LeagueName": "NBB",
        "LeagueLink": "https://www.livescore.com/en/basketball/brazil/nbb/",
        "Id": "nbb"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-brazil.jpg",
        "LeagueName": "NBB: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/brazil/nbb-play-off/",
        "Id": "nbb-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-bulgaria.jpg",
    "CategoryName": "Bulgaria",
    "Id": "bulgaria",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bulgaria.jpg",
        "LeagueName": "NBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/bulgaria/nbl/",
        "Id": "nbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-bulgaria.jpg",
        "LeagueName": "NBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/bulgaria/nbl-play-off/",
        "Id": "nbl-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-canada.jpg",
    "CategoryName": "Canada",
    "Id": "canada",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-canada.jpg",
        "LeagueName": "NBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/canada/nbl-play-off/",
        "Id": "nbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-canada.jpg",
        "LeagueName": "NBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/canada/nbl/",
        "Id": "nbl"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-china.jpg",
    "CategoryName": "China",
    "Id": "china",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-china.jpg",
        "LeagueName": "CBA: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/china/cba-play-off/",
        "Id": "cba-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-china.jpg",
        "LeagueName": "CBA",
        "LeagueLink": "https://www.livescore.com/en/basketball/china/cba/",
        "Id": "cba"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-croatia.jpg",
    "CategoryName": "Croatia",
    "Id": "croatia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-croatia.jpg",
        "LeagueName": "Premijer liga: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/croatia/premijer-liga-play-off/",
        "Id": "premijer-liga-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-croatia.jpg",
        "LeagueName": "Premijer liga: Championship Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/croatia/premijer-liga-championship-group/",
        "Id": "premijer-liga-championship-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-croatia.jpg",
        "LeagueName": "Premijer liga",
        "LeagueLink": "https://www.livescore.com/en/basketball/croatia/premijer-liga/",
        "Id": "premijer-liga"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-croatia.jpg",
        "LeagueName": "Premijer liga: Relegation Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/croatia/premijer-liga-relegation-group/",
        "Id": "premijer-liga-relegation-group"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-cyprus.jpg",
    "CategoryName": "Cyprus",
    "Id": "cyprus",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-cyprus.jpg",
        "LeagueName": "A League",
        "LeagueLink": "https://www.livescore.com/en/basketball/cyprus/a-league/",
        "Id": "a-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-cyprus.jpg",
        "LeagueName": "A League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/cyprus/a-league-play-off/",
        "Id": "a-league-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
    "CategoryName": "Czech Republic",
    "Id": "czech-republic",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "NBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/nbl/",
        "Id": "nbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "NBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/nbl-play-off/",
        "Id": "nbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "NBL: group A1",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/nbl-group-a1/",
        "Id": "nbl-group-a1"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "NBL: group A2",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/nbl-group-a2/",
        "Id": "nbl-group-a2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "NBL: Qualification Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/nbl-qualification-play-off/",
        "Id": "nbl-qualification-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "ZBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/zbl/",
        "Id": "zbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "ZBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/zbl-play-off/",
        "Id": "zbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "ZBL: Placement matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/zbl-placement-matches/",
        "Id": "zbl-placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-czech-republic.jpg",
        "LeagueName": "ZBL: Relegation Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/czech-republic/zbl-relegation-play-off/",
        "Id": "zbl-relegation-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
    "CategoryName": "Denmark",
    "Id": "denmark",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Basket Ligaen: Winners Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/basket-ligaen-winners-stage/",
        "Id": "basket-ligaen-winners-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Basket Ligaen",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/basket-ligaen/",
        "Id": "basket-ligaen"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Basket Ligaen: Losers Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/basket-ligaen-losers-stage/",
        "Id": "basket-ligaen-losers-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Basket Ligaen: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/basket-ligaen-play-off/",
        "Id": "basket-ligaen-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Basket Ligaen: Placement Matches",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/basket-ligaen-placement-matches/",
        "Id": "basket-ligaen-placement-matches"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/cup/",
        "Id": "cup"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Dameligaen",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/dameligaen/",
        "Id": "dameligaen"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Dameligaen: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/dameligaen-play-off/",
        "Id": "dameligaen-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-denmark.jpg",
        "LeagueName": "Cup Women",
        "LeagueLink": "https://www.livescore.com/en/basketball/denmark/cup-women/",
        "Id": "cup-women"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-estonia.jpg",
    "CategoryName": "Estonia",
    "Id": "estonia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-estonia.jpg",
        "LeagueName": "KML: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/estonia/kml-play-off/",
        "Id": "kml-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
    "CategoryName": "Finland",
    "Id": "finland",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga: Championship Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/korisliiga-championship-group/",
        "Id": "korisliiga-championship-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga: Relegation Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/korisliiga-relegation-group/",
        "Id": "korisliiga-relegation-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/korisliiga/",
        "Id": "korisliiga"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/korisliiga-play-off/",
        "Id": "korisliiga-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "I Divisioona A: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/i-divisioona-a-playoff/",
        "Id": "i-divisioona-a-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "I Divisioona A",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/i-divisioona-a/",
        "Id": "i-divisioona-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "I Divisioona B: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/i-divisioona-b-playoff/",
        "Id": "i-divisioona-b-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "I Divisioona B: Relegation Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/i-divisioona-b-relegation-playoff/",
        "Id": "i-divisioona-b-relegation-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "I Divisioona B",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/i-divisioona-b/",
        "Id": "i-divisioona-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga Women: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-korisliiga-playoff/",
        "Id": "naisten-korisliiga-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga Women: Playoff Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-korisliiga-playoff-group/",
        "Id": "naisten-korisliiga-playoff-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga Women: Relegation Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-korisliiga-relegation-group/",
        "Id": "naisten-korisliiga-relegation-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Korisliiga Women",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-korisliiga/",
        "Id": "naisten-korisliiga"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Naisten I Divisioona: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-i-divisioona-playoff/",
        "Id": "naisten-i-divisioona-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Naisten I Divisioona: Relegation Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-i-divisioona-relegation-playoff/",
        "Id": "naisten-i-divisioona-relegation-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-finland.jpg",
        "LeagueName": "Naisten I Divisioona",
        "LeagueLink": "https://www.livescore.com/en/basketball/finland/naisten-i-divisioona/",
        "Id": "naisten-i-divisioona"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
    "CategoryName": "France",
    "Id": "france",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
        "LeagueName": "LNB Pro A: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/france/lnb-pro-a-play-off/",
        "Id": "lnb-pro-a-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
        "LeagueName": "LNB Pro A",
        "LeagueLink": "https://www.livescore.com/en/basketball/france/lnb-pro-a/",
        "Id": "lnb-pro-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
        "LeagueName": "Pro B: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/france/pro-b-playoff/",
        "Id": "pro-b-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
        "LeagueName": "Pro B",
        "LeagueLink": "https://www.livescore.com/en/basketball/france/pro-b-2020/",
        "Id": "pro-b-2020"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-france.jpg",
        "LeagueName": "French Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/france/french-cup/",
        "Id": "french-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-germany.jpg",
    "CategoryName": "Germany",
    "Id": "germany",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-germany.jpg",
        "LeagueName": "BBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/germany/bbl-play-off/",
        "Id": "bbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-germany.jpg",
        "LeagueName": "BBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/germany/bbl/",
        "Id": "bbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-germany.jpg",
        "LeagueName": "BBL Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/germany/bbl-cup/",
        "Id": "bbl-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-britain.jpg",
    "CategoryName": "Great Britain",
    "Id": "britain",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-britain.jpg",
        "LeagueName": "BBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/britain/bbl-play-off/",
        "Id": "bbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-britain.jpg",
        "LeagueName": "BBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/britain/bbl/",
        "Id": "bbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-britain.jpg",
        "LeagueName": "BBL Trophy",
        "LeagueLink": "https://www.livescore.com/en/basketball/britain/bbl-trophy/",
        "Id": "bbl-trophy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-britain.jpg",
        "LeagueName": "BBL Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/britain/bbl-cup/",
        "Id": "bbl-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
    "CategoryName": "Greece",
    "Id": "greece",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
        "LeagueName": "Basket League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/greece/basket-league-play-off/",
        "Id": "basket-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
        "LeagueName": "Basket League",
        "LeagueLink": "https://www.livescore.com/en/basketball/greece/basket-league/",
        "Id": "basket-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
        "LeagueName": "A2: Promotion Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/greece/a2-promotion-playoff/",
        "Id": "a2-promotion-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
        "LeagueName": "A2: Relegation Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/greece/a2-relegation-play-off/",
        "Id": "a2-relegation-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-greece.jpg",
        "LeagueName": "A2",
        "LeagueLink": "https://www.livescore.com/en/basketball/greece/a2/",
        "Id": "a2"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-hungary.jpg",
    "CategoryName": "Hungary",
    "Id": "hungary",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-hungary.jpg",
        "LeagueName": "NB I. A",
        "LeagueLink": "https://www.livescore.com/en/basketball/hungary/nbi-a/",
        "Id": "nbi-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-hungary.jpg",
        "LeagueName": "NB I. A: Championship play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/hungary/nb-i-a-championship-play-off/",
        "Id": "nb-i-a-championship-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-hungary.jpg",
        "LeagueName": "NB I. A: Placement play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/hungary/nb-i-a-placement-play-off/",
        "Id": "nb-i-a-placement-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-hungary.jpg",
        "LeagueName": "NB I. A: Relegation stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/hungary/nb-i-a-relegation-stage/",
        "Id": "nb-i-a-relegation-stage"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-indonesia.jpg",
    "CategoryName": "Indonesia",
    "Id": "indonesia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-indonesia.jpg",
        "LeagueName": "IBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/indonesia/ibl/",
        "Id": "ibl"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-israel.jpg",
    "CategoryName": "Israel",
    "Id": "israel",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-israel.jpg",
        "LeagueName": "Super League: Winners Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/israel/super-league-winners-stage/",
        "Id": "super-league-winners-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-israel.jpg",
        "LeagueName": "Super League",
        "LeagueLink": "https://www.livescore.com/en/basketball/israel/super-league/",
        "Id": "super-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-israel.jpg",
        "LeagueName": "Super League: Losers Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/israel/super-league-losers-stage/",
        "Id": "super-league-losers-stage"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
    "CategoryName": "Italy",
    "Id": "italy",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Lega Basket Serie A: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/lega-basket-serie-a-play-off/",
        "Id": "lega-basket-serie-a-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Lega Basket Serie A",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/lega-basket-serie-a/",
        "Id": "lega-basket-serie-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2: Play-offs",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-play-offs/",
        "Id": "serie-a2-play-offs"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2 Blue Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-blue-group/",
        "Id": "serie-a2-blue-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2 White Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-white-group/",
        "Id": "serie-a2-white-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2 Yellow Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-yellow-group/",
        "Id": "serie-a2-yellow-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2 Relegation Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-relegation-group/",
        "Id": "serie-a2-relegation-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2: Green",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-green/",
        "Id": "serie-a2-green"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Serie A2: Red",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a2-red/",
        "Id": "serie-a2-red"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Super Cup: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/super-cup-play-off/",
        "Id": "super-cup-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Women's Serie A1: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/womens-serie-a1-play-off/",
        "Id": "womens-serie-a1-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Women's Serie A1",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/serie-a1-women/",
        "Id": "serie-a1-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-italy.jpg",
        "LeagueName": "Women's Serie A1: Relegation Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/italy/womens-serie-a1-relegation-play-off/",
        "Id": "womens-serie-a1-relegation-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-japan.jpg",
    "CategoryName": "Japan",
    "Id": "japan",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-japan.jpg",
        "LeagueName": "B.League: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/japan/b-league-play-off/",
        "Id": "b-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-japan.jpg",
        "LeagueName": "B.League",
        "LeagueLink": "https://www.livescore.com/en/basketball/japan/b-league/",
        "Id": "b-league"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-kazakhstan.jpg",
    "CategoryName": "Kazakhstan",
    "Id": "kazakhstan",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-kazakhstan.jpg",
        "LeagueName": "National League: Pplay-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/kazakhstan/national-league-play-off/",
        "Id": "national-league-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-kazakhstan.jpg",
        "LeagueName": "National League",
        "LeagueLink": "https://www.livescore.com/en/basketball/kazakhstan/national-league/",
        "Id": "national-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-kazakhstan.jpg",
        "LeagueName": "Premier League",
        "LeagueLink": "https://www.livescore.com/en/basketball/kazakhstan/premier-league/",
        "Id": "premier-league"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-latvia.jpg",
    "CategoryName": "Latvia",
    "Id": "latvia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-latvia.jpg",
        "LeagueName": "LBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/latvia/lbl-play-off/",
        "Id": "lbl-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-lithuania.jpg",
    "CategoryName": "Lithuania",
    "Id": "lithuania",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-lithuania.jpg",
        "LeagueName": "LKL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/lithuania/lkl-play-off/",
        "Id": "lkl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-lithuania.jpg",
        "LeagueName": "LKL",
        "LeagueLink": "https://www.livescore.com/en/basketball/lithuania/lkl/",
        "Id": "lkl"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-mexico.jpg",
    "CategoryName": "Mexico",
    "Id": "mexico",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-mexico.jpg",
        "LeagueName": "LNBP",
        "LeagueLink": "https://www.livescore.com/en/basketball/mexico/lnbp/",
        "Id": "lnbp"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-mexico.jpg",
        "LeagueName": "LNBP: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/mexico/lnbp-play-off/",
        "Id": "lnbp-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-holland.jpg",
    "CategoryName": "Holland",
    "Id": "holland",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-holland.jpg",
        "LeagueName": "DBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/holland/dbl-play-off/",
        "Id": "dbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-holland.jpg",
        "LeagueName": "DBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/holland/dbl-2020-2021/",
        "Id": "dbl-2020-2021"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-holland.jpg",
        "LeagueName": "DBL: Winners Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/holland/dbl-winners-stage/",
        "Id": "dbl-winners-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-holland.jpg",
        "LeagueName": "DBL: Losers Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/holland/dbl-losers-stage/",
        "Id": "dbl-losers-stage"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-north-macedonia.jpg",
    "CategoryName": "North Macedonia",
    "Id": "north-macedonia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-north-macedonia.jpg",
        "LeagueName": "Superleague: Championship",
        "LeagueLink": "https://www.livescore.com/en/basketball/north-macedonia/superleague-championship/",
        "Id": "superleague-championship"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-north-macedonia.jpg",
        "LeagueName": "Superleague",
        "LeagueLink": "https://www.livescore.com/en/basketball/north-macedonia/superleague/",
        "Id": "superleague"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-north-macedonia.jpg",
        "LeagueName": "Superleague: Relegation",
        "LeagueLink": "https://www.livescore.com/en/basketball/north-macedonia/superleague-relegation/",
        "Id": "superleague-relegation"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
    "CategoryName": "Norway",
    "Id": "norway",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
        "LeagueName": "BLNO: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/norway/blno-play-off/",
        "Id": "blno-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
        "LeagueName": "BLNO",
        "LeagueLink": "https://www.livescore.com/en/basketball/norway/blno/",
        "Id": "blno"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
        "LeagueName": "BLNO Qualification Group",
        "LeagueLink": "https://www.livescore.com/en/basketball/norway/blno-qualification-group/",
        "Id": "blno-qualification-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
        "LeagueName": "Kvinneligaen Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/norway/kvinneligaen-playoff/",
        "Id": "kvinneligaen-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-norway.jpg",
        "LeagueName": "Kvinneligaen",
        "LeagueLink": "https://www.livescore.com/en/basketball/norway/kvinneligaen/",
        "Id": "kvinneligaen"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
    "CategoryName": "Philippines",
    "Id": "philippines",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
        "LeagueName": "Commissioner's Cup Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/philippines/commissioners-cup-playoff/",
        "Id": "commissioners-cup-playoff"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
        "LeagueName": "Commissioner's Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/philippines/commissioners-cup/",
        "Id": "commissioners-cup"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
        "LeagueName": "Philippine Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/philippines/philippine-cup/",
        "Id": "philippine-cup"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
        "LeagueName": "Governors Cup: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/philippines/governors-cup-play-off/",
        "Id": "governors-cup-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-philippines.jpg",
        "LeagueName": "Governors' Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/philippines/governors-cup/",
        "Id": "governors-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-poland.jpg",
    "CategoryName": "Poland",
    "Id": "poland",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-poland.jpg",
        "LeagueName": "Energa Basket Liga",
        "LeagueLink": "https://www.livescore.com/en/basketball/poland/energa-basket-liga/",
        "Id": "energa-basket-liga"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-poland.jpg",
        "LeagueName": "Energa Basket Liga: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/poland/energa-basket-liga-play-off/",
        "Id": "energa-basket-liga-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-poland.jpg",
        "LeagueName": "Women's Energa Basket Liga",
        "LeagueLink": "https://www.livescore.com/en/basketball/poland/energa-basket-liga-women/",
        "Id": "energa-basket-liga-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-poland.jpg",
        "LeagueName": "Women's Energa Basket Liga: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/poland/energa-basket-liga-play-off-women/",
        "Id": "energa-basket-liga-play-off-women"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-serbia.jpg",
    "CategoryName": "Serbia",
    "Id": "serbia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-serbia.jpg",
        "LeagueName": "Super League: Group A",
        "LeagueLink": "https://www.livescore.com/en/basketball/serbia/super-league-group-a/",
        "Id": "super-league-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-serbia.jpg",
        "LeagueName": "Super League: Group B",
        "LeagueLink": "https://www.livescore.com/en/basketball/serbia/super-league-group-b/",
        "Id": "super-league-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-serbia.jpg",
        "LeagueName": "Basketball League of Serbia",
        "LeagueLink": "https://www.livescore.com/en/basketball/serbia/basketball-league-of-serbia/",
        "Id": "basketball-league-of-serbia"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-slovakia.jpg",
    "CategoryName": "Slovakia",
    "Id": "slovakia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-slovakia.jpg",
        "LeagueName": "SBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/slovakia/sbl-play-off/",
        "Id": "sbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-slovakia.jpg",
        "LeagueName": "SBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/slovakia/extraliga/",
        "Id": "extraliga"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-slovenia.jpg",
    "CategoryName": "Slovenia",
    "Id": "slovenia",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-slovenia.jpg",
        "LeagueName": "Liga Nova KBM: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/slovenia/liga-nova-kbm-play-off/",
        "Id": "liga-nova-kbm-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-slovenia.jpg",
        "LeagueName": "Liga Nova KBM",
        "LeagueLink": "https://www.livescore.com/en/basketball/slovenia/liga-nova-kbm/",
        "Id": "liga-nova-kbm"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-south-korea.jpg",
    "CategoryName": "South Korea",
    "Id": "south-korea",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-south-korea.jpg",
        "LeagueName": "KBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/south-korea/kbl/",
        "Id": "kbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-south-korea.jpg",
        "LeagueName": "KBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/south-korea/kbl-play-off/",
        "Id": "kbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-south-korea.jpg",
        "LeagueName": "WKBL Women",
        "LeagueLink": "https://www.livescore.com/en/basketball/south-korea/wkbl-women/",
        "Id": "wkbl-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-south-korea.jpg",
        "LeagueName": "WKBL: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/south-korea/wkbl-play-off/",
        "Id": "wkbl-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-spain.jpg",
    "CategoryName": "Spain",
    "Id": "spain",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-spain.jpg",
        "LeagueName": "Liga ACB",
        "LeagueLink": "https://www.livescore.com/en/basketball/spain/liga-acb/",
        "Id": "liga-acb"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-spain.jpg",
        "LeagueName": "LEB Oro",
        "LeagueLink": "https://www.livescore.com/en/basketball/spain/leb-oro/",
        "Id": "leb-oro"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-spain.jpg",
        "LeagueName": "Copa del Rey",
        "LeagueLink": "https://www.livescore.com/en/basketball/spain/copa-del-rey/",
        "Id": "copa-del-rey"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-spain.jpg",
        "LeagueName": "Super Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/spain/super-cup/",
        "Id": "super-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
    "CategoryName": "Taiwan",
    "Id": "taiwan",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
        "LeagueName": "SBL: play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/taiwan/sbl-play-off/",
        "Id": "sbl-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
        "LeagueName": "SBL: 2nd Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/taiwan/sbl-2nd-stage/",
        "Id": "sbl-2nd-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
        "LeagueName": "SBL: 1st Stage",
        "LeagueLink": "https://www.livescore.com/en/basketball/taiwan/sbl-1st-stage/",
        "Id": "sbl-1st-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
        "LeagueName": "WSBL",
        "LeagueLink": "https://www.livescore.com/en/basketball/taiwan/wsbl/",
        "Id": "wsbl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-taiwan.jpg",
        "LeagueName": "WSBL: Playoff",
        "LeagueLink": "https://www.livescore.com/en/basketball/taiwan/wsbl-playoff/",
        "Id": "wsbl-playoff"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-tajikistan.jpg",
    "CategoryName": "Tajikistan",
    "Id": "tajikistan",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-tajikistan.jpg",
        "LeagueName": "National League",
        "LeagueLink": "https://www.livescore.com/en/basketball/tajikistan/national-league/",
        "Id": "national-league"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-turkey.jpg",
    "CategoryName": "Turkey",
    "Id": "turkey",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-turkey.jpg",
        "LeagueName": "BSL",
        "LeagueLink": "https://www.livescore.com/en/basketball/turkey/bsl/",
        "Id": "bsl"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-turkey.jpg",
        "LeagueName": "Cup",
        "LeagueLink": "https://www.livescore.com/en/basketball/turkey/cup/",
        "Id": "cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-ukraine.jpg",
    "CategoryName": "Ukraine",
    "Id": "ukraine",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-ukraine.jpg",
        "LeagueName": "FBU Superleague",
        "LeagueLink": "https://www.livescore.com/en/basketball/ukraine/superliga-favorit-sport/",
        "Id": "superliga-favorit-sport"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/xbb-usa.jpg",
    "CategoryName": "USA",
    "Id": "usa",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-usa.jpg",
        "LeagueName": "BIG3: Play-off",
        "LeagueLink": "https://www.livescore.com/en/basketball/usa/big3-play-off/",
        "Id": "big3-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/xbb-usa.jpg",
        "LeagueName": "BIG3",
        "LeagueLink": "https://www.livescore.com/en/basketball/usa/big3/",
        "Id": "big3"
      }
    ]
  }
]
export const soccerCompetitionList = [
  {
    id: 55555,
    logo: "/assets/flags/champions.jpg",
    name: "UEFA Champions League",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 74,
        name: "Final",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 5,
    logo: "https://www.scoreupdate.com/assets/flags/england.jpg",
    name: "England",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 92,
        name: "Premier League",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 177,
        name: "Championship",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 178,
        name: "Divison 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 106,
        name: "Divison 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 189,
        name: "Conference",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 365,
        name: "ENGCN",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 367,
        name: "ECSD",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 515,
        name: "ENG-N PR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 409,
        name: "ENG-S PR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1556,
        name: "ENG-SLCD",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 514,
        name: "ERILPD",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 348,
        name: "ENG WPR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 55,
        name: "FA Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 53,
        name: "England League Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 199,
        name: "JPT",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 259,
        name: "FA Trophy",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 545,
        name: "ENG FA WC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1128,
        name: "ENG WSL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 931,
        name: "ENG WNPL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 895,
        name: "ENG WSPL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1156,
        name: "EWSLC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 281,
        name: "ENG YFAC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 75,
        name: "ENG CS",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1407,
        name: "ENG U21",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1410,
        name: "ENG LCU23",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1408,
        name: "ENG U21D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 6,
    logo: "https://www.scoreupdate.com/assets/flags/spain.jpg",
    name: "Spain",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 85,
        name: "Primera Division",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 96,
        name: "Segunda Division",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 351,
        name: "Division D3-A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 352,
        name: "Division D3-B",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 353,
        name: "Division D3-C",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 354,
        name: "Division D3-D",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 561,
        name: "SPAD3PO",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 609,
        name: "SPA WDP",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 647,
        name: "SPA FDH",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 54,
        name: "Spanish Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 125,
        name: "SPA SC",
        priority: true,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1109,
        name: "SPA FC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 721,
        name: "SPAQC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1554,
        name: "Cata Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1635,
        name: "SPA D3-E",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1674,
        name: "SPA RFEFA",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1675,
        name: "SPA RFEFB",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 4,
    logo: "https://www.scoreupdate.com/assets/flags/germany.jpg",
    name: "Germany",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 39,
        name: "Bundesliga",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 140,
        name: "Bundesliga 2",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 732,
        name: "3. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 187,
        name: "Regional Sued",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 186,
        name: "Regional Nord",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 730,
        name: "Regional West",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1203,
        name: "Regional Bayern",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1205,
        name: "Regional Nordost",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 118,
        name: "Junioren Bundesliga Nord",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 523,
        name: "Junioren Bundesliga Sud",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 212,
        name: "Junioren Bundesliga West",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 917,
        name: "GER BJBN",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 916,
        name: "GER BJBW",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 889,
        name: "GER BJBS",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 526,
        name: "Frauen Bundesliga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 52,
        name: "Germany Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 734,
        name: "Super Cup",
        priority: true,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 603,
        name: "Junioren Bundesliga Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 558,
        name: "Woman's Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1473,
        name: "GER WD2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 2,
    logo: "https://www.scoreupdate.com/assets/flags/italy.jpg",
    name: "Italy",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 34,
        name: "Serie A",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 95,
        name: "Serie B",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 213,
        name: "C1A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 214,
        name: "C1B",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1305,
        name: "C1C",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 299,
        name: "C1 PO",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 709,
        name: "ITA YTHL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1170,
        name: "IWD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 332,
        name: "ITA Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 179,
        name: "Super Cup",
        priority: true,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 416,
        name: "Torneo Di Viareggio",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 296,
        name: "ITA PRO LC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1516,
        name: "ITA YD2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 769,
        name: "ITA YCup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1251,
        name: "Trofeo Dossena",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 1,
    logo: "https://www.scoreupdate.com/assets/flags/france.jpg",
    name: "France",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 93,
        name: "Le Championnat",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 171,
        name: "Division 2",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 295,
        name: "Nationa League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 802,
        name: "FRA D4A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 804,
        name: "FRA D4B",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 805,
        name: "FRA D4C",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 803,
        name: "FRA D4D",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 524,
        name: "WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 907,
        name: "FRA U19A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1079,
        name: "FRA U19B",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1080,
        name: "FRA U19C",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1081,
        name: "FRA U19D",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 101,
        name: "French Cup",
        priority: true,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 62,
        name: "League Cup",
        priority: true,
        currentSeason: "2019-2020",
        type: "1",
      },
      {
        id: 168,
        name: "FRA SC",
        priority: true,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 95,
    logo: "https://www.scoreupdate.com/assets/flags/netherlands.jpg",
    name: "Netherlands",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 99,
        name: "Eredivisie",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 202,
        name: "Eerste Divisie",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 622,
        name: "WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 146,
        name: "Amstel Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 83,
        name: "DSC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1409,
        name: "HOL D3",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 55556,
    logo: "/assets/flags/europa.jpg",
    name: "UEFA Europa League",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 58,
        name: "Final",
        priority: true,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 55559,
    logo: "/assets/flags/euro.jpg",
    name: "EURO Cup",
    priority: true,
    currentSeason: "2023-2024",
    type: "C",
    competitions: [
      {
        id: 87,
        name: "Final",
        priority: true,
        currentSeason: "2023-2024",
        type: "1",
      },
    ],
  },
  {
    id: 55562,
    logo: "/assets/flags/fifa-women.jpg",
    name: "FIFA Women's World Cup",
    priority: true,
    currentSeason: "2023",
    type: "1",
    competitions: [
      {
        id: 127,
        name: "Final",
        priority: true,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 55561,
    logo: "/assets/flags/fifa-u20.jpg",
    name: "FIFA U20",
    priority: true,
    currentSeason: "2023",
    type: "1",
    competitions: [
      {
        id: 132,
        name: "Final",
        priority: true,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 55563,
    logo: "http://data.7m.com.cn/database/icons/149.jpg",
    name: "World Cup 2022",
    priority: false,
    currentSeason: "2022",
    type: "1",
    competitions: [
      {
        id: 149,
        name: "Final",
        priority: true,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 81,
    logo: "https://www.scoreupdate.com/assets/flags/portugal.jpg",
    name: "Portugal",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 88,
        name: "Primera Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 253,
        name: "Liga de Honora",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 111,
        name: "Portugal Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 251,
        name: "League Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 122,
        name: "POR SC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1557,
        name: "POR LRU23",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1653,
        name: "POR TRU23",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1656,
        name: "POR LRU19",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 98,
    logo: "https://www.scoreupdate.com/assets/flags/belgium.jpg",
    name: "Belgium",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 100,
        name: "Jupiler League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 172,
        name: "2e Klasse",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1394,
        name: "BEL AD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1357,
        name: "BEL WPL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 638,
        name: "BEL WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 109,
        name: "Belgian Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 254,
        name: "BEL SC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 982,
        name: "BEL U21",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 85,
    logo: "https://www.scoreupdate.com/assets/flags/scotland.jpg",
    name: "Scotland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 76,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 236,
        name: "SCO LCH",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 237,
        name: "Division 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 238,
        name: "Division 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 596,
        name: "Reserves League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 411,
        name: "Highland Football League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 138,
        name: "League Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 145,
        name: "FA Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 228,
        name: "Bells Challenge Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1061,
        name: "SCO WPL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 83,
    logo: "https://www.scoreupdate.com/assets/flags/austria.jpg",
    name: "Austria",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 130,
        name: "Bundesliga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 201,
        name: "Erste Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 591,
        name: "D3E",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 226,
        name: "D3M",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1033,
        name: "AFB",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 150,
        name: "Austria Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 56,
    logo: "https://www.scoreupdate.com/assets/flags/serbia.jpg",
    name: "Serbia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 239,
        name: "Serbia - 1. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1032,
        name: "SER D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 283,
        name: "Serbia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 46,
    logo: "https://www.scoreupdate.com/assets/flags/turkey.jpg",
    name: "Turkey",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 180,
        name: "Super Lig",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 241,
        name: "2 Ligi A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1069,
        name: "TUR2BB",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1068,
        name: "TUR2BK",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 791,
        name: "TUR WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1074,
        name: "TUR3-1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1075,
        name: "TUR3-2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1076,
        name: "TUR3-3",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 697,
        name: "D3 Y",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 455,
        name: "TURSC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 245,
        name: "Turkey Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 96,
    logo: "https://www.scoreupdate.com/assets/flags/switzerland.jpg",
    name: "Switzerland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 133,
        name: "National Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 197,
        name: "Challenge League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 595,
        name: "D2-1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 880,
        name: "D2-2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 881,
        name: "D2-3",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 224,
        name: "Switzerland Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1605,
        name: "SUI D2P",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 47,
    logo: "https://www.scoreupdate.com/assets/flags/ukraine.jpg",
    name: "Ukraine",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 190,
        name: "Vyscha Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 588,
        name: "Ukraine Persha Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 939,
        name: "UKR U21",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 280,
        name: "Ukraine Cup",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 322,
        name: "Super Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
    ],
  },
  {
    id: 84,
    logo: "https://www.scoreupdate.com/assets/flags/czech-republic.jpg",
    name: "Czech Republic",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 181,
        name: "Gambrinus Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 357,
        name: "2. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 480,
        name: "CFL-A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 894,
        name: "MSFL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 842,
        name: "U19 liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1160,
        name: "WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 205,
        name: "Czech Republic Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1596,
        name: "CFL-B",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 7,
    logo: "https://www.scoreupdate.com/assets/flags/russia.jpg",
    name: "Russia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 165,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 309,
        name: "First Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 864,
        name: "RUS D3-2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 860,
        name: "RUS D3-1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 866,
        name: "RUS D3-3",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 867,
        name: "RUS D3-4",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 568,
        name: "RUS YCL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1017,
        name: "RUS  WPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 232,
        name: "Russia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1458,
        name: "RUS FNL",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
    ],
  },
  {
    id: 8,
    logo: "https://www.scoreupdate.com/assets/flags/romania.jpg",
    name: "Romania",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 196,
        name: "Divizia A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 512,
        name: "ROM D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 274,
        name: "Romania Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 330,
        name: "ROM SC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 45,
    logo: "https://www.scoreupdate.com/assets/flags/kazakhstan.jpg",
    name: "Kazakhstan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 469,
        name: "Super League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1050,
        name: "KAZ D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 602,
        name: "KAZ CUP",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 48,
    logo: "https://www.scoreupdate.com/assets/flags/sweden.jpg",
    name: "Sweden",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 103,
        name: "Allsvenskan",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 184,
        name: "Superettan",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 432,
        name: "Div 1 Sodra",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 427,
        name: "Div 1 Norra",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 433,
        name: "Div 2 N",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 437,
        name: "Div 2 NS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1117,
        name: "Div 2NG",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 660,
        name: "Div 2 SS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 435,
        name: "Div 2 VG",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 530,
        name: "Damallsvenskan",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 164,
        name: "Cupen",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 560,
        name: "Woman's Cupen",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1240,
        name: "SWE U21AN",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1239,
        name: "SWE U21AS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1023,
        name: "SWD WD2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 434,
        name: "SWE D2SG",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1729,
        name: "SWE U21O",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1730,
        name: "SWE U21V",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 49,
    logo: "https://www.scoreupdate.com/assets/flags/finland.jpg",
    name: "Finland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 105,
        name: "Veikkausliga",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 288,
        name: "Ykkonen",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 571,
        name: "WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 220,
        name: "Suomen Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 268,
        name: "League Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 443,
        name: "FIN D3-A",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 444,
        name: "FIN D3-B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 445,
        name: "FIN D3-C",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1691,
        name: "YKK CUP",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 50,
    logo: "https://www.scoreupdate.com/assets/flags/norway.jpg",
    name: "Norway",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 104,
        name: "Tippeligaen",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 198,
        name: "Adeccoligaen",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 343,
        name: "2 Division A",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 344,
        name: "2 Division B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 564,
        name: "Women's Tippeligaen",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 227,
        name: "NM Cupen",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 589,
        name: "WNCUP",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1482,
        name: "NORW D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 51,
    logo: "https://www.scoreupdate.com/assets/flags/poland.jpg",
    name: "Poland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 195,
        name: "1. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 302,
        name: "2. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 876,
        name: "3. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 582,
        name: "CLJ",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1132,
        name: "WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 209,
        name: "Poland Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 52,
    logo: "https://www.scoreupdate.com/assets/flags/belarus.jpg",
    name: "Belarus",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 305,
        name: "Premier League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 872,
        name: "Belarus Pershaya Liga",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 688,
        name: "BLR CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1056,
        name: "BWPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 53,
    logo: "https://www.scoreupdate.com/assets/flags/greece.jpg",
    name: "Greece",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 193,
        name: "GRE D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 249,
        name: "Greece Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1609,
        name: "GRE D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 54,
    logo: "https://www.scoreupdate.com/assets/flags/bulgaria.jpg",
    name: "Bulgaria",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 204,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1210,
        name: "BUL D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 282,
        name: "Bulgaria Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 55,
    logo: "https://www.scoreupdate.com/assets/flags/iceland.jpg",
    name: "Iceland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 293,
        name: "Premier Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 447,
        name: "D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 870,
        name: "Iceland Division 2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1014,
        name: "ICE WPR",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1113,
        name: "ICE LD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 320,
        name: "Iceland Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 819,
        name: "ICE LC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1281,
        name: "REYT",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1116,
        name: "ICE CH",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1321,
        name: "ICE CH2",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1034,
        name: "ICE WC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1001,
        name: "ICE WLC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1368,
        name: "ICE RWT",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1534,
        name: "ICE LC2",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1528,
        name: "ICE FWC",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
    ],
  },
  {
    id: 80,
    logo: "https://www.scoreupdate.com/assets/flags/hungary.jpg",
    name: "Hungary",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 221,
        name: "NB1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 583,
        name: "HUN D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 233,
        name: "Hungary Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 945,
        name: "HUN WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 929,
        name: "HUN U19",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 82,
    logo: "https://www.scoreupdate.com/assets/flags/azerbaijan.jpg",
    name: "Azerbaijan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 542,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 671,
        name: "AZE CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 86,
    logo: "https://www.scoreupdate.com/assets/flags/ireland.jpg",
    name: "Ireland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 183,
        name: "Premier League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 203,
        name: "Division 1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 185,
        name: "FAI Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 304,
        name: "League Cup",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
    ],
  },
  {
    id: 87,
    logo: "https://www.scoreupdate.com/assets/flags/georgia.jpg",
    name: "Georgia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 518,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1007,
        name: "GEO PL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 693,
        name: "GEO C",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 88,
    logo: "https://www.scoreupdate.com/assets/flags/latvia.jpg",
    name: "Latvia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 290,
        name: "LAT D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1031,
        name: "LAT D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 316,
        name: "Latvia Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 89,
    logo: "https://www.scoreupdate.com/assets/flags/lithuania.jpg",
    name: "Lithuania",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 308,
        name: "1. Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 615,
        name: "LIT Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 873,
        name: "LIT D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 90,
    logo: "https://www.scoreupdate.com/assets/flags/croatia.jpg",
    name: "Croatia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 194,
        name: "1. Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 218,
        name: "Croatia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1440,
        name: "CRO D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 91,
    logo: "https://www.scoreupdate.com/assets/flags/bosnia-and-herzegovina.jpg",
    name: "Bosnia and Herzegovina",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 421,
        name: "Premijer Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 423,
        name: "BOSC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 92,
    logo: "https://www.scoreupdate.com/assets/flags/slovakia.jpg",
    name: "Slovakia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 217,
        name: "1. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 361,
        name: "SVK D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 200,
        name: "Slovakia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 93,
    logo: "https://www.scoreupdate.com/assets/flags/estonia.jpg",
    name: "Estonia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 355,
        name: "Meistri Liiga",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 879,
        name: "EST D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1236,
        name: "D2B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1051,
        name: "WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 533,
        name: "EST Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1367,
        name: "EST WT",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 94,
    logo: "https://www.scoreupdate.com/assets/flags/denmark.jpg",
    name: "Denmark",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 131,
        name: "SAS Ligaen",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 188,
        name: "1. Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 336,
        name: "DEN D2-A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 553,
        name: "WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 745,
        name: "WD2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1057,
        name: "DEN RL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 831,
        name: "DEN U19",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 944,
        name: "DEN U17",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 151,
        name: "Denmark Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 610,
        name: "DWLWC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 97,
    logo: "https://www.scoreupdate.com/assets/flags/moldova.jpg",
    name: "Moldova",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 457,
        name: "Divizia Nationala",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 935,
        name: "MOL  D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 613,
        name: "Mol Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 99,
    logo: "https://www.scoreupdate.com/assets/flags/armenia.jpg",
    name: "Armenia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 470,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1035,
        name: "Division 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 830,
        name: "ARM  Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 100,
    logo: "https://www.scoreupdate.com/assets/flags/albania.jpg",
    name: "Albania",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 384,
        name: "Super league",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 506,
        name: "ALB Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 101,
    logo: "https://www.scoreupdate.com/assets/flags/north-macedonia.jpg",
    name: "North Macedonia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 468,
        name: "North Macedonia Prva liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 875,
        name: "North Macedonia Vtora Fudbalska Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 620,
        name: "North Macedonia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 102,
    logo: "https://www.scoreupdate.com/assets/flags/israel.jpg",
    name: "Israel",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 247,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 174,
        name: "Leumit League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 825,
        name: "ISR D3",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 248,
        name: "ISR CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 742,
        name: "ISR LATTC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 874,
        name: "ISR LLTTC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 960,
        name: "ISR WD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 103,
    logo: "https://www.scoreupdate.com/assets/flags/slovenia.jpg",
    name: "Slovenia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 219,
        name: "1. Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 673,
        name: "SLO D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 215,
        name: "Slovenia Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 104,
    logo: "https://www.scoreupdate.com/assets/flags/wales.jpg",
    name: "Wales",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 210,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 269,
        name: "Wales Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 466,
        name: "Wales League Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1603,
        name: "WAL CS",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1602,
        name: "WAL CN",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 105,
    logo: "https://www.scoreupdate.com/assets/flags/northern-ireland.jpg",
    name: "Northern Ireland",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 363,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 793,
        name: "NIR CH",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 439,
        name: "NIR CUP",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 772,
        name: "NILCUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 106,
    logo: "https://www.scoreupdate.com/assets/flags/montenegro.jpg",
    name: "Montenegro",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 521,
        name: "Prva Crnogorska Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 884,
        name: "MNE D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 689,
        name: "MNE CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 107,
    logo: "https://www.scoreupdate.com/assets/flags/cyprus.jpg",
    name: "Cyprus",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 223,
        name: "Division 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 801,
        name: "CYP D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 289,
        name: "Cyprus Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 108,
    logo: "https://www.scoreupdate.com/assets/flags/malta.jpg",
    name: "Malta",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 471,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 838,
        name: "MAL D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 991,
        name: "MWD1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 687,
        name: "MAL Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 741,
        name: "MAL SC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 109,
    logo: "https://www.scoreupdate.com/assets/flags/luxembourg.jpg",
    name: "Luxembourg",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 472,
        name: "National Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1279,
        name: "LUX Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 110,
    logo: "https://www.scoreupdate.com/assets/flags/faroe-islands.jpg",
    name: "Faroe Islands",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 552,
        name: "Formuladeildin",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1020,
        name: "Logmanssteypid",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 130,
    logo: "https://www.scoreupdate.com/assets/flags/andorra.jpg",
    name: "Andorra",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 800,
        name: "ANDSL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1176,
        name: "AND Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 175,
    logo: "https://www.scoreupdate.com/assets/flags/san-marino.jpg",
    name: "San Marino",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 473,
        name: "SMR D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1475,
        name: "SMR Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 187,
    logo: "https://www.scoreupdate.com/assets/flags/kosovo.jpg",
    name: "Kosovo",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1511,
        name: "Kos SL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 12,
    logo: "https://www.scoreupdate.com/assets/flags/argentina.jpg",
    name: "Argentina",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 108,
        name: "Super League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 477,
        name: "Primera B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 932,
        name: "ARG D2M1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 937,
        name: "ARG D2M2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 938,
        name: "ARG D2M3",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1152,
        name: "ARG Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 414,
        name: "Torneo Pentagonal de Verano",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 11,
    logo: "https://www.scoreupdate.com/assets/flags/brazil.jpg",
    name: "Brazil",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 160,
        name: "Serie A",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 360,
        name: "Serie B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 261,
        name: "Campeonato Paulista",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 262,
        name: "Campeonato Carioca",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1266,
        name: "BRA D3",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 976,
        name: "BRA PE",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 987,
        name: "BRA CP",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 977,
        name: "BRA CGD",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 984,
        name: "BRA MG",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 978,
        name: "BRA CCD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 975,
        name: "BRA PR",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 979,
        name: "BRA CBD",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 980,
        name: "BRA CGD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 985,
        name: "BRA PB",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 970,
        name: "BRA CeaD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1376,
        name: "BRA MT",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1646,
        name: "BRA PI",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1574,
        name: "BRA AM",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1287,
        name: "BRA SPB",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 949,
        name: "BRA U20",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 266,
        name: "Copa do Brasil",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1269,
        name: "BRA CP",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1220,
        name: "U20 Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 792,
        name: "BRA SPYC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1382,
        name: "BRA CV",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1038,
        name: "BRA CNF",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1306,
        name: "Copa Rio",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 9,
    logo: "https://www.scoreupdate.com/assets/flags/canada.jpg",
    name: "Canada",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1579,
        name: "CAN CPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 546,
        name: "Premier League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 700,
        name: "CAN CC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 61,
    logo: "https://www.scoreupdate.com/assets/flags/uruguay.jpg",
    name: "Uruguay",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 244,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1721,
        name: "URU Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 10,
    logo: "https://www.scoreupdate.com/assets/flags/usa.jpg",
    name: "USA",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 107,
        name: "MLS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1044,
        name: "USLC",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 835,
        name: "WPS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 569,
        name: "Open Cup",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1234,
        name: "Mobile Mini Sun Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1291,
        name: "Carolina Challenge Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1629,
        name: "NWSL CCUP",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 13,
    logo: "https://www.scoreupdate.com/assets/flags/mexico.jpg",
    name: "Mexico",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 191,
        name: "Primera Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 807,
        name: "MEX D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1204,
        name: "MEXC",
        priority: false,
        currentSeason: "2019-2020",
        type: "1",
      },
      {
        id: 981,
        name: "MEX U20",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 14,
    logo: "https://www.scoreupdate.com/assets/flags/peru.jpg",
    name: "Peru",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 315,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 15,
    logo: "https://www.scoreupdate.com/assets/flags/colombia.jpg",
    name: "Colombia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 326,
        name: "Copa Mustang A",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 686,
        name: "COL Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 812,
        name: "COL D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 16,
    logo: "https://www.scoreupdate.com/assets/flags/bolivia.jpg",
    name: "Bolivia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 424,
        name: "Liga de Futbol Prof",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1299,
        name: "BOL LC",
        priority: false,
        currentSeason: "",
        type: "1",
      },
    ],
  },
  {
    id: 62,
    logo: "https://www.scoreupdate.com/assets/flags/honduras.jpg",
    name: "Honduras",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 460,
        name: "Liga Nacional",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 57,
    logo: "https://www.scoreupdate.com/assets/flags/chile.jpg",
    name: "Chile",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 192,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 658,
        name: "Primera B",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 753,
        name: "Chile Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1263,
        name: "CHI SC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
    ],
  },
  {
    id: 58,
    logo: "https://www.scoreupdate.com/assets/flags/paraguay.jpg",
    name: "Paraguay",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 422,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1048,
        name: "PAR D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1555,
        name: "PAR Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 59,
    logo: "https://www.scoreupdate.com/assets/flags/ecuador.jpg",
    name: "Ecuador",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 358,
        name: "Copa Pilsener A",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1341,
        name: "ECU D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 60,
    logo: "https://www.scoreupdate.com/assets/flags/venezuela.jpg",
    name: "Venezuela",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 426,
        name: "Primera Division",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1153,
        name: "VENCUP",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 63,
    logo: "https://www.scoreupdate.com/assets/flags/guatemala.jpg",
    name: "Guatemala",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 462,
        name: "Liga Nacional",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 64,
    logo: "https://www.scoreupdate.com/assets/flags/costa-rica.jpg",
    name: "Costa Rica",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 461,
        name: "Primera Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 65,
    logo: "https://www.scoreupdate.com/assets/flags/el-salvador.jpg",
    name: "El Salvador",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 520,
        name: "Primera Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 194,
    logo: "https://www.scoreupdate.com/assets/flags/panama.jpg",
    name: "Panama",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1411,
        name: "PAN D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 195,
    logo: "https://www.scoreupdate.com/assets/flags/jamaica.jpg",
    name: "Jamaica",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1358,
        name: "Jam D1",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
    ],
  },
  {
    id: 17,
    logo: "https://www.scoreupdate.com/assets/flags/china.jpg",
    name: "China",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 152,
        name: "Premier League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 231,
        name: "Divison 1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 744,
        name: "Divison 2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 691,
        name: "WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 123,
        name: "FA Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1184,
        name: "Super Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 276,
        name: "CSGF",
        priority: false,
        currentSeason: "",
        type: "1",
      },
      {
        id: 833,
        name: "CSGF-W",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 581,
        name: "WFC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 385,
        name: "HK Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1311,
        name: "HK 1st Division",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 544,
        name: "HK FA Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 415,
        name: "Lunar New Year CUP",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 775,
        name: "HKSS",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 849,
        name: "CSGU18",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1271,
        name: "CSGWU18",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1323,
        name: "Mac D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1513,
        name: "CSGU20",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1421,
        name: "HK D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1625,
        name: "TPE D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1624,
        name: "TPE WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 21,
    logo: "https://www.scoreupdate.com/assets/flags/japan.jpg",
    name: "Japan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 102,
        name: "Division 1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 347,
        name: "Division 2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1294,
        name: "JPN D3",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 823,
        name: "JFL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 551,
        name: "Women Division 1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1678,
        name: "JPN WE",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 549,
        name: "Women Division 2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 158,
        name: "League Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 252,
        name: "Annual Emperor's Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 143,
        name: "JSC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 593,
        name: "Youth Sahara Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 600,
        name: "Women League Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 607,
        name: "JPN WCup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1480,
        name: "JWCLW",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 25,
    logo: "https://www.scoreupdate.com/assets/flags/korea.jpg",
    name: "Korea",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 250,
        name: "League Classic",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1244,
        name: "Korea K2 League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 680,
        name: "Korea K3 League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1628,
        name: "Korea K4 League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 846,
        name: "WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 565,
        name: "FA Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 728,
        name: "NLC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 550,
        name: "National League",
        priority: false,
        currentSeason: "2019",
        type: "2",
      },
    ],
  },
  {
    id: 79,
    logo: "https://www.scoreupdate.com/assets/flags/australia.jpg",
    name: "Australia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 339,
        name: "League A",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 661,
        name: "AUS NSW",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 519,
        name: "AUS VPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1045,
        name: "AUS VD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 821,
        name: "AUS BPL",
        priority: false,
        currentSeason: "2021",
        type: "2",
      },
      {
        id: 1010,
        name: "FFSA SL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1043,
        name: "FFSA PL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 575,
        name: "WAUS D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1028,
        name: "AUS CGP",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1027,
        name: "AUS NPLQ",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 667,
        name: "AUS VLU21",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 986,
        name: "AUS PLNS",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 765,
        name: "AUS WAL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 547,
        name: "VWPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 755,
        name: "YTH",
        priority: false,
        currentSeason: "2019-2020",
        type: "2",
      },
      {
        id: 1255,
        name: "AUS VCUP",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1037,
        name: "AUS NSWC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1303,
        name: "AUS FFA",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1328,
        name: "AUS BCL1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1200,
        name: "AUS TPL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1346,
        name: "AUS FWPU20",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1046,
        name: "Aus BCC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1353,
        name: "AUS NNSW",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1052,
        name: "AUS NSWSL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1532,
        name: "AUS QSL1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1700,
        name: "AUS QSL2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1340,
        name: "WAUS WD1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1489,
        name: "AUS NSWW",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 18,
    logo: "https://www.scoreupdate.com/assets/flags/saudi-arabia.jpg",
    name: "Saudi Arabia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 350,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 783,
        name: "KSA D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 934,
        name: "KSA D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 790,
        name: "KSA  YTH",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 690,
        name: "KSA CC",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
    ],
  },
  {
    id: 19,
    logo: "https://www.scoreupdate.com/assets/flags/iran.jpg",
    name: "Iran",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 362,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 922,
        name: "Division 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 668,
        name: "Iran Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 20,
    logo: "https://www.scoreupdate.com/assets/flags/yemen.jpg",
    name: "Yemen",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 510,
        name: "Division 1",
        priority: false,
        currentSeason: "2021",
        type: "2",
      },
    ],
  },
  {
    id: 116,
    logo: "https://www.scoreupdate.com/assets/flags/thailand.jpg",
    name: "Thailand",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 722,
        name: "THA PR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1016,
        name: "Thai League 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 902,
        name: "THA FAC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1090,
        name: "THA LC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 395,
        name: "KCTH",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 22,
    logo: "https://www.scoreupdate.com/assets/flags/malaysia.jpg",
    name: "Malaysia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 389,
        name: "Super League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 390,
        name: "Premier League",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
      {
        id: 484,
        name: "Malaysia Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 346,
        name: "FA Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1560,
        name: "MALA CC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 23,
    logo: "https://www.scoreupdate.com/assets/flags/oman.jpg",
    name: "Oman",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 404,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1217,
        name: "OMA FC",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 926,
        name: "OMA CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 72,
    logo: "https://www.scoreupdate.com/assets/flags/jordan.jpg",
    name: "Jordan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 381,
        name: "Premier League",
        priority: false,
        currentSeason: "2023-2024",
        type: "2",
      },
      {
        id: 403,
        name: "Jordan Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 729,
        name: "JOR SC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1049,
        name: "JOR D1",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
    ],
  },
  {
    id: 74,
    logo: "https://www.scoreupdate.com/assets/flags/kuwait.jpg",
    name: "Kuwait",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 396,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 942,
        name: "KUW D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 649,
        name: "Federation Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 413,
        name: "Crown Prince Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 566,
        name: "Emir Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 911,
        name: "KUW SC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
    ],
  },
  {
    id: 73,
    logo: "https://www.scoreupdate.com/assets/flags/united-arab-emirates.jpg",
    name: "United Arab Emirates",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 368,
        name: "UAE LP",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 778,
        name: "UAE D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 402,
        name: "UAE Presidents Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 760,
        name: "UAE EC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 909,
        name: "UAE SC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
    ],
  },
  {
    id: 75,
    logo: "https://www.scoreupdate.com/assets/flags/qatar.jpg",
    name: "Qatar",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 382,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 541,
        name: "QAT PC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 834,
        name: "QAT CPC",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 915,
        name: "QAT CUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1065,
        name: "QSJC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 76,
    logo: "https://www.scoreupdate.com/assets/flags/lebanon.jpg",
    name: "Lebanon",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 399,
        name: "League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 570,
        name: "LEBC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 897,
        name: "LBNEC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 77,
    logo: "https://www.scoreupdate.com/assets/flags/singapore.jpg",
    name: "Singapore",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 272,
        name: "League",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 318,
        name: "Singapore Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 78,
    logo: "https://www.scoreupdate.com/assets/flags/bahrain.jpg",
    name: "Bahrain",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 391,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 920,
        name: "Bahrain Division 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 663,
        name: "BHR Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 788,
        name: "BHR KCUP",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1541,
        name: "BHR EC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 128,
    logo: "https://www.scoreupdate.com/assets/flags/iraq.jpg",
    name: "Iraq",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 401,
        name: "IRQ D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 132,
    logo: "https://www.scoreupdate.com/assets/flags/india.jpg",
    name: "India",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1312,
        name: "IND SL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 806,
        name: "IND D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1165,
        name: "INDSC",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 133,
    logo: "https://www.scoreupdate.com/assets/flags/vietnam.jpg",
    name: "Vietnam",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 810,
        name: "D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1196,
        name: "D2",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1214,
        name: "Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1362,
        name: "VIE U21",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 135,
    logo: "https://www.scoreupdate.com/assets/flags/uzbekistan.jpg",
    name: "Uzbekistan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 723,
        name: "PFL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
      {
        id: 1133,
        name: "UZB D1A",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
      {
        id: 845,
        name: "UZB Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1289,
        name: "UZB PFLC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1591,
        name: "UZB LC",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 150,
    logo: "https://www.scoreupdate.com/assets/flags/indonesia.jpg",
    name: "Indonesia",
    type: "C",
    priority: true,
    competitions: [
      {
        id: 321,
        name: "IDN D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 1571,
        name: "IDN Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1190,
        name: "IDN PCup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 186,
    logo: "https://www.scoreupdate.com/assets/flags/myanmar.jpg",
    name: "Myanmar",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1369,
        name: "MYA D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 196,
    logo: "https://www.scoreupdate.com/assets/flags/tajikistan.jpg",
    name: "Tajikistan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1623,
        name: "TJK D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 197,
    logo: "https://www.scoreupdate.com/assets/flags/turkmenistan.jpg",
    name: "Turkmenistan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1626,
        name: "TKM YL",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 198,
    logo: "https://www.scoreupdate.com/assets/flags/bangladesh.jpg",
    name: "Bangladesh",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1446,
        name: "BAN D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 201,
    logo: "https://www.scoreupdate.com/assets/flags/mongolia.jpg",
    name: "Mongolia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 1631,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 44,
    logo: "https://www.scoreupdate.com/assets/flags/new-zealand.jpg",
    name: "New Zealand",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 376,
        name: "Championships",
        priority: false,
        currentSeason: "2020-2021",
        type: "2",
      },
      {
        id: 1722,
        name: "New Zealand Football  National League",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
    ],
  },
  {
    id: 26,
    logo: "https://www.scoreupdate.com/assets/flags/algeria.jpg",
    name: "Algeria",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 349,
        name: "Division 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 781,
        name: "ALG D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 405,
        name: "ALGC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 42,
    logo: "https://www.scoreupdate.com/assets/flags/south-africa.jpg",
    name: "South Africa",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 379,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 794,
        name: "SAFL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 534,
        name: "SAC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1002,
        name: "SAKO",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 27,
    logo: "https://www.scoreupdate.com/assets/flags/egypt.jpg",
    name: "Egypt",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 375,
        name: "Premier League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 543,
        name: "EGYCup",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 1690,
        name: "EGY LC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
    ],
  },
  {
    id: 28,
    logo: "https://www.scoreupdate.com/assets/flags/morocco.jpg",
    name: "Morocco",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 394,
        name: "Super League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 782,
        name: "MAR D2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 562,
        name: "MARCup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 43,
    logo: "https://www.scoreupdate.com/assets/flags/tunisia.jpg",
    name: "Tunisia",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 400,
        name: "Division 1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 785,
        name: "Division 2",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 685,
        name: "Tun Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 126,
    logo: "https://www.scoreupdate.com/assets/flags/libya.jpg",
    name: "Libya",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 398,
        name: "LIB D1",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 127,
    logo: "https://www.scoreupdate.com/assets/flags/ghana.jpg",
    name: "Ghana",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 764,
        name: "GHA PRL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 136,
    logo: "https://www.scoreupdate.com/assets/flags/sudan.jpg",
    name: "Sudan",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 724,
        name: "SUD PR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 151,
    logo: "https://www.scoreupdate.com/assets/flags/nigeria.jpg",
    name: "Nigeria",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 933,
        name: "NGA PR",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 155,
    logo: "https://www.scoreupdate.com/assets/flags/angola.jpg",
    name: "Angola",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 822,
        name: "Girabola League",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 159,
    logo: "https://www.scoreupdate.com/assets/flags/zimbabwe.jpg",
    name: "Zimbabwe",
    type: "C",
    priority: false,
    competitions: [
      {
        id: 871,
        name: "ZIM D1",
        priority: false,
        currentSeason: "2023",
        type: "2",
      },
    ],
  },
  {
    id: 41,
    logo: "http://data.7m.com.cn/database/icons/41.jpg",
    name: "International",
    type: "O",
    priority: true,
    competitions: [
      {
        id: 149,
        name: "World Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 97,
        name: "FIFA CWC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 291,
        name: "FIFA Confederations Cup",
        priority: false,
        currentSeason: "2017",
        type: "1",
      },
      {
        id: 132,
        name: "FIFA U20",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 169,
        name: "Men's Olympic Football Tournament",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 173,
        name: "Woman's Olympic Football Tournament",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 327,
        name: "FIFA U17 World Championship",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 300,
        name: "Toulon Tournament",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 465,
        name: "FIFA U20 Women's World Championship",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 420,
        name: "Algarve Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 260,
        name: "Granatkin Memorial Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 664,
        name: "Futsal World Cup (Preliminaries) Europe",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 756,
        name: "Futsal World Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 766,
        name: "FIFA Women's World Cup U17",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1151,
        name: "MWU FT",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1150,
        name: "WWU FT",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1254,
        name: "Guinness International Champions Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1285,
        name: "Al Kass International Cup",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 127,
        name: "FIFA Women's World Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1453,
        name: "China Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 364,
        name: "WCP-PO",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1595,
        name: "WPG",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 39,
    logo: "http://data.7m.com.cn/database/icons/39.jpg",
    name: "Europe",
    type: "O",
    priority: true,
    competitions: [
      {
        id: 87,
        name: "EURO Cup",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 74,
        name: "UEFA Champions League",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 58,
        name: "UEFA Europa League",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1667,
        name: "UEFA CL",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1530,
        name: "UEFA NL",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
      {
        id: 86,
        name: "UEFA Super Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1515,
        name: "INT PLIC",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 175,
        name: "World Cup (Preliminaries) Europe",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 182,
        name: "UEFA-EURO U21 Championship",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 464,
        name: "UEFA - EURO U21 Qualifying",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 292,
        name: "UEFA-EURO U19 Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 307,
        name: "Mediterranean Games - Football",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 284,
        name: "UEFA-EURO U17 Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 563,
        name: "Emirates Stadium Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 704,
        name: "Baltic Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 738,
        name: "Women Baltic Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 650,
        name: "Tipsport Liga",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 303,
        name: "UEFA European Women's Chamiponship",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 852,
        name: "Audi Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 467,
        name: "UEFA European Womens Chamiponship Qual. Round",
        priority: false,
        currentSeason: "2019-2021",
        type: "1",
      },
      {
        id: 329,
        name: "UEFA European Women's U19 Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 601,
        name: "UEFA European Women's U17 Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 535,
        name: "UEFA Women's Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 617,
        name: "UEFA Futsal Championship",
        priority: false,
        currentSeason: "2020-2022",
        type: "1",
      },
      {
        id: 904,
        name: "WWCPE",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 1275,
        name: "UEFA U19",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1531,
        name: "The Atlantic Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1565,
        name: "INT EL20",
        priority: false,
        currentSeason: "2022-2023",
        type: "2",
      },
    ],
  },
  {
    id: 38,
    logo: "http://data.7m.com.cn/database/icons/38.jpg",
    name: "America",
    type: "O",
    priority: false,
    competitions: [
      {
        id: 162,
        name: "America Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 114,
        name: "Concacaf Gold Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 159,
        name: "Copa Libertadores",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 216,
        name: "Conmebol - Copa Sudamericana",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 577,
        name: "Pan-American Games - Men's",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 153,
        name: "Pan-American Games - Women's",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 256,
        name: "Conmebol - Sudamericano U20",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 511,
        name: "Conmebol - Sudamericano U17",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1168,
        name: "Conmebol - Sudamericano U15",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 380,
        name: "CONCACAF Champions League",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 486,
        name: "UNCAF Nations Cup",
        priority: false,
        currentSeason: "2017",
        type: "1",
      },
      {
        id: 492,
        name: "CONCACAF Championship U20",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 528,
        name: "CONCACAF Championship U17",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 672,
        name: "Conmebol-Sudamericano Women U20",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 652,
        name: "Conmebol-Sudamericano Women U17",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 720,
        name: "CONCACAF Women Under 20",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 614,
        name: "North and Central American Olympics Qualifiers",
        priority: false,
        currentSeason: "2019-2021",
        type: "1",
      },
      {
        id: 1094,
        name: "Conmebol-Sudamericano Women",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 675,
        name: "North and Central American Olympics Quali-Woman's",
        priority: false,
        currentSeason: "2019-2020",
        type: "1",
      },
      {
        id: 695,
        name: "CONCACAF Futsal Championship",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 708,
        name: "Futsal WCPSA",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 914,
        name: "Copa Libertadores Femenino",
        priority: false,
        currentSeason: "2022",
        type: "2",
      },
      {
        id: 1093,
        name: "Women's World Cup (Qualifying) CONCACAF",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 176,
        name: "WCPCA",
        priority: false,
        currentSeason: "2021-2022",
        type: "2",
      },
      {
        id: 1136,
        name: "Copa Libertadores U20",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1242,
        name: "Central American Games",
        priority: false,
        currentSeason: "2017",
        type: "1",
      },
      {
        id: 1241,
        name: "Central American Games - Women",
        priority: false,
        currentSeason: "2017",
        type: "1",
      },
      {
        id: 1293,
        name: "SAG W",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1316,
        name: "Women C. American and Caribbean  Games",
        priority: false,
        currentSeason: "2018",
        type: "1",
      },
      {
        id: 776,
        name: "CCC",
        priority: false,
        currentSeason: "2016-2017",
        type: "1",
      },
      {
        id: 126,
        name: "WCPSA",
        priority: false,
        currentSeason: "2020-2022",
        type: "1",
      },
      {
        id: 1313,
        name: "CWCQ",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1485,
        name: "CONCACAF L",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1561,
        name: "CON  NL",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1615,
        name: "SA OLYQ",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 335,
        name: "RESU",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
    ],
  },
  {
    id: 40,
    logo: "http://data.7m.com.cn/database/icons/40.jpg",
    name: "Asia",
    type: "O",
    priority: false,
    competitions: [
      {
        id: 161,
        name: "ASIAN CUP",
        priority: false,
        currentSeason: "2021-2024",
        type: "1",
      },
      {
        id: 134,
        name: "East Asian Football Championship",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 454,
        name: "West Asian Football Federation Championship",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 139,
        name: "Asia Champions League Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 374,
        name: "AFC CUP",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 297,
        name: "Kirin Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 378,
        name: "SAFF",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 246,
        name: "Gulf Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 243,
        name: "ASEAN Football Championship(Suzuki Cup)",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 369,
        name: "Sea Games-football",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 294,
        name: "Arabian Champions League",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 370,
        name: "AFC Youth Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 758,
        name: "AFF U18",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 594,
        name: "Asean Football Federation U16 Youth Championship",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 474,
        name: "Asian Games Soccer",
        priority: false,
        currentSeason: "2018",
        type: "1",
      },
      {
        id: 483,
        name: "Asian Games Soccer Women",
        priority: false,
        currentSeason: "2018",
        type: "1",
      },
      {
        id: 113,
        name: "AFC Women's Asian Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 621,
        name: "Sea Games Womens Football",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 333,
        name: "East Asian Football Championship Women's Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 605,
        name: "AFF Woman's Championship",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 425,
        name: "AFC Women's U19 Championship",
        priority: false,
        currentSeason: "2018-2019",
        type: "1",
      },
      {
        id: 517,
        name: "Asian Women Championship U16",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 694,
        name: "AFC Futsal Championship",
        priority: false,
        currentSeason: "2020",
        type: "1",
      },
      {
        id: 507,
        name: "Olympic (Preliminaries) Asian-Woman's",
        priority: false,
        currentSeason: "2023-2024",
        type: "1",
      },
      {
        id: 757,
        name: "AFC Youth Championship U16",
        priority: false,
        currentSeason: "2019-2020",
        type: "1",
      },
      {
        id: 992,
        name: "WAWC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1194,
        name: "AFC U23",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 679,
        name: "Asian CQW",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1314,
        name: "WSAFF",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 141,
        name: "FIFA WCQL",
        priority: false,
        currentSeason: "2019-2021",
        type: "1",
      },
      {
        id: 1356,
        name: "SAFF U20",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 988,
        name: "SAGA",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1378,
        name: "WSAG",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1573,
        name: "AFF U22",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1359,
        name: "WAFF U23",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1727,
        name: "AFC Women's(U20) Championship",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
  {
    id: 37,
    logo: "http://data.7m.com.cn/database/icons/37.jpg",
    name: "Africa",
    type: "O",
    priority: false,
    competitions: [
      {
        id: 383,
        name: "African Nations Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1008,
        name: "West African Football Union Cup",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 642,
        name: "Council of East and Central Africa Football Associ",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 816,
        name: "African Nations Championship",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 258,
        name: "African Youth Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 824,
        name: "African Youth Cup U17",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 310,
        name: "CAF Champions League",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 334,
        name: "CAF Confederation Cup",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 853,
        name: "Council of East and Central Africa Football Associations Clubs Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 811,
        name: "CAF SC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 522,
        name: "Olympic (Preliminaries) African",
        priority: false,
        currentSeason: "2022-2023",
        type: "1",
      },
      {
        id: 1118,
        name: "MAAS",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1155,
        name: "WAAS",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 157,
        name: "WCPAF",
        priority: false,
        currentSeason: "2019-2021",
        type: "1",
      },
      {
        id: 1021,
        name: "CAF WNC",
        priority: false,
        currentSeason: "2021-2022",
        type: "1",
      },
      {
        id: 1058,
        name: "CAF YCQ",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1264,
        name: "COSAFA Cup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1449,
        name: "CAF CoC20",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1563,
        name: "COSAFA WCup",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1333,
        name: "UNAF U20",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
    ],
  },
  {
    id: 71,
    logo: "http://data.7m.com.cn/database/icons/71.jpg",
    name: "Oceania",
    type: "O",
    priority: false,
    competitions: [
      {
        id: 265,
        name: "WCPO",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 665,
        name: "Olympic (Preliminaries) Oceanian",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 408,
        name: "OFC Club Championship League",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 1129,
        name: "OFC U19",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1484,
        name: "OFC WU19",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
    ],
  },
  {
    id: 179,
    logo: "http://data.7m.com.cn/database/icons/179.jpg",
    name: "Beach Soccer",
    type: "O",
    priority: false,
    competitions: [
      {
        id: 612,
        name: "Beach Soccer World Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1169,
        name: "BSIC",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1185,
        name: "Copa America Beach Soccer",
        priority: false,
        currentSeason: "2018",
        type: "1",
      },
      {
        id: 1174,
        name: "Beach Soccer Lagos Cup",
        priority: false,
        currentSeason: "2017",
        type: "1",
      },
      {
        id: 1228,
        name: "BSWCPA",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1232,
        name: "Beach Soccer World Cup - CONMEBOL Qualifier",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
      {
        id: 372,
        name: "Euro Beach Soccer League",
        priority: false,
        currentSeason: "2022",
        type: "1",
      },
      {
        id: 1192,
        name: "International Beach Soccer Tourament",
        priority: false,
        currentSeason: "2015-2017",
        type: "1",
      },
      {
        id: 1611,
        name: "AWBG",
        priority: false,
        currentSeason: "2019",
        type: "1",
      },
      {
        id: 1664,
        name: "CAF Beach Soccer Africa Cup",
        priority: false,
        currentSeason: "2021",
        type: "1",
      },
      {
        id: 1262,
        name: "Asian Beach Soccer Cup",
        priority: false,
        currentSeason: "2023",
        type: "1",
      },
    ],
  },
];

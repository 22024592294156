import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { MatchHockeyDetail, SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import TabsComponent, { ITabData } from "../tabs/tabComponent";
import SummaryFootballLayout from "./summaryFootballLayout";
import MatchInforFootballLayout from "./matchInforFootballLayout";

type IRouting = {
  info?: boolean;
  summary?: boolean;

} & WithTranslation;

type IStats = {
  title?: string;
  scores?: number[];
};

type IStatsRes = {
  name?: string;
  home?: string;
  away?: string;
};

// type RenderAwaitedProps = {
//     nav: NavigateFunction,
//     params: SportParam,
// } & IRouting;

const KEY_STORAGE_LANG = "lang";
const LANG_VN = "vn";

function MatchFootballDetailLayout(props: IRouting) {

  

  const [detailData, setDetailData] = useState<any>();
  const link: any = new URL(window.location.href).searchParams.get("link");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const matchInforLink = `${link}info`

  const nav = useNavigate();
  const params = useParams<SportParam>();
  const matchId: any = params.matchId;


  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const detail = await Services.getDetailFootball(matchId, matchInforLink);
        setDetailData(detail);
        detail && setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [link, matchId]);


  const handleNavigateTo = (path: string) => {
    const qs = new URLSearchParams([["link", link || ""]]);
    const cname = params.cid?.toString();
    const coname = params.coid?.toString();

    nav(
      getNavLink(
        params.cid
          ? {
              cname,
              coname,
              team1: detailData?.HomeName,
              team2: detailData?.AwayName,
              matchId: params.matchId,
              tab: path,
            }
          : {
              matchId: params.matchId,
            },
        qs
      )
    );
  };

  const data: ITabData[] = [
    {
      name: "Info",
      path: "info",
      active: props.info,
    },
    {
      name: "Summary",
      path: "summary",
      active: props.summary,
    },
  ];

  detailData &&
    (document.title = `Soccer - ${detailData.HomeName} vs ${detailData.AwayName} - ${
      props.info ? "Info" : props.summary ? "Summary" : ""
    } `);

  return (
    <>
      {isLoading === true ? (
        <GlobalSpinner />
      ) : (
        <div>
          {detailData && (
            <CompetitionLinker
              isButton={true}
              league={detailData}
              coid={detailData?.LeagueId}
              navigate={nav}
            />
          )}
          <video
            style={{ display: "none" }}
            className="rounded-lg w-full my-6"
            controls
          >
            <source src="/docs/videos/flowbite.mp4" type="video/mp4" />
            {props.t("Your browser does not support the video tag.")}
          </video>
          {detailData && (
            <div className="sp-item flex-col">
              <div className="flex flex-row justify-center items-center w-full h-auto relative pt-3 text-center">
                <div className="flex flex-col items-center w-full">
                  <img
                    src={detailData?.HomeIcon}
                    alt={detailData?.HomeName}
                    className="h-6 w-6 sm:h-8 sm:w-8"
                  />
                  <span className="font-semibold px-3 py-4">
                    {detailData?.HomeName}
                  </span>
                </div>
                <div className="flex flex-col w-full">
                  <div className="text-white dark:text-black font-bold text-3xl">
                    {detailData?.ScoreOrTime}
                  </div>
                  <div className="text-primary text-sm my-1">
                    {detailData?.ScoreAgg}
                  </div>
                  <div className="text-primary text-sm my-1">
                    {detailData?.Status}
                  </div>
                </div>
                <div className="flex flex-col items-center w-full">
                  <img
                    src={detailData?.AwayIcon}
                    alt={detailData?.AwayName}
                    className="h-6 w-6 sm:h-8 sm:w-8"
                  />
                  <span className="font-semibold px-3 py-4">
                    {detailData?.AwayName}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="max-w-screen-xl mx-auto mt-5" />
          {detailData && (
            <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />
          )}
          {props.info && detailData && <MatchInforFootballLayout details={detailData} />}
          {props.summary && detailData && (
            <SummaryFootballLayout details={detailData} />
          )}{" "}
        </div>
      )}
    </>
  );
}
 
export default withTranslation()(MatchFootballDetailLayout);

import { Await, LoaderFunctionArgs, redirect, useNavigate } from "react-router-dom";
import { SportParam, getNavLink, MatchBasketballDetail, TEAMS_DEFAULT_IMAGE } from "../../mods/common";
import { deferHold, useAsyncValue, useDeferredLoaderData } from "../../mods/defer";
import Services from "../../mods/services";
import { GlobalSpinner } from "../globalSpinner";
import { Suspense } from "react";
import CompetitionLinker from "../competitionLinker";
import MatchInfoLayout from './matchInfoLayout'
import TeamLayout from './teamLayout'
import { WithTranslation, withTranslation } from "react-i18next";
import NoData from "../shared/NoData";

type LoaderDataType = {
    details: MatchBasketballDetail
}

type IRouting = {
    info?: boolean,
    summary?: boolean,
    table?: boolean
} & WithTranslation

export async function loader(arg: LoaderFunctionArgs) {
    const sParam = arg.params as SportParam;
    const link = new URL(arg.request.url).searchParams.get('link');
    const matchId = sParam.matchId;
    const coid = sParam.coid
    if (!matchId || !link || !coid)
        return redirect(getNavLink())

    return deferHold<LoaderDataType>(async () => ({
        details: await Services.getBasketballMatch(matchId, link),
    }));
}

function MatchDetailLayout(props: IRouting) {
    const nav = useNavigate();
    const { result } = useDeferredLoaderData<LoaderDataType>();

    function RenderAwaited() {
        let { details } = useAsyncValue<LoaderDataType>()

        const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = event.target as HTMLImageElement;
            target.src = TEAMS_DEFAULT_IMAGE;
        }

        document.title = `Basketball - ${details.HomeName} vs ${details.AwayName} - ${(props.info ? "Info" : props.summary ? "Summary" : props.table ? "Table" : "")} `;

        return <>
            {details && <CompetitionLinker
                isButton={true}
                league={details}
                coid={details.LeagueId}
                navigate={nav} />}

            <video style={{ display: 'none' }} className="rounded-lg w-full my-6" controls>
                <source src="/docs/videos/flowbite.mp4" type="video/mp4" />
                {props.t("Your browser does not support the video tag.")}
            </video>

            <div className="sp-item flex-col">
                <div className="flex flex-row justify-center items-center w-full h-auto relative pt-3 text-center">
                    <div className="flex flex-col items-center w-full">
                        <img onError={handleImageError} src={details.HomeIcon} alt={details.HomeName} className="h-9 w-9" />
                        <div className="font-semibold px-3 pt-4 h-10">{details.HomeName}</div>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="text-white dark:text-black font-bold text-3xl">{props.t(details.ScoreOrTime, details.ScoreOrTime)}</div>
                        <div className="text-primary text-sm pt-4 h-10">{props.t(details.Status)}</div>
                    </div>
                    <div className="flex flex-col items-center w-full">
                        <img onError={handleImageError} src={details.AwayIcon} alt={details.AwayName} className="h-9 w-9" />
                        <div className="font-semibold px-3 pt-4 h-10">{details.AwayName}</div>
                    </div>
                </div>
            </div>

            <div className="max-w-screen-xl mx-auto mt-5" />
            {props.info ? <TeamLayout details={details} /> : <NoData text="There is no data available" />}
            {props.info && <MatchInfoLayout details={details} />}
        </>
    }

    return <Suspense fallback={<GlobalSpinner />}>
        <Await resolve={result}>
            <RenderAwaited />
        </Await>
    </Suspense>
}

export default withTranslation()(MatchDetailLayout);
import { useEffect, useState } from "react";
import Services from "../../mods/services";
import { GlobalSpinner } from "../globalSpinner";
import { NewsPost } from "@lib/models";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import NoData from "../shared/NoData";
import NewsRelatedArticles from "./newsRelatedArticles";
import { LIMIT_NEWS_RELATED } from '../../mods/common'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const KEY_STORAGE_LANG = "lang";
const LANG_VN = "vn";
const SHARE_FACEBOOK_URL = "https://www.facebook.com/sharer/sharer.php?u=";
const SHARE_TWITTER_URL = "https://twitter.com/intent/tweet?url=";

function NewsDetail() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [relatedArticlesLoading, setRelatedArticlesLoading] = useState(true);
  const [categories, setCategories] = useState<
    { id: number; name: string; slug: string }[] | null
  >();
  const [newsContent, setNewsContent] = useState<NewsPost>();
  const [relatedArticles, setRelatedArticles] = useState<NewsPost[]>([]);
  const navigate = useNavigate();
  const params = useParams();

  const handleActiveCategory = (item: { id: number; slug: string }) =>
    navigate(`/news?category=${item.slug}`);

  const handleShareFacebook = (type: string) => {
    return type === "FB"
      ? window.open(SHARE_FACEBOOK_URL + window.location.href)
      : window.open(SHARE_TWITTER_URL + window.location.href);
  };

  useEffect(() => {
    const lang =
      localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "";
    (async () => {
      try {
        setIsLoading(true);
        const categories = await Services.getNewsCategories(lang);
        setCategories(categories);
        const detail = await Services.getNewsDetail(
          params.lang || lang,
          params.slug
        );
        setNewsContent(detail);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [localStorage.getItem(KEY_STORAGE_LANG), location.pathname]);

  useEffect(() => {
    const lang = localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "";

    if (newsContent?.Tags?.length) {
      (async () => {
        try {
          setRelatedArticles([])
          setRelatedArticlesLoading(true);
          
          const uniqueSlugs = new Set<string>();
          let newArticles: NewsPost[] = [];

          for (const tag of newsContent?.Tags || []) {
            if (newArticles.length >= LIMIT_NEWS_RELATED) break;
            const listNews = await Services.getTeamNews(tag, lang);

            for (const news of listNews) {
              if (uniqueSlugs.size >= LIMIT_NEWS_RELATED) break;
              if (!uniqueSlugs.has(news.Slug)) {
                uniqueSlugs.add(news.Slug);
                newArticles.push(news);
              }
            }
          }
          const newList = newArticles.filter(item=>item.Slug !== params.slug)
          setRelatedArticles(newList);
        } catch (error) {
          console.log(error);
        } finally {
          setRelatedArticlesLoading(false);
        }
      })();
    }
  }, [newsContent?.Tags, location.pathname]);
  document.title = `ScoreUpdate | ${newsContent?.Title.toString()}`

  return (
    <div className="md:grid-cols-9 md:grid md:gap-x-2 w-full lg:contents">
      <div className="lg:w-full flex flex-col gap-y-4 w-full md:col-span-7">
        {isLoading ? (
          <GlobalSpinner />
        ) : (
          <>
            <div className="horizontal-navigation-container">
              <Swiper
                spaceBetween={5}
                slidesPerView="auto"
                navigation
                modules={[Navigation]}
                pagination={{ clickable: true }}
              >
                {categories &&
                  categories.map((item) => (
                    <SwiperSlide
                      onClick={() => handleActiveCategory(item)}
                      key={`NEWS_CATEGORIES:${item.id}`}
                      className={`border font-bold rounded-2xl !w-fit text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer text-[#aaa] bg-[#181818] border-[#181818]  dark:border-zinc-300 dark:bg-slate-200 dark:text-black`}
                    >
                      <span>{item.name}</span>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            {newsContent ? (
              <div className="news-wrapper p-0 my-2 tab-content">
                <div className="featured-articles-wrapper p-4 mb-4">
                  <div className="my-4 flex justify-between">
                    <div className="w-full">
                      <h1
                        className=" text-[#fdfdfd] dark:text-black font-bold text-4xl leading-10"
                        dangerouslySetInnerHTML={{
                          __html: newsContent.Title.toString(),
                        }}
                      />
                      <p>{newsContent.PostTime.toString()}</p>
                      {localStorage.getItem(KEY_STORAGE_LANG) !== LANG_VN && <div className="news-content">
                        <img
                          src={newsContent.ImageUrl}
                          alt={newsContent.Title}
                          className="my-4"
                        />
                      </div>}
                    </div>
                    <div className="items-start min-w-[50px] flex justify-between mt-1">
                      <button onClick={() => handleShareFacebook("FB")}>
                        <img
                          alt="facebook-icon"
                          className="w-[20px] mx-2"
                          src="/assets/icons/Facebook.svg"
                        />
                      </button>
                      <button onClick={() => handleShareFacebook("TWITTER")}>
                        <img
                          alt="twitter-icon"
                          className="w-[20px] mx-2"
                          src="/assets/icons/Twitter.svg"
                        />
                      </button>
                    </div>
                  </div>
                  {newsContent && (
                    <div
                      className="news-content  text-[#fdfdfd] dark:text-black"
                      dangerouslySetInnerHTML={{
                        __html: newsContent.Content?.toString(),
                      }}
                    />
                  )}
                </div>
                {newsContent.Tags?.length && newsContent.Tags[0] !== "" && (
                  <>
                    <div className="border-t border-primary-darker dark:border-zinc-200  p-4">
                      <h2 className="font-bold  text-[#fdfdfd] dark:text-black">TAGS</h2>
                    </div>
                    <div className="border-t border-primary-darker dark:border-zinc-200  p-4 flex flex-wrap">
                      {newsContent.Tags.filter((i: string) => i !== "").map(
                        (item: string, index: number) => (
                          <div
                            key={`TAGS_NEWS:${index}`}
                            className="bg-[#181818] my-1 cursor-pointer text-[#aaa] text-sm rounded-2xl ml-2 px-2 py-1 dark:bg-[#ccc] dark:text-black"
                          >
                            {item}
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <NoData text="There is no data available" />
            )}
          </>
        )}
      </div>
      <div className="md:flex md:flex-col md:col-span-2">
        <div className="tab-content featured-news self-end">
          <NewsRelatedArticles data={relatedArticles.length > LIMIT_NEWS_RELATED ? relatedArticles.slice(0, LIMIT_NEWS_RELATED) : relatedArticles} isLoading={relatedArticlesLoading} />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(NewsDetail);

import { Component, ReactNode } from "react";

class ButtonFacebook extends Component<{ url: string, className?: string, svgClassName?: string }> {
	render(): ReactNode {
		return <button className={this.props.className} onClick={this.onClick}>
			<svg viewBox="0 0 300 300" className={this.props.svgClassName}>
				<defs>
					<clipPath clipPathUnits="userSpaceOnUse" id="clipPath4674">
						<path d="M 0,500 1024,500 1024,0 0,0 0,500 Z" />
					</clipPath>
				</defs>
				<g transform="translate(-33.466291,-429.48076)">
					<g transform="matrix(1.1165523,0,0,-1.1165523,-103.48743,863.08638)">
						<g clipPath="url(#clipPath4674)">
							<g transform="translate(375.7163,120.5527)">
								<path d="m 0,0 c 8.134,0 14.73,6.596 14.73,14.73 l 0,237.434 c 0,8.137 -6.596,14.731 -14.73,14.731 l -237.433,0 c -8.137,0 -14.73,-6.594 -14.73,-14.731 l 0,-237.434 c 0,-8.134 6.593,-14.73 14.73,-14.73 L 0,0 Z"
									style={{ fill: "#2aa4f4", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }} />
							</g>
							<g transform="translate(307.7046,120.5527)">
								<path d="m 0,0 0,103.355 34.693,0 5.194,40.28 -39.887,0 0,25.717 c 0,11.662 3.238,19.609 19.962,19.609 l 21.33,0.01 0,36.026 c -3.69,0.49 -16.351,1.587 -31.081,1.587 -30.753,0 -51.806,-18.771 -51.806,-53.244 l 0,-29.705 -34.781,0 0,-40.28 34.781,0 L -41.595,0 0,0 Z"
									style={{ fill: "#ffffff", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }} />
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
	}

	onClick = () => {
		window.open(this.props.url, "_blank");
	}
}
export default ButtonFacebook;
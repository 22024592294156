import React, { Component } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { CompetitionGroup, CompetitionInfo, getNavLink, ROUTING, SportParam } from "../mods/common";
import Services from "../mods/services";
import { GlobalSpinner } from "./globalSpinner";
import { WithTranslation, withTranslation } from "react-i18next";
import { convertToSlug } from "../utils/ConvertSlug";

const DefaultShowCount = 15;

type Props = {
    params: SportParam
    route: string
    router: any
    isMobile?: boolean
    handleShowHideMenu?: ()=>void;
} & WithTranslation

type State = {
    error?: any
    loading: boolean
    showPriority: boolean
    showPriorityCo: boolean
    searchMode: boolean
    search?: string
    active?: CompetitionGroup
    activeCo?: CompetitionInfo
}

class CompetitionList extends Component<Props, State> {
    private groups?: CompetitionGroup[];
    private searchInt = 0
    private searchInput: React.RefObject<HTMLInputElement> = React.createRef();

    state: State = {
        loading: true,
        showPriority: true,
        showPriorityCo: true,
        searchMode: false
    }

    handleClearSearch() {
        this.searchInt++;
        const inp: React.MutableRefObject<HTMLInputElement> = {
            current: this.searchInput.current!
        };
        inp.current.value = '';

        this.setState(st => ({
            loading: st.loading,
            showPriority: st.showPriority,
            showPriorityCo: st.showPriorityCo,
            searchMode: false,
            active: undefined,
            activeCo: undefined
        }))
    }

    setSearch(val: string) {
        this.searchInt++;

        ((search: string, si: number) => {
            setTimeout(() => {
                if (si === this.searchInt) {
                    this.setState(st => ({
                        loading: st.loading,
                        showPriority: st.showPriority,
                        showPriorityCo: st.showPriorityCo,
                        searchMode: !!search,
                        search: search,
                        active: undefined,
                        activeCo: undefined
                    }))
                }
            }, 300)
        }).call(this, val, this.searchInt);
    }

    handleTogglePriority = () => {
        this.setState(st => ({
            loading: st.loading,
            showPriority: !st.showPriority,
            showPriorityCo: st.showPriorityCo,
            searchMode: false,
            active: st.active,
            activeCo: st.activeCo
        }))
    }

    handleTogglePriorityCo = () => {
        this.setState(st => ({
            loading: st.loading,
            showPriority: st.showPriority,
            showPriorityCo: !st.showPriorityCo,
            searchMode: false,
            active: st.active,
            activeCo: st.activeCo
        }))
    }

    selectCompetition(activeCo: CompetitionInfo) {
        this.setState(st => ({
            loading: st.loading,
            showPriority: st.showPriority,
            showPriorityCo: st.showPriorityCo,
            searchMode: false,
            active: st.active,
            activeCo: activeCo
        }))

        const link = getNavLink({
            cid: this.state.active!.id,
            cname: convertToSlug(this.state.active!.name),
            coid: activeCo.id,
            coname: convertToSlug(activeCo.name),
        })
        
        this.props.router.navigate(link);
        typeof this.props.handleShowHideMenu === 'function' && this.props.handleShowHideMenu()
    }

    handleClearGroup = () => {
        this.selectGroup();
    }

    selectGroup(active?: CompetitionGroup) {
        this.setState(st => ({
            loading: st.loading,
            showPriority: st.showPriority,
            showPriorityCo: st.showPriorityCo,
            searchMode: false,
            active: active,
            activeCo: undefined
        }))
    }

    updateActive(o?: SportParam | number, pCoId?: number) {
        if (this.groups) {
            const cid = (typeof o === 'object') ? (o.cid ? parseInt(o.cid) : undefined) : o;
            const coid = (typeof o === 'object') ? (o.coid ? parseInt(o.coid) : undefined) : pCoId;

            const active = cid ? this.groups.find((v) => v.id === cid) : undefined
            const activeCo = active ? active.competitions.find((v) => v.id === coid) : undefined

            let showPriorityCo = this.state.showPriorityCo;
            if (activeCo && !activeCo.priority && this.state.showPriorityCo) {
                showPriorityCo = false;
            }

            this.setState(st => ({
                loading: false,
                searchMode: st.searchMode,
                showPriority: st.showPriority,
                showPriorityCo: showPriorityCo,
                active: active,
                activeCo: activeCo
            }))
        }
    }

    getServicesSport(route: string) {
        switch (route) {
            case ROUTING.SOCCER:
                // return Services.getCompetitionList()
                return Services.getSoccerCompetitionList()
            case ROUTING.BASKETBALL:
                return Services.getBasketballCompetitionList()
            case ROUTING.HOCKEY:
                return Services.getHockeyCompetitionList()
            default:
                console.log(`unknown route ${route}`);
                return new Promise((resolve, reject) => { resolve(new Map<string, any>()) });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { params, router } = this.props;
        const { location } = router || {}
        const newcid = params?.cid || location?.state?.cid || undefined
        const newcoid = params?.coid || location?.state?.coid || undefined
        const cid =  newcid && parseInt(newcid)
        const coid = newcoid && parseInt(newcoid)

        if (this.groups) {
            if ((prevProps.params.cid !== this.props.params.cid) || (prevProps.params.coid !== this.props.params.coid)) {
                if (cid || coid) {
                    if ((cid !== this.state.active?.id) || (coid !== this.state.activeCo?.id)) {
                        this.updateActive(cid, coid);
                    }
                }
            }

            if ((location?.pathname === '/' || location?.pathname === '/soccer') && this.state.activeCo?.id) {
                this.updateActive(undefined, undefined);
            }
        }
    }

    componentWillUnmount(): void {
        this.searchInt++;
    }

    componentDidMount(): void {
        const sports = this.getServicesSport(this.props.route)

          sports.then((v: any) => {
            this.groups = v;
            this.updateActive(this.props.params);
        }, (err: any) => {
            this.setState({ error: err })
        })
    }

    static filterCompetitions(state: State, isMobile: any) {
        let competitions = state.active!.competitions;
        if (competitions) {
            const arr = competitions.filter(v => (state.showPriorityCo) ? v.priority : true);
            const countDefaultList = (isMobile ? 10 : DefaultShowCount)
            if (arr.length < countDefaultList) {
                return arr.concat(competitions.filter(v => (state.showPriorityCo) ? !v.priority : false).slice(0, countDefaultList - arr.length));
            } else {
                return arr;
            }
        }
        return [];
    }

    static filterCountries(groups: CompetitionGroup[], state: State, isMobile: any) {
        const s = state.search?.toLowerCase();
        if (s && state.searchMode) {
            return groups.filter(v => v.name.toLowerCase().indexOf(s) >= 0);
        }
        const arr = groups.filter(v => (state.showPriority) ? v.priority : true);
        const countDefaultList = (isMobile ? 10 : DefaultShowCount)
        if (arr.length < countDefaultList) {
            return arr.concat(groups.filter(v => (state.showPriority) ? !v.priority : false).slice(0, countDefaultList - arr.length));
        }
        return arr;
    }

    render(): React.ReactNode {
        let translateSearch = this.props.t("Search");
        return <>
            {(!this.state.loading && this.state.active) ?
                <button onClick={this.handleClearGroup} className="flex items-center h-10 mt-1 mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-primary-lighter dark:fill-black rotate-180">
                        <path d="M9.00003 20C8.80228 19.9999 8.60898 19.9413 8.44457 19.8314C8.28016 19.7215 8.15202 19.5653 8.07635 19.3826C8.00068 19.1999 7.98088 18.9989 8.01945 18.8049C8.05802 18.611 8.15322 18.4328 8.29303 18.293L14.586 12L8.29303 5.70698C8.11087 5.51838 8.01008 5.26578 8.01236 5.00358C8.01464 4.74138 8.1198 4.49057 8.30521 4.30516C8.49062 4.11975 8.74143 4.01458 9.00363 4.01231C9.26583 4.01003 9.51843 4.11082 9.70703 4.29298L16.707 11.293C16.8945 11.4805 16.9998 11.7348 16.9998 12C16.9998 12.2651 16.8945 12.5195 16.707 12.707L9.70703 19.707C9.51954 19.8945 9.26523 19.9999 9.00003 20Z"></path>
                    </svg>
                    <img src={this.state.active.logo} alt={this.state.active.name} className="w-7 ml-3 max-h-[28px]" />
                    <span className=" text-primary-lighter dark:text-black font-semibold text-lg pl-3 truncate w-[64%]">{this.state.active.name}</span>
                </button>
                :
                <div className="relative rounded-lg shadow-sm">
                    <div className="flex items-center h-10 mb-3">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-primary h-6 w-6">
                                <path d="M21.5301 20.47L17.6891 16.629C19.1452 14.8997 19.8771 12.6747 19.7321 10.4186C19.5871 8.16256 18.5764 6.04965 16.9109 4.52091C15.2454 2.99217 13.0538 2.16574 10.7936 2.21411C8.53334 2.26247 6.37913 3.1819 4.78054 4.78048C3.18196 6.37906 2.26253 8.53328 2.21417 10.7935C2.1658 13.0537 2.99224 15.2453 4.52097 16.9108C6.04971 18.5763 8.16262 19.587 10.4187 19.732C12.6748 19.877 14.8997 19.1451 16.6291 17.689L20.4701 21.53C20.6123 21.6625 20.8003 21.7346 20.9946 21.7312C21.1889 21.7278 21.3743 21.6491 21.5117 21.5116C21.6491 21.3742 21.7278 21.1889 21.7313 20.9945C21.7347 20.8002 21.6626 20.6122 21.5301 20.47ZM3.75009 11C3.75009 9.56611 4.17529 8.1644 4.97193 6.97214C5.76857 5.77989 6.90087 4.85063 8.22563 4.3019C9.5504 3.75316 11.0081 3.60959 12.4145 3.88933C13.8209 4.16908 15.1127 4.85957 16.1266 5.8735C17.1405 6.88743 17.831 8.17926 18.1108 9.58562C18.3905 10.992 18.2469 12.4497 17.6982 13.7745C17.1495 15.0992 16.2202 16.2315 15.028 17.0282C13.8357 17.8248 12.434 18.25 11.0001 18.25C9.07792 18.2479 7.23508 17.4834 5.8759 16.1242C4.51672 14.765 3.7522 12.9222 3.75009 11Z" />
                            </svg>
                        </div>
                        <input ref={this.searchInput} onChangeCapture={(e) => this.setSearch(e.currentTarget.value)} type="text" name="searchBox" id="searchBox" className="dark:bg-slate-100 block w-full rounded-lg border-0 items-center pl-10 pt-2 pb-2 bg-main-bg-lighter dark:bg-slate-100 text-primary placeholder:font-medium focus:ring-2 focus:ring-accent" placeholder={translateSearch} />
                        <button className={(this.state.searchMode ? "" : "hidden ") + "absolute inset-y-0 right-0 flex items-center pr-2"} onClick={this.handleClearSearch.bind(this)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" className="fill-primary h-4 w-4">
                                <path d="M16.203 0c0.44 0 0.797 0.357 0.797 0.797 0 0.184-0.062 0.353-0.166 0.487l-0.068 0.077-7.139 7.14 7.14 7.14c0.143 0.143 0.233 0.343 0.233 0.563 0 0.44-0.357 0.797-0.797 0.797-0.22 0-0.42-0.089-0.563-0.234l-7.14-7.139-7.14 7.14c-0.115 0.115-0.266 0.194-0.433 0.223l-0.13 0.010c-0.44 0-0.797-0.357-0.797-0.797 0-0.22 0.089-0.42 0.234-0.563l7.139-7.14-7.14-7.14-0.067-0.077c-0.104-0.134-0.166-0.303-0.166-0.487 0-0.44 0.357-0.797 0.797-0.797 0.184 0 0.353 0.062 0.487 0.166l0.077 0.068 7.14 7.139 7.14-7.14 0.076-0.067c0.134-0.104 0.303-0.166 0.487-0.166z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            }
            <hr className="md:block hidden border-primary-darker dark:border-zinc-200  dark:border-zinc-200 mb-5" />
            <div className={`${!this.props.isMobile ? "flex md:block flex-col" : "block md:flex md:flex-col relative z-10 bg-main-bg dark:bg-white max-h-[calc(100vh_-_55vh)] overflow-auto"}`}>
                {(this.state.loading) ?
                    <GlobalSpinner />
                    :
                    (this.state.active) ?
                        CompetitionList.filterCompetitions(this.state, this.props.isMobile).map(v => {
                            const isActive = (this.state.activeCo ? this.state.activeCo.id === v.id : false);
                            return <button onClick={() => this.selectCompetition(v)} key={v.id} className="flex items-center mb-4 pl-2 w-full">
                                <img src={this.state.active!.logo} alt={this.state.active!.name} className="w-8 max-h-[20px] min-h-[20px] min-w-[30px] max-w-[30px] rounded" />
                                <span className={(isActive ? "text-accent font-semibold" : " text-primary-lighter dark:text-black") + " pl-3 truncate"}>{v.name}</span>
                            </button>
                        })
                        :
                        CompetitionList.filterCountries(this.groups!, this.state, this.props.isMobile).map(v =>
                            <button onClick={() => this.selectGroup(v)} key={v.id} className="flex items-center mb-4">
                                <img src={v.logo} alt={v.name} className="w-8 max-h-[20px] min-h-[20px] min-w-[30px] max-w-[30px] rounded" />
                                <span className=" text-primary-lighter dark:text-black pl-3 truncate w-full">{v.name}</span>
                            </button>
                        )}
            </div>
            {(!this.state.searchMode) ?
                (this.state.active) ?
                    <button className="rounded-lg bg-accent  text-primary-lighter text-lg font-semibold w-full p-3 mt-1"
                        onClick={this.handleTogglePriorityCo}
                    >{this.props.t(this.state.showPriorityCo ? "View All" : "Less")}</button>
                    :
                    <button className="rounded-lg bg-accent  text-primary-lighter text-lg font-semibold w-full p-3 mt-1"
                        onClick={this.handleTogglePriority}
                    >{this.props.t(this.state.showPriority ? "View All" : "Less")}</button>
                :
                <></>
            }
        </>
    }
}

export const withRouter = (Component: React.JSXElementConstructor<any>) => {
    const ComponentWithRouterProp = (props: any) => {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();

      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

export default withTranslation()(withRouter(CompetitionList));
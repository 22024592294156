import { NavigateFunction } from "react-router-dom";
import { memo } from "react";
import CompetitionLinker from "../competitionLinker";
import CricketMatchListItem from "./cricketMatchListItem";
import { CompetitionCricketMatches, MatchesCricket } from "@lib/models";

type Props = {
    navigate: NavigateFunction,
    league: CompetitionCricketMatches,
    limit?: number
}

function CricketMatchList(props: Props) {
    const league = props.league;
    const matches = props.limit ? league.Matches.slice(0, props.limit) : league.Matches;
    return <div>
        <CompetitionLinker
            isButton={true}
            league={league}
            navigate={props.navigate} />

        {matches.map((match: MatchesCricket, index: number) => {
            return <div
                className="flex flex-col gap-y-3 mb-1"
                key={`CricketMatchList-${match.Id}-${index}`}
            >
                <CricketMatchListItem
                    navigate={props.navigate}
                    match={match}
                    coid={league.LeagueName}
                    cid={league.CategoryName}
                />
            </div>
        })}
    </div>
}

export default memo(CricketMatchList)
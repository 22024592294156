
import { padDate, padHour, padMinute } from "@lib/tools";

import { FixtureInfo } from "./common";
import { GameStatus } from "@lib/models";
import { Params } from "react-router-dom";
import { convertToSlug } from "../utils/ConvertSlug";
import { Fixture } from "./common";



export const API_BASE = "https://www.scoreupdate.com/api";
// export const API_BASE = (process.env.NODE_ENV === "development") ? "https://localhost:3303/api" : "/api";
export const TEAM_ASSET_BASE = (process.env.NODE_ENV === "development") ? "https://localhost:3000/assets/teams/" : "/assets/teams/";
export const TEAM_ASSET_EXT = "jpg"
export const TEAMS_DEFAULT_IMAGE = "/assets/teams/defaultImage.svg"

export const TIME_WAIT_SPINNER = 500;
export const TIME_RELOAD_PAGE = 60000;

export const LIMIT_NUMBER = 3
export const LIMIT_NEWS_RELATED = 10

export interface SoccerFixtureUpdaters {
    [key: string]: Updater<Fixture | any> | undefined
}

export class Updater<T> {
    private curr: T;

    constructor(current: T, private setter: (val: T) => void) {
        this.curr = current;
    }
    current() { return this.curr };
    setValue(val: T) {
        this.curr = val;
        this.setter(val);
    }
}

export const ROUTING = {
    SOCCER: "soccer",
    BASKETBALL: "basketball",
    HOCKEY: "hockey",
    TENNIS: "tennis",
    CRICKET: "cricket"
}

export const BASKETBALL = {
    OVERVIEW: "overview",
    MATCHES: "matches",
    FIXTURES: "fixtures",
    RESULTS: "results",
    INFO: "info",
    TABLE: "table"
}

export const HOCKEY = {
    OVERVIEW: "overview",
    MATCHES: "matches",
    FIXTURES: "fixtures",
    RESULTS: "results",
    INFO: "info",
    SUMMARY: "summary",
    TABLE: "table"
}

type ITypeRange = {
    [key: string]: {
        value: string;
        colorClass: string;
    };
}

export const TYPE_RANGE: ITypeRange = {
    "5": {
      value: "Champions League",
      colorClass: "bg-blue-500",
    },
    "8": {
      value: "Europa League",
      colorClass: "bg-yellow-600",
    },
    "4": {
        value: "Relegation play-off",
        colorClass: "bg-[#aaa]"
    },
    "6": {
        value: "Champions League qualification",
        colorClass: "bg-[#6002ee]"
    },
    "2": {
      value: "Relegation",
      colorClass: "bg-red-600",
    },
    "1": {
        value: "Promotion",
        colorClass: "bg-[#23df8c]"
    }
  };


export const SOCCER_TABS = {
    INFO: "info",
    SUMMARY: "summary"
}

export enum TIME_HOCKEY_MODE {
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    OT = "OT"
}

export enum RESULTS_MATCH {
    WIN = "W",
    LOSE = "L",
    DRAW = "D"
}
 
export const TABLE_OPTION = {
    ALL: "All",
    HOME: "Home",
    AWAY: "Away"
}

export type {
    Teams,
    Fixture,
    NewsSport,
    Standings,
    FixtureInfo,
    StandingInfo,
    TeamDetailInfo,
    FixtureDetails,
    CompetitionInfo,
    CompetitionGroup,
    CompetitionFixture,
    CompetitionSchedule,
    CompetitionStanding,
    CompetitionFixtureGroup,
} from "../../../server/src/mods/soccerService/_common"

export { GameStatus } from "@lib/models";

export const LiveDate = "live"

export interface SportParam extends Params, SportParamType {
}

type SportParamType = {
    cid?: string  // country
    cname?: string  // country name
    coid?: string  // competition
    coname?: string  // competition name
    page?: string
    date?: string
    matchId?: string
    matchName?: string
    tab?: string
    team1?: string
    team2?: string
    teamId?: any
    teamName?: string
}

type SportParamNavType = {
    cid?: string | number, // country
    cname?: string  // country name
    coid?: string | number, // competition
    coname?: string  // competition name
    page?: string
    date?: string
    matchId?: string | number,
    matchName?: string
    tab?: string
    team1?: string
    team2?: string
    teamId?: number
    teamName?: string
}

function appendQuery(link: string, qs?: URLSearchParams) {
    if (qs)
        return `${link}?${qs.toString()}`;
    return link;
}

export function getNavLink(params?: SportParamType | SportParamNavType, qs?: URLSearchParams) {
    if (!params) {
        return appendQuery('/', qs);
    };

    let fParam = '';
    let sParam = ''
    if (params.cid && params.coid && params.cname && params.coname) {
        fParam = `/${params.cname}/${params.coname}/overview/${params.cid}/${params.coid}`

        if (params.page) {
            sParam = `/${params.page}`
        }
    } 

    if (params.matchId && params.team1 && params.team2 && params.cname && params.coname) {
        fParam = `/${convertToSlug(params.cname)}/${convertToSlug(params.coname)}/${convertToSlug(
            params.team1
          )}-vs-${convertToSlug(params.team2)}/${params.matchId}${params.cid && params.coid ? `/${params.cid}/${params.coid}` : ''}/${params.tab || SOCCER_TABS.SUMMARY}`

        if (params.page) {
            sParam = `/${params.page}`
        }
    }

    if (params.teamId && params.teamName) {
        fParam = `/${convertToSlug(params.teamName)}/${params.teamId}/${params.cid}/${params.coid}/${params.tab || 'overview'}`

        if (params.page) {
            sParam = `/${params.page}`
        }
    }
    
    if (params.date) {
        fParam = `/${params.date}`
    }

    return appendQuery(`/${window.location.pathname.split('/')[1] || ROUTING.SOCCER}${fParam}${sParam}`, qs);
}

export function eventListener(self: any, handler: ((evt: Event) => void)): EventListenerObject {
    return {
        handleEvent: (ev: Event) => {
            handler.call(self, ev);
        }
    }
}
export function customEventListener<T>(self: any, handler: ((evt: CustomEvent<T>) => void)): EventListenerObject {
    return {
        handleEvent: (ev: CustomEvent<T>) => {
            handler.call(self, ev);
        }
    }
}

function ensureMinute(minute: number) {
    if (minute < 0)
        return 0;
    return Math.floor(minute / 1000 / 60);
}

export function shortDateDisplay(date: Date) {
    return `${padDate(date.getDate())} ${date.toLocaleDateString('default', { month: 'short' }).toLocaleUpperCase()}`;
}

function displayMinute(serverTime: number, liveTime: number, plusMinute: number, maxMinute: number) {
    let minute = plusMinute + ensureMinute(serverTime - liveTime);
    let outTime = minute - maxMinute;
    return outTime > 0 ? `${(minute - outTime)}+${outTime}"` : `${minute}"`
}

export function timeDisplay(v: FixtureInfo | any, serverTime?: number) {
    let date = new Date(v.date);
    if (date.getMinutes() === 59) {
        date.setMinutes(date.getMinutes() + 1);
    }

    if (v.liveStatus) {
        switch (v.liveStatus) {
            case GameStatus.FirstHalf:
                if (v.liveTime && serverTime) {
                    return displayMinute(serverTime, v.liveTime, 0, 45);
                }
                break;

            case GameStatus.SecondHalf:
                if (v.liveTime && serverTime) {
                    return displayMinute(serverTime, v.liveTime, 45, 90);
                }
                return "HT";

            case GameStatus.FirstExtraTime:
                if (v.liveTime && serverTime) {
                    return displayMinute(serverTime, v.liveTime, 0, 15);
                }
                return "ET";

            case GameStatus.SecondExtraTime:
                if (v.liveTime && serverTime) {
                    return displayMinute(serverTime, v.liveTime, 15, 30);
                }
                return "HT - ET"

            case GameStatus.PenaltyShootout:
                return `PEN"`

            case GameStatus.FullTime:
                return "FT"

            case GameStatus.HalfTime:
                return "HT"

            case GameStatus.FullTimeExtra:
                return "FT - ET"

            case GameStatus.HalfExtraTime:
                return "HT - ET"

            case GameStatus.FullTimePenalty:
                return "FT - PEN"

            case GameStatus.Postponed:
                return "Post."

            case GameStatus.Cancelled:
                return "Canc."

            case GameStatus.Abandoned:
                return "Abnd."
        }
    } else {
        var dtCheck = new Date(date.valueOf())
        dtCheck.setMinutes(dtCheck.getMinutes() + 90);
        if (dtCheck < new Date()) {
            return "FT";
        }
    }

    return `${padHour(date.getHours())}:${padMinute(date.getMinutes())}`
}


export type {
    CompetitionBasketballMatches, CompetitionHockeyMatches, CompetitionCricketMatches, CompetitionTennisMatches,
    MatchesBasketball, MatchesHockey, MatchesCricket, MatchesTennis,
    MatchHockeyDetail, MatchBasketballDetail, MatchCricketDetail, MatchTennisDetail, MatchesFootball , MatchesFootballDetail,
    SummaryHockeyDetail, LeagueHockeyDetail, LeagueBasketballDetail, TeamInfo, LineupPlayer, LeagueHockeyDetailTable,
     HistorysTeamDetail, FixturesTeamDetail, FixtureGroup, MatchesTeamDetail, LineUpMatch, LeagueBasketballDetailTable, ITeamDetailNews ,

} from '@lib/models'

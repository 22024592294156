import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
	text: string
} & WithTranslation

class NoData extends Component<Props>{
	render(): React.ReactNode {
		return <div className="sp-item mt-2">
			<div className="text-center w-full p-2">
				<span>{this.props.t(this.props.text)}</span>
			</div>
		</div>
	}
}

export default withTranslation()(NoData);
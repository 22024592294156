import { Await, LoaderFunctionArgs, NavigateFunction, redirect, useNavigate, useParams } from "react-router-dom"
import { BASKETBALL, SportParam, getNavLink, LeagueBasketballDetail } from "../../mods/common"
import { deferHold, useAsyncValue, useDeferredLoaderData } from "../../mods/defer"
import CompetitionDetailTable from './competitionDetailTable'
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import MatchListItem from "./matchListItem";
import NoData from "../shared/NoData";
import Services from "../../mods/services"
import { Suspense } from "react";
import TabsComponent from '../tabs/tabComponent'
import TextLinker from "../textLinker";
import { LeagueDetailType } from "@lib/models";
import { convertToSlug } from "../../utils/ConvertSlug";

type LoaderDataType = {
    league: LeagueBasketballDetail | undefined
}


type IData = {
    name: string,
    path: string,
    childs?: IData[]
    active?: boolean
    hidden?: boolean
}

export async function loader(arg: LoaderFunctionArgs) {
    const sParam = arg.params as SportParam;
    const link = new URL(arg.request.url).searchParams.get('link');
    if (!sParam.coid)
        return redirect(getNavLink())

    return deferHold<LoaderDataType>(async () => ({
        league: await Services.getBasketballLeague(sParam.coid || "", link || ""),
    }));
}

export default function CompetitionLayout(props: { table?: boolean, fixtures?: boolean, results?: boolean }) {
    const params = useParams<SportParam>();
    const nav = useNavigate();
    const { result } = useDeferredLoaderData<LoaderDataType>();

    return <Suspense fallback={<GlobalSpinner />}>
        <Await resolve={result}>
            <RenderAwaited nav={nav} params={params} table={props.table} fixtures={props.fixtures} results={props.results} />
        </Await>
    </Suspense>
}

function RenderAwaited(props: { nav: NavigateFunction, params: SportParam, table?: boolean, fixtures?: boolean, results?: boolean }) {
    const { league } = useAsyncValue<LoaderDataType>()

    const overviewMode = !props.fixtures && !props.table && !props.results

    const data: IData[] = [
        {
            name: BASKETBALL.OVERVIEW,
            path: '',
            active: !props.fixtures && !props.table && !props.results
        },
        {
            name: BASKETBALL.MATCHES,
            path: 'fixtures',
            active: props.fixtures || props.results,
            childs: [
                {
                    name: BASKETBALL.FIXTURES,
                    path: "fixtures",
                    active: props.fixtures
                },
                {
                    name: BASKETBALL.RESULTS,
                    path: "results",
                    active: props.results
                },
            ],
        },
        {
            name: BASKETBALL.TABLE,
            path: 'table',
            active: props.table,
            hidden: !Object.keys(league!.Table).length
        },
    ]

    const handleNavigateTo = (path: string) => {
        if (props.params?.cid && props.params?.coid) props.nav(`/${window.location.pathname.split('/')[1]}/${convertToSlug(props.params?.cid)}/${convertToSlug(props.params?.coid)}/${path || 'overview'}`);
    }

    if (league) {
        document.title = `Basketball - ${league.LeagueName}`;

        function renderMatchListItems(matches?: LeagueDetailType[], limit?: number) {
            if (!matches?.length) {
                return <NoData text="There is no data available" />
            }
            if (limit) {
                matches = matches.slice(0, limit);
            }
            return matches.map((match: LeagueDetailType, index: number) => {
                return <div className="flex flex-col gap-y-3 mb-1" key={`$match-${index}`}>
                    <MatchListItem
                        navigate={props.nav}
                        cid={props.params.cid}
                        coid={props.params.coid}
                        matchesBasketball={match} />
                </div>
            })
        }

        return <>
            <CompetitionLinker
                isButton={false}
                league={league}
                navigate={props.nav} />

            <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />

            {overviewMode && <TextLinker text="FIXTURES" className="font-bold text-sm text-primary" link={"fixtures"} navigate={props.nav} isTextLink={true} />}
            {(overviewMode || props.fixtures) && renderMatchListItems(league.Fixtures, overviewMode ? 3 : undefined)}

            {overviewMode && <TextLinker text="RESULTS" className="font-bold text-sm text-primary" link={"results"} navigate={props.nav} isTextLink={true} />}
            {(overviewMode || props.results) && renderMatchListItems(league.Results, overviewMode ? 3 : undefined)}

            {overviewMode  && !!Object.keys(league.Table).length &&  <TextLinker text="TABLE" className="font-bold text-sm text-primary" link={"table"} navigate={props.nav} isTextLink={true} />}
            { overviewMode && !!Object.keys(league.Table).length &&  <CompetitionDetailTable data={league.Table} isOverview={!props.table} /> }
            { props.table && !!Object.keys(league.Table).length && <CompetitionDetailTable data={league.Table} isOverview={!props.table} /> }
        </>
    } else {
        return <NoData text="There is no data available" />;
    }
}
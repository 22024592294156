import { LeagueItem } from "@lib/models";
import { NavigateFunction } from "react-router-dom";
import { convertToSlug } from "../utils/ConvertSlug";

type Props = {
    navigate: NavigateFunction
    league: LeagueItem & { CategoryName: string } | any
    isButton: boolean
    coid?: string
}

function CompetitionLinker(props: Props) {
    let league = props.league;

    function onClick() {
        props.navigate(`/${window.location.pathname.split('/')[1]}/${convertToSlug(league.CategoryName)}/${convertToSlug(league.LeagueName || league.Id)}/overview?link=${(league.LeagueLink)}`);
    }

    return <div className="flex items-center justify-between py-4">
        {(props.isButton ?
            <><div className="flex items-center">
                <button className="border border-primary-darker dark:border-zinc-200  dark:border-zinc-200 rounded-full p-3" onClick={onClick}>
                    <img src={league.LeagueIcon} alt={league.LeagueIcon} className="w-8" />
                </button>
                <button className="flex flex-col pl-3" onClick={onClick}>
                    <span className="font-semibold text-lg  text-primary-lighter dark:text-black dark:text-black">{league.LeagueName}</span>
                    <span>{league.CategoryName}</span>
                </button>

            </div>
                <button onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-primary-lighter dark:fill-black ">
                        <path d="M9.00003 20C8.80228 19.9999 8.60898 19.9413 8.44457 19.8314C8.28016 19.7215 8.15202 19.5653 8.07635 19.3826C8.00068 19.1999 7.98088 18.9989 8.01945 18.8049C8.05802 18.611 8.15322 18.4328 8.29303 18.293L14.586 12L8.29303 5.70698C8.11087 5.51838 8.01008 5.26578 8.01236 5.00358C8.01464 4.74138 8.1198 4.49057 8.30521 4.30516C8.49062 4.11975 8.74143 4.01458 9.00363 4.01231C9.26583 4.01003 9.51843 4.11082 9.70703 4.29298L16.707 11.293C16.8945 11.4805 16.9998 11.7348 16.9998 12C16.9998 12.2651 16.8945 12.5195 16.707 12.707L9.70703 19.707C9.51954 19.8945 9.26523 19.9999 9.00003 20Z" />
                    </svg>
                </button>
            </>
            :
            <div className="flex items-center">
                <div className="border border-primary-darker dark:border-zinc-200  rounded-full p-3">
                    <img src={league.LeagueIcon} alt={league.LeagueIcon} className="w-8" />
                </div>
                <div className="flex flex-col pl-3">
                    <span className="font-semibold text-lg  text-primary-lighter dark:text-black dark:text-black">{league.LeagueName}</span>
                    <span>{league.CategoryName || "Others"}</span>
                </div>
            </div>
        )}
    </div>
}

export default CompetitionLinker;
import { MatchesTennis } from "@lib/models";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { getNavLink } from "../../mods/common";

type Props = {
    navigate: NavigateFunction
    match: MatchesTennis
    showDate?: boolean
    cid?: string
    coid?: string
}

function TennisMatchListItem(props: Props) {
    const { t } = useTranslation();
    let match = props.match;

    let parseScores = function (text?: string[]): number[][] {
        return text ? text.map((scores: string, indexScores: number) => {
            return scores.split('\n').map((score: string, indexScore: number) => {
                return parseFloat(score);
            })
        }) : []
    }

    let homeScores = parseScores(match.HomeScore?.split(';') || []);
    let awayScores = parseScores(match.AwayScore?.split(';') || []);

    let isT1Win = parseFloat(match.HomeScore || "0") > parseFloat(match.AwayScore || "0");
    let isT2Win = parseFloat(match.AwayScore || "0") > parseFloat(match.HomeScore || "0");
    let classWin = "text-primary-lighter dark:text-black text-lg";
    let classLose = "text-primary text-lg";

    const homeNames = match.HomeName.split(';');
    const awayNames = match.AwayName.split(';');

    function renderStatus(status: string) {
        return <div className="w-24 text-center self-center">
            {t(status, status)}
        </div>
    }

    function renderBallIcon(isShow: boolean) {
        return isShow
            ? <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35 35"
                width="35"
                height="35"
                className="absolute top-1/2 -left-2 w-3 h-3 origin-center -translate-x-1/2 -translate-y-1/2"
            >
                <g transform="translate(-351.84448,-605.54242)">
                    <g transform="matrix(0.22808152,0,0,0.22800807,271.59526,494.49358)">
                        <path d="m 505.12336,568.95132 c -2.83631,42.28675 -39.45045,74.1952 -81.73721,71.42336 -42.28676,-2.83631 -74.25967,-39.45045 -71.3589,-81.80167 2.8363,-42.22229 39.45045,-74.19521 81.67274,-71.3589 42.28676,2.83631 74.25968,39.45045 71.42337,81.73721" style={{ "fill": "#e8e000" }} />
                        <path style={{ fill: "#ffffff" }} d="M 19.8125 0.40625 C 18.034562 0.49526787 16.58438 1.5115418 15.8125 2.4375 C 14.930349 3.4957379 11.032921 12.368687 10.3125 14.25 C 9.577375 16.131311 7.8119791 19.433042 5.5625 21.34375 C 3.1218863 23.460224 0.5625 21.21875 0.5625 21.21875 C 1.0476819 23.540992 3.3351037 24.08578 5.6875 22.96875 C 8.0251942 21.837025 10.930351 16.618329 11.8125 14.53125 C 12.694649 12.444169 15.579337 5.8937384 16.84375 3.21875 C 18.093461 0.55845672 20.59375 0.4375 20.59375 0.4375 C 20.327268 0.40994152 20.066491 0.39353316 19.8125 0.40625 z M 34.875 15.5 C 34.875 15.5 32.536954 22.348371 25.40625 27.375 C 18.30495 32.416327 15.03125 34.8125 15.03125 34.8125 L 16.625 34.96875 L 26 28.4375 C 26.882149 27.790798 35.933579 21.89352 34.875 15.5 z " transform="matrix(4.3843973,0,0,4.3858097,351.84446,487.03908)" />
                    </g>
                </g>
            </svg>
            : null;
    }

    function renderName(names: string[]) {
        return names.map((t: string, index: number) => {
            return <div className={`text-left`} key={`T2-${names}-${index}`}>{t}</div>
        })
    }

    function onButtonDetailClicked() {
        let cid = (props.cid || "");
        let coid = (props.coid || "");
        let matchID = (props.match.Id);
        let qs = new URLSearchParams([['link', props.match.Link]])
        
        props.navigate(getNavLink(cid ? {
            cname: cid,
            coname: coid,
            team1: homeNames[0]?.toString(),
            team2: awayNames[0]?.toString(),
            matchId: matchID,
        } : {
            matchId: matchID
        }, qs));
    }

    function renderScores(currentScores: number[][], compareScores: number[][]) {
        let list = [];
        for (let index = 0; index < Math.min(5, currentScores.length); index++) {
            if (index < currentScores.length) {
                let currentLower = currentScores[index][0] || 0;
                let currentUpper = currentScores[index][1] || 0;
                let compareLower = compareScores[index][0] || 0;
                let compareUpper = compareScores[index][1] || 0;
                let isWin = currentLower + currentUpper > compareLower + compareUpper;
                list.push(<div
                    key={`renderScores-${currentScores}-${index}`}
                    className={(isWin ? classWin : classLose)} >
                    {currentLower}
                    {currentUpper ? <sup className="-top-3 text-sm">{currentScores[index][1]}</sup> : <></>}
                </div>);
            } else {
                list.push(<div key={`renderScores-${currentScores}-${index}`}></div>)
            }
        }
        return list;
    }

    function renderLivePoint(value?: string) {
        return <div className="text-orange-500 font-medium">{match.LiveAwayScore}</div>
    }

    return <button
        className={`flex flex-row bg-main-bg-lighter dark:bg-slate-200 rounded-lg py-2  text-primary-lighter dark:text-black before:border-l-8 before:h-full before:absolute relative before:top-0 before:left-0 before:rounded-r-lg cursor-pointer hover:bg-[#333] ${match.IsLive ? `before:border-orange-500 ` : "before:border-transparent"}`}
        onClick={onButtonDetailClicked}>

        {renderStatus(match.StatusOrTime)}

        <div className="grid-rows-2 w-full">
            { /** team 1 */}
            <div className={`grid grid-cols-2 content-center`}>
                <div className={`relative grid ${isT1Win ? classWin : classLose}`}>
                    {renderBallIcon(match.BallIcon === "Home")}
                    {renderName(homeNames)}
                </div>
                <div className="grid grid-cols-5 text-center content-center">
                    {renderScores(homeScores, awayScores)}
                    {renderLivePoint(match.LiveHomeScore)}
                </div>
            </div>

            {(homeNames.length > 1 && <hr className="border-primary-darker dark:border-zinc-200  my-2 mx-2" />)}

            { /** team 2 */}
            <div className={`grid grid-cols-2 content-center`}>
                <div className={`relative grid ${isT2Win ? classWin : classLose}`}>
                    {renderBallIcon(match.BallIcon === "Away")}
                    {renderName(awayNames)}
                </div>
                <div className="grid grid-cols-5 text-center content-center">
                    {renderScores(awayScores, homeScores)}
                    {renderLivePoint(match.LiveAwayScore)}
                </div>
            </div>
        </div>
    </button>
}

export default memo(TennisMatchListItem);

import { MatchesCricket } from "@lib/models";
import { memo } from "react";
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from "react-router-dom";
import { getNavLink } from "../../mods/common";

type Props = {
    navigate: NavigateFunction
    match: MatchesCricket
    cid?: string
    coid?: string
    highlightName?: boolean,
}

function CricketMatchListItem(props: Props) {
    const { t } = useTranslation();

    function renderPhase(phase: string) {
        const phases = phase.split('\n');

        return <div className="flex flex-row w-full text-sm">
            <div className="flex-auto text-left">{phases[0] || ""}</div>
            <div className="text-right w-32">{phases[1] || ""}</div>
        </div>
    }

    function renderStatus(status: string) {
        const classHighlight = status !== "FT" ? "text-orange-500" : "";
        return <div className={`text-left text-sm ${classHighlight}`}>
            {t(status, status)}
        </div>
    }

    function renderIcon(url?: string) {
        return url ? <img
            alt="icons"
            src={url}
            className="self-center w-4 h-4 mr-2"
        /> : null;
    }

    function renderName(name: string) {
        return <div className={`flex-auto text-lg text-left self-center ${props.highlightName ? "text-white dark:text-black" : ""}`}>
            {name}
        </div>
    }

    function renderScore(teamScore?: string) {
        if (teamScore) {
            const scores = teamScore.match(/\d+(\/\d+)?(\.?\d+)?/gi);
            if (scores) {
                return <div className="text-right">
                    <div className="w-32 text-lg text-white dark:text-black inline-block">{scores[0]}</div>
                    {scores[1] ? <div className="pl-2 text-sm inline-block">({scores[1]})</div> : null}
                </div>;
            }
        }
        return null;
    }

    function renderComment(comment: string) {
        return <div className="text-left text-sm">{comment}</div>
    }

    function onButtonDetailClicked(match: MatchesCricket) {
        let cid = (props.cid || "");
        let coid = (props.coid || "");
        let matchID = encodeURIComponent(props.match.Id);
        let qs = new URLSearchParams([['link', props.match.Link]])
        
        props.navigate(getNavLink(cid ? {
            cname: cid,
            coname: coid,
            matchId: matchID,
            team1: props.match?.HomeName,
            team2: props.match?.AwayName,
            tab: "info"
        } : {
            matchId: matchID
        }, qs));
    }

    const match = props.match;
    return <button
        className={`relative bg-main-bg-lighter dark:bg-slate-200 rounded-lg w-full my-2 text-primary py-2 before:border-l-4 before:absolute ${match.IsLive ? `before:border-orange-500 before:rounded-r-lg before:h-full before:left-0 before:origin-center before:top-1/2 before:-translate-y-1/2` : "before:border-transparent"}`}
        onClick={() => match.Id ? onButtonDetailClicked(match) : {}}>

        <div className="flex flex-col px-6">
            {renderPhase(match.Phase)}
            {renderStatus(match.StatusOrTime)}

            <div className="flex flex-row w-full">
                {renderIcon(match.HomeIcon)}
                {renderName(match.HomeName)}
                {renderScore(match.HomeScore)}
            </div>

            <div className="flex flex-row w-full">
                {renderIcon(match.AwayIcon)}
                {renderName(match.AwayName)}
                {renderScore(match.AwayScore)}
            </div>

            {renderComment(match.StatusComment)}
        </div>
    </button>
}

export default memo(CricketMatchListItem);

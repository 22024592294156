import { memo } from "react";
import {
  MatchHockeyDetail,
  TIME_HOCKEY_MODE,
  SummaryHockeyDetail,
} from "../../mods/common";
import NoData from "../shared/NoData";

type Props = {
  details: any;
};

const SummaryFootballLayout = ({ details }: Props) => {


  if (!details.Summary) {
    return <NoData text="There is no data available" />;
  }
  return (
    <div className="tab-content p-0 overflow-hidden">
      {details.Summary.map((item: any, index: number) => (
        <div key={`SUMMARY_HOCKEY:${index}`}>
          {item.Time in TIME_HOCKEY_MODE ? (
            <div className="text-sm min-h-[38px] bg-main-bg-lighter dark:bg-slate-200 items-center border-b flex flex-nowrap justify-between relative border-primary-darker dark:border-zinc-200  dark:border-zinc-200">
              <span className="whitespace-nowrap flex items-center justify-center w-[50px]">
                {item.Time}
              </span>
              <span className="whitespace-nowrap flex-1 overflow-hidden text-ellipsis text-right"></span>
              <div className="flex  text-[#fdfdfd] dark:text-black flex-shrink-0 flex w-[80px] font-bold justify-center text-center">
                <span className="whitespace-nowrap">{item.Score}</span>
              </div>
              <span className="whitespace-nowrap flex-1 overflow-hidden text-ellipsis"></span>
            </div>
          ) : (
            <div className="text-sm items-center flex text-[#aaa] whitespace-nowrap justify-between relative border-b border-primary-darker dark:border-zinc-200  dark:border-zinc-200">
              <span className="items-center flex justify-center h-[50px] min-w-[50px] w-[50px]">
                {item.Time}
              </span>
              <div className="justify-end flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap">
                {item.HomePenalty && (
                  <>
                    <div className="text-right flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-right">
                        {item.HomePenalty}
                      </span>
                  
                    </div>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <span className="relative w-[14px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="14"
                          name="FootballYellowCard"
                        >
                          <rect
                            width="10"
                            height="14"
                            x="2"
                            rx="2"
                            transform="translate(-1.68)"
                            fill="#FFCE00"
                            fill-rule="evenodd"
                          ></rect>
                        </svg>
                      </span>
                    </div>
                  </>
                )}
                {item?.HomeAssists && item?.HomeGoal && (
                  <>
                    <div className="text-right flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap  text-right ">
                        {item?.HomeGoal}
                      </span>
                      <span className="  text-gray-500  text-xs dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-right">
                        {item?.HomeAssists}
                      </span>
                    </div>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        name="FootballGoal"
                      >
                        <g
                          transform="translate(.63)"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle>
                          <path
                            fill="#222"
                            d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col text-center w-[36px]  text-[#fdfdfd] dark:text-black font-bold">
                {item.Score && <span>{item.Score}</span>}
              </div>
              <div className="flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap pl-1">
                {item.AwayPenalty && (
                  <>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <span className="relative w-[14px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="14"
                          name="FootballYellowCard"
                        >
                          <rect
                            width="10"
                            height="14"
                            x="2"
                            rx="2"
                            transform="translate(-1.68)"
                            fill="#FFCE00"
                            fill-rule="evenodd"
                          ></rect>
                        </svg>
                      </span>
                    </div>
                    <div className="text-left flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-left">
                        {item.AwayPenalty}
                      </span>
                    </div>
                  </>
                )}

                {item?.AwayAssists && item?.AwayGoal && (
                  <>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        name="FootballGoal"
                      >
                        <g
                          transform="translate(.63)"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <circle cx="7" cy="7" r="6" fill="#FDFDFD"></circle>
                          <path
                            fill="#222"
                            d="M7 0h.28H7a6.8 6.8 0 01.56.02l.14.01.25.03h.02l.04.01.22.04.13.02.14.03.13.03.24.06.08.03.23.07.04.01.53.2.03.01c.95.42 1.8 1.03 2.47 1.8a5.96 5.96 0 01.62.82l.08.13.06.1c.42.7.72 1.49.87 2.32l.02.07a7.47 7.47 0 01.07 1.85 6.97 6.97 0 01-.94 2.9l-.03.05-.08.13.1-.18-.2.34-.03.04-.37.5-.04.03c-.12.16-.26.3-.4.45l-.05.05-.12.12-.4.35-.07.05-.19.15-.04.02v.01l-.25.17.25-.18a7.3 7.3 0 01-1.67.9l-.05.02-.22.08-.06.02a5.9 5.9 0 01-.37.1l-.16.04a6.95 6.95 0 01-3.11.01l-.06-.01-.15-.04-.09-.02-.03-.01a6.16 6.16 0 01-.24-.07l-.09-.03-.2-.07-.06-.02a7.96 7.96 0 01-.24-.1h-.03c-.5-.22-.96-.48-1.38-.79h-.01l-.04-.03-.2-.16.24.18a6.66 6.66 0 01-.82-.7l-.05-.04a6.47 6.47 0 01-.4-.45l-.04-.04A6.97 6.97 0 01.03 7.66a7.5 7.5 0 010-1.34l.02-.13.01-.11.04-.27.02-.11c.16-.82.45-1.59.87-2.28l.06-.1.08-.13A6.94 6.94 0 014.22.58l.04-.02.51-.2.06-.01.23-.08.06-.01.25-.07c.05 0 .09-.02.13-.03l.14-.03.13-.02L6 .07h.06L6.3.03h.14A3.85 3.85 0 017 0zm1.88 1.3L7.44 2.45a.7.7 0 01-.8.05l-.08-.05L5.12 1.3a6 6 0 00-2.96 2.16l.65 1.72a.7.7 0 01-.2.78L2.54 6 1 7.02v.2a5.96 5.96 0 001.14 3.29l1.83-.09a.7.7 0 01.68.43l.03.09.49 1.77a5.94 5.94 0 003.66 0l.49-1.77a.7.7 0 01.62-.51h.09l1.84.08A5.96 5.96 0 0013 7.02l-1.54-1.01a.7.7 0 01-.3-.75l.03-.08.65-1.72A6.01 6.01 0 008.88 1.3zM7.4 4.5l1.84 1.33c.24.18.35.5.25.79l-.7 2.16a.7.7 0 01-.66.48H5.86a.7.7 0 01-.66-.48l-.7-2.16a.7.7 0 01.25-.78L6.59 4.5a.7.7 0 01.82 0z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div className="text-left flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-left">
                        {item.AwayGoal}
                      </span>
                      <span className="  text-gray-500  text-xs dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-left">
                        {item.AwayAssists}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(SummaryFootballLayout);

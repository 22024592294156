"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(cache, ttl, fn) {
    if (cache && (Date.now() - cache.time < cache.ttl)) {
        return cache;
    }
    return {
        promise: fn(),
        time: Date.now(),
        ttl: ttl
    };
}
exports.default = default_1;

export const tennisCompetitionList = [
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
    "CategoryName": "Australian Open",
    "Id": "australian-open",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Men's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/mens-singles/",
        "Id": "mens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Women's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/womens-singles/",
        "Id": "womens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Men's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/mens-doubles/",
        "Id": "mens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Women's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/womens-doubles/",
        "Id": "womens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Mixed Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/mixed-doubles/",
        "Id": "mixed-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Men's Qualifiers",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/mens-qualifiers/",
        "Id": "mens-qualifiers"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Women's Qualifiers",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/womens-qualifiers/",
        "Id": "womens-qualifiers"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Australian Open Juniors, Boys",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/australian-open-juniors-boys/",
        "Id": "australian-open-juniors-boys"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-australian-open.jpg",
        "LeagueName": "Australian Open Juniors, Girls",
        "LeagueLink": "https://www.livescore.com/en/tennis/australian-open/australian-open-juniors-girls/",
        "Id": "australian-open-juniors-girls"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
    "CategoryName": "French Open",
    "Id": "french-open",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Men's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/mens-singles/",
        "Id": "mens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Women's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/singles-women/",
        "Id": "singles-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Men's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/mens-doubles/",
        "Id": "mens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Women's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/womens-doubles/",
        "Id": "womens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Mixed Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/mixed-doubles/",
        "Id": "mixed-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Men's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/qualification-men/",
        "Id": "qualification-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "Women's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/qualification-women/",
        "Id": "qualification-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "French Open Juniors, Boys",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/roland-garros-juniors-boys/",
        "Id": "roland-garros-juniors-boys"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-french-open.jpg",
        "LeagueName": "French Open Juniors, Girls",
        "LeagueLink": "https://www.livescore.com/en/tennis/french-open/roland-garros-juniors-girls/",
        "Id": "roland-garros-juniors-girls"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
    "CategoryName": "Wimbledon",
    "Id": "wimbledon",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Men's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/mens-singles/",
        "Id": "mens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Women's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/womens-singles/",
        "Id": "womens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Men's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/mens-doubles/",
        "Id": "mens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Women's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/womens-doubles/",
        "Id": "womens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Mixed Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/mixed-doubles/",
        "Id": "mixed-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Men's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/mens-qualification/",
        "Id": "mens-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Women's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/womens-qualification/",
        "Id": "womens-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Juniors: Girls",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/juniors-girls/",
        "Id": "juniors-girls"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wimbledon.jpg",
        "LeagueName": "Juniors: Boys",
        "LeagueLink": "https://www.livescore.com/en/tennis/wimbledon/juniors-boys/",
        "Id": "juniors-boys"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
    "CategoryName": "US Open",
    "Id": "us-open",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Men's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/mens-singles/",
        "Id": "mens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Women's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/womens-singles/",
        "Id": "womens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Men's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/mens-doubles/",
        "Id": "mens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Women's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/womens-doubles/",
        "Id": "womens-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Mixed Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/mixed-doubles/",
        "Id": "mixed-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Men's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/mens-qualification/",
        "Id": "mens-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Women's Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/womens-qualification/",
        "Id": "womens-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Juniors: Boys",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/juniors-boys/",
        "Id": "juniors-boys"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Juniors: Boys",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/juniors-boys-doubles/",
        "Id": "juniors-boys-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Juniors: Girls",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/juniors-girls/",
        "Id": "juniors-girls"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-us-open.jpg",
        "LeagueName": "Juniors: Girls",
        "LeagueLink": "https://www.livescore.com/en/tennis/us-open/juniors-girls-doubles/",
        "Id": "juniors-girls-doubles"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp.jpg",
    "CategoryName": "ATP",
    "Id": "atp",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp.jpg",
        "LeagueName": "Laver Cup",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp/laver-cup/",
        "Id": "laver-cup"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
    "CategoryName": "United Cup",
    "Id": "united-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Final Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-final-stage/",
        "Id": "united-cup-final-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Play-offs",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-play-offs/",
        "Id": "united-cup-play-offs"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group A",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-a/",
        "Id": "united-cup-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group B",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-b/",
        "Id": "united-cup-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group C",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-c/",
        "Id": "united-cup-group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group D",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-d/",
        "Id": "united-cup-group-d"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group E",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-e/",
        "Id": "united-cup-group-e"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-united-cup.jpg",
        "LeagueName": "United Cup: Group F",
        "LeagueLink": "https://www.livescore.com/en/tennis/united-cup/united-cup-group-f/",
        "Id": "united-cup-group-f"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
    "CategoryName": "ATP 1000",
    "Id": "atp-1000-masters",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Canadian Open (Montreal): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/canadian-open-qualification/",
        "Id": "canadian-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Canadian Open (Montreal)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/canadian-open-montreal/",
        "Id": "canadian-open-montreal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Cincinnati",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/cincinnati/",
        "Id": "cincinnati"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Cincinnati",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/cincinnati-doubles/",
        "Id": "cincinnati-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Cincinnati: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/cincinnati-qualification/",
        "Id": "cincinnati-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Canadian Open (Montreal)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/canadian-open-montreal-doubles/",
        "Id": "canadian-open-montreal-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Indian Wells Masters",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/indian-wells-masters/",
        "Id": "indian-wells-masters"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Indian Wells Masters: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/indian-wells-masters-qualification/",
        "Id": "indian-wells-masters-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Internazionali BNL d'Italia (Rome)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/internazionali-bnl-italia-rome/",
        "Id": "internazionali-bnl-italia-rome"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Internazionali BNL d'Italia: Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/internazionali-bnl-italia-qualification/",
        "Id": "internazionali-bnl-italia-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Madrid Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/madrid-open-2021/",
        "Id": "madrid-open-2021"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Madrid Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/madrid-open-qualification/",
        "Id": "madrid-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Miami Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/miami-open/",
        "Id": "miami-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Miami Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/miami-open-qualification/",
        "Id": "miami-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Monte-Carlo Masters",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/monte-carlo-masters/",
        "Id": "monte-carlo-masters"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Monte-Carlo Masters: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/monte-carlo-masters-qualification/",
        "Id": "monte-carlo-masters-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Paris Masters",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/masters-paris/",
        "Id": "masters-paris"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-1000-masters.jpg",
        "LeagueName": "Paris Masters: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-1000-masters/masters-paris-qualification/",
        "Id": "masters-paris-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
    "CategoryName": "ATP 500",
    "Id": "atp-500",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Astana Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/astana-open/",
        "Id": "astana-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Astana Open; Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/astana-open-qualification/",
        "Id": "astana-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Barcelona Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/barcelona-open/",
        "Id": "barcelona-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Barcelona Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/barcelona-open-qualification/",
        "Id": "barcelona-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Dubai Tennis Championships",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/dubai-tennis-championships/",
        "Id": "dubai-tennis-championships"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Dubai Tennis Championships: Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/dubai-tennis-championship-qualification/",
        "Id": "dubai-tennis-championship-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Erste Bank Open (Vienna)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/vienna-open/",
        "Id": "vienna-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Erste Bank Open (Vienna): Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/vienna-open-qualification/",
        "Id": "vienna-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Halle Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/halle-open/",
        "Id": "halle-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Halle Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/halle-open-qualification/",
        "Id": "halle-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Hamburg European Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/hamburg-european-open-singles/",
        "Id": "hamburg-european-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Hamburg European Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/hamburg-european-open-doubles/",
        "Id": "hamburg-european-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Hamburg European Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/hamburg-european-open-qualification/",
        "Id": "hamburg-european-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Japan Open (Tokyo)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/japan-open-tokyo/",
        "Id": "japan-open-tokyo"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Japan Open (Tokyo): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/japan-open-tokyo-qualification/",
        "Id": "japan-open-tokyo-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Mexican Open (Acapulco)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/mexican-open-acapulco/",
        "Id": "mexican-open-acapulco"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Mexican Open (Acapulco): Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/mexican-open-acapulco-qualification/",
        "Id": "mexican-open-acapulco-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Queen's Club Championships (London): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/queens-club-championships-london-qualification/",
        "Id": "queens-club-championships-london-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Queen's Club Championships (London)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/queens-club-championships-london/",
        "Id": "queens-club-championships-london"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Rio Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/rio-open/",
        "Id": "rio-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Rio Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/rio-open-qualification/",
        "Id": "rio-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Rotterdam Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/abn-amro-world-tournament-rotterdam/",
        "Id": "abn-amro-world-tournament-rotterdam"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Rotterdam Open: Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/abn-amro-world-tournament-rotterdam-qualification/",
        "Id": "abn-amro-world-tournament-rotterdam-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Swiss Indoors Basel",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/swiss-indoor-basel/",
        "Id": "swiss-indoor-basel"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Swiss Indoors Basel: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/swiss-indoors-basel-qualification/",
        "Id": "swiss-indoors-basel-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Washington Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/washington-open/",
        "Id": "washington-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Washington Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/washington-open-doubles/",
        "Id": "washington-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Washington Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/washington-open-qualification/",
        "Id": "washington-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-500.jpg",
        "LeagueName": "Washington Open: Doubles Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-500/washington-open-doubles-qualification/",
        "Id": "washington-open-doubles-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
    "CategoryName": "ATP 250",
    "Id": "atp-250",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Adelaide International",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/adelaide-international/",
        "Id": "adelaide-international"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Adelaide International: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/adelaide-international-qualification/",
        "Id": "adelaide-international-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Adelaide International 2",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/adelaide-international-2/",
        "Id": "adelaide-international-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Adelaide International 2: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/adelaide-international-2-qualification/",
        "Id": "adelaide-international-2-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Andalucia Open (Marbella)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/andalucia-open-marbella/",
        "Id": "andalucia-open-marbella"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Andalucia Open (Marbella): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/andalucia-open-marbella-qualification/",
        "Id": "andalucia-open-marbella-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Antalya Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/antalya-open/",
        "Id": "antalya-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Antalya Open, Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/antalya-open-qualification/",
        "Id": "antalya-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Argentina Open (Buenos Aires)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/argentina-open-buenos-aires/",
        "Id": "argentina-open-buenos-aires"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Argentina Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/argentina-open-qualification/",
        "Id": "argentina-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "ASB Classic (Auckland): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/asb-classic-auckland-qualification/",
        "Id": "asb-classic-auckland-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "ASB Classic (Auckland)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/classic-auckland/",
        "Id": "classic-auckland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Atlanta Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/atlanta-open/",
        "Id": "atlanta-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Atlanta Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/atlanta-open-doubles/",
        "Id": "atlanta-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Atlanta Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/atlanta-open-qualification/",
        "Id": "atlanta-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "bett1Hulks Indoors (Cologne)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bett1hulks-indoors-cologne/",
        "Id": "bett1hulks-indoors-cologne"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "bett1Hulks Indoors (Cologne): Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bett1hulks-indoors-cologne-qualification/",
        "Id": "bett1hulks-indoors-cologne-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "bett1Hulks Championship (Cologne)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bett1hulks-championship-cologne/",
        "Id": "bett1hulks-championship-cologne"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "bett1Hulks Championship (Cologne): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bett1hulks-championship-qualification/",
        "Id": "bett1hulks-championship-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Bavarian International Tennis Championships",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bmw-open-munich/",
        "Id": "bmw-open-munich"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Bavarian International Tennis Championships (Qualification)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/bmw-open-munich-qualification/",
        "Id": "bmw-open-munich-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Chile Open (Santiago)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/chile-open/",
        "Id": "chile-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Chile Open (Santiago): Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/chile-open-santiago-qualification/",
        "Id": "chile-open-santiago-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Croatia Open (Umag): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/croatia-open-qualification-singles/",
        "Id": "croatia-open-qualification-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Dallas Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/dallas-open/",
        "Id": "dallas-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Dallas Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/dallas-open-qualification/",
        "Id": "dallas-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Delray Beach Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/delray-beach-open/",
        "Id": "delray-beach-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Delray Beach Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/delray-beach-open-qualification/",
        "Id": "delray-beach-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Geneva Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/geneva-open/",
        "Id": "geneva-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Cordoba Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/cordoba-open/",
        "Id": "cordoba-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Cordoba Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/cordoba-open-qualification/",
        "Id": "cordoba-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Croatia Open (Umag)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/croatia-open-umag/",
        "Id": "croatia-open-umag"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Croatia Open (Umag)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/croatia-open-umag-doubles/",
        "Id": "croatia-open-umag-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Eastbourne International",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/eastbourne-international/",
        "Id": "eastbourne-international"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Eastbourne International: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/eastbourne-international-qualification/",
        "Id": "eastbourne-international-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Emilia-Romagna Open (Parma)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/emilia-romagna-open-parma/",
        "Id": "emilia-romagna-open-parma"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Emilia-Romagna Open (Parma): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/emilia-romagna-open-parma-qualification/",
        "Id": "emilia-romagna-open-parma-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Estoril Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/estoril-open/",
        "Id": "estoril-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "Estoril Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/estoril-open-qualification/",
        "Id": "estoril-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "European Open (Antwerp)",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/european-open/",
        "Id": "european-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/european-open-antwerp-qualification/",
        "Id": "european-open-antwerp-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/firenze-open-florence/",
        "Id": "firenze-open-florence"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/firenze-open-qualification/",
        "Id": "firenze-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/forte-village-sardegna-open/",
        "Id": "forte-village-sardegna-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/forte-village-sardegna-open-qualification/",
        "Id": "forte-village-sardegna-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/generali-open-kitzbuhel/",
        "Id": "generali-open-kitzbuhel"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/generali-open-kitzbuhel-doubles/",
        "Id": "generali-open-kitzbuhel-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/generali-open-kitzbuhel-qualification/",
        "Id": "generali-open-kitzbuhel-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/gijon-open/",
        "Id": "gijon-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/gijon-open-qualification/",
        "Id": "gijon-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/grand-prix-hassan-ii/",
        "Id": "grand-prix-hassan-ii"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/grand-prix-hassan-ii-marrakesh-qualification/",
        "Id": "grand-prix-hassan-ii-marrakesh-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/great-ocean-road-open/",
        "Id": "great-ocean-road-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/geneva-open-qualification/",
        "Id": "geneva-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/hall-of-fame-open-newport-usa-singles/",
        "Id": "hall-of-fame-open-newport-usa-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/hall-of-fame-open-newport-usa-doubles/",
        "Id": "hall-of-fame-open-newport-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/hall-of-fame-open-newport/",
        "Id": "hall-of-fame-open-newport"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/hall-of-fame-open-newport-qualification/",
        "Id": "hall-of-fame-open-newport-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/korea-opem-seoul/",
        "Id": "korea-opem-seoul"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/korea-open-seoul-qualification/",
        "Id": "korea-open-seoul-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/los-cabos-open/",
        "Id": "los-cabos-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/los-cabos-open-doubles/",
        "Id": "los-cabos-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/los-cabos-open-qualification/",
        "Id": "los-cabos-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/lyon-open/",
        "Id": "lyon-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/lyon-open-qualification/",
        "Id": "lyon-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/maharashtra-open-pune/",
        "Id": "maharashtra-open-pune"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/maharashtra-open-qualification/",
        "Id": "maharashtra-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/mallorca-championships/",
        "Id": "mallorca-championships"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/mallorca-championships-qualification/",
        "Id": "mallorca-championships-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/melbourne-summer-set/",
        "Id": "melbourne-summer-set"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/melbourne-summer-set-qualification/",
        "Id": "melbourne-summer-set-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/moselle-open-metz/",
        "Id": "moselle-open-metz"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/open-de-moselle-qualification/",
        "Id": "open-de-moselle-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/murray-river-open/",
        "Id": "murray-river-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/napoli-open/",
        "Id": "napoli-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/napoli-open-qualification/",
        "Id": "napoli-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/new-york-open/",
        "Id": "new-york-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/new-york-open-qualification/",
        "Id": "new-york-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/open-13-marseille/",
        "Id": "open-13-marseille"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/open-13-marseille-qualification/",
        "Id": "open-13-marseille-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/open-sud-de-france-montpellier/",
        "Id": "open-sud-de-france-montpellier"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/open-sud-de-france-qualification/",
        "Id": "open-sud-de-france-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/qatar-open-doha/",
        "Id": "qatar-open-doha"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/qatar-open-doha-qualification/",
        "Id": "qatar-open-doha-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/rosmalen-grass-court-championships/",
        "Id": "rosmalen-grass-court-championships"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/rosmalen-grass-court-championships-qualification/",
        "Id": "rosmalen-grass-court-championships-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/san-diego-open/",
        "Id": "san-diego-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/san-diego-open-qualification/",
        "Id": "san-diego-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/singapore-open/",
        "Id": "singapore-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/singapore-open-qualification/",
        "Id": "singapore-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/sofia-open/",
        "Id": "sofia-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/sofia-open-qualification/",
        "Id": "sofia-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/stockholm-open/",
        "Id": "stockholm-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/stockholm-open-qualification/",
        "Id": "stockholm-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/stuttgart-open/",
        "Id": "stuttgart-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/stuttgart-open-qualification/",
        "Id": "stuttgart-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swedish-open-singles/",
        "Id": "swedish-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swedish-open-doubles/",
        "Id": "swedish-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swedish-open/",
        "Id": "swedish-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swedish-open-qualification/",
        "Id": "swedish-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swiss-open-singles/",
        "Id": "swiss-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swiss-open-doubles/",
        "Id": "swiss-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/swiss-open-qualification/",
        "Id": "swiss-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/sydney-tennis-classic/",
        "Id": "sydney-tennis-classic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/sydney-tennis-classic-qualification/",
        "Id": "sydney-tennis-classic-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/tel-aviv-watergen-open/",
        "Id": "tel-aviv-watergen-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/tel-aviv-watergen-open-qualification/",
        "Id": "tel-aviv-watergen-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/us-mens-clay-court-championships/",
        "Id": "us-mens-clay-court-championships"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/us-mens-clay-court-championships-qualification/",
        "Id": "us-mens-clay-court-championships-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/winston-salem-open/",
        "Id": "winston-salem-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/winston-salem-open-doubles/",
        "Id": "winston-salem-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/winston-salem-open-qualification/",
        "Id": "winston-salem-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/banja-luka-open/",
        "Id": "banja-luka-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-250/banja-luka-open-qualification/",
        "Id": "banja-luka-open-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
    "CategoryName": "ATP Cup",
    "Id": "atp-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
        "LeagueName": "Final Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-cup/final-stage/",
        "Id": "final-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-cup/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-cup/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
        "LeagueName": "Group C",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-cup/group-c/",
        "Id": "group-c"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-cup.jpg",
        "LeagueName": "Group D",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-cup/group-d/",
        "Id": "group-d"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
    "CategoryName": "ATP World Finals",
    "Id": "atp-world-finals",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/atp-finals-play-off/",
        "Id": "atp-finals-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Doubles: Play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/atp-finals-doubles-play-off/",
        "Id": "atp-finals-doubles-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Green Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/green-group/",
        "Id": "green-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Red Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/red-group/",
        "Id": "red-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Doubles: Green Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/doubles-green-group/",
        "Id": "doubles-green-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-world-finals.jpg",
        "LeagueName": "ATP Finals: Doubles: Red Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-world-finals/doubles-red-group/",
        "Id": "doubles-red-group"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
    "CategoryName": "WTA 1000",
    "Id": "wta-1000",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Canadian Open (Toronto)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/canadian-open-toronto/",
        "Id": "canadian-open-toronto"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Canadian Open (Toronto)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/canadian-open-toronto-doubles/",
        "Id": "canadian-open-toronto-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Canadian Open (Toronto): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/canadian-open-qualification/",
        "Id": "canadian-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Cincinnati",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/cincinnati/",
        "Id": "cincinnati"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Cincinnati",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/cincinnati-doubles/",
        "Id": "cincinnati-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Cincinnati: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/cincinnati-qualification/",
        "Id": "cincinnati-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Dubai Tennis Championships",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/dubai-tennis-championship/",
        "Id": "dubai-tennis-championship"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Dubai Tennis Championships: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/dubai-tennis-championships-qualification/",
        "Id": "dubai-tennis-championships-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Indian Wells Masters",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/indian-wells-masters/",
        "Id": "indian-wells-masters"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Indian Wells Masters: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/indian-wells-masters-qualification/",
        "Id": "indian-wells-masters-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Guadalajara Open Akron",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/guadalajara-open-akron/",
        "Id": "guadalajara-open-akron"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Guadalajara Open Akron: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/guadalajara-open-akron-qualification/",
        "Id": "guadalajara-open-akron-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Internazionali BNL d'Italia",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/internazionali-bnl-d-italia/",
        "Id": "internazionali-bnl-d-italia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Internazionali BNL d'Italia (Rome): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/internazionali-bnl-d-italia-qualification/",
        "Id": "internazionali-bnl-d-italia-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Madrid Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/madrid-open/",
        "Id": "madrid-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Madrid Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/madrid-open-qualification/",
        "Id": "madrid-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Miami Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/miami-open/",
        "Id": "miami-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-1000.jpg",
        "LeagueName": "Miami Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-1000/miami-open-qualification/",
        "Id": "miami-open-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
    "CategoryName": "WTA 500",
    "Id": "wta-500",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Abu Dhabi Women’s Tennis Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/abu-dhabi-wta-womens-tennis-open/",
        "Id": "abu-dhabi-wta-womens-tennis-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Abu Dhabi Women’s Tennis Open: Qual.",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/abu-dhabi-wta-womens-tennis-open-qualification/",
        "Id": "abu-dhabi-wta-womens-tennis-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Adelaide International 1",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/adelaide-international-1/",
        "Id": "adelaide-international-1"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Adelaide International 1: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/adelaide-international-1-qualification/",
        "Id": "adelaide-international-1-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Charleston Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/charleston-open/",
        "Id": "charleston-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Charleston Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/charleston-open-qualification/",
        "Id": "charleston-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Eastbourne International",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/eastbourne-international/",
        "Id": "eastbourne-international"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Eastbourne International: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/eastbourne-international-qualification/",
        "Id": "eastbourne-international-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "German Open, Berlin",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/german-open-berlin/",
        "Id": "german-open-berlin"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "German Open, Berlin: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/grass-court-championships-qualification/",
        "Id": "grass-court-championships-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Ostrava Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/ostrava-open/",
        "Id": "ostrava-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Ostrava Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/ostrava-open-qualification/",
        "Id": "ostrava-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Pan Pacific Open (Tokyo)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/pan-pacific-open/",
        "Id": "pan-pacific-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Pan Pacific Open (Tokyo): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/pan-pacific-open-qualification/",
        "Id": "pan-pacific-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "San Diego Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/san-diego-open/",
        "Id": "san-diego-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "San Diego Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/san-diego-open-qualification/",
        "Id": "san-diego-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Silicon Valley Classic",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/silicon-valley-classic/",
        "Id": "silicon-valley-classic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Silicon Valley Classic",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/silicon-valley-classic-doubles/",
        "Id": "silicon-valley-classic-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Silicon Valley Classic (San Jose): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/silicon-valley-classic-san-jose-qualification/",
        "Id": "silicon-valley-classic-san-jose-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Stuttgart Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/stuttgart-open/",
        "Id": "stuttgart-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Stuttgart Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/stuttgart-open-qualification/",
        "Id": "stuttgart-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Sydney Tennis Classic",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/sydney-tennis-classic/",
        "Id": "sydney-tennis-classic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Sydney Tennis Classic: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/sydney-tennis-classic-qualification/",
        "Id": "sydney-tennis-classic-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Qatar Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/qatar-open/",
        "Id": "qatar-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-500.jpg",
        "LeagueName": "Qatar Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-500/qatar-open-qualification/",
        "Id": "qatar-open-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
    "CategoryName": "WTA 250",
    "Id": "wta-250",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Abierto de Guadalajara",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/abierto-de-guadalajara/",
        "Id": "abierto-de-guadalajara"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Abierto de Guadalajara: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/abierto-de-guadalajara-qualification/",
        "Id": "abierto-de-guadalajara-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Adelaide International 2",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/adelaide-international-2/",
        "Id": "adelaide-international-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Adelaide International 2: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/adelaide-international-2-qualification/",
        "Id": "adelaide-international-2-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "ASB Classic (Auckland)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/asb-classic-auckland/",
        "Id": "asb-classic-auckland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "ASB Classic (Auckland): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/asb-classic-auckland-qualification/",
        "Id": "asb-classic-auckland-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Austin Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/austin-open/",
        "Id": "austin-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Austin Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/austin-open-qualification/",
        "Id": "austin-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Bad Homburg Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/bad-homburg-open/",
        "Id": "bad-homburg-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Bad Homburg Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/bad-homburg-open-qualification/",
        "Id": "bad-homburg-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Birmingham Classic",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/birmingham-classic/",
        "Id": "birmingham-classic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Birmingham Classic: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/birimingham-classic-qualification/",
        "Id": "birimingham-classic-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Championnats Banque Nationale de Granby",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/championnats-banque-nationale-de-granby/",
        "Id": "championnats-banque-nationale-de-granby"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Championnats Banque Nationale de Granby",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/championnats-banque-nationale-de-granby-doubles/",
        "Id": "championnats-banque-nationale-de-granby-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Championnats Banque Nationale de Granby: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/championnats-banque-nationale-de-granby-qualification/",
        "Id": "championnats-banque-nationale-de-granby-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Chennai Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/chennai-open/",
        "Id": "chennai-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Chennai Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/chennai-open-doubles/",
        "Id": "chennai-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Chennai Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/chennai-open-qualification/",
        "Id": "chennai-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Cleveland Ladies Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/cleveland-ladies-open-doubles/",
        "Id": "cleveland-ladies-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Cleveland Ladies Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/cleveland-ladies-open/",
        "Id": "cleveland-ladies-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Cleveland Ladies Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/cleveland-ladies-open-qualification/",
        "Id": "cleveland-ladies-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Copa Colsanitas (Bogota)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/copa-colsanitas/",
        "Id": "copa-colsanitas"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Copa Colsanitas (Bogota): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/copa-colsanitas-bogota-qualification/",
        "Id": "copa-colsanitas-bogota-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "GP SAR La Princesse Lalla Meryem",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/gp-sar-la-princesse-lalla-meryem/",
        "Id": "gp-sar-la-princesse-lalla-meryem"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "GP SAR La Princesse Lalla Meryem: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/gp-sar-la-princesse-lalla-meryem-qualification/",
        "Id": "gp-sar-la-princesse-lalla-meryem-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hobart International",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hobart-international/",
        "Id": "hobart-international"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hobart International: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hobart-international-qualification/",
        "Id": "hobart-international-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hungarian Grand Prix",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hungarian-grand-prix-singles/",
        "Id": "hungarian-grand-prix-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hungarian Grand Prix",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hungarian-grand-prix-doubles/",
        "Id": "hungarian-grand-prix-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hungarian Grand Prix",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hungarian-grand-prix/",
        "Id": "hungarian-grand-prix"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Hungarian Grand Prix: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hungarian-grand-prix-qualification/",
        "Id": "hungarian-grand-prix-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Internationaux de Strasbourg",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/internationaux-de-strasbourg/",
        "Id": "internationaux-de-strasbourg"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Internationaux de Strasbourg: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/internationaux-de-strasbourg-qualification/",
        "Id": "internationaux-de-strasbourg-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Istanbul Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/istanbul-open/",
        "Id": "istanbul-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Istanbul Open: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/istanbul-open-qualification/",
        "Id": "istanbul-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Jasmin Open (Tunisia)",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/jasmin-open-tunisia/",
        "Id": "jasmin-open-tunisia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Jasmin Open (Tunisia): Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/jasmin-open-tunisia-qualification/",
        "Id": "jasmin-open-tunisia-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Korea Open, Seoul",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/korea-open-seoul/",
        "Id": "korea-open-seoul"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Korea Open, Seoul: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/korea-open-qualification/",
        "Id": "korea-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "Lyon Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/lyon-open/",
        "Id": "lyon-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/lyon-open-qualification/",
        "Id": "lyon-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/melbourne-summer-set-1/",
        "Id": "melbourne-summer-set-1"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/melbourne-summer-set-1-qualification/",
        "Id": "melbourne-summer-set-1-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/melbourne-summer-set-2/",
        "Id": "melbourne-summer-set-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/melbourne-summer-set-2-qualification/",
        "Id": "melbourne-summer-set-2-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/merida-open/",
        "Id": "merida-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/merida-open-qualification/",
        "Id": "merida-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/monterrey-open/",
        "Id": "monterrey-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/monterrey-open-qualification/",
        "Id": "monterrey-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/nottingham-open-qualification/",
        "Id": "nottingham-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/parma-ladies-open/",
        "Id": "parma-ladies-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/parma-ladies-open-qualification/",
        "Id": "parma-ladies-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/rosmalen-grass-court-championships/",
        "Id": "rosmalen-grass-court-championships"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/rosmalen-grass-court-championships-qualification-womens/",
        "Id": "rosmalen-grass-court-championships-qualification-womens"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/slovenia-open-portoroz/",
        "Id": "slovenia-open-portoroz"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/slovenia-open-portoroz-doubles/",
        "Id": "slovenia-open-portoroz-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/swiss-open-singles/",
        "Id": "swiss-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/swiss-open-doubles/",
        "Id": "swiss-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/swiss-open/",
        "Id": "swiss-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/swiss-open-qualification/",
        "Id": "swiss-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/tallin-open-estonia/",
        "Id": "tallin-open-estonia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/tallin-open-estonia-qualification/",
        "Id": "tallin-open-estonia-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/transylvania-open/",
        "Id": "transylvania-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/transylvania-open-qualification/",
        "Id": "transylvania-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/washington-open/",
        "Id": "washington-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/washington-open-doubles/",
        "Id": "washington-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/washington-open-qualification/",
        "Id": "washington-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hamburg-european-open-singles/",
        "Id": "hamburg-european-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/hamburg-european-open-doubles/",
        "Id": "hamburg-european-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/palermo-ladies-open-singles/",
        "Id": "palermo-ladies-open-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/palermo-ladies-open-doubles/",
        "Id": "palermo-ladies-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/palermo-ladies-open-qualification/",
        "Id": "palermo-ladies-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/poland-open/",
        "Id": "poland-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/poland-open-doubles/",
        "Id": "poland-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/poland-open-qualification/",
        "Id": "poland-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/prague-open/",
        "Id": "prague-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/prague-open-doubles/",
        "Id": "prague-open-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/prague-open-qualification/",
        "Id": "prague-open-qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-250.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-250/slovenia-open-qualification/",
        "Id": "slovenia-open-qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
    "CategoryName": "ATP Challenger",
    "Id": "atp-challenger",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Aguascalientes, Mexico",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/aguascalientes-mexico/",
        "Id": "aguascalientes-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Aix en Provence, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/aix-en-provence-france/",
        "Id": "aix-en-provence-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Alicante, Spain",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/alicante-spain/",
        "Id": "alicante-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Almaty, Kazakhstan",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/almaty-kazakhstan/",
        "Id": "almaty-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Almaty 2, Kazakhstan",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/almaty-2-kazakhstan/",
        "Id": "almaty-2-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Ambato, Ecuador",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ambato-ecuador/",
        "Id": "ambato-ecuador"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Amersfoort, Netherlands",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/amersfoort-netherlands-singles/",
        "Id": "amersfoort-netherlands-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Amersfoort, Netherlands",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/amersfoort-netherlands-doubles/",
        "Id": "amersfoort-netherlands-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Amersfoort, Netherlands",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/amersfoort-netherlands/",
        "Id": "amersfoort-netherlands"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Andria, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/andria-italy/",
        "Id": "andria-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Ann Arbor, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ann-arbor-usa/",
        "Id": "ann-arbor-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Antalya, Turkey",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/antalya/",
        "Id": "antalya"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Antalya 2, Turkey",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/antalya-2-turkey/",
        "Id": "antalya-2-turkey"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Antalya 3, Turkey",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/antalya-3-turkey/",
        "Id": "antalya-3-turkey"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Antalya 4, Turkey",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/antalya-4-italy/",
        "Id": "antalya-4-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bangkok, Thailand",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bangkok-thailand/",
        "Id": "bangkok-thailand"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bangkok 2, Thailand",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bangkok-2-thailand/",
        "Id": "bangkok-2-thailand"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Banja Luka, Bosnia-Herzegovina",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/banja-luka-bosnia-singles/",
        "Id": "banja-luka-bosnia-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Banja Luka, Bosnia-Herzegovina",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/banja-luka-bosnia-herzegovina-doubles/",
        "Id": "banja-luka-bosnia-herzegovina-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Banja Luka, Bosnia-Herzegovina",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/banja-luka-bosnia-herzegovina/",
        "Id": "banja-luka-bosnia-herzegovina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Barcelona, Spain",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/barcelona-spain/",
        "Id": "barcelona-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bari, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bari-italy/",
        "Id": "bari-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Barletta, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/barletta-italy/",
        "Id": "barletta-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Belgrade, Serbia",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/belgrade-serbia/",
        "Id": "belgrade-serbia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bendigo, Australia",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bendigo-australia/",
        "Id": "bendigo-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bengaluru 2, India",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bengaluru-2-india/",
        "Id": "bengaluru-2-india"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bengaluru, India",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bengaluru-india/",
        "Id": "bengaluru-india"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bergamo, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bergamo-italy/",
        "Id": "bergamo-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biel, Switzerland",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biel-switzerland/",
        "Id": "biel-switzerland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-italy/",
        "Id": "biella-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 5, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-5-italy/",
        "Id": "biella-5-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 2, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-2-italy/",
        "Id": "biella-2-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 3, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-3-italy/",
        "Id": "biella-3-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 4, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-4-italy/",
        "Id": "biella-4-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 6, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-6-italy/",
        "Id": "biella-6-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Biella 7, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/biella-7-italy/",
        "Id": "biella-7-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Blois, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/blois-france/",
        "Id": "blois-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Blumenau, Brazil",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/blumenau-brazil/",
        "Id": "blumenau-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bogota, Colombia",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bogota-colombia/",
        "Id": "bogota-colombia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "Bordeaux, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bordeaux-france/",
        "Id": "bordeaux-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/braga-portugal/",
        "Id": "braga-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/brasilia-brazil/",
        "Id": "brasilia-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bratislava-slovakia/",
        "Id": "bratislava-slovakia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bratislava-2-slovakia/",
        "Id": "bratislava-2-slovakia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/braunschweig-germany/",
        "Id": "braunschweig-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/brest-france/",
        "Id": "brest-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/buenos-aires-argentina/",
        "Id": "buenos-aires-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tigre-argentina/",
        "Id": "tigre-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tigre-2-argentina/",
        "Id": "tigre-2-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/buenos-aires-2-argentina/",
        "Id": "buenos-aires-2-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/buenos-aires-3-argentina/",
        "Id": "buenos-aires-3-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/bucharest-romania/",
        "Id": "bucharest-romania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/burnie-tasmania-aus/",
        "Id": "burnie-tasmania-aus"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/busan-south-korea/",
        "Id": "busan-south-korea"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/calgary-canada/",
        "Id": "calgary-canada"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cali-colombia/",
        "Id": "cali-colombia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/campinas-brazil/",
        "Id": "campinas-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/canberra-australia/",
        "Id": "canberra-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cary-usa-singles/",
        "Id": "cary-usa-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cary-usa-doubles/",
        "Id": "cary-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cary-2-usa/",
        "Id": "cary-2-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cassis-france-singles/",
        "Id": "cassis-france-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cassis-france-doubles/",
        "Id": "cassis-france-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/champaign-usa/",
        "Id": "champaign-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/charleston-usa/",
        "Id": "charleston-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/charlottesville-usa/",
        "Id": "charlottesville-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/chennai-india/",
        "Id": "chennai-india"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/chennai-india-doubles/",
        "Id": "chennai-india-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cagliari-italy/",
        "Id": "cagliari-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/chicago-usa/",
        "Id": "chicago-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/chicago-usa-doubles/",
        "Id": "chicago-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cherbourg-france/",
        "Id": "cherbourg-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cleveland-usa/",
        "Id": "cleveland-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/columbus-usa/",
        "Id": "columbus-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/columbus-2-usa/",
        "Id": "columbus-2-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/como-italy/",
        "Id": "como-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/como-italy-doubles/",
        "Id": "como-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/concepcion-chile/",
        "Id": "concepcion-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/concepcion-2-chile/",
        "Id": "concepcion-2-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/coquimbo-chile/",
        "Id": "coquimbo-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/coquimbo-chile-2/",
        "Id": "coquimbo-chile-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cordenons-italy/",
        "Id": "cordenons-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/cordenons-italy-doubles/",
        "Id": "cordenons-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/corrientes-argentina/",
        "Id": "corrientes-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/dallas-usa/",
        "Id": "dallas-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/drummondville-canada/",
        "Id": "drummondville-canada"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/eckental-germany/",
        "Id": "eckental-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/fairfield-usa/",
        "Id": "fairfield-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/florianopolis-brazil/",
        "Id": "florianopolis-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-italy/",
        "Id": "forli-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-2-italy/",
        "Id": "forli-2-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-3-italy/",
        "Id": "forli-3-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-4-italy/",
        "Id": "forli-4-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-5-italy/",
        "Id": "forli-5-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/forli-6-italy/",
        "Id": "forli-6-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/francavilla/",
        "Id": "francavilla"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/granby-canada/",
        "Id": "granby-canada"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/granby-canada-doubles/",
        "Id": "granby-canada-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/gran-canaria-1-spain/",
        "Id": "gran-canaria-1-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/gran-canaria-2-spain/",
        "Id": "gran-canaria-2-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/genoa-italy/",
        "Id": "genoa-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/girona-spain/",
        "Id": "girona-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/guayaquil-ecuador/",
        "Id": "guayaquil-ecuador"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/gwangju-south-korea/",
        "Id": "gwangju-south-korea"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/hamburg-germany/",
        "Id": "hamburg-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/heilbronn-germany/",
        "Id": "heilbronn-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/helsinki-finland/",
        "Id": "helsinki-finland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/iasi-romania-singles/",
        "Id": "iasi-romania-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/iasi-romania-doubles/",
        "Id": "iasi-romania-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ilkley-england/",
        "Id": "ilkley-england"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/indian-wells-usa/",
        "Id": "indian-wells-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ismaning-germany/",
        "Id": "ismaning-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/istanbul-turkiye/",
        "Id": "istanbul-turkiye"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/istanbul-turkiye-doubles/",
        "Id": "istanbul-turkiye-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/istanbul-2-turkey/",
        "Id": "istanbul-2-turkey"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/indianapolis-usa-singles/",
        "Id": "indianapolis-usa-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/indianapolis-usa-doubles/",
        "Id": "indianapolis-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/knoxville-usa/",
        "Id": "knoxville-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/kozerki-poland-singles/",
        "Id": "kozerki-poland-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/kozerki-poland-doubles/",
        "Id": "kozerki-poland-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/kobe-japan/",
        "Id": "kobe-japan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/koblenz-germany/",
        "Id": "koblenz-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/kyiv-ukraine/",
        "Id": "kyiv-ukraine"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/las-vegas-usa/",
        "Id": "las-vegas-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/latrobe-city-traralgon-australia/",
        "Id": "latrobe-city-traralgon-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/leon-mexico/",
        "Id": "leon-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/les-franquesas-del-valles-spain/",
        "Id": "les-franquesas-del-valles-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lexington-usa/",
        "Id": "lexington-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lexington-usa-doubles/",
        "Id": "lexington-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/launceston-australia/",
        "Id": "launceston-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/liberec-czech-republic/",
        "Id": "liberec-czech-republic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/liberec-czech-republic-doubles/",
        "Id": "liberec-czech-republic-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/liberec-czech-republic-2021/",
        "Id": "liberec-czech-republic-2021"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lille-france/",
        "Id": "lille-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lima-peru/",
        "Id": "lima-peru"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lima-peru-doubles/",
        "Id": "lima-peru-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lisbon-portugal/",
        "Id": "lisbon-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/losinj-croatia/",
        "Id": "losinj-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/little-rock-usa/",
        "Id": "little-rock-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/luedenscheid-hermany/",
        "Id": "luedenscheid-hermany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lugano-switzerland/",
        "Id": "lugano-switzerland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/lyon-france/",
        "Id": "lyon-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/madrid-spain/",
        "Id": "madrid-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/maia-portugal/",
        "Id": "maia-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/maia-2-portugal/",
        "Id": "maia-2-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/malaga-spain/",
        "Id": "malaga-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/mallorca-spain-singles/",
        "Id": "mallorca-spain-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/mallorca-spain-doubles/",
        "Id": "mallorca-spain-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/manama-bahrain/",
        "Id": "manama-bahrain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/marbella-spain/",
        "Id": "marbella-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/maspalomas-spain/",
        "Id": "maspalomas-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/matsuyama-japan/",
        "Id": "matsuyama-japan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/mauthausen-austria/",
        "Id": "mauthausen-austria"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/meerbusch-germany/",
        "Id": "meerbusch-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/meerbusch-germany-doubles/",
        "Id": "meerbusch-germany-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/milan-italy/",
        "Id": "milan-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/montevideo-uruguay/",
        "Id": "montevideo-uruguay"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/mexico-city-mexico/",
        "Id": "mexico-city-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/monterrey-mexico/",
        "Id": "monterrey-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/morelos-mexico/",
        "Id": "morelos-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/murcia-italy/",
        "Id": "murcia-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/mouilleron-le-captif-france/",
        "Id": "mouilleron-le-captif-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/napoli-italy/",
        "Id": "napoli-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/napoli-2/",
        "Id": "napoli-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/newport-beach-usa/",
        "Id": "newport-beach-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nonthaburi-thailand/",
        "Id": "nonthaburi-thailand"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nonthaburi-thailand-doubles/",
        "Id": "nonthaburi-thailand-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nonthaburi-2-thailand/",
        "Id": "nonthaburi-2-thailand"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nonthaburi-3-thailand-singles/",
        "Id": "nonthaburi-3-thailand-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nonthaburi-3-thailand-doubles/",
        "Id": "nonthaburi-3-thailand-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nottingham-england/",
        "Id": "nottingham-england"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nottingham-2-england/",
        "Id": "nottingham-2-england"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/noumea-new-caledonia/",
        "Id": "noumea-new-caledonia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nur-sultan-kazakhstan-singles/",
        "Id": "nur-sultan-kazakhstan-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nur-sultan-kazakhstan-doubles/",
        "Id": "nur-sultan-kazakhstan-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nur-sultan-2-kazakhstan/",
        "Id": "nur-sultan-2-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nur-sultan-3-kazakhstan/",
        "Id": "nur-sultan-3-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/nur-sultan-4-kazakhstan/",
        "Id": "nur-sultan-4-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/oeiras-portugal/",
        "Id": "oeiras-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/oeiras-2-portugal/",
        "Id": "oeiras-2-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/oeiras-3-portugal/",
        "Id": "oeiras-3-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/orlando-usa/",
        "Id": "orlando-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/orlando-2-usa/",
        "Id": "orlando-2-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/orleans-france/",
        "Id": "orleans-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ortisei-italy/",
        "Id": "ortisei-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ostrava-czech-republic/",
        "Id": "ostrava-czech-republic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/ottignies-louvain-la-nueve-belgium/",
        "Id": "ottignies-louvain-la-nueve-belgium"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/parma-italy/",
        "Id": "parma-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/parma-2-italy/",
        "Id": "parma-2-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/pau-france/",
        "Id": "pau-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/pereira-colombia/",
        "Id": "pereira-colombia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/perugia-italy/",
        "Id": "perugia-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/phoenix-usa/",
        "Id": "phoenix-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/piracicaba-brazil/",
        "Id": "piracicaba-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/playford-australia/",
        "Id": "playford-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/porto-portugal/",
        "Id": "porto-portugal"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/potchefstroom-south-africa/",
        "Id": "potchefstroom-south-africa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/potchefstroom-2-south-africa/",
        "Id": "potchefstroom-2-south-africa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/poznan-poland/",
        "Id": "poznan-poland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/pozoblanco-spain-singles/",
        "Id": "pozoblanco-spain-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/pozoblanco-spain-doubles/",
        "Id": "pozoblanco-spain-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/prague-czech-republic/",
        "Id": "prague-czech-republic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/prague-2/",
        "Id": "prague-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/prague-3-czech-republic-singles/",
        "Id": "prague-3-czech-republic-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/prague-3-czech-republic-doubles/",
        "Id": "prague-3-czech-republic-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/prostejov-czech-republic/",
        "Id": "prostejov-czech-republic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/puerto-vallarta-mexico/",
        "Id": "puerto-vallarta-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/pune-india/",
        "Id": "pune-india"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/punta-del-este-uruguay/",
        "Id": "punta-del-este-uruguay"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/quimper-france/",
        "Id": "quimper-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/quimper-2-france/",
        "Id": "quimper-2-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/quito-ecuador/",
        "Id": "quito-ecuador"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rennes-france/",
        "Id": "rennes-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rennes-france-doubles/",
        "Id": "rennes-france-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/roanne-france/",
        "Id": "roanne-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rome-italy/",
        "Id": "rome-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rome-2-italy/",
        "Id": "rome-2-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rome-usa/",
        "Id": "rome-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/roseto-degli-abruzzi-italy/",
        "Id": "roseto-degli-abruzzi-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/roseto-degli-abruzzi-2/",
        "Id": "roseto-degli-abruzzi-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rovereto-italy/",
        "Id": "rovereto-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/rio-de-janeiro-brazil/",
        "Id": "rio-de-janeiro-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/salinas-ecuador/",
        "Id": "salinas-ecuador"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/salinas-2-ecuador/",
        "Id": "salinas-2-ecuador"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/salvador-de-bahia-brazil/",
        "Id": "salvador-de-bahia-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/salzburg-anif-austria/",
        "Id": "salzburg-anif-austria"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-luis-potosi-mexico/",
        "Id": "san-luis-potosi-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-marino-san-marino/",
        "Id": "san-marino-san-marino"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-marino-san-marino-doubles/",
        "Id": "san-marino-san-marino-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-marino-san-marino-2021/",
        "Id": "san-marino-san-marino-2021"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sanremo-italy/",
        "Id": "sanremo-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santo-domingo-dominican-republic-singles/",
        "Id": "santo-domingo-dominican-republic-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santo-domingo-dominican-republic-doubles/",
        "Id": "santo-domingo-dominican-republic-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santiago-chile/",
        "Id": "santiago-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santiago-2-chile/",
        "Id": "santiago-2-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santiago-3-chile/",
        "Id": "santiago-3-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sarasota-usa/",
        "Id": "sarasota-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/segovia-spain/",
        "Id": "segovia-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/segovia-spain-doubles/",
        "Id": "segovia-spain-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santa-cruz-bolivia/",
        "Id": "santa-cruz-bolivia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/santa-cruz-2-bolivia/",
        "Id": "santa-cruz-2-bolivia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-benedetto-italy/",
        "Id": "san-benedetto-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/san-benedetto-italy-doubles/",
        "Id": "san-benedetto-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sao-leopoldo-brazil/",
        "Id": "sao-leopoldo-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sao-paulo-brazil/",
        "Id": "sao-paulo-brazil"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/savannah-usa/",
        "Id": "savannah-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/seoul-south-korea/",
        "Id": "seoul-south-korea"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/seville-spain-singles/",
        "Id": "seville-spain-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/seville-spain-doubles/",
        "Id": "seville-spain-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/shymkent-kazakhstan/",
        "Id": "shymkent-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/shymkent-2-kazakhstan/",
        "Id": "shymkent-2-kazakhstan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sibiu-romania/",
        "Id": "sibiu-romania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/split-croatia/",
        "Id": "split-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/split-2-croatia/",
        "Id": "split-2-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/st-brieuc-france/",
        "Id": "st-brieuc-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/st-tropez-france/",
        "Id": "st-tropez-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/surbiton-england/",
        "Id": "surbiton-england"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/sydney-australia/",
        "Id": "sydney-australia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/szczecin-poland-singles/",
        "Id": "szczecin-poland-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/szczecin-poland-doubles/",
        "Id": "szczecin-poland-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tallahassee-usa/",
        "Id": "tallahassee-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tampere-finland-singles/",
        "Id": "tampere-finland-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tampere-finland-doubles/",
        "Id": "tampere-finland-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/temuco-chile/",
        "Id": "temuco-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/vina-del-mar-chile/",
        "Id": "vina-del-mar-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tenerife-spain/",
        "Id": "tenerife-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tenerife-2-spain/",
        "Id": "tenerife-2-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tenerife-3-spain/",
        "Id": "tenerife-3-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tiburon-usa/",
        "Id": "tiburon-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/todi-italy/",
        "Id": "todi-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/todi-italy-2020/",
        "Id": "todi-italy-2020"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/toulouse-france/",
        "Id": "toulouse-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/trieste-italy-singles/",
        "Id": "trieste-italy-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/trieste-italy-doubles/",
        "Id": "trieste-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/trieste-italy/",
        "Id": "trieste-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/troisdorf-germany/",
        "Id": "troisdorf-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/troyes-france/",
        "Id": "troyes-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tulln-austria-singles/",
        "Id": "tulln-austria-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tulln-austria-doubles/",
        "Id": "tulln-austria-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/turin-italy/",
        "Id": "turin-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/tunis-tunisia/",
        "Id": "tunis-tunisia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/valencia-spain/",
        "Id": "valencia-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/vancouver-canada-singles/",
        "Id": "vancouver-canada-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/vancouver-canada-doubles/",
        "Id": "vancouver-canada-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/verona-italy-singles/",
        "Id": "verona-italy-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/verona-italy-doubles/",
        "Id": "verona-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/verona-italy/",
        "Id": "verona-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/vicenza-italy/",
        "Id": "vicenza-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/villa-maria-argentina/",
        "Id": "villa-maria-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/vilnius-lithuania/",
        "Id": "vilnius-lithuania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/warsaw-poland/",
        "Id": "warsaw-poland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/waco-usa/",
        "Id": "waco-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/winnipeg-canada/",
        "Id": "winnipeg-canada"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/yokkaichi-japan/",
        "Id": "yokkaichi-japan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/yokohama-japan/",
        "Id": "yokohama-japan"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/winnipeg-canada-doubles/",
        "Id": "winnipeg-canada-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/zadar-croatia/",
        "Id": "zadar-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/zagreb-croatia/",
        "Id": "zagreb-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/zug-switzerland/",
        "Id": "zug-switzerland"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/zug-switzerland-doubles/",
        "Id": "zug-switzerland-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-atp-challenger.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/atp-challenger/kiskut-open-hungary/",
        "Id": "kiskut-open-hungary"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
    "CategoryName": "Billie Jean King Cup",
    "Id": "fed-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Pool A",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-pool-a/",
        "Id": "group-1-europe-africa-pool-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Pool B",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-pool-b/",
        "Id": "group-1-europe-africa-pool-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Americas",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-americas/",
        "Id": "group-1-americas"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Asia/Oceania",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-asia-oceania/",
        "Id": "group-1-asia-oceania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Play-off 1-2",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-play-off-1-2/",
        "Id": "group-1-europe-africa-play-off-1-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Play-off 5-6",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-play-off-5-6/",
        "Id": "group-1-europe-africa-play-off-5-6"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Playoff 7-8",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-play-off-7-8/",
        "Id": "group-1-europe-africa-play-off-7-8"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Promotion",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-promotion/",
        "Id": "group-1-europe-africa-promotion"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Group 1: Europe/Africa: Relegation",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/group-1-europe-africa-relegation/",
        "Id": "group-1-europe-africa-relegation"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-fed-cup.jpg",
        "LeagueName": "Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/fed-cup/qualification/",
        "Id": "qualification"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
    "CategoryName": "Davis Cup",
    "Id": "davis-cup",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
        "LeagueName": "World Group I",
        "LeagueLink": "https://www.livescore.com/en/tennis/davis-cup/world-group-i/",
        "Id": "world-group-i"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
        "LeagueName": "World Group II",
        "LeagueLink": "https://www.livescore.com/en/tennis/davis-cup/world-group-ii/",
        "Id": "world-group-ii"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
        "LeagueName": "Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/davis-cup/qualification/",
        "Id": "qualification"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
        "LeagueName": "World Group 1: Play-offs",
        "LeagueLink": "https://www.livescore.com/en/tennis/davis-cup/world-group-1st-play-off/",
        "Id": "world-group-1st-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-davis-cup.jpg",
        "LeagueName": "World Group 2: Play-offs",
        "LeagueLink": "https://www.livescore.com/en/tennis/davis-cup/world-group-2nd-play-off/",
        "Id": "world-group-2nd-play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
    "CategoryName": "Exhibition",
    "Id": "exhibition",
    "Leagues": [
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Adria Tour: play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/adria-tour-play-off/",
        "Id": "adria-tour-play-off"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Adria Tour: Group A",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/adria-tour-group-a/",
        "Id": "adria-tour-group-a"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Adria Tour: Group B",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/adria-tour-group-b/",
        "Id": "adria-tour-group-b"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Altec Styslinger Tennis Exhibition",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/altec-styslinger-tennis-exhibition/",
        "Id": "altec-styslinger-tennis-exhibition"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of the Brits: play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-brits-play-off/",
        "Id": "battle-of-brits-play-off"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of Brits: Cathie Sabin Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-brits-cathie-sabin-group/",
        "Id": "battle-of-brits-cathie-sabin-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of the Brits: Greg Rusedski Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-the-brits-greg-rusedski-group/",
        "Id": "battle-of-the-brits-greg-rusedski-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of Brits: Paul Hutchins Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-brits-paul-hutchins-group/",
        "Id": "battle-of-brits-paul-hutchins-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of the Brits Team Tennis",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-the-brits-team-tennis/",
        "Id": "battle-of-the-brits-team-tennis"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Battle of Brits: Tim Henman Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/battle-of-brits-tim-henman-group/",
        "Id": "battle-of-brits-tim-henman-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "bett1 ACES 1",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/bett1-aces-1/",
        "Id": "bett1-aces-1"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "bett1 ACES 2",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/bett-aces-2/",
        "Id": "bett-aces-2"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Bratislava Open",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/bratislava-open/",
        "Id": "bratislava-open"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Bratislava Open: Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/bratislava-open-women/",
        "Id": "bratislava-open-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT - Nice: play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft-nice-play-off/",
        "Id": "challenge-elite-fft-nice-play-off"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT - Nice: Group Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft-nice-group-stage/",
        "Id": "challenge-elite-fft-nice-group-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT 2 - Cannes: Group Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft2-cannes-group-stage/",
        "Id": "challenge-elite-fft2-cannes-group-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT 2 - Cannes: Final Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft-2-cannes-final-stage/",
        "Id": "challenge-elite-fft-2-cannes-final-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT 3 - Villeneuve-Loubet: play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft-3-villeneuve-loubet-play-off/",
        "Id": "challenge-elite-fft-3-villeneuve-loubet-play-off"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Challenge Elite FFT 3 - Villeneuve-Loubet: Group Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/challenge-elite-fft-3-villeneuve-loubet-group-stage/",
        "Id": "challenge-elite-fft-3-villeneuve-loubet-group-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Credit One Bank Invitational",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/credit-one-bank-invitational/",
        "Id": "credit-one-bank-invitational"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "DTB German Pro Series",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/dtb-german-pro-series/",
        "Id": "dtb-german-pro-series"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "DTB German Pro Series Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/dtb-german-pro-series-women/",
        "Id": "dtb-german-pro-series-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "DTB German Pro Series: 2nd Stage Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/dtb-german-pro-series-2nd-stage-women/",
        "Id": "dtb-german-pro-series-2nd-stage-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "DTB German Pro Series: 3rd Stage Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/dtb-german-pro-series-3rd-stage-women/",
        "Id": "dtb-german-pro-series-3rd-stage-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "DraftKings All-American Team Cup",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/draftkings-all-american-team-cup/",
        "Id": "draftkings-all-american-team-cup"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Eastern European Championship",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/eastern-european-championship/",
        "Id": "eastern-european-championship"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Eastern European Championship: 2nd Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/eastern-european-championship-2-stage/",
        "Id": "eastern-european-championship-2-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Eastern European Championship: 1st Stage Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/eastern-european-championship-1st-stage-women/",
        "Id": "eastern-european-championship-1st-stage-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Eastern European Championship: 2nd Stage Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/eastern-european-championship-2nd-stage-women/",
        "Id": "eastern-european-championship-2nd-stage-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Elle Spirit Open: play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/elle-spirit-open-play-off/",
        "Id": "elle-spirit-open-play-off"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Elle Spirit Open: Pink Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/elle-spirit-open-pink-group/",
        "Id": "elle-spirit-open-pink-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Elle Spirit Open: Black Group",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/elle-spirit-open-black-group/",
        "Id": "elle-spirit-open-black-group"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Exo-Tennis Exhibition: 1st Stage",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/exo-tennis-exhibition-1st-stage/",
        "Id": "exo-tennis-exhibition-1st-stage"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Exo-Tennis Exhibition Women",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/exo-tennis-exhibition-women/",
        "Id": "exo-tennis-exhibition-women"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "International Premier League A",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/international-premier-league-a/",
        "Id": "international-premier-league-a"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "International Tennis Series",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/international-tennis-series/",
        "Id": "international-tennis-series"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Italian Championships: Qualification",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/italian-championship-qualification/",
        "Id": "italian-championship-qualification"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Italian Championships",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/italian-championships/",
        "Id": "italian-championships"
      },
      {
        "LeagueIcon": "https://www.livescore.com/ls-web-assets/images/international-tennis-a416f4268ac1b1f9b9eb5e71d9d92c0e.webp",
        "LeagueName": "Italian Championships 2",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/italian-championships-2/",
        "Id": "italian-championships-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/liga-mapfre-de-tenis-men/",
        "Id": "liga-mapfre-de-tenis-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/liga-mapfre-de-tenis-women/",
        "Id": "liga-mapfre-de-tenis-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/livescore-cup-play-off/",
        "Id": "livescore-cup-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/livescore-cup-group-a/",
        "Id": "livescore-cup-group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/livescore-cup-group-b/",
        "Id": "livescore-cup-group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/matchplay-120-series-men/",
        "Id": "matchplay-120-series-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/matchplay-120-series-women/",
        "Id": "matchplay-120-series-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/o-pohar-pratelstvi-men/",
        "Id": "o-pohar-pratelstvi-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/o-pohar-pratelstvi-women/",
        "Id": "o-pohar-pratelstvi-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/piestany-ladies-open/",
        "Id": "piestany-ladies-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/privatbanka-open/",
        "Id": "privatbanka-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/region-of-valencia/",
        "Id": "region-of-valencia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/securitas-pro-cup/",
        "Id": "securitas-pro-cup"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/tennis-point-exhibition-series-3rd-stage/",
        "Id": "tennis-point-exhibition-series-3rd-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/tennis-point-exhibition-series-2nd-stage/",
        "Id": "tennis-point-exhibition-series-2nd-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/tennis-point-exhibition-series-1st-stage-play-off/",
        "Id": "tennis-point-exhibition-series-1st-stage-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/tennis-point-exhibition-series-1st-stage/",
        "Id": "tennis-point-exhibition-series-1st-stage"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/thiems7-play-off/",
        "Id": "thiems7-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/thiems7-ifa-group/",
        "Id": "thiems7-ifa-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/thiems7-soccercoin-group/",
        "Id": "thiems7-soccercoin-group"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/tipsport-charity-cup-women/",
        "Id": "tipsport-charity-cup-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/ultimate-tennis-showdown-play-off/",
        "Id": "ultimate-tennis-showdown-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/ultimate-tennis-showdown/",
        "Id": "ultimate-tennis-showdown"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/ultimate-tennis-showdown-2/",
        "Id": "ultimate-tennis-showdown-2"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/utr-pro-match-series-men/",
        "Id": "utr-pro-match-series-men"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/utr-pro-match-series-women/",
        "Id": "utr-pro-match-series-women"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/winners-open-play-off/",
        "Id": "winners-open-play-off"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/winners-open/",
        "Id": "winners-open"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/world-teamtennis/",
        "Id": "world-teamtennis"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/world-tennis-league/",
        "Id": "world-tennis-league"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-exhibition.jpg",
        "LeagueName": "",
        "LeagueLink": "https://www.livescore.com/en/tennis/exhibition/generali-austrian-pro-series/",
        "Id": "generali-austrian-pro-series"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-next-gen-atp-finals.jpg",
    "CategoryName": "Next Generation ATP Finals",
    "Id": "next-gen-atp-finals",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-next-gen-atp-finals.jpg",
        "LeagueName": "Group A",
        "LeagueLink": "https://www.livescore.com/en/tennis/next-gen-atp-finals/group-a/",
        "Id": "group-a"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-next-gen-atp-finals.jpg",
        "LeagueName": "Group B",
        "LeagueLink": "https://www.livescore.com/en/tennis/next-gen-atp-finals/group-b/",
        "Id": "group-b"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-next-gen-atp-finals.jpg",
        "LeagueName": "Play-off",
        "LeagueLink": "https://www.livescore.com/en/tennis/next-gen-atp-finals/play-off/",
        "Id": "play-off"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-olympics-men.jpg",
    "CategoryName": "Olympics Men",
    "Id": "olympics-men",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-olympics-men.jpg",
        "LeagueName": "Men's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/olympics-men/mens-singles/",
        "Id": "mens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-olympics-men.jpg",
        "LeagueName": "Men's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/olympics-men/mens-doubles/",
        "Id": "mens-doubles"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-olympics-women.jpg",
    "CategoryName": "Olympics Women",
    "Id": "olympics-women",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-olympics-women.jpg",
        "LeagueName": "Women's Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/olympics-women/womens-singles/",
        "Id": "womens-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-olympics-women.jpg",
        "LeagueName": "Women's Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/olympics-women/womens-doubles/",
        "Id": "womens-doubles"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-olympics.jpg",
    "CategoryName": "Olympics",
    "Id": "olympics",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-olympics.jpg",
        "LeagueName": "Mixed Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/olympics/mixed-doubles/",
        "Id": "mixed-doubles"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
    "CategoryName": "WTA Challenger",
    "Id": "wta-challenger",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Andorra",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/andorra/",
        "Id": "andorra"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Angers, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/open-angers-arena-loire-france/",
        "Id": "open-angers-arena-loire-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Bari, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/bari-italy/",
        "Id": "bari-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Bari, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/bari-italy-doubles/",
        "Id": "bari-italy-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Bastad, Sweden",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/bastad/",
        "Id": "bastad"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Belgrade, Serbia",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/belgrade-serbia/",
        "Id": "belgrade-serbia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Bol, Croatia",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/bol-croatia/",
        "Id": "bol-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Budapest",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/budapest/",
        "Id": "budapest"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Bucharest, Romania",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/bucharest-romania/",
        "Id": "bucharest-romania"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Buenos Aires, Argentina",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/buenos-aires-argentina/",
        "Id": "buenos-aires-argentina"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Cali, Colombia",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/cali-colombia/",
        "Id": "cali-colombia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Charleston, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/charlestown-usa/",
        "Id": "charlestown-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Chicago, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/chicago-usa/",
        "Id": "chicago-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Colina, Chile",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/colina-chile/",
        "Id": "colina-chile"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Columbus, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/columbus-usa/",
        "Id": "columbus-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Concord, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/concord-usa/",
        "Id": "concord-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Concord, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/concord-usa-doubles/",
        "Id": "concord-usa-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Gaiba, Italy",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/gaiba-italy/",
        "Id": "gaiba-italy"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Iasi, Romania",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/iasi-romania-singles/",
        "Id": "iasi-romania-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Iasi, Romania",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/iasi-romania-doubles/",
        "Id": "iasi-romania-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Indian Wells, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/indian-wells-usa/",
        "Id": "indian-wells-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Karlsruhe, Germany",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/karlsruhe-germany/",
        "Id": "karlsruhe-germany"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Limoges",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/limoges/",
        "Id": "limoges"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Makarska, Croatia",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/makarska-croatia/",
        "Id": "makarska-croatia"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Marbella, Spain",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/marbella-spain/",
        "Id": "marbella-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Midland, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/midland-usa/",
        "Id": "midland-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Montevideo, Uruguay",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/montevideo-uruguay/",
        "Id": "montevideo-uruguay"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Newport Beach, USA",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/newport-beach-usa/",
        "Id": "newport-beach-usa"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Paris, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/paris-france/",
        "Id": "paris-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Prague, Czech Republic",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/prague-czech-republic/",
        "Id": "prague-czech-republic"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Reus, Spain",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/reus-spain/",
        "Id": "reus-spain"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Rouen, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/rouen-france/",
        "Id": "rouen-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Saint-Malo, France",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/saint-malo-france/",
        "Id": "saint-malo-france"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "San Luis Potosi, Mexico",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/san-luis-potosi-mexico/",
        "Id": "san-luis-potosi-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Seoul, South Korea",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/seoul-south-korea/",
        "Id": "seoul-south-korea"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Tampico, Mexico",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/tampico-mexico/",
        "Id": "tampico-mexico"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Vancouver, Canada",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/vancouver-canada-singles/",
        "Id": "vancouver-canada-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Vancouver, Canada",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/vancouver-canada-doubles/",
        "Id": "vancouver-canada-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-challenger.jpg",
        "LeagueName": "Valencia, Spain",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-challenger/valencia-spain/",
        "Id": "valencia-spain"
      }
    ]
  },
  {
    "CategoryIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
    "CategoryName": "WTA Finals",
    "Id": "wta-finals",
    "Leagues": [
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Final Stage, Singles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/final-stage-singles/",
        "Id": "final-stage-singles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Final Stage, Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/final-stage-doubles/",
        "Id": "final-stage-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Group Tracy Austin",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/group-tracy-austin/",
        "Id": "group-tracy-austin"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Group Nancy Richey",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/group-nancy-richey/",
        "Id": "group-nancy-richey"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Group Rosie Casals, Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/group-rosie-casals-doubles/",
        "Id": "group-rosie-casals-doubles"
      },
      {
        "LeagueIcon": "https://static.livescore.com/i2/fh/mx-wta-finals.jpg",
        "LeagueName": "Group Pam Shriver, Doubles",
        "LeagueLink": "https://www.livescore.com/en/tennis/wta-finals/group-pam-shriver-doubles/",
        "Id": "group-pam-shriver-doubles"
      }
    ]
  }
]
import { Suspense } from "react";
import { Await, LoaderFunctionArgs, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { CompetitionFixtureGroup, CompetitionGroup, getNavLink, LiveDate, SportParam, ROUTING } from "../mods/common";
import { deferHold, useAsyncValue, useDeferredLoaderData } from "../mods/defer";
import Services from "../mods/services";
import DateNavigator, { DateChangeData } from "./dateNavigator";
import { GlobalSpinner } from "./globalSpinner";
import { padDate, padMonth } from "@lib/tools"
import MatchList from "./soccer/matchList";
import MatchListBasketball from './basketball/matchList'
import MatchListHockey from './hockey/matchList'
import { Virtuoso } from 'react-virtuoso'

import { CompetitionBasketballMatches, CompetitionHockeyMatches } from '../mods/common'
import NoData from "./shared/NoData";
import MatchListFootball from "./football/matchListFootball";

type LoaderDataType = {
    competitions: CompetitionGroup[] | any
    matches: CompetitionFixtureGroup | CompetitionBasketballMatches[] | CompetitionHockeyMatches[] | any
}

type IProps = {
    route: string
}

export const DEFAULT_OTHERS_LEAGUES = 1111111

export async function loader(arg: LoaderFunctionArgs, route: string) {
    const sParam = arg.params as SportParam;
    const date = sParam.date ? new Date(sParam.date) : new Date();

    const newDate = date.getUTCDate();
    const newMonth = date.getUTCMonth();
    const newYear = date.getUTCFullYear();
   
    let tComp: any;
    let tMatches: any;

    if (route === ROUTING.SOCCER) {
        tComp = Services.getFootballList();
        if (sParam.date) {
            tMatches = Services.getFootballListFromDate(newYear, newMonth + 1, newDate )
        } else {
            tMatches =  Services.getFootballList()
        }
    } else if (route === ROUTING.BASKETBALL) {
        tComp = Services.getBasketballCompetitionList();
        tMatches = (sParam.date === LiveDate) ? Services.getBasketballLiveSchedule() : Services.getBasketballSchedule(newYear, newMonth + 1, newDate);
    } else if (route === ROUTING.HOCKEY) {
        tComp = Services.getHockeyCompetitionList();
        tMatches = (sParam.date === LiveDate) ? Services.getHockeyLiveSchedule() : Services.getHockeySchedule(newYear, newMonth + 1, newDate);
    }
    return deferHold<LoaderDataType>(async () => ({
        competitions: await tComp,
        matches: await tMatches
    }));
}

export default function MatchesLayout({ route }: IProps) {



    
    const params = useParams<SportParam>();
    const nav = useNavigate();
    const { result } = useDeferredLoaderData<LoaderDataType>();

    function dateChanged(e: DateChangeData) {
        const date = e.date ? new Date(e.date) : new Date();
        const newDate = date.getDate();
        const newMonth = date.getMonth() + 1;
        const newYear = date.getFullYear();
        const newdate = newYear + "-" + padMonth(newMonth) + "-" + padDate(newDate);

        nav(getNavLink({
            cid: params.cid,
            coid: params.coid,
            date: e.isLive ? LiveDate : newdate
        }))
    }

    return <>
        <DateNavigator date={params.date} onDateChanged={dateChanged} />
        <Suspense fallback={<GlobalSpinner />}>
            <Await resolve={result}>
                <RenderAwaited nav={nav} route={route} isLive={params.date} />
            </Await>
        </Suspense>
    </>
}

function RenderAwaited(props: { nav: NavigateFunction, route: string, isLive?: string }) {

    
    let { matches } = useAsyncValue<LoaderDataType>()

    const LEAGUES_TOP_PRIORITY = JSON.parse(process.env?.REACT_APP_LEAGUES_TOP_PRIORITY || `[]`).reverse()

    const sortLeagues: any[] = Object.entries(matches)
        .sort(([keyA], [keyB]) => {
            return LEAGUES_TOP_PRIORITY.indexOf(matches[keyB].name) - LEAGUES_TOP_PRIORITY.indexOf(matches[keyA].name)
        })
        .map(([key, value]: any) => ({ id: Number(key), ...value }));

    function filterLive(data: any) {
        if (props.isLive === LiveDate) {
            matches = data.map((item: any) => {
                return {
                    LeagueName: item.LeagueName,
                    CategoryName: item.CategoryName,
                    LeagueIcon: item.LeagueIcon,
                    Matches: item.Matches.filter((match: any) => match.IsLive === true)
                }
            }).filter((item: any) => item.Matches.length > 0);
        }
    }

    switch (props.route) {
        case ROUTING.SOCCER:
            const updaters = Services.getSoccerUpdaters(true);
            let serverTime = matches.serverTime;
            let newMatches: any = [];
            for (let i = 0; i < sortLeagues.length; i++) {
                let match = sortLeagues[i];
                if (typeof match === "object" && match?.id) {
                    match.group = Services.getCompetitionGroup(match.id) || {id: DEFAULT_OTHERS_LEAGUES, name: 'Others', logo: 'https://data.7m.com.cn/database/icons/96.jpg'}
                    newMatches.push(match);
                }
            }

            // for (let key in matches) {
            //     let match = matches[key];
            //     if (typeof match == "object") {
            //         match.id = parseInt(key);
            //         newMatches.push(match);
            //     }
            // }
            if(Object.keys(matches).length === 1) return <NoData text="There are no games scheduled" />;
            return  matches && (
                <Virtuoso
                  useWindowScroll
                  totalCount={matches.length}
                  itemContent={(index) => {
               
                    
                    return <MatchListFootball league={matches[index]} />
                  }}
                />
              )
        case ROUTING.BASKETBALL:
            filterLive(matches);
            if(matches.length === 0) return <NoData text="There are no games scheduled" />;
            return matches && <Virtuoso
                useWindowScroll
                totalCount={matches.length}
                itemContent={index => <MatchListBasketball
                    key={`fixtures-${index}`}
                    league={matches[index]}
                    navigate={props.nav} />}
            />
        case ROUTING.HOCKEY:
            filterLive(matches);
            if(matches.length === 0) return <NoData text="There are no games scheduled" />;
            return matches && <Virtuoso
                useWindowScroll
                totalCount={matches.length}
                itemContent={index => <MatchListHockey
                    key={`fixtures-${index}`}
                    league={matches[index]}
                    navigate={props.nav} />}
            />
    }

    return <NoData text="There are no games scheduled" />
}


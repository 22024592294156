import { CompetitionItem, LeagueItem } from "@lib/models";
import { LinkHelper } from "@lib/tools";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { ROUTING, SportParam } from "../mods/common";
import Services from "../mods/services";
import { GlobalSpinner } from "./globalSpinner";
import { convertToSlug } from "../utils/ConvertSlug";
import { withRouter } from "./competitionList";

const DefaultShowCount = 7;

type Props = {
    navigate: NavigateFunction
    params: SportParam
    route: string
    isMobile?: boolean
    handleShowHideMenu?: () => void;
    router: any
} & WithTranslation

type State = {
    loading: boolean
    expand1: boolean
    expand2: boolean
    searchMode: boolean
    search?: string
    active1?: CompetitionItem
    active2?: LeagueItem
    list?: CompetitionItem[]
}

class CompetitionList2 extends Component<Props, State> {
    private searchInt = 0
    private searchInput: React.RefObject<HTMLInputElement> = React.createRef();

    state: State = {
        loading: true,
        expand1: false,
        expand2: false,
        searchMode: false,
        list: undefined,
    }

    handleClearSearch() {
        this.searchInt++;
        const inp: React.MutableRefObject<HTMLInputElement> = {
            current: this.searchInput.current!
        };
        inp.current.value = '';

        this.setState(st => ({
            searchMode: false,
            active1: undefined,
        }))
    }

    setSearch(val: string) {
        this.searchInt++;

        ((search: string, si: number) => {
            setTimeout(() => {
                if (si === this.searchInt) {
                    this.setState(st => ({
                        expand1: st.expand1,
                        expand2: st.expand2,
                        searchMode: !!search,
                        search: search,
                        active1: undefined,
                        active2: undefined
                    }))
                }
            }, 300)
        }).call(this, val, this.searchInt);
    }

    handleTogglePriorityCo = () => {
        this.setState(st => ({
            expand2: !st.expand2,
        }))
    }

    componentDidMount(): void {
        this.refreshList();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        let paths = window.location.pathname.split('/');
        let categoryName = paths[2] ? (paths[2]) : '';
        let leagueId = paths[3] ? (paths[3]) : '';
        let active1: any = this.state.list?.find((item: CompetitionItem) => convertToSlug(item.CategoryName.toString()) === categoryName);
        let active2: any = active1?.Leagues.find((item: LeagueItem) => convertToSlug(item.LeagueName || item.Id) === leagueId);

        if (!this.state.list || prevProps.route !== this.props.route) {
            this.refreshList();
        }

        if ((prevProps.params.cid !== this.props.params.cid) || (prevProps.params.coid !== this.props.params.coid)) {
            active1 && active2 && this.setState(st => ({
                ...st,
                active1: active1,
                active2: active2,
            }));
        }

        if ((prevProps.router?.location?.pathname !== '/basketball') && !this.props.router?.params?.cid && this.state.active2) {
            this.setState(st => ({
                ...st,
                active1: undefined,
                active2: undefined,
            }));
        }
    }

    getServicesSport(route: string): Promise<CompetitionItem[]> {
        switch (route) {
            case ROUTING.TENNIS:
                return Services.getTennisCompetitionList()
            case ROUTING.CRICKET:
                return Services.getCricketCompetitionList()
            case ROUTING.HOCKEY:
                return Services.getHockeyCompetitionList()
            case ROUTING.BASKETBALL:
                return Services.getBasketballCompetitionList()
            default:
                console.log(`unknown route=${route}`)
                return new Promise((resolve, reject) => { resolve([]) });
        }
    }

    refreshList() {
        const sports = this.getServicesSport(this.props.route)

        sports.then((list: CompetitionItem[]) => {
            let paths = window.location.pathname.split('/');
            let categoryName = (paths[2]);
            let leagueId = (paths[3]);
            let active1: any = list.find((item: CompetitionItem, index: number) => convertToSlug(item.CategoryName) === categoryName);
            let active2: any = active1?.Leagues.find((item: LeagueItem, index: number) => convertToSlug(item.LeagueName || item.Id) === leagueId);

            this.setState(st => ({
                loading: false,
                active1: active1,
                active2: active2,
                list: list,
            }));
        }, (err: any) => {
            console.log(err);
        });
    }

    render(): React.ReactNode {
        return <>
            {this.state.active1 ? this.renderActiveMatches() : this.renderSearch()}
            <hr className="md:block hidden border-primary-darker dark:border-zinc-200  dark:border-zinc-200 pb-5" />
            <div className={`${!this.props.isMobile ? "flex md:block flex-col" : "block md:flex md:flex-col relative z-10 bg-main-bg dark:bg-white max-h-[calc(100vh_-_55vh)] overflow-auto"}`}>
                {this.state.loading
                    ? <GlobalSpinner />
                    : (this.state.active1
                        ? this.renderLevel2()
                        : this.renderLevel1())
                }
            </div>
        </>
    }

    renderActiveMatches() {
        return <button onClick={this.onButtonDeactiveClicked.bind(this)} className="flex items-center h-10 mt-1 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-primary-lighter dark:fill-black rotate-180">
                <path d="M9.00003 20C8.80228 19.9999 8.60898 19.9413 8.44457 19.8314C8.28016 19.7215 8.15202 19.5653 8.07635 19.3826C8.00068 19.1999 7.98088 18.9989 8.01945 18.8049C8.05802 18.611 8.15322 18.4328 8.29303 18.293L14.586 12L8.29303 5.70698C8.11087 5.51838 8.01008 5.26578 8.01236 5.00358C8.01464 4.74138 8.1198 4.49057 8.30521 4.30516C8.49062 4.11975 8.74143 4.01458 9.00363 4.01231C9.26583 4.01003 9.51843 4.11082 9.70703 4.29298L16.707 11.293C16.8945 11.4805 16.9998 11.7348 16.9998 12C16.9998 12.2651 16.8945 12.5195 16.707 12.707L9.70703 19.707C9.51954 19.8945 9.26523 19.9999 9.00003 20Z"></path>
            </svg>
            <span className={`font-medium text-lg pl-3`}>{this.state.active1?.CategoryName}</span>
        </button>
    }

    onButtonDeactiveClicked() {
        this.setState({
            active1: undefined
        });
    }

    renderSearch() {
        let translateSearch = this.props.t("Search");

        return <div className="relative rounded-lg shadow-sm">
            <div className="flex items-center h-10 mb-3">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-primary h-6 w-6">
                        <path d="M21.5301 20.47L17.6891 16.629C19.1452 14.8997 19.8771 12.6747 19.7321 10.4186C19.5871 8.16256 18.5764 6.04965 16.9109 4.52091C15.2454 2.99217 13.0538 2.16574 10.7936 2.21411C8.53334 2.26247 6.37913 3.1819 4.78054 4.78048C3.18196 6.37906 2.26253 8.53328 2.21417 10.7935C2.1658 13.0537 2.99224 15.2453 4.52097 16.9108C6.04971 18.5763 8.16262 19.587 10.4187 19.732C12.6748 19.877 14.8997 19.1451 16.6291 17.689L20.4701 21.53C20.6123 21.6625 20.8003 21.7346 20.9946 21.7312C21.1889 21.7278 21.3743 21.6491 21.5117 21.5116C21.6491 21.3742 21.7278 21.1889 21.7313 20.9945C21.7347 20.8002 21.6626 20.6122 21.5301 20.47ZM3.75009 11C3.75009 9.56611 4.17529 8.1644 4.97193 6.97214C5.76857 5.77989 6.90087 4.85063 8.22563 4.3019C9.5504 3.75316 11.0081 3.60959 12.4145 3.88933C13.8209 4.16908 15.1127 4.85957 16.1266 5.8735C17.1405 6.88743 17.831 8.17926 18.1108 9.58562C18.3905 10.992 18.2469 12.4497 17.6982 13.7745C17.1495 15.0992 16.2202 16.2315 15.028 17.0282C13.8357 17.8248 12.434 18.25 11.0001 18.25C9.07792 18.2479 7.23508 17.4834 5.8759 16.1242C4.51672 14.765 3.7522 12.9222 3.75009 11Z" />
                    </svg>
                </div>
                <input
                    id="searchBox"
                    ref={this.searchInput}
                    onChangeCapture={(e) => this.setSearch(e.currentTarget.value)}
                    type="text"
                    name="searchBox"
                    className=" block w-full rounded-lg border-0 items-center pl-10 pt-2 pb-2 bg-main-bg-lighter dark:bg-slate-100 text-primary placeholder:font-medium focus:ring-2 focus:ring-accent"
                    placeholder={translateSearch} />
                <button className={(this.state.searchMode ? "" : "hidden ") + "absolute inset-y-0 right-0 flex items-center pr-2"} onClick={this.handleClearSearch.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" className="fill-primary h-4 w-4">
                        <path d="M16.203 0c0.44 0 0.797 0.357 0.797 0.797 0 0.184-0.062 0.353-0.166 0.487l-0.068 0.077-7.139 7.14 7.14 7.14c0.143 0.143 0.233 0.343 0.233 0.563 0 0.44-0.357 0.797-0.797 0.797-0.22 0-0.42-0.089-0.563-0.234l-7.14-7.139-7.14 7.14c-0.115 0.115-0.266 0.194-0.433 0.223l-0.13 0.010c-0.44 0-0.797-0.357-0.797-0.797 0-0.22 0.089-0.42 0.234-0.563l7.139-7.14-7.14-7.14-0.067-0.077c-0.104-0.134-0.166-0.303-0.166-0.487 0-0.44 0.357-0.797 0.797-0.797 0.184 0 0.353 0.062 0.487 0.166l0.077 0.068 7.14 7.139 7.14-7.14 0.076-0.067c0.134-0.104 0.303-0.166 0.487-0.166z"></path>
                    </svg>
                </button>
            </div>
        </div>
    }

    renderLevel1() {
        const list: any = [];
        const search = this.state.search?.toLowerCase() || "";
        this.state.list?.forEach((competitionItem: CompetitionItem, index: number) => {
            if (search && this.state.searchMode) {
                if (competitionItem.CategoryName.toLowerCase().indexOf(search) < 0) {
                    return;
                }
            }

            list.push(<button
                key={`${competitionItem.Id}-${competitionItem.CategoryName}`}
                onClick={() => this.onButtonMatchesClicked(competitionItem)}
                className="flex items-center mb-4 w-full">
                <img src={competitionItem.CategoryIcon} alt={competitionItem.CategoryName} className="w-8 max-h-[20px] min-h-[20px] min-w-[30px] max-w-[30px] rounded" />
                <span className={`${this.state.active1 === competitionItem ? "text-accent font-semibold" : " text-primary-lighter dark:text-black"} pl-3 truncate`}>
                    {competitionItem.CategoryName}
                </span>
            </button>);
        })
        return <>
            {this.state.expand1 ? list : list.slice(0, DefaultShowCount)}
            {list.length > DefaultShowCount
                ? <button
                    className="rounded-lg bg-accent  text-primary-lighter text-lg font-semibold w-full p-3 mt-1"
                    onClick={this.onButtonToggleLevel1Clicked}
                >{this.props.t(!this.state.expand1 ? "View All" : "Less")}
                </button>
                : <></>}
        </>
    }

    onButtonMatchesClicked(active1: CompetitionItem) {
        this.setState(st => ({
            active1: active1,
        }))
    }

    onButtonToggleLevel1Clicked = () => {
        this.setState(st => ({
            expand1: !st.expand1,
        }))
    }

    renderLevel2() {
        let competitionItem: CompetitionItem = this.state.active1!!;
        let list = competitionItem.Leagues || [];
        if (!this.state.expand2) {
            list = list.slice(0, DefaultShowCount);
        }
        return <>
            {list.map((leagueItem: LeagueItem, index: number) => {
                return <button
                    key={`Level2-${leagueItem.Id}-${leagueItem.LeagueName}`}
                    onClick={() => this.onButtonLeagueClicked(competitionItem, leagueItem)}
                    className="flex items-center mb-4 pl-2 w-full">
                    {leagueItem.LeagueIcon ? <img src={leagueItem.LeagueIcon} alt={leagueItem.LeagueName} className="w-8 max-h-[20px] min-h-[20px] min-w-[30px] max-w-[30px] rounded" /> : null}
                    <span className={`${this.state.active2 === leagueItem ? "text-accent font-semibold" : " text-primary-lighter dark:text-black"} pl-3 truncate`}>{leagueItem.LeagueName}</span>
                </button>
            })}
            {competitionItem.Leagues.length <= DefaultShowCount
                ? <></>
                : <button
                    className="rounded-lg bg-accent  text-primary-lighter  text-lg font-semibold w-full p-3 mt-1"
                    onClick={this.onButtonToggleLevel2Clicked.bind(this)}
                >{this.props.t(!this.state.expand2 ? "View All" : "Less")}
                </button>
            }
        </>
    }

    onButtonToggleLevel2Clicked() {
        this.setState(st => ({
            expand2: !st.expand2,
        }))
    }

    onButtonLeagueClicked(competitionItem: CompetitionItem, leagueItem: LeagueItem) {
        this.setState(st => ({
            active2: leagueItem,
        }));
        typeof this.props.handleShowHideMenu === 'function' && this.props.handleShowHideMenu()
        this.props.navigate(`/${this.props.route}/${convertToSlug(competitionItem.CategoryName)}/${convertToSlug(leagueItem.Id)}/overview?link=${LinkHelper.encrypt(leagueItem.LeagueLink)}`);
    }

    componentWillUnmount(): void {
        this.searchInt++;
    }
}

export default withTranslation()(withRouter(CompetitionList2));
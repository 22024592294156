import { MatchCricketDetail, MatchesCricket } from "@lib/models";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { redirect, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import Commentary from "../shared/commentary";
import TableRounded from "../shared/tableRounded";
import TableTbodyRounded from "../shared/tableTbodyRounded";
import TabsComponent, { ITabData } from "../tabs/tabComponent";
import TextLinker from "../textLinker";
import CricketMatchListItem from "./cricketMatchListItem";

const FLAG_COMMENTARY = "commentary";

type Props = {
    info?: boolean,
    scorecard?: boolean,
    summary?: boolean,
    teams?: boolean,
    table?: boolean,
} & WithTranslation


function CricketMatchDetailLayout(props: Props) {
    const [match, setMatch] = useState<MatchesCricket | null>()
    const [detail, setDetail] = useState<MatchCricketDetail>()
    const link = new URL(window.location.href).searchParams.get('link');
    const params = useParams();
    const matchId = params.matchId;
    const coid = params.coid
    const [searchParams] = useSearchParams();
    const nav = useNavigate();

    useEffect(() => {
        (async () => {
            if (!matchId || !link || !coid) { redirect(getNavLink()) }
            else {
                const tMatch = await Services.getCricketMatch(matchId)
                const tDetail = await Services.getCricketDetail(matchId, link)
                tMatch && setMatch(tMatch)
                tDetail && setDetail(tDetail)
            }
        })()
    }, [matchId, link])


    detail && (document.title = `Cricket - ${detail.HomeName} vs ${detail.AwayName} - ${(props.info ? "Info" : props.scorecard ? "Scorecard" : props.summary ? "Summary" : props.teams ? "Teams" : props.table ? "Table" : "")} `);

    return <>
        {!detail && <GlobalSpinner />}
        {detail && <CompetitionLinker
            isButton={true}
            league={detail}
            coid={detail.LeagueId}
            navigate={nav} />}

        {match && detail && <CricketMatchListItem
            highlightName={true}
            navigate={nav}
            match={match}
            cid={params?.cid || detail.CategoryName}
            coid={params?.coid || detail.LeagueId}
            key={`$match-${detail.LeagueName}`}
        />}

        {detail && renderTabsComponent(detail)}
        {detail && renderBody(detail)}
    </>


    function renderTabsComponent(detail: MatchCricketDetail) {
        // const scoreCardsChilds: any = [];
        const summaryChilds: ITabData[] = [
            {
                name: 'Commentary',
                path: `summary/${FLAG_COMMENTARY}`,
                active: params.tab === FLAG_COMMENTARY,
            }
        ];
        // detail.pageProps.initialEventData.event.innings.forEach((inning: any, index: number) => {
        //     scoreCardsChilds.push({
        //         name: inning.name,
        //         path: `scorecard/${inning.id}`,
        //         active: props.scorecard && params.tab == inning.id,
        //     });
        //     summaryChilds.push({
        //         name: inning.name,
        //         path: `summary/${inning.id}`,
        //         active: props.summary && params.tab == inning.id,
        //     });
        // });
        // const isWicketActive = summaryChilds.find((wicketChild: any, index: number) => wicketChild.active) !== undefined;

        const data: ITabData[] = [
            {
                name: 'Info',
                path: 'info',
                active: props.info
            },
            // {
            //     name: 'Scorecard',
            //     path: scoreCardsChilds[scoreCardsChilds.length - 1].path,
            //     active: props.scorecard,
            //     childs: scoreCardsChilds,
            // },
        ]

        detail.Summary && data.push({
            name: 'Summary',
            path: `summary/${FLAG_COMMENTARY}`,
            active: props.summary,
            childs: summaryChilds,
        });
        detail.HomePlayers && data.push({
            name: 'Teams',
            path: 'teams',
            active: props.teams
        });
        detail.Table && data.push({
            name: 'Table',
            path: 'table',
            active: props.table
        });

        return <TabsComponent data={data} handleNavigateTo={(path) => handleNavigateTo(path, detail)} />
    }

    function renderBody(detail: MatchCricketDetail) {
        if (props.info) {
            return renderInfoTab(detail);
        }
        if (props.scorecard) {
            // let inning: any = cricketFakeSummary.pageProps.initialEventData.event.innings.find((inning, index) => { return inning.id == params.tab });
            // return renderScorecard(inning);
        }
        if (props.summary) {
            if (params.tab === FLAG_COMMENTARY) {
                return renderCommentaryTab(detail.Summary!);
            }
            // else {
            //     let inning: any = cricketFakeSummary.pageProps.initialEventData.event.innings.find((inning, index) => { return inning.id == params.tab });
            //     return renderWicketsTab(inning.wickets);
            // }
        }
        if (props.teams) {
            return renderTeamsTab(detail);
        }
        if (props.table) {
            return renderTableTab(detail.Table!);
        }
    }

    function renderInfoTab(detail: MatchCricketDetail) {
        return <>
            <TextLinker
                text="MATCH INFO"
                className="font-bold text-sm text-primary"
            />

            <TableTbodyRounded
                classTable=""
                classTR="h-14"
                classTD="px-4"
                classCols={[
                    "px-4 w-36",
                    "",
                ]}
                tbody={Object.entries(detail.MatchInfo).map((arr: any, index: number) => {
                    return [{ child: arr[0] }, { child: arr[1] }]
                })}
            />
        </>
    }

    // function renderScorecard(inning: any) {
    //     return <>
    //         {renderScorecardBatters(inning)}
    //         {renderScorecardStats(inning)}
    //         {renderScorecardBowling(inning)}
    //     </>
    // }
    // function renderScorecardBatters(inning: any) {
    //     return <TableTbodyRounded
    //         mark={true}
    //         classCols={[
    //             "text-left",
    //             "text-center w-12",
    //             "text-center w-12",
    //             "text-center w-12",
    //             "text-center w-12",
    //             "text-center w-20",
    //         ]}
    //         thead={[
    //             { child: "BATTERS", className: "text-left" },
    //             { child: "R", title: "Runs" },
    //             { child: "B", title: "Balls" },
    //             { child: "4S" },
    //             { child: "6S" },
    //             { child: "S/R", title: "Strike rate" },
    //         ]}
    //         tbody={inning.batsmen.map((personHome: any, index: number) => {
    //             let validStatus = personHome.status !== "yet to bat";
    //             return [
    //                 {
    //                     child: <>
    //                         <div className="text-gray-300">{personHome.personName}</div>
    //                         <div>{personHome.status}</div>
    //                     </>,
    //                     mark: personHome.isOnAField,
    //                 },
    //                 { child: validStatus ? personHome.runs : "", className: "text-gray-300" },
    //                 { child: validStatus ? personHome.balls : "" },
    //                 { child: validStatus ? personHome.fours : "" },
    //                 { child: validStatus ? personHome.sixes : "" },
    //                 { child: validStatus ? personHome.strikeRate : "" },
    //             ]
    //         })}
    //     />
    // }
    // function renderScorecardStats(inning: any) {
    //     return <>
    //         <TextLinker
    //             text="STATS"
    //             className="font-bold text-sm text-primary"
    //         />
    //         <div className="flex flex-col border border-gray-500 rounded-xl p-3">
    //             <div className="flex flex-row">
    //                 <div className=" w-20">Total:</div>
    //                 <div className="text-gray-300">{inning.points} ({inning.oversCount})</div>
    //             </div>
    //             <div className="flex flex-row">
    //                 <div className=" w-20">Run Rate:</div>
    //                 <div className="text-gray-300">{inning.runRate}</div>
    //             </div>
    //             <div className="flex flex-wrap">
    //                 <div className="w-20">Extras:</div>
    //                 <div className="flex text-gray-300">{inning.extras}</div>

    //                 <div className="flex ml-8 mr-2" title="byes">B:</div>
    //                 <div className="flex text-gray-300">{inning.byes}</div>

    //                 <div className="flex ml-8 mr-2" title="legByes">LB:</div>
    //                 <div className="flex text-gray-300">{inning.legByes}</div>

    //                 <div className="flex ml-8 mr-2" title="noBalls">NB:</div>
    //                 <div className="flex text-gray-300">{inning.noBalls}</div>

    //                 <div className="flex ml-8 mr-2" title="wides">WD:</div>
    //                 <div className="flex text-gray-300">{inning.wides}</div>

    //                 <div className="flex ml-8 mr-2" title="penalties">P:</div>
    //                 <div className="flex text-gray-300">{inning.penalties}</div>
    //             </div>
    //         </div>
    //     </>
    // }
    // function renderScorecardBowling(inning: any) {
    //     return <TableTbodyRounded
    //         classTable="mt-8"
    //         classTR="h-10"
    //         classCols={[
    //             "text-left pl-4",
    //             "text-center w-10",
    //             "text-center w-10",
    //             "text-center w-10",
    //             "text-center w-10",
    //             "text-center w-10",
    //             "text-center w-10",
    //             "text-center w-20",
    //         ]}
    //         thead={[
    //             { child: "BOWLING", className: "text-left" },
    //             { child: "O", title: "Overs" },
    //             { child: "M", title: "Maidens" },
    //             { child: "R", title: "Runs" },
    //             { child: "W", title: "Wickets" },
    //             { child: "NB", title: "No Balls" },
    //             { child: "WD", title: "Wides" },
    //             { child: "E/R", title: "Economy rate" },
    //         ]}
    //         tbody={inning.bowlers.map((personHome: any, index: number) => {
    //             return [
    //                 { child: personHome.personName, className: "text-left text-gray-300" },
    //                 { child: personHome.overs, className: "text-center" },
    //                 { child: personHome.maidens, className: "text-center" },
    //                 { child: personHome.runs, className: "text-center" },
    //                 { child: personHome.wickets, className: "text-center text-gray-300" },
    //                 { child: personHome.noBalls, className: "text-center" },
    //                 { child: personHome.wides, className: "text-center" },
    //                 { child: personHome.economyRate, className: "text-center" },
    //             ]
    //         })}
    //     />
    // }

    function renderCommentaryTab(summary: Map<string, string>[]) {
        return <Commentary
            classTR="py-2"
            tbody={
                summary.map((comment: any, index: number) => {
                    if (comment.Score === undefined) {
                        return [
                            { child: comment.Description, className: "pl-4 text-left text-gray-300" },
                        ]
                    }
                    return [
                        {
                            className: "flex flex-none w-16 justify-center",
                            child: comment.Item,
                        },
                        {
                            className: "flex flex-none w-16 justify-center",
                            child: <div className={`flex justify-center items-center w-8 h-8 rounded-full text-white  ${comment.Score === "W" ? "bg-accent" : "bg-gray-700"}`}>
                                {comment.Score.toUpperCase()}
                            </div>,
                        },
                        {
                            className: "",
                            child: <>
                                {/* <div className="text-white dark:text-black">
                                    {comment.bowlerName} to {comment.batsmanName}
                                </div> */}
                                <div>{comment.Description}</div>
                            </>,
                        },
                    ]
                })
            } />
    }

    // function renderWicketsTab(wickets: any) {
    //     return <table className="table-fixed w-full">
    //         <tbody>
    //             {wickets.map((wicket: any, index: number) => {
    //                 return <tr
    //                     key={`wicket-${index}`}
    //                     className={`h-14`}
    //                 >
    //                     <td className="w-12">
    //                         {wicket.overs}
    //                     </td>
    //                     <td className="w-14">
    //                         <div className="flex w-10 h-10 bg-accent rounded-full text-white dark:text-black items-center justify-center">W</div>
    //                     </td>
    //                     <td className="bg-pink">
    //                         {wicket.bowlerName} to {wicket.batsmanName}
    //                     </td>
    //                     <td className="w-16">
    //                         {wicket.score}
    //                     </td>
    //                 </tr>
    //             })}
    //         </tbody>
    //     </table>
    // }

    function renderTeamsTab(detail: MatchCricketDetail) {
        return <>
            <TextLinker
                text="PLAYERS"
                className="font-bold text-sm text-primary"
            />

            <TableTbodyRounded
                classTR="h-6"
                classCols={[
                    "p-4 text-left",
                    "p-4 text-right",
                ]}
                tbody={detail.HomePlayers!.map((home: string, index: number) => {
                    return [
                        { child: home },
                        { child: detail.AwayPlayers![index] }
                    ]
                })} />
        </>
    }

    function renderTableTab(table: string[][]) {

        const MAX_COL_THEAD = 9
        const thead = table[0]
        const theads = []
        let tempThead: string[] = []
        let tempBody: string[][] = []
        let tbodys: any[] = []

        /**
         * Get thead of table
         */
        for (let index = 1; index <= thead.length; index++) {
            tempThead.push(thead[index - 1])
            if ((index !== 0 && index % MAX_COL_THEAD === 0)) {
                theads.push(tempThead)
                tempThead = []
            }
        }

        /**
         * Get Tbody of table
         */
        for (let index = 1; index < table.length; index++) {
            tempBody.push(table[index])
            if (((table[index][0] || '').toString() === "1" && index !== 1) || index === table.length - 1) {
                if (index === table.length - 1) {
                    tbodys.push(tempBody)
                }
                else {

                    tbodys.push(tempBody.splice(0, tempBody.length - 1))
                }
                const lastIteam = tempBody[tempBody.length - 1]
                tempBody = [lastIteam]
            }

        }

        return <>{
            theads.map((thead: any, index: number) =>

                <TableRounded
                    key={`renderTableTab-${index}`}
                    mark={true}
                    classTable={"my-3"}
                    classTR={"h-12"}
                    classCols={[
                        "w-10 text-center",
                        "w-full text-left",
                        "w-10 text-center",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center",
                        "w-24 text-center",
                    ]}
                    thead={
                        thead?.map((cell: string, indexCell: number) => {
                            return { child: cell }
                        })}
                    tbody={
                        tbodys[index]?.map((row: string[], indexRow: number) => {
                            return row.map((cell: string, indexCell: number) => {
                                return { child: cell };
                            });
                        })}
                />


            )
        }
        </>


    }

    function handleNavigateTo(path: string, detail: MatchCricketDetail) {
        let cid = encodeURIComponent(params.cid || "");
        let coid = encodeURIComponent(params.coid || "");
        let qs = new URLSearchParams([['link', searchParams.get("link") || ""]])
        nav(getNavLink(cid ? {
            cname: cid,
            coname: coid,
            team1: detail.HomeName,
            team2: detail.AwayName,
            matchId: params.matchId,
            tab: path,
        } : {
            matchId: params.matchId
        }, qs));
    }


}

export default withTranslation()(CricketMatchDetailLayout);
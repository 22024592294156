import { Component, ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
    navigate?: NavigateFunction
    text: string
    nested?: boolean
    className?: string
    subText?: string
    subTextClass?: string
    link?: string
    isTextLink?: boolean
} & WithTranslation

class TextLinker extends Component<Props> {
    handleNavigateTo = () => {
        const { navigate, link } = this.props;
        if (navigate && link) {
          navigate(window.location.pathname.replace("/overview", `/${link}`));
        }
    }

    render(): ReactNode {
        return <div className="flex items-center justify-between py-4">
            {this.props.isTextLink ? this.renderButton() : this.renderText()}
            {this.props.link && <button onClick={this.handleNavigateTo}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="fill-primary-lighter dark:fill-black">
                    <path d="M9.00003 20C8.80228 19.9999 8.60898 19.9413 8.44457 19.8314C8.28016 19.7215 8.15202 19.5653 8.07635 19.3826C8.00068 19.1999 7.98088 18.9989 8.01945 18.8049C8.05802 18.611 8.15322 18.4328 8.29303 18.293L14.586 12L8.29303 5.70698C8.11087 5.51838 8.01008 5.26578 8.01236 5.00358C8.01464 4.74138 8.1198 4.49057 8.30521 4.30516C8.49062 4.11975 8.74143 4.01458 9.00363 4.01231C9.26583 4.01003 9.51843 4.11082 9.70703 4.29298L16.707 11.293C16.8945 11.4805 16.9998 11.7348 16.9998 12C16.9998 12.2651 16.8945 12.5195 16.707 12.707L9.70703 19.707C9.51954 19.8945 9.26523 19.9999 9.00003 20Z" />
                </svg>
            </button>}
        </div>
    }


    renderText() {
        if (this.props.subText) {
            return <div className="flex flex-col">
                <span className={this.props.className || ""}>{this.props.t(this.props.text)}</span>
                <span className={this.props.subTextClass || ""}>{this.props.t(this.props.subText)}</span>
            </div>
        } else {
            return <div>
                <span className={this.props.className || ""}>{this.props.t(this.props.text)}</span>
            </div>
        }
    }

    renderButton() {
        return <button className="flex flex-col" onClick={this.handleNavigateTo}>
            <span className={this.props.className || ""}>{this.props.t(this.props.text)}</span>
            {this.props.subText && <span className={this.props.subTextClass || ""}>{this.props.t(this.props.subText)}</span>}
        </button>
    }
}

export default withTranslation()(TextLinker);
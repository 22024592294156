import {
    CompetitionCricketMatches,
    CompetitionItem,
    CompetitionTennisMatches,
    LeagueBasketballDetail,
    LeagueCricketDetail,
    LeagueTennisDetail,
    MatchTennisDetail,
    MatchesCricket,
    News,
    NewsPost,
    HighlightPost
} from "@lib/models";
import { padDate, padMonth } from "@lib/tools";
import axios from "axios";
import { hockeyCompetitionList } from '../mods/mockHockey';
import { tennisCompetitionList } from '../mods/mockTennis';
import type { CompetitionBasketballMatches, CompetitionHockeyMatches } from './common';
import {
    API_BASE, CompetitionFixtureGroup, CompetitionGroup, CompetitionSchedule,
    CompetitionStanding, FixtureDetails, LineUpMatch, LeagueHockeyDetail,
    MatchBasketballDetail, MatchHockeyDetail, TeamDetailInfo, MatchesFootball , MatchesFootballDetail ,

} from "./common";
import { soccerCompetitionList } from './mockSoccer';
import { basketballCompetitionList } from './mockBasketball';
import { cricketCompetitionList } from "./mockCricket";
import { newsCategories } from "./mockNewsCategories";

const ax = axios.create({
    baseURL: API_BASE
})

export class ApiClient {

     // football 

     async getfootballList(): Promise <MatchesFootball[]> {
        const resp = await ax.get(`football/schedule`)
        return resp.data
    }

    async getfootballScheduleDataFromDate(year: number, month: number, date: number): Promise<MatchesFootball[]> {
        const resp = await ax.get(`football/schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return resp.data;   
    }

    async getDetailFootballMatch(matchId: string, link: string): Promise<MatchesFootballDetail[]> {
        const resp = await ax.get(`football/match/${matchId}?link=${link}`)
        return resp.data
    }

    
    async getNews(lang?: string, id?: number, page?: number, per_page?: number): Promise<News> {
        const resp = await ax.get(`news${lang ? `/${lang}` : "/en"}${id ? `/${id}` : ""}?page=${page}&per_page=${per_page}`)
        return resp.data
    }

    async getNewsDetail(lang?: string,slug?: string): Promise<NewsPost> {
        const resp = await ax.get(`news/detail${lang ? `/${lang}` : "/en"}${slug ? `/${slug}` : ""}`)
        return resp.data
    }

    async getNewsCaterories(lang?: string): Promise<{id: number, name: string, slug: string}[] | any> {
        return newsCategories
    }

    async getNewsSportList(lang?: string,route?: string): Promise<NewsPost[]> {
        const resp = await ax.get(`news-sport${lang ? `/${lang}` : "/en"}${route && `/${route}`}`)
        return resp.data
    }

    async getHighlightsList(lang?: string, id?: number, page?: number, per_page?: number): Promise<NewsPost[]> {
        const resp = await ax.get(`highlights${lang ? `/${lang}` : "/en"}${id ? `/${id}?page=${page}&per_page=${per_page}` : `/232`}`)
        return resp.data
    }

    async getHighlightDetail(lang?: string,slug?: string): Promise<NewsPost> {
        const resp = await ax.get(`highlights/detail${lang ? `/${lang}` : "/en"}${slug ? `/${slug}` : ""}`)
        return resp.data
    }

    async getHighlightByTag(lang?: string,tag?: string): Promise<HighlightPost[]> {
        const resp = await ax.get(`highlights${lang ? `/${lang}` : "/en"}${tag ? `/tag/${tag}` : ""}`)
        return resp.data
    }

    async getMatch(matchId: number): Promise<FixtureDetails | null> {
        try {
            const resp = await ax.get(`match/${matchId}`)
            return resp.data
        } catch (err) {
            return null;
        }
    }

    async getLineUpMatch(matchId: number): Promise<LineUpMatch> {
        const resp = await ax.get(`line-up/${matchId}`)
        return resp.data
    }

    async getCompetitionSchedules(coid: number): Promise<CompetitionSchedule> {
        const resp = await ax.get(`competitions/${coid}/schedules`)
        return resp.data
    }

    async getCompetitionStandings(coid: number): Promise<CompetitionStanding> {
        const resp = await ax.get(`competitions/${coid}/standings`)
        return resp.data
    }

    async getScheduleData(year: number, month: number, date: number): Promise<CompetitionFixtureGroup> {
        const resp = await ax.get(`schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return resp.data
    }

    async getLiveScheduleData(): Promise<CompetitionFixtureGroup> {
        const resp = await ax.get(`live`)
        return resp.data
    }

    async getCompetitionList(): Promise<CompetitionGroup[]> {
        const resp = await ax.get('competitions')
        return resp.data
    }

    async getTeamDetail(teamId: number): Promise<TeamDetailInfo | any> {
        const resp = await ax.get(`team/${teamId}`)
        return resp.data
    }

    async getTeamNews(teamName: string, lang?: string): Promise<NewsPost[] | any> {
        const resp = await ax.get(`news/team${lang ? `/${lang}` : '/en'}${teamName ? `/${teamName}` : ''}`)
        return resp.data
    }

    // basketball
    async getBasketballMatch(matchId: string, link: string): Promise<MatchBasketballDetail> {
        const resp = await ax.get(`basketball/match/${matchId}?link=${link}`)
        return resp.data
    }

    async getSoccerCompetitionList(): Promise<CompetitionGroup[]> {
        return soccerCompetitionList
    }

    async getBasketballCompetitionList(): Promise<CompetitionItem[]> {
        return basketballCompetitionList
    }

    basketballLiveScheduleData?: CompetitionBasketballMatches[] = undefined
    async getBasketballLiveScheduleData(): Promise<CompetitionBasketballMatches[]> {
        const resp = await ax.get(`basketball/schedule`)
        return this.basketballLiveScheduleData = this.filterLiveData(resp.data);
    }

    basketballScheduleData?: CompetitionBasketballMatches[] = undefined
    async getBasketballScheduleData(year: number, month: number, date: number): Promise<CompetitionBasketballMatches[]> {
        const resp = await ax.get(`basketball/schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return this.basketballScheduleData = resp.data;
    }

    async getBasketballLeague(leagueId: string, link: string): Promise<LeagueBasketballDetail | undefined> {
        const resp = await ax.get(`basketball/league/${leagueId}?link=${link}`)
        return resp.data;
    }

    // hockey
    async getHockeyMatch(matchId: string, link: string): Promise<MatchHockeyDetail | any> {
        const resp = await ax.get(`hockey/match/${matchId}?link=${link}`)
        return resp.data
    }

    async getHockeyCompetitionList(): Promise<CompetitionItem[]> {
        return hockeyCompetitionList
    }

    hockeyLiveScheduleData?: CompetitionHockeyMatches[] = undefined
    async getHockeyLiveScheduleData(): Promise<CompetitionHockeyMatches[] | any> {
        const resp = await ax.get(`hockey/schedule`)
        return this.hockeyLiveScheduleData = this.filterLiveData(resp.data);
    }

    hockeyScheduleData?: CompetitionHockeyMatches[] = undefined;
    async getHockeyScheduleData(year: number, month: number, date: number): Promise<CompetitionHockeyMatches[]> {
        const resp = await ax.get(`hockey/schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return this.hockeyScheduleData = resp.data
    }

    async getHockeyLeague(leagueId: string, link: string): Promise<LeagueHockeyDetail | undefined> {
        const resp = await ax.get(`hockey/league/${leagueId}?link=${link}`)
        return resp.data;
    }

    // tennis
    tennisLiveScheduleData?: CompetitionTennisMatches[] = undefined
    async getTennisLiveScheduleData(): Promise<CompetitionTennisMatches[] | any> {
        const resp = await ax.get(`tennis/schedule`)
        return this.tennisLiveScheduleData = this.filterLiveData(resp.data);
    }
    tennisScheduleData?: CompetitionTennisMatches[] = undefined
    async getTennisScheduleData(year: number, month: number, date: number): Promise<CompetitionTennisMatches[]> {
        const resp = await ax.get(`tennis/schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return this.tennisScheduleData = resp.data
    }
    async getTennisCompetitionList(): Promise<CompetitionItem[]> {
        return tennisCompetitionList
    }
    async getTennisLeague(leagueId: string, link: string): Promise<LeagueTennisDetail | null> {
        const resp = await ax.get(`tennis/league/${leagueId}?link=${link}`)
        return resp.data;
    }

    async getTennisDetail(matchId: string, link: string): Promise<MatchTennisDetail> {
        const resp = await ax.get(`tennis/match/${matchId}?link=${link}`)
        return resp.data
    }

    // cricket
    cricketLiveScheduleData?: CompetitionCricketMatches[] = undefined
    async getCricketLiveScheduleData(): Promise<CompetitionCricketMatches[] | any> {
        const resp = await ax.get(`cricket/schedule`)
        return this.cricketLiveScheduleData = this.filterLiveData(resp.data);
    }
    cricketScheduleData?: CompetitionCricketMatches[] = undefined
    async getCricketScheduleData(year: number, month: number, date: number): Promise<CompetitionCricketMatches[]> {
        const resp = await ax.get(`cricket/schedule/${year}-${padMonth(month)}-${padDate(date)}`)
        return this.cricketScheduleData = resp.data
    }
    async getCricketCompetitionList(): Promise<CompetitionItem[]> {
        return cricketCompetitionList
    }
    async getCricketLeague(leagueId: string, link: string): Promise<LeagueCricketDetail | null> {
        const resp = await ax.get(`cricket/league/${leagueId}?link=${link}`)
        return resp.data;
    }

    async getCricketMatch(matchId: string): Promise<MatchesCricket | null> {
        let result = null;
        (this.cricketLiveScheduleData || this.cricketScheduleData)?.find((schedule: CompetitionCricketMatches, indexSchedule: number) => {
            schedule.Matches.find((matches: MatchesCricket, indexMatches: number) => {
                if (matches.Id === matchId) {
                    result = matches;
                    return matches;
                }
                return null;
            });
            return null;
        });
        return result;
    }

    async getCricketDetail(matchId: string, link: string): Promise<any> {
        const resp = await ax.get(`cricket/match/${matchId}?link=${link}`)
        return resp.data
    }

    private filterLiveData(data: any) {
        return data.filter((competitionCricketMatches: any) => {
            competitionCricketMatches.Matches = competitionCricketMatches.Matches.filter((match: any) => {
                return match.IsLive;
            });
            return competitionCricketMatches.Matches.length > 0;
        });
    }
}
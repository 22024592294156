import React, { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { WithTranslation, withTranslation } from "react-i18next";
import ButtonFacebook from './buttonFacebook';
import ButtonTwitter from './buttonTwitter';
import ButtonTelegram from './buttonTelegram';

type Props = {
    navigate: NavigateFunction
} & WithTranslation;

class Footer extends Component<Props> {
    render(): React.ReactNode {
        const { navigate } = this.props

        return <footer className="max-w-screen-xl mx-auto pb-6">
            <hr className="border-primary-darker dark:border-zinc-200  my-6 dark:border-zinc-200"></hr>
            <div className="mb-4 flex justify-between">
                <div className="mb-4 flex items-center">
                    <img className="w-[3rem] mr-2 fill-black"  src="/assets/LogoSport.svg" alt="logo-sport"></img>
                    {/* <img className="w-[150px] fill-black" src="/assets/Logo.svg" alt="logo"></img> */}
                    <svg width="146" height="38" viewBox="0 0 146 38" className="fill-primary-lighter w-[150px] dark:fill-[#666]" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.21566 24.918C5.17552 24.918 4.127 24.8667 3.07008 24.764C2.02995 24.6613 1.09886 24.5147 0.276811 24.324V21.31C1.87057 21.442 3.77469 21.508 5.98918 21.508C6.92866 21.508 7.62488 21.4127 8.07784 21.222C8.53081 21.0167 8.75729 20.6573 8.75729 20.144V19.726C8.75729 19.3447 8.61469 19.0513 8.32949 18.846C8.04429 18.626 7.66682 18.516 7.19708 18.516H5.73753C3.80825 18.516 2.36547 18.1493 1.40922 17.416C0.469739 16.668 0 15.4653 0 13.808V13.038C0 11.454 0.528457 10.2733 1.58537 9.496C2.64229 8.71867 4.1941 8.33 6.24083 8.33C8.00235 8.33 9.91486 8.53533 11.9784 8.946V11.938C9.64644 11.806 7.91008 11.74 6.76928 11.74C5.88013 11.74 5.21746 11.85 4.78128 12.07C4.34509 12.2753 4.127 12.664 4.127 13.236V13.544C4.127 14.4533 4.75611 14.908 6.01434 14.908H7.7507C9.41157 14.908 10.6782 15.304 11.5506 16.096C12.4397 16.888 12.8843 17.9293 12.8843 19.22V20.034C12.8843 21.3393 12.6159 22.3513 12.079 23.07C11.5589 23.774 10.8208 24.258 9.86453 24.522C8.90827 24.786 7.69199 24.918 6.21566 24.918Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M20.5532 24.918C18.7246 24.918 17.307 24.4707 16.3004 23.576C15.2938 22.6667 14.7905 21.1193 14.7905 18.934C14.7905 17.3647 15.0086 16.1327 15.4448 15.238C15.8977 14.3287 16.552 13.6907 17.4076 13.324C18.2632 12.9427 19.3621 12.752 20.7042 12.752C21.5598 12.752 22.3567 12.8033 23.0948 12.906C23.833 12.994 24.6299 13.148 25.4855 13.368V15.546H22.0882C21.3165 15.546 20.721 15.6413 20.3016 15.832C19.8821 16.0227 19.5802 16.3527 19.3956 16.822C19.2279 17.2913 19.144 17.9807 19.144 18.89C19.144 19.7993 19.2279 20.4813 19.3956 20.936C19.5634 21.376 19.8486 21.6767 20.2512 21.838C20.6706 21.9847 21.2998 22.058 22.1386 22.058H25.6868V24.214C25.0325 24.4193 24.2272 24.588 23.271 24.72C22.3147 24.852 21.4088 24.918 20.5532 24.918Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M33.4685 24.896C31.8916 24.896 30.6417 24.7053 29.719 24.324C28.8131 23.928 28.1504 23.2973 27.731 22.432C27.3284 21.552 27.1271 20.3567 27.1271 18.846C27.1271 17.2473 27.3284 16.0153 27.731 15.15C28.1504 14.27 28.8131 13.654 29.719 13.302C30.6249 12.9353 31.8748 12.752 33.4685 12.752C35.0623 12.752 36.3121 12.9353 37.2181 13.302C38.1408 13.6687 38.8034 14.292 39.2061 15.172C39.6255 16.052 39.8352 17.2767 39.8352 18.846C39.8352 20.4007 39.6339 21.6107 39.2312 22.476C38.8454 23.3413 38.1911 23.9647 37.2684 24.346C36.3625 24.7127 35.0959 24.896 33.4685 24.896ZM33.4685 21.882C34.0222 21.882 34.4332 21.816 34.7016 21.684C34.97 21.552 35.163 21.2733 35.2804 20.848C35.4146 20.408 35.4817 19.7333 35.4817 18.824C35.4817 17.9147 35.4146 17.2473 35.2804 16.822C35.163 16.3967 34.97 16.118 34.7016 15.986C34.4332 15.854 34.0222 15.788 33.4685 15.788C32.9149 15.788 32.5039 15.854 32.2355 15.986C31.9838 16.118 31.7909 16.3967 31.6567 16.822C31.5393 17.2327 31.4805 17.9 31.4805 18.824C31.4805 19.748 31.5393 20.4227 31.6567 20.848C31.7909 21.2733 31.9922 21.552 32.2606 21.684C32.5291 21.816 32.9317 21.882 33.4685 21.882Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M41.9791 12.95H45.3512L46.3326 14.666C46.8359 14.0793 47.3727 13.6173 47.9431 13.28C48.5135 12.9427 49.1594 12.774 49.8808 12.774C50.3002 12.774 50.6525 12.8107 50.9377 12.884V16.778C50.1995 16.7193 49.6543 16.69 49.302 16.69C48.6477 16.69 48.0773 16.778 47.5908 16.954C47.1043 17.13 46.6849 17.4013 46.3326 17.768V24.786H41.9791V12.95Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M58.6068 24.896C57.0298 24.896 55.7968 24.72 54.9076 24.368C54.0185 24.016 53.3642 23.4147 52.9448 22.564C52.5421 21.6987 52.3408 20.474 52.3408 18.89C52.3408 17.262 52.5254 16.0153 52.8945 15.15C53.2803 14.27 53.901 13.654 54.7566 13.302C55.629 12.9353 56.8537 12.752 58.4307 12.752C59.8567 12.752 60.9723 12.8767 61.7776 13.126C62.5828 13.3607 63.1616 13.7787 63.5139 14.38C63.883 14.9667 64.0675 15.81 64.0675 16.91C64.0675 18.098 63.6817 18.9413 62.91 19.44C62.1383 19.9387 61.0142 20.188 59.5379 20.188H56.644C56.6775 20.6867 56.7782 21.068 56.946 21.332C57.1305 21.596 57.4409 21.7867 57.877 21.904C58.3132 22.0213 58.9507 22.08 59.7896 22.08H63.5894V24.302C62.8009 24.4927 62.0208 24.6393 61.2491 24.742C60.4942 24.8447 59.6134 24.896 58.6068 24.896ZM59.0598 17.944C59.4792 17.944 59.7812 17.8707 59.9657 17.724C60.1502 17.5627 60.2425 17.2987 60.2425 16.932C60.2425 16.404 60.0999 16.03 59.8147 15.81C59.5463 15.59 59.0849 15.48 58.4307 15.48C57.7428 15.48 57.2647 15.656 56.9963 16.008C56.7279 16.3453 56.5937 16.9907 56.5937 17.944H59.0598Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M69.9846 24.918C68.7934 24.918 67.854 24.6027 67.1661 23.972C66.4951 23.3267 66.1595 22.454 66.1595 21.354V12.95H70.513V20.166C70.513 20.694 70.6472 21.0607 70.9157 21.266C71.2009 21.4713 71.6119 21.574 72.1487 21.574C72.501 21.574 72.8953 21.5007 73.3315 21.354C73.7677 21.2073 74.1283 21.024 74.4135 20.804V12.95H78.7419V24.786H75.1433L74.4135 23.62C72.8366 24.4853 71.3602 24.918 69.9846 24.918Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M81.2497 12.95H84.8985L85.6031 14.05C86.1735 13.6393 86.7272 13.324 87.264 13.104C87.8009 12.8693 88.4216 12.752 89.1262 12.752C90.7535 12.752 91.953 13.2727 92.7247 14.314C93.5132 15.3407 93.9075 16.866 93.9075 18.89C93.9075 20.8993 93.5552 22.4173 92.8506 23.444C92.1627 24.456 90.9213 24.962 89.1262 24.962C88.5055 24.962 87.8764 24.9033 87.2388 24.786C86.6181 24.6833 86.0729 24.522 85.6031 24.302V30H81.2497V12.95ZM87.6666 21.838C88.9249 21.838 89.554 20.8553 89.554 18.89C89.554 17.746 89.3946 16.9467 89.0759 16.492C88.7571 16.0373 88.2958 15.81 87.6918 15.81C86.9537 15.81 86.2574 15.986 85.6031 16.338V21.398C85.838 21.53 86.14 21.64 86.5091 21.728C86.8949 21.8013 87.2808 21.838 87.6666 21.838Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M100.498 24.918C98.8873 24.918 97.6794 24.4633 96.8741 23.554C96.0689 22.63 95.6662 21.112 95.6662 19C95.6662 17.4893 95.8172 16.2867 96.1192 15.392C96.438 14.4827 96.958 13.8153 97.6794 13.39C98.4176 12.9647 99.4409 12.752 100.749 12.752C101.773 12.752 102.847 12.9207 103.971 13.258V8H108.324V24.786H104.65L103.971 23.686C103.484 24.0527 102.939 24.3533 102.335 24.588C101.731 24.808 101.119 24.918 100.498 24.918ZM102.058 21.882C102.729 21.882 103.367 21.6987 103.971 21.332V16.008C103.333 15.7293 102.746 15.59 102.209 15.59C101.639 15.59 101.202 15.678 100.9 15.854C100.599 16.0153 100.372 16.3233 100.221 16.778C100.087 17.2327 100.02 17.9073 100.02 18.802C100.02 19.6673 100.078 20.32 100.196 20.76C100.33 21.1853 100.54 21.4787 100.825 21.64C101.11 21.8013 101.521 21.882 102.058 21.882Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M113.758 24.918C112.718 24.918 111.879 24.6467 111.242 24.104C110.604 23.5613 110.285 22.8353 110.285 21.926V20.782C110.285 19.8433 110.663 19.0953 111.418 18.538C112.173 17.966 113.305 17.68 114.815 17.68H117.885V17.108C117.885 16.756 117.818 16.4847 117.684 16.294C117.549 16.1033 117.281 15.964 116.878 15.876C116.493 15.788 115.905 15.744 115.117 15.744H111.141V13.566C112.751 13.0673 114.622 12.8107 116.753 12.796C118.581 12.796 119.949 13.0967 120.854 13.698C121.777 14.2847 122.239 15.3333 122.239 16.844V24.786H118.791L118.112 23.576C117.726 23.8987 117.138 24.2067 116.35 24.5C115.578 24.7787 114.714 24.918 113.758 24.918ZM115.998 22.3C116.283 22.3147 116.635 22.2707 117.055 22.168C117.474 22.0507 117.751 21.9553 117.885 21.882V19.462L116.274 19.594C115.167 19.6967 114.614 20.1073 114.614 20.826V21.288C114.614 21.9627 115.075 22.3 115.998 22.3Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M130.059 24.786C128.935 24.786 128.037 24.654 127.366 24.39C126.712 24.1113 126.234 23.6567 125.932 23.026C125.646 22.3953 125.529 21.5373 125.579 20.452L125.705 15.964H123.616V13.544L125.881 12.95L126.435 9.628H129.882V12.95H132.827V15.964H129.882V20.43C129.882 20.8993 129.975 21.244 130.159 21.464C130.344 21.6693 130.621 21.7867 130.99 21.816L132.701 21.97V24.786H130.059Z" className="fill-primary-lighter dark:fill-[#666]" />
                        <path d="M140.539 24.896C138.962 24.896 137.729 24.72 136.84 24.368C135.951 24.016 135.297 23.4147 134.877 22.564C134.475 21.6987 134.273 20.474 134.273 18.89C134.273 17.262 134.458 16.0153 134.827 15.15C135.213 14.27 135.833 13.654 136.689 13.302C137.561 12.9353 138.786 12.752 140.363 12.752C141.789 12.752 142.905 12.8767 143.71 13.126C144.515 13.3607 145.094 13.7787 145.446 14.38C145.815 14.9667 146 15.81 146 16.91C146 18.098 145.614 18.9413 144.842 19.44C144.071 19.9387 142.947 20.188 141.47 20.188H138.576C138.61 20.6867 138.711 21.068 138.878 21.332C139.063 21.596 139.373 21.7867 139.81 21.904C140.246 22.0213 140.883 22.08 141.722 22.08H145.522V24.302C144.733 24.4927 143.953 24.6393 143.182 24.742C142.427 24.8447 141.546 24.896 140.539 24.896ZM140.992 17.944C141.412 17.944 141.714 17.8707 141.898 17.724C142.083 17.5627 142.175 17.2987 142.175 16.932C142.175 16.404 142.032 16.03 141.747 15.81C141.479 15.59 141.017 15.48 140.363 15.48C139.675 15.48 139.197 15.656 138.929 16.008C138.66 16.3453 138.526 16.9907 138.526 17.944H140.992Z" className="fill-primary-lighter dark:fill-[#666]" />
                    </svg>
                </div>
                <div className='nav-item pt-4 flex items-center'>
                    <div className="flex flex-row items-center">
                        <h3>Follow Us: </h3>
                        <ButtonFacebook className='px-2' url='https://www.facebook.com/scoreupdate.official' />
                        <ButtonTwitter className='px-2' url='https://twitter.com/ScoreUpdate247' />
                        <ButtonTelegram className='px-2' url='https://t.me/scoreupdate_bot' />
                    </div>
                </div>
            </div>
            <div>
                <h6 className=" text-primary-lighter dark:text-black text-base lg:text-lg mb-2.5 lg:mb-5">{this.props.t("Footer1")}</h6>
                <p className="mb-2.5 lg:mb-5">
                    {this.props.t("Footer2")}
                    <button className=' text-primary-lighter dark:text-black underline hover:no-underline' onClick={() => navigate('/')}>Scoreupdate.com</button>
                    &nbsp;
                    {this.props.t("Footer3")}
                </p>
                <p>
                    {this.props.t("Footer4")}
                </p>
            </div>
            <hr className="border-primary-darker dark:border-zinc-200  dark:border-zinc-200 my-6"></hr>
            <div className="flex justify-center text-sm lg:text-base tracking-tight sm:tracking-normal">
                <span> © {new Date().getFullYear()} ScoreUpdate </span>
                {/* <div className="flex flex-wrap items-center">
                    <a href="#">Support</a>
                    <div className="border border-primary-darker dark:border-zinc-200  h-4 mx-2 lg:mx-3"></div>
                    <a href="#">Privacy Policy</a>
                    <div className="border border-primary-darker dark:border-zinc-200  h-4 mx-2 lg:mx-3"></div>
                    <a href="#">Terms & Condition</a>
                </div> */}
            </div>
        </footer >
    }
}

export default withTranslation()(Footer);
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import i18n from "../i18n/i18n";
import { useNavigate } from "react-router-dom";


const KEY_STORAGE_LANG = "lang";
const LANG_EN = "en";
const LANG_VN = "vn";
const LANG_TH = "th";
const LANG_ID = "id";

type Lang = {
  code: string,
  flag: string,
  text: string
}

const langs: Lang[] = [
  {
    code: LANG_EN,
    flag: `/assets/flags/usa.jpg`,
    text: "English",
  },
  {
    code: LANG_TH,
    flag: `/assets/flags/thailand.jpg`,
    text: "ไทย",
  },
  {
    code: LANG_ID,
    flag: `/assets/flags/indonesia.jpg`,
    text: "Bahasa",
  },
  {
    code: LANG_VN,
    flag: `/assets/flags/vietnam.jpg`,
    text: "Tiếng Việt",
  }
];

var langCookie;
const cookies = document.cookie.split(';').map(m => m.trim());
const myCookie = cookies.find(cookie => cookie.startsWith('lang='));
if (myCookie) langCookie = myCookie.split('=')[1];

const defLang = localStorage.getItem(KEY_STORAGE_LANG) || langCookie || LANG_EN;
i18n.changeLanguage(defLang);

interface IProps {
  isNews: boolean,
  isHighlights: boolean
}

export default function LanguageSelector({isNews,isHighlights}: IProps) {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(defLang);
  const currentLang = langs.find((lang) => (lang.code || '').toString() === (selected || '').toString());

  const renderLang = (lang?: Lang) => {
    return lang && <div className="flex flex-rows">
      <img src={lang.flag} width="25px" height="15px" alt={`flag ${lang.flag}`} />
      {/* <span className={`block truncate pl-2 ${selected ? "font-medium" : "font-normal"}`}      >
        {lang.text}
      </span> */}
    </div>
  }

  const onButtonLanguageClicked = (event: string) => {
    if(isNews) navigate('/news')
    if(isHighlights) navigate('/highlights')
    setSelected(event);
    localStorage.setItem(KEY_STORAGE_LANG, event);
    i18n.changeLanguage(event);
  };

  useEffect(()=>{
    !localStorage.getItem(KEY_STORAGE_LANG) && localStorage.setItem(KEY_STORAGE_LANG,LANG_EN)
  },[])

  return (
    <Listbox
      value={selected}
      onChange={(event) => onButtonLanguageClicked(event)}
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative font-semibold bg-accent  text-primary-lighter dark:text-black sm:text-base w-full cursor-pointer rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          {renderLang(currentLang)}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5  text-primary-lighter"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
            {langs.map((lang, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-4 pr-4 ${active ? "bg-accent  text-primary-lighter dark:text-black" : "text-primary-darker"}`
                }
                value={lang.code}
              >
                {renderLang(lang)}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

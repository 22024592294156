import { useEffect, useState } from "react";
import Services from "../../mods/services";
import { GlobalSpinner } from "../globalSpinner";
import { HighlightPost } from "@lib/models";
import { withTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NoData from "../shared/NoData";
import NewsRelatedArticles from "./highlightsRelatedHighlights";
import { LIMIT_NEWS_RELATED } from "../../mods/common";
import { newsCategories } from "../../mods/mockHighlightsCat";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const KEY_STORAGE_LANG = "lang";
const LANG_VN = "vn";
const SHARE_FACEBOOK_URL = "https://www.facebook.com/sharer/sharer.php?u=";
const SHARE_TWITTER_URL = "https://twitter.com/intent/tweet?url=";

function HighlightDetail() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [relatedArticlesLoading, setRelatedArticlesLoading] = useState(false);
  const [newsContent, setNewsContent] = useState<HighlightPost>();
  const [relatedArticles, setRelatedArticles] = useState<HighlightPost[]>([]);
  const [categories, setCategories] = useState<
    { id: number; name: string; slug: string }[] | null
  >();
  // const query = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const handleShareFacebook = (type: string) => {
    return type === "FB"
      ? window.open(SHARE_FACEBOOK_URL + window.location.href)
      : window.open(SHARE_TWITTER_URL + window.location.href);
  };

  const handleActiveCategory = (item: { id: number; slug: string }) => {
    navigate(`/highlights?category=${item.slug}`);
  };


  useEffect(() => {
    (async () => {
      try {
        setIsLoadingCategories(true);
        setCategories(() => [...newsCategories]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingCategories(false);
      }
    })();
  }, []);

  useEffect(() => {
    const lang =
      localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "";
    (async () => {
      try {
        setIsLoading(true);
        const detail = await Services.getHighlightDetail(
          params.lang || lang,
          params.slug
        );
        setNewsContent(detail);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [localStorage.getItem(KEY_STORAGE_LANG), location.pathname]);

  useEffect(() => {
    const lang = localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "";
    (async () => {
      try {
        const uniqueSlugs = new Set<string>();
        setRelatedArticlesLoading(true);
        let newArticles: HighlightPost[] = [];
        for (const tag of newsContent?.Tags || []) {
          if (newArticles.length >= LIMIT_NEWS_RELATED) break;
          const listNews = await Services.getHighlightByTag(lang, tag);
          if (listNews && Symbol.iterator in Object(listNews)) {
            for (const news of listNews || []) {
              if (uniqueSlugs.size >= LIMIT_NEWS_RELATED) break;
              if (!uniqueSlugs.has(news.Slug)) {
                uniqueSlugs.add(news.Slug);
                newArticles.push(news);
              }
            }
          }
        }
        const newList = newArticles.filter(item => item.Slug !== params.slug)
        setRelatedArticles(newList);
      } catch (error) {
        console.log(error);
      } finally {
        setRelatedArticlesLoading(false);
      }
    })();
  }, [newsContent?.Tags, location.pathname]);
  document.title = `ScoreUpdate | ${newsContent?.Title ? newsContent?.Title.toString() : ''}`;

  return (
    <>
      <div className="md:w-[75%] lg:w-full flex flex-col gap-y-4 w-full md:col-span-7">
        {isLoadingCategories ? (
          <GlobalSpinner />
        ) : (
          <>
            <div className="horizontal-navigation-container">
              <Swiper
                spaceBetween={5}
                slidesPerView="auto"
                navigation
                modules={[Navigation]}
                pagination={{ clickable: true }}
              >
                {categories?.length &&
                  categories.map((item) => (
                    <SwiperSlide
                      onClick={() => handleActiveCategory(item)}
                      key={`NEWS_CATEGORIES:${item.id}`}
                      className={`border font-bold rounded-2xl !w-fit text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer text-[#aaa] bg-[#181818] border-[#181818] dark:bg-slate-200 dark:border-zinc-300 dark:text-black`}>
                      <span>{item.name}</span>
                    </SwiperSlide>
                  ))}

              </Swiper>
            </div>
            {isLoading ? (
              <GlobalSpinner />
            ) : newsContent?.Title ? (
              <div className="news-wrapper p-0 tab-content">
                <div className="featured-articles-wrapper p-4 mb-4">
                  <div className="my-4 flex justify-between">
                    <div className="w-full">
                      <h1
                        className=" text-[#fdfdfd] dark:text-black font-bold text-4xl leading-10"
                        dangerouslySetInnerHTML={{
                          __html: newsContent?.Title ? newsContent?.Title.toString() : '',
                        }}
                      />
                      <p>{newsContent.PostTime ? newsContent.PostTime.toString() : ''}</p>
                    </div>
                    <div className="items-start min-w-[50px] flex justify-between mt-1">
                      <button onClick={() => handleShareFacebook("FB")}>
                        <img
                          alt="facebook-icon"
                          className="w-[20px] mx-2"
                          src="/assets/icons/Facebook.svg"
                        />
                      </button>
                      <button onClick={() => handleShareFacebook("TWITTER")}>
                        <img
                          alt="twitter-icon"
                          className="w-[20px] mx-2"
                          src="/assets/icons/Twitter.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <iframe
                    className="w-full min-h-[500px] rounded-2xl"
                    src={newsContent.Link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                  {newsContent && (
                    <div
                      className="news-content  text-[#fdfdfd] dark:text-black mt-5 lg:min-h-[500px]"
                      dangerouslySetInnerHTML={{
                        __html: newsContent.Content?.replace(
                          "fasthighlights",
                          ""
                        )
                          .replace("Fasthighlights", "ScoreUpdate")
                          .replace("FastHighlights", "ScoreUpdate")
                          .toString(),
                      }}
                    />
                  )}
                </div>
                {newsContent.Tags?.length && newsContent.Tags[0] !== "" && (
                  <>
                    <div className="border-t border-primary-darker dark:border-zinc-200  dark:border-zinc-200 p-4">
                      <h2 className="font-bold  text-[#fdfdfd] dark:text-black">TAGS</h2>
                    </div>
                    <div className="border-t border-primary-darker dark:border-zinc-200  p-4 flex flex-wrap">
                      {newsContent.Tags.filter((i: string) => i !== "").map(
                        (item: string, index: number) => (
                          <div
                            key={`TAGS_NEWS:${index}`}
                            className="bg-[#181818] my-1 cursor-pointer text-[#aaa] text-sm rounded-2xl ml-2 px-2 py-1 dark:bg-[#ccc] dark:text-black"
                          >
                            {item}
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <NoData text="There is no data available" />
            )}
          </>
        )}
      </div>

      <div className="md:flex md:flex-col md:col-span-2">
        <div className="tab-content featured-news self-end">
          <NewsRelatedArticles
            data={
              relatedArticles.length > LIMIT_NEWS_RELATED
                ? relatedArticles.slice(0, LIMIT_NEWS_RELATED)
                : relatedArticles
            }
            isLoading={relatedArticlesLoading}
          />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(HighlightDetail);

import { useState } from "react";
import { LeagueHockeyDetailTable } from '../../mods/common'

type IProps = {
    data: {
        [key: string]: LeagueHockeyDetailTable[]
    },
    isOverview: boolean
}
const CompetitionDetailTable = ({ data, isOverview }: IProps) => {
  const [newData] = useState(Object.keys(data).length ? Object.keys(data) : []);
  const [option, setOption] = useState(newData[0]);
  const handleTable = (option: string) => setOption(option);

  return (
    <>
      <div className="w-full flex">
        {newData.length > 1 && newData.map((item, index) => (
          <div
            onClick={() => handleTable(item)}
            className={`grow-[4] ${
              item === option ? "btn-actived" : ""
            } cursor-pointer text-center border border-[#1F262D] first:rounded-l-lg last:rounded-r-lg`}
            key={`DETAIL_TABLE:${index}`}
          >
            <a className="py-3 block font-bold">{item}</a>
          </div>
        ))}
      </div>
      {data[option].length && data[option].map((item: LeagueHockeyDetailTable, index: number) => (
        <div key={`MAIN_TABLE:${index}`} className="border border-[#1F262D] rounded-lg mt-2">
          <table className="border-collapse text-sm text-center w-full">
            <tbody>
              <tr>
                {item.Header.length && item.Header.map((i: string, index: number) => (
                  <th key={`HEADER_TABLE:${index}`} className="text-left pl-2 font-bold px-3 py-4">{i}</th>
                ))}
              </tr>
              {item.Body.length && item.Body.map((i: any, index: number) => {
                if(index > 4 && isOverview) return;
                return (
                <tr key={`BODY_TABLE:${index}`} className="border-y border-[#1F262D] last:border-none mx-2.5 text-[#AAAAAA]">
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {index + 1}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    <div className="items-center  text-[#fdfdfd] dark:text-black flex">
                      <div className="w-[20px] mr-1.5">
                        <img src={i.Icon} alt={i.Name} />
                      </div>
                      {i.Name}
                    </div>
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.Played}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.Wins}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.Losses}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.GoalsFor}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.GoalsAgainst}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.GoalsDiff}
                  </th>
                  <th className="text-left pl-2 font-bold px-3 py-4">
                    {i.Points}
                  </th>
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};

export default CompetitionDetailTable;
import en from "./resources/en.json"
import i18n from "i18next";
import id from "./resources/id.json"
import { initReactI18next } from "react-i18next";
import th from "./resources/th.json"
import vn from "./resources/vn.json"

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		resources: {
			en: en,
			vn: vn,
			th: th,
			id: id,
		},

		// language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		lng: "en",
		fallbackLng: "en",

		interpolation: {
			// react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			escapeValue: false
		}
	});

export default i18n;
import {
  Outlet,
  useParams,
  useNavigate,
  useNavigation,
  Link,
  useOutletContext,
} from "react-router-dom";
import CompetitionList from "./competitionList";
import NewsList from "./soccer/newsList";
import { ROUTING, SportParam, TIME_WAIT_SPINNER } from "../mods/common";
import { GlobalSpinner } from "./globalSpinner";
import { useEffect, useRef, useState } from "react";
import CompetitionList2 from "./competitionList2";
import Services from '../mods/services'
import { WithTranslation, withTranslation } from "react-i18next";
import { NewsPost } from "@lib/models";

type IData = {
  name: string;
  path: string;
  childs?: IData[];
  active?: boolean;
};

type IProps = {
  route: string;
  new: boolean;
} & WithTranslation

type HandleShowHideMenuFunction = () => void;
type OutletContextType = [boolean, HandleShowHideMenuFunction];

const KEY_STORAGE_LANG = "lang";
const LANG_VN = "vn";


function SportLayout(props: IProps) {
  const nav = useNavigate();
  const params = useParams<SportParam>();
  const navigation = useNavigation();
  const [isShowMenu, handleShowHideMenu]: OutletContextType = useOutletContext();

  const [newsSport,setNewsSport] = useState<NewsPost[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [isLoading,setIsLoading] = useState(false)
  const refTimeoutHandle = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (navigation.state !== "idle") {
      if (!showSpinner && !refTimeoutHandle.current) {
        refTimeoutHandle.current = setTimeout(() => {
          refTimeoutHandle.current = null;
          setShowSpinner(true);
        }, TIME_WAIT_SPINNER);
      }
    } else {
      if (showSpinner) {
        setShowSpinner(false);
      }

      if (refTimeoutHandle.current) {
        clearTimeout(refTimeoutHandle.current);
        refTimeoutHandle.current = null;
      }
    }
  }, [navigation.state, showSpinner]);

  useEffect(()=>{
    const lang = localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : '';

    (async () => {
      try {
        setIsLoading(true)
        const response = await Services.getNewsSportList(lang,props.route);
        setNewsSport(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false)
      }
    })();
  },[localStorage.getItem(KEY_STORAGE_LANG), props.route])

  const routing: IData[] = [
    {
      name: "Football",
      path: "",
      active: props.route === ROUTING.SOCCER,
    },
    {
      name: "Basketball",
      path: "basketball",
      active: props.route === ROUTING.BASKETBALL,
    },
    {
      name: "Cricket",
      path: "cricket",
      active: props.route === ROUTING.CRICKET,
    },
    {
      name: "Hockey",
      path: "hockey",
      active: props.route === ROUTING.HOCKEY,
    },
    {
      name: "Tennis",
      path: "tennis",
      active: props.route === ROUTING.TENNIS,
    },
  ];

  const routeName = routing.find((item: IData, index: number) => item.path === props.route)?.name || routing[0].name
  document.title = `ScoreUpdate | Live ${routeName} Scores, Fixtures & Results`;

  return (
    <>
      <div className="hidden md:block md:tab-content lg:col-span-2 search-box">
        {props.route && props.new ? <CompetitionList2 navigate={nav} params={params} route={props.route} /> : <CompetitionList params={params} route={props.route} isShowMenu={isShowMenu} />}
      </div>
      <div className="flex flex-col gap-y-4 w-full lg:col-span-5">
        <div className="relative z-10">
          <div className="tab-content px-3 border">
            <div className="btn-list border-0 flex gap-x-2 lg:gap-x-4 overflow-auto">
              {routing.map((item: IData, index: number) => (
                <Link
                  key={`TAB_CHILDS:${index}`}
                  to={`/${item.path}`}
                  className={`tab rounded-lg bg-main-bg-lighter dark:bg-slate-200 dark:text-black font-semibold py-2 px-2 mt-1 flex flex-wrap content-center justify-center whitespace-nowrap clear-both ${
                    item.active ? "active" : ""
                  }`}
                >
                  {props.t(item.name)}
                </Link>
              ))}
            </div>
          </div>
          {
            isShowMenu && <div className="md:hidden block absolute z-10 w-full bg-main-bg dark:bg-white h-full mt-2">
            {props.route && props.new ? 
              <CompetitionList2 
                navigate={nav} 
                params={params} 
                route={props.route} 
                isMobile={isShowMenu} 
                handleShowHideMenu={handleShowHideMenu} 
              /> : 
              <CompetitionList 
                params={params} 
                route={props.route}
                isMobile={isShowMenu} 
                handleShowHideMenu={handleShowHideMenu} 
              />}
          </div>
          }
          <div className={`mt-5 sm:tab-content min-h-[75vh] sm:min-h-screen md:block ${isShowMenu ? "hidden": ""}`}>
            {showSpinner ? <GlobalSpinner /> : <Outlet />}
          </div>
        </div>
      </div>

      <div className="hidden lg:flex lg:flex-col lg:col-span-2">
        <div className="tab-content featured-news self-end">
          <NewsList data={newsSport} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(SportLayout);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SOURCER_PREFIX_LINK = 'https://www.livescore.com';
const buffer_1 = require("buffer");
const LinkHelper = {
    encrypt(link) {
        if (link) {
            return buffer_1.Buffer.from(link.replace(SOURCER_PREFIX_LINK, '')).toString('base64');
        }
        return "";
    },
    decrypt(link) {
        if (link) {
            let bLink = encodeURIComponent(buffer_1.Buffer.from(link, "base64").toString("utf-8"));
            return SOURCER_PREFIX_LINK + bLink;
        }
        return "";
    }
};
exports.default = LinkHelper;

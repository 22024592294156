import { Component, ReactNode } from "react";

type Cell = {
	className?: string,
	child: ReactNode
}

type Props = {
	classTable?: string
	classTR?: string
	tbody: Cell[][]
}

export default class Commentary extends Component<Props> {
	render(): ReactNode {
		const tbody = this.props.tbody;

		return <div className={`border border-gray-700 rounded-lg ${this.props.classTable || ""}`}>
			{tbody.map((row: Cell[], indexRow: number) => {
				return <div key={`tbody-row-${indexRow}`} className={`flex flex-row border-b border-gray-700 items-center ${this.props.classTR || ""}`}>
					{row.map((cell: Cell, indexCell: number) => {
						return <div
							key={`tbody-row${indexRow}-cell${indexCell}`}
							className={cell.className}>
							{cell.child}
						</div>
					})}
				</div>
			})}
		</div>
	}
}
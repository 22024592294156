import { Component, ReactNode } from "react";

class ButtonTelegram extends Component<{
  url: string;
  className?: string;
  svgClassName?: string;
}> {
  render(): ReactNode {
    return (
      <button className={this.props.className} onClick={this.onClick}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 600 600"
          className={this.props.svgClassName}
        >
          <g>
            <path
              className="st0"
              fill="#2CA5E0"
              d="M442.9,519.4L318,427.9l-70,65.7c-4,3.2-8.1,4.8-12.4,4.8c-9.7,0-16.2-4.1-19.4-12.4l-49.5-149.7L43.9,298.1
		C36.4,296,32.1,290.6,31,282c0-8.6,4.3-14.5,12.9-17.8C379.9,138.5,549,75.7,551.2,75.7c3.9,0,8,1.8,12.1,5.4c4.1,3.6,6,8.3,5.7,14
		l-85.6,408.2c-2.9,14-11.3,21-25.3,21C452.6,524.3,447.6,522.7,442.9,519.4z M241.6,369.7c153.6-138.6,230.6-208.3,230.7-209.2
		c0.2-0.9,0.3-1.5,0.3-1.9c0-3.2-2.3-4.8-7-4.8c-1.1,0-90.8,57.1-269.2,171.2L234,448.4L241.6,369.7z"
            />
          </g>
        </svg>
      </button>
    );
  }

  onClick = () => {
    window.open(this.props.url, "_blank");
  };
}
export default ButtonTelegram;

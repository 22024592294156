import { useTranslation, withTranslation } from "react-i18next";

export type IPageFetcher = {
	pageIndex: number,
	next: boolean,
	onPageChanged: (pageIndex: number) => void,
}

const PAGES = 3;
const CLASS_NORMAL = "flex justify-center bg-transparent rounded mx-1 px-2";
const CLASS_SELECTED = "border border-primary text-primary-lighter dark:text-primary";
const CLASS_UNSELECTED = "border border-transparent";

function PageFetcher(props: IPageFetcher) {
	const { t } = useTranslation();
	return <div className="pt-3 w-full">
		<div className="flex justify-center">
			{RenderPageFirst(props, t("First"))}
			{RenderPagePrevious(props, t("Previous"))}
			{RenderPages(props)}
			{RenderPageNext(props, t("Next"))}
		</div>
	</div>
}

function RenderPageFirst(props: IPageFetcher, text: string) {
	return props.pageIndex > 0 && <button
		className={CLASS_NORMAL}
		onClick={() => props.onPageChanged(0)}>
		{text}
	</button>
}

function RenderPagePrevious(props: IPageFetcher, text: string) {
	return props.pageIndex > 0 && <button
		className={CLASS_NORMAL}
		onClick={() => props.onPageChanged(props.pageIndex - 1)}>
		{text}
	</button>
}

function RenderPages(props: IPageFetcher) {
	let list = [];
	for (let pageIndex = Math.max(0, props.pageIndex - PAGES); props.next ? pageIndex < props.pageIndex + 2 : pageIndex <= props.pageIndex; pageIndex++) {
		list.push(<button
			key={`Button-${pageIndex}`}
			className={`${CLASS_NORMAL} ${pageIndex === props.pageIndex ? CLASS_SELECTED : CLASS_UNSELECTED}`}
			onClick={() => props.onPageChanged(pageIndex)}>
			{pageIndex + 1}
		</button>);
	}
	return <div className="flex">
		{list}
	</div>
}

function RenderPageNext(props: IPageFetcher, text: string) {
	return props.next && <button
		className={CLASS_NORMAL}
		onClick={() => props.onPageChanged(props.pageIndex + 1)}>
		{text}
	</button>
}

export default withTranslation()(PageFetcher);
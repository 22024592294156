import { withTranslation, WithTranslation } from 'react-i18next';
import { MatchBasketballDetail } from '../../mods/common';

type IProps = {
  details: MatchBasketballDetail;
} & WithTranslation;

const teamLayout = (props: IProps) => {

  return (
    <>
      <table className="border-collapse text-sm text-center w-full">
        <tbody>
          <tr>
            <th className="text-left pl-2 font-bold px-3 py-4">{props.t("TEAM")}</th>
            {props.details.Headers?.map((item: string, index: number) => (<th key={`TABLE_HEADER:${index}`}>{item}</th>))}
          </tr>
          <tr className="border border-primary-darker dark:border-zinc-200  dark:border-zinc-200 mx-2.5 text-[#AAAAAA]">
            <th className="text-left pl-2 font-bold px-3 py-4">
              {props.details.HomeName}
            </th>
            {props.details.HomeScores?.map((item: string, index: number) => (<th key={`TABLE_DETAIL:${index}`}>{item}</th>))}
          </tr>
          <tr className="border border-primary-darker dark:border-zinc-200  dark:border-zinc-200 mx-2.5 text-[#AAAAAA]">
            <th className="text-left pl-2 font-bold px-3 py-4">{props.details.AwayName}</th>
            {props.details.AwayScores?.map((item: string, index: number) => (<th key={`TABLE_DETAIL:${index}`}>{item}</th>))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default withTranslation()(teamLayout);

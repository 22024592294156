export const newsCategories = [
    {
        "id": 182,
        "count": 143,
        "description": "Tất cả tin mới trong ngày",
        "link": "https://news.scoreupdate.com/vi/all-vi/",
        "name": "All",
        "slug": "all",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/182"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=182"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=182"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=182"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 187,
        "count": 52,
        "description": "",
        "link": "https://news.scoreupdate.com/vi/basketball/",
        "name": "Basketball",
        "slug": "basketball",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/187"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=187"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=187"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=187"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 28,
        "count": 57,
        "description": "",
        "link": "https://news.scoreupdate.com/bundesliga/",
        "name": "Bundesliga",
        "slug": "bundesliga",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/28"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=28"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=28"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=28"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 43,
        "count": 61,
        "description": "",
        "link": "https://news.scoreupdate.com/champions-league/",
        "name": "Champions League",
        "slug": "champions-league",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/43"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=43"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=43"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=43"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 45,
        "count": 0,
        "description": "",
        "link": "https://news.scoreupdate.com/copa-america/",
        "name": "Copa América",
        "slug": "copa-america",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/45"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=45"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=45"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=45"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 220,
        "count": 26,
        "description": "",
        "link": "https://news.scoreupdate.com/cricket/",
        "name": "Cricket",
        "slug": "cricket",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/220"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=220"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=220"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=220"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 44,
        "count": 1,
        "description": "",
        "link": "https://news.scoreupdate.com/europa-league/",
        "name": "Europa League",
        "slug": "europa-league",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/44"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=44"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=44"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=44"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 222,
        "count": 33,
        "description": "",
        "link": "https://news.scoreupdate.com/hockey/",
        "name": "Hockey",
        "slug": "hockey",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/222"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=222"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=222"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=222"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 26,
        "count": 69,
        "description": "",
        "link": "https://news.scoreupdate.com/la-liga/",
        "name": "La Liga",
        "slug": "la-liga",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/26"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=26"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=26"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=26"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 24,
        "count": 56,
        "description": "",
        "link": "https://news.scoreupdate.com/ligue-1/",
        "name": "Ligue 1",
        "slug": "ligue-1",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/24"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=24"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=24"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=24"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 21,
        "count": 65,
        "description": "",
        "link": "https://news.scoreupdate.com/premier-league/",
        "name": "Premier League",
        "slug": "premier-league",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/21"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=21"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=21"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=21"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 23,
        "count": 55,
        "description": "",
        "link": "https://news.scoreupdate.com/serie-a/",
        "name": "Serie A",
        "slug": "serie-a",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/23"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=23"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=23"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=23"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 204,
        "count": 1588,
        "description": "",
        "link": "https://news.scoreupdate.com/team-news/",
        "name": "Team News",
        "slug": "team-news",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/204"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=204"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=204"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=204"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 189,
        "count": 68,
        "description": "",
        "link": "https://news.scoreupdate.com/vi/tennis/",
        "name": "Tennis",
        "slug": "tennis",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],

        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/189"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=189"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=189"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=189"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 185,
        "count": 22,
        "description": " V-LEAGUE",
        "link": "https://news.scoreupdate.com/vi/v-league/",
        "name": "V-LEAGUE",
        "slug": "v-league",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/185"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=185"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=185"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=185"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    },
    {
        "id": 46,
        "count": 45,
        "description": "",
        "link": "https://news.scoreupdate.com/world-cup/",
        "name": "World Cup",
        "slug": "world-cup",
        "taxonomy": "category",
        "parent": 0,
        "meta": [],
        "acf": [],
        "_links": {
            "self": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories/46"
                }
            ],
            "collection": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/categories"
                }
            ],
            "about": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/taxonomies/category"
                }
            ],
            "wp:post_type": [
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/posts?categories=46"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/en-eng?categories=46"
                },
                {
                    "href": "https://news.scoreupdate.com/wp-json/wp/v2/vi-vn?categories=46"
                }
            ],
            "curies": [
                {
                    "name": "wp",
                    "href": "https://api.w.org/{rel}",
                    "templated": true
                }
            ]
        }
    }
]
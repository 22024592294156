import './components/gtm';

import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import CompetitionBasketballLayout, { loader as CompetitionBasketballLayoutLoader } from './components/basketball/competitionLayout';
import MatchBasketballDetailLayout, { loader as MatchBasketballDetailLoader } from './components/basketball/matchDetailLayout';
import CricketCompetitionLayout from './components/cricket/cricketCompetitionLayout';
import CricketMatchDetailLayout from './components/cricket/cricketMatchDetailLayout';
import CricketMatchLayout from './components/cricket/cricketMatchLayout';
import CompetitionHockeyLayout from './components/hockey/competitionLayout';
import MatchHockeylDetailLayout from './components/hockey/matchDetailLayout';
import MatchLayout, { loader as CompetitionMatchesLoader } from './components/matchLayout';
import CompetitionLayout from './components/soccer/competitionLayout';
import MatchDetailLayout from './components/soccer/matchDetailLayout';
import TeamDetailLayout from './components/soccer/teamDetailLayout';
import TennisCompetitionLayout from './components/tennis/tennisCompetitionLayout';
import TennisMatchDetailLayout from './components/tennis/tennisMatchDetailLayout';
import TennisMatchLayout from './components/tennis/tennisMatchLayout';
import NewsLayout from './components/news/newsLayout';
import NewsDetail from './components/news/newsDetail';
import HighlightsLayout from './components/highlights/highlightsLayout';
import HighlightDetail from './components/highlights/highlightsDetail';
import MatchFootballDetailLayout from "./components/football/matchDetailLayout"
import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from './components/errorPage';
import Layout from './components/layout';
import NotFound from './components/notFound';
import SportLayout from './components/sportLayout';
import { ROUTING } from './mods/common';
import reportWebVitals from './reportWebVitals';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import 'react-lazy-load-image-component/src/effects/blur.css';
import './components/layout.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={<Layout />} errorElement={<ErrorPage />}>
    <Route path='/soccer?' element={<SportLayout route={ROUTING.SOCCER} new={false} />}>
      <Route path=':date?' loader={(arg) => CompetitionMatchesLoader(arg, ROUTING.SOCCER)} element={<MatchLayout route={ROUTING.SOCCER} />} />
      <Route path=':cid/:coid' element={<Outlet />}>
      <Route path=':match/:matchId' element={<Outlet />}>
          <Route path='info'  element={<MatchFootballDetailLayout info={true} />} />
          <Route path='summary'  element={<MatchFootballDetailLayout summary={true} />}  />
        </Route>
      </Route>
      // this is old logic , I didn't change anything 
      {/* <Route path=':cname/:coname' element={<Outlet />}>
        <Route path=":cid/:coid" element={<CompetitionLayout />} />
        <Route path='overview/:cid/:coid'element={<CompetitionLayout />} />
        <Route path='fixtures/:cid/:coid' element={<CompetitionLayout fixtures={true} />} />
        <Route path='table/:cid/:coid' element={<CompetitionLayout table={true} />} />
        <Route path='results/:cid/:coid' element={<CompetitionLayout results={true} />} />
        <Route path=':match/:matchId/:cid/:coid' element={<Outlet />}>
          <Route path='info' element={<MatchDetailLayout info={true} />}  />
          <Route path='summary' element={<MatchDetailLayout summary={true} />}  />
          <Route path='stats' element={<MatchDetailLayout stats={true} />} />
          <Route path='line-ups' element={<MatchDetailLayout lineUps={true} />} />
          <Route path='h2h' element={<MatchDetailLayout h2h={true} />}  />
          <Route path='table' element={<MatchDetailLayout table={true} />}  />
          <Route path='news' element={<MatchDetailLayout news={true} />}  />
        </Route>
      </Route> */}
      {/* <Route path=':team/:teamId/:cid/:coid' element={<Outlet />}>
        <Route path='table' element={<TeamDetailLayout table={true} />} />
        <Route path='matches/fixtures' element={<TeamDetailLayout  matches={true} fixtures={true}/>} />
        <Route path='matches/results' element={<TeamDetailLayout  matches={true} results={true}/>} />
        <Route path='overview' element={<TeamDetailLayout overview={true} />} />
        <Route path='news' element={<TeamDetailLayout news={true} />} />
      </Route> */}
    </Route>
    <Route path='/basketball' element={<SportLayout route={ROUTING.BASKETBALL} new={true} />}>
      <Route path=':date?' loader={(arg) => CompetitionMatchesLoader(arg, ROUTING.BASKETBALL)} element={<MatchLayout route={ROUTING.BASKETBALL} />} />
      <Route path=':cid/:coid' element={<Outlet />}>
        <Route index={true} loader={CompetitionBasketballLayoutLoader} element={<CompetitionBasketballLayout />} />
        <Route path='overview' loader={CompetitionBasketballLayoutLoader} element={<CompetitionBasketballLayout />} />
        <Route path='fixtures' loader={CompetitionBasketballLayoutLoader} element={<CompetitionBasketballLayout fixtures={true} />} />
        <Route path='results' loader={CompetitionBasketballLayoutLoader} element={<CompetitionBasketballLayout results={true} />} />
        <Route path='table' loader={CompetitionBasketballLayoutLoader} element={<CompetitionBasketballLayout table={true} />} />
        <Route path=':match/:matchId' element={<Outlet />}>
          <Route path='info' loader={MatchBasketballDetailLoader} element={<MatchBasketballDetailLayout info={true} />} />
        </Route>
      </Route>
    </Route>
    <Route path='/hockey' element={<SportLayout route={ROUTING.HOCKEY} new={true} />}>
      <Route path=':date?' loader={(arg) => CompetitionMatchesLoader(arg, ROUTING.HOCKEY)} element={<MatchLayout route={ROUTING.HOCKEY} />} />
      <Route path=':cid/:coid' element={<Outlet />}>
        <Route index={true}  element={<CompetitionHockeyLayout />} />
        <Route path='overview' element={<CompetitionHockeyLayout />} />
        <Route path='fixtures'  element={<CompetitionHockeyLayout fixtures={true} />} />
        <Route path='results'  element={<CompetitionHockeyLayout results={true} />} />
        <Route path='table' element={<CompetitionHockeyLayout table={true} />} />
        <Route path=':match/:matchId' element={<Outlet />}>
          <Route path='info'  element={<MatchHockeylDetailLayout info={true} />}  />
          <Route path='summary'  element={<MatchHockeylDetailLayout summary={true} />}  />
        </Route>
      </Route>
    </Route>
    <Route path='/tennis' element={<SportLayout route={ROUTING.TENNIS} new={true} />}>
      <Route path=':date?' element={<TennisMatchLayout route={ROUTING.TENNIS} />} />
      <Route path=':cid/:coid' element={<Outlet />}>
        <Route index={true}  element={<TennisCompetitionLayout />} />
        <Route path='overview'element={<TennisCompetitionLayout />} />
        <Route path={'fixtures'}  element={<TennisCompetitionLayout fixtures={true} />} />
        <Route path={'results'}  element={<TennisCompetitionLayout results={true} />} />

        <Route path=':match/:matchId' element={<Outlet />}>
          <Route path={'info'}  element={<TennisMatchDetailLayout info={true} />} />
          <Route path={'summary'} element={<TennisMatchDetailLayout summary={true} />} />
        </Route>
      </Route>
    </Route>

    <Route path='/cricket?' element={<SportLayout route={ROUTING.CRICKET} new={true} />}>
      <Route path=':date?'  element={<CricketMatchLayout route={ROUTING.CRICKET} />} />
      <Route path=':cid/:coid' element={<Outlet />}>
        <Route index={true}  element={<CricketCompetitionLayout />} />
        <Route path='overview' element={<CricketCompetitionLayout />} />
        <Route path='fixtures' element={<CricketCompetitionLayout fixtures={true} />} />
        <Route path='table'  element={<CricketCompetitionLayout table={true} />} />
        <Route path='results' element={<CricketCompetitionLayout results={true} />} />

        <Route path=':match/:matchId' element={<Outlet />}>
          <Route path='info' element={<CricketMatchDetailLayout info={true} />} />
          <Route path='scorecard/:tab'  element={<CricketMatchDetailLayout scorecard={true} />} />
          <Route path='summary/:tab' element={<CricketMatchDetailLayout summary={true} />} />
          <Route path='teams' element={<CricketMatchDetailLayout teams={true} />} />
          <Route path='table'  element={<CricketMatchDetailLayout table={true} />} />
        </Route>
      </Route>
    </Route>

    <Route path='/news' element={<Outlet />} >
      <Route index={true} element={<NewsLayout />} />
      <Route path='detail/:lang/:slug' element={<NewsDetail />} />
    </Route>

    <Route path='/highlights' element={<Outlet />} >
      <Route index={true} element={<HighlightsLayout />} />
      <Route path='detail/:lang/:slug' element={<HighlightDetail />} />
    </Route>

    <Route path='*' element={<NotFound />} />
  </Route>
))

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

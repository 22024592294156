import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

export type ITabData = {
  name?: string;
  path?: string;
  active?: boolean;
  dropdown?: string;
  childs?: ITabData[];
  hidden?: boolean
};

type Props = {
  data: ITabData[];
  handleNavigateTo: (path: string, child?: boolean) => void;
} & WithTranslation;

const TabsComponent = (props: Props) => {
  const [childs, setChilds] = useState<ITabData>();
  const [dropdown, setDropdown] = useState<ITabData>();
  const scroll1: any = useRef();

  useEffect(() => {
    const tabChilds = props.data?.find((item) => item.active && item.childs);
    const tabDropdown = props.data?.find((item) => item.dropdown);

    setChilds(tabChilds);
    setDropdown(tabDropdown);
  }, [props.data]);

  useEffect(restoreScroll1);

  function renderLevel1() {
    return <div
      ref={scroll1}
      id="scrollbar"
      className="flex gap-x-3 lg:gap-x-6 overflow-auto"
      onScroll={saveScroll1}
    >
      {props.data?.map((item: ITabData, index: number) => item.hidden ? null : (
        <button
          key={`TABS:${index}`}
          onClick={() => props.handleNavigateTo(item?.path || "")}
          className={`tab-detail${item.active ? " active" : ""}`}
        >
          <div className="active-line"></div>
          <div className="flex items-center px-4 lg:px-0 capitalize">
            <span>{props.t(item?.name || "")}</span>
          </div>
        </button>
      ))}
    </div>
  }

  function saveScroll1(syntheticBaseEvent: BaseSyntheticEvent) {
    localStorage.setItem("scroll1", JSON.stringify({
      innerWidth: window.innerWidth,
      scrollLeft: syntheticBaseEvent.target.scrollLeft,
    }));
  }

  function restoreScroll1() {
    let json = JSON.parse(localStorage.getItem("scroll1") || "{}");
    if (window.innerWidth === json.innerWidth) {
      scroll1.current?.scrollTo(json.scrollLeft || 0, 0);
    }
  }

  function renderDropdown() {
    return dropdown && (
      <div className="flex items-center ml-3">
        <button className="rounded-lg bg-primary-lighter text-accent text-lg font-semibold w-full px-1.5 py-1 lg:px-3 lg:py-2 flex items-center">
          <span className="mr-1 lg:mr-2">{dropdown.dropdown}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-accent rotate-90 w-5 h-5"
          >
            <path d="M9.00003 20C8.80228 19.9999 8.60898 19.9413 8.44457 19.8314C8.28016 19.7215 8.15202 19.5653 8.07635 19.3826C8.00068 19.1999 7.98088 18.9989 8.01945 18.8049C8.05802 18.611 8.15322 18.4328 8.29303 18.293L14.586 12L8.29303 5.70698C8.11087 5.51838 8.01008 5.26578 8.01236 5.00358C8.01464 4.74138 8.1198 4.49057 8.30521 4.30516C8.49062 4.11975 8.74143 4.01458 9.00363 4.01231C9.26583 4.01003 9.51843 4.11082 9.70703 4.29298L16.707 11.293C16.8945 11.4805 16.9998 11.7348 16.9998 12C16.9998 12.2651 16.8945 12.5195 16.707 12.707L9.70703 19.707C9.51954 19.8945 9.26523 19.9999 9.00003 20Z" />
          </svg>
        </button>
      </div>
    )
  }

  function renderLevel2() {
    if (childs && childs.active && childs.childs) {
      return <div className="px-3 pb-6 h-30">
        <div id="scrollbar" className="btn-list border-0 flex gap-x-4 overflow-auto pb-1.5">
          {childs.childs.map((item: ITabData, index: number) => (
            <button
              key={`TAB_CHILDS:${index}`}
              onClick={() => props.handleNavigateTo(item?.path || "", true)}
              className={`tab w-auto rounded-lg bg-main-bg-lighter dark:bg-slate-200 lg:text-sm font-semibold py-2 px-4 mt-1 uppercase whitespace-nowrap text-center ${item.active && "active"}`}
            >
              {props.t(item.name || "")}
            </button>
          ))}
        </div>
      </div>
    } return null
  }
  return (
    <>
      {
        props.data[0].name &&
        <div className="border-t border-primary-darker dark:border-zinc-200  flex flex-row justify-between">
          {renderLevel1()}
          {renderDropdown()}
        </div>
      }
      {renderLevel2()}
    </>
  );
};

export default withTranslation()(TabsComponent);

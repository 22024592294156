import TagManager from "react-gtm-module"

if (process.env.REACT_APP_GOOGLE_TAG_CODE) {
	TagManager.initialize({
		gtmId: process.env.REACT_APP_GOOGLE_TAG_CODE
	});
} else {
	TagManager.initialize({
		gtmId: "G-2DTWX5CFMR"
	});
	console.log('Using default google tag')
}
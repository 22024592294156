import { Component, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

type Cell = {
	className?: string,
	mark?: boolean,
	title?: string,
	child: ReactNode
}

type Props = {
	mark?: boolean
	classTable?: string
	classTR?: string
	classTH?: string
	classTD?: string
	classCols?: string[]
	thead: Cell[]
	tbody: Cell[][]
} & WithTranslation

class TableRounded extends Component<Props> {
	render(): ReactNode {
		let tbody = this.props.tbody;
		return <table className={`table table-fixed w-full border-separate border-spacing-0 ${this.props.classTable || ""}`} >
			<thead>
				<tr className={this.props.classTR || ""}>
					{
						this.props.thead.map((cell, indexCell) => {
							let isTop = true;
							let isLeft = indexCell <= 0;
							let isRight = indexCell >= this.props.thead.length - 1;
							let classes = [
								"border-b border-gray-700",
								this.props.classTH,
								this.props.classCols ? this.props.classCols[indexCell] : undefined,
								isTop ? `border-t${isLeft ? " rounded-tl-lg" : ""} ${isRight ? " rounded-tr-lg" : ""}` : undefined,
								isLeft ? `border-l` : undefined,
								isRight ? `border-r` : undefined,
								cell.className
							]
							return <th
								key={`thead-row-${indexCell}`}
								className={classes.filter((className, index) => className).join(" ")}
								title={cell.title}>
								{typeof cell.child === "string" ? this.props.t(cell.child) : cell.child}
							</th>
						})}
				</tr>
			</thead>
			<tbody>
				{tbody.map((row, indexRow) => {
					let isBottom = indexRow === tbody.length - 1;
					return <tr key={`tbody-row-${indexRow}`} className={this.props.classTR || ""}>
						{row.map((cell, indexCell) => {
							let isLeft = indexCell <= 0;
							let isRight = indexCell >= row.length - 1;
							let classes = [
								"border-b border-gray-700",
								this.props.classTD,
								this.props.classCols ? this.props.classCols[indexCell] : undefined,
								cell.className,
								isBottom ? `${(isLeft ? "rounded-bl-lg" : "")} ${isRight ? "rounded-br-lg" : ""}` : undefined,
								isLeft ? `border-l` : undefined,
								isRight ? `border-r` : undefined,
								this.props.mark
									? ("relative before:absolute before:border-l-4 " + (
										cell.mark
											? "before:top-1/2 before:left-0 before:h-3/5 before:border-orange-500 before:rounded-r-lg before:-translate-y-1/2 before:origin-center"
											: "before:border-transparent"
									))
									: undefined
							]
							return <td
								key={`tbody-row${indexRow}-cell${indexCell}`}
								className={classes.filter((className, index) => className).join(' ')}>
								{this.props.mark && typeof cell.mark === "boolean" ? <div className="pl-4">{cell.child}</div> : cell.child}
							</td>
						})}
					</tr>
				})}
			</tbody>
		</table >
	}
}

export default withTranslation()(TableRounded);
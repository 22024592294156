import { MatchesBasketball } from "@lib/models";
import React, { Component } from "react";
import { NavigateFunction } from "react-router-dom";
import { BASKETBALL, getNavLink, TEAMS_DEFAULT_IMAGE } from "../../mods/common";
import MatchState from "../matchState";

type Props = {
    navigate: NavigateFunction
    cid?: number | string
    coid?: number | string
    matchesBasketball: MatchesBasketball
}

class MatchListItem extends Component<Props> {
    handleGoMatchDetail = () => {
        const qs = new URLSearchParams([['link', this.props.matchesBasketball.Link]])
        const cname = this.props.cid?.toString()
        const coname = this.props.coid?.toString()

        if (cname && coname && this.props.matchesBasketball) {
            this.props.navigate(getNavLink({
                cname,
                coname,
                team1: this.props.matchesBasketball?.HomeName,
                team2: this.props.matchesBasketball?.AwayName,
                matchId: this.props.matchesBasketball.Id,
                tab: BASKETBALL.INFO
            }, qs));
        }
    }

    handleImageError(event: React.SyntheticEvent<HTMLImageElement, Event>) {
        const target = event.target as HTMLImageElement;
        target.src = TEAMS_DEFAULT_IMAGE;
    }
    render(): React.ReactNode {
        let matchesBasketball = this.props.matchesBasketball;
        const isShowScores = matchesBasketball.HomeScore || matchesBasketball.AwayScore;

        return <div onClick={this.handleGoMatchDetail} className={`sp-item-hover before:border-l-8 ${matchesBasketball.IsLive ? "before:border-orange-500 before:rounded-r-lg before:h-16" : ""}`} >
            <MatchState first={matchesBasketball.StatusOrTime} second={""} accented={matchesBasketball.IsLive} className="min-w-[3rem]" />
            <div className="flex flex-col sm:flex-row justify-between sm:justify-center items-start sm:items-center sm:px-1 w-full h-auto relative">
                <div className="flex flex-row-reverse sm:flex-row items-center justify-end w-full lg:max-w-[13rem]">
                    <span className="px-2 text-lg lg:text-base truncate">{matchesBasketball.HomeName}</span>
                    <img onError={this.handleImageError} src={matchesBasketball.HomeIcon || TEAMS_DEFAULT_IMAGE} alt="" className="h-7 w-7 mb-1 sm:mb-0" />
                </div>
                <button className="score" >
                    <span>{isShowScores ? matchesBasketball.HomeScore || 0 : "-"}</span>
                    <span className="invisible sm:px-3 sm:visible">:</span>
                    <span>{isShowScores ? matchesBasketball.AwayScore || 0 : "-"}</span>
                </button>
                <div className="flex items-center w-full lg:max-w-[13rem]">
                    <img onError={this.handleImageError} src={matchesBasketball.AwayIcon || TEAMS_DEFAULT_IMAGE} alt="" className="h-7 w-7 mt-1 sm:mt-0" />
                    <span className="px-2 text-lg lg:text-base truncate">{matchesBasketball.AwayName}</span>
                </div>
            </div>
            <div className="flex justify-center items-center h-16 sm:h-auto" style={{ display: 'none' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" className="fill-accent h-5 w-5">
                    <path d="M20.4041 10.0894L17.1261 13.414L17.8997 18.104C17.9621 18.4781 17.9236 18.8632 17.7885 19.2153C17.6535 19.5674 17.4273 19.8723 17.1359 20.0952C16.8392 20.3255 16.4874 20.4633 16.1202 20.4933C15.7531 20.5232 15.3853 20.4439 15.0587 20.2645L10.9994 18.0456L6.94018 20.2645C6.61363 20.4443 6.24583 20.5238 5.87861 20.4938C5.5114 20.4639 5.15951 20.3258 4.86298 20.0952C4.57155 19.8723 4.34539 19.5674 4.21033 19.2153C4.07526 18.8632 4.03673 18.4781 4.09914 18.104L4.87275 13.414L1.5948 10.0894C1.33264 9.82664 1.14634 9.4909 1.05746 9.12102C0.968584 8.75115 0.980767 8.36227 1.0926 7.9994C1.20457 7.63114 1.41309 7.30389 1.69401 7.05557C1.97493 6.80724 2.31675 6.648 2.67988 6.59629L7.21568 5.91052L9.24299 1.63832C9.4076 1.29586 9.65885 1.00822 9.96888 0.807291C10.2789 0.606362 10.6356 0.5 10.9994 0.5C11.3632 0.5 11.7199 0.606362 12.03 0.807291C12.34 1.00822 12.5913 1.29586 12.7559 1.63832L14.7832 5.91052L19.3193 6.59629C19.6823 6.64809 20.024 6.80737 20.3049 7.05569C20.5857 7.30401 20.7941 7.63121 20.9061 7.9994C21.0179 8.36225 21.0301 8.75112 20.9413 9.12099C20.8525 9.49086 20.6662 9.82661 20.4041 10.0894Z" />
                </svg>
            </div>
        </div>
    }
}

export default MatchListItem;

import { LeagueCricketTable } from "@lib/models";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import NoData from "../shared/NoData";
import TableRounded from "../shared/tableRounded";

type Props = {
    tables?: LeagueCricketTable[]
} & WithTranslation

class CricketCompetitionTables extends Component<Props> {
    render(): React.ReactNode {
        return this.props.tables && this.props.tables.length > 0
            ? this.renderTable(this.props.tables!!)
            : <NoData text="There is no data available" />;
    }

    renderTable(tables: LeagueCricketTable[]) {
        return <>
            {tables.map((table: LeagueCricketTable, index: number) => {
                return <TableRounded
                    key={`CricketCompetitionTables-${index}`}
                    mark={true}
                    classTable={"my-3"}
                    classTR={"h-12"}
                    classCols={[
                        "w-10 text-center",
                        "w-full text-left",
                        "w-10 text-center",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center hidden sm:table-cell",
                        "w-10 text-center",
                        "w-24 text-center",
                    ]}
                    thead={
                        table.Header.map((cell: string, indexCell: number) => {
                            return { child: cell }
                        })
                    }
                    tbody={
                        table.Body.map((row: { [id: string]: string }, indexRow: number) => {
                            return [
                                { child: row.Position },
                                { child: row.Name },
                                { child: row.Played },
                                { child: row.Wins },
                                { child: row.Losses },
                                { child: row.Ties },
                                { child: row.NetRun },
                                { child: row.Points },
                                { child: row.NoResults },
                            ]
                        })
                    }
                />
            })}
            <div className="flex sm:hidden flex-row w-full justify-center items-center mt-10 text-gray-400  ">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className="mr-2">
                    <g fill="#AAA" fillRule="evenodd">
                        <path d="M16.5 9.5c.245 0 .45.177.492.41L17 10c0 3.866-3.134 7-7 7-2.187 0-4.14-1.003-5.424-2.575l-.722.721c-.196.196-.512.196-.708 0-.093-.093-.146-.22-.146-.353V12.5c0-.206.125-.383.303-.46l-.013-.039h.2L5.794 12c.276 0 .5.224.5.5 0 .133-.053.26-.147.354l-.86.859C6.386 15.106 8.088 16 10 16c3.314 0 6-2.686 6-6 0-.276.224-.5.5-.5zM9.899 5.858l4.243 4.243c.39.39.39 1.024 0 1.414l-2.627 2.627c-.39.39-1.023.39-1.414 0L5.858 9.899c-.39-.39-.39-1.024 0-1.414l2.627-2.627c.39-.39 1.023-.39 1.414 0zM10 3c2.188 0 4.141 1.004 5.425 2.575l.721-.721c.196-.196.512-.196.708 0 .093.093.146.22.146.353V7.5c0 .206-.124.382-.302.46l.012.04h-2.503c-.276 0-.5-.224-.5-.5 0-.133.053-.26.147-.354l.86-.86C13.613 4.895 11.911 4 10 4c-3.314 0-6 2.686-6 6 0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L3 10c0-3.866 3.134-7 7-7z" transform="translate(-33.000000, -1057.000000) translate(10.000000, 215.000000) translate(1.000000, 1.000000) translate(0.000000, 819.000000) translate(15.000000, 15.000000) translate(4.000000, 4.000000)" />
                    </g>
                </svg>
                {this.props.t("Rotate to view expanded table")}
            </div>
        </>
    }
}

export default withTranslation()(CricketCompetitionTables);
import { Component, ReactNode } from "react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
} from '@heroicons/react/20/solid'
import DateUtils from "../utils/DateUtils";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
	selectedDate: Date,
	fromSunday: boolean,
	onCalenderCurrentDateChanged: (date: Date) => void
} & WithTranslation

type State = {
	date: Date;
	offsetMonth: number;
}

class Calendar extends Component<Props, State> {
	state: State = {
		date: new Date(),
		offsetMonth: 0,
	}

	getDates() {
		const dates = [];
		const month = DateUtils.calcMonth(this.props.selectedDate, this.state.offsetMonth);
		const offsetMonday = this.props.fromSunday ? 0 : 1;

		// get first day of month
		const dateBeginMonth = new Date(this.state.date.getFullYear(), month, 1);

		const dateStart = new Date(dateBeginMonth.getTime());
		dateStart.setDate(dateBeginMonth.getDate() - dateBeginMonth.getDay() + offsetMonday);

		// get last day of month
		const dateEndMonth = new Date(this.state.date.getFullYear(), month + 1, 0);

		// full fill last week
		const dateEnd = new Date(dateEndMonth.getFullYear(), dateEndMonth.getMonth(), dateEndMonth.getDate() + 7 - dateEndMonth.getDay() + offsetMonday);

		// list all date from dateBeginMonth to dateEnd;
		let dateCurrent = dateStart;
		while (dateCurrent < dateEnd) {
			dates.push(dateCurrent);
			dateCurrent = new Date(dateCurrent.getTime());
			dateCurrent.setDate(dateCurrent.getDate() + 1);
		}
		return dates;
	}

	onButtonPreviousMonthClicked() {
		this.setState(st => ({
			offsetMonth: st.offsetMonth - 1,
		}));
	}

	onButtonNextMonthClicked() {
		this.setState(st => ({
			offsetMonth: st.offsetMonth + 1,
		}));
	}

	onDateClick(date: Date) {
		this.setState({
			date: date
		})
		this.props.onCalenderCurrentDateChanged(date);
	}

	render(): ReactNode {
		const month = DateUtils.calcMonth(this.props.selectedDate, this.state.offsetMonth);
		const dates = this.getDates();
		return <div className="mt-0 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-0 xl:col-start-9">
			<div className="flex items-center text-gray-900 py-2">
				<button
					type="button"
					className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
					onClick={this.onButtonPreviousMonthClicked.bind(this)}
				>
					<span className="sr-only" />
					<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
				</button>
				<div className="flex-auto text-lg font-semibold">{this.props.t("Long_" + DateUtils.MonthNames[month])}</div>
				<button
					type="button"
					className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
					onClick={this.onButtonNextMonthClicked.bind(this)}
				>
					<span className="sr-only" />
					<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
				</button>
			</div>
			<div className="mt-2 grid grid-cols-7 text-base leading-6 text-gray-500">
				{DateUtils.getDayShortENNames(this.props.fromSunday).map((dayName, index) => {
					return <div key={`dayName-${index}`}>{this.props.t("Short_" + dayName)}</div>
				})}
			</div>
			<div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-base ring-1 ring-gray-200">
				{dates.map((date, dayIdx) => {
					const isToday = DateUtils.compareDate(date, this.state.date);
					const isCurrentMonth = month === date.getMonth();
					const isSelected = DateUtils.compareDate(date, this.props.selectedDate);
					return <button
						key={"btn" + date.getTime()}
						type="button"
						className={
							"hover:bg-gray-100 focus:z-10 sm:py-0 px-1" +
							(isCurrentMonth ? " bg-white" : " bg-gray-50") +
							(isSelected || isToday ? " font-semibold" : "") +
							(isSelected ? " text-white dark:text-black" : "") +
							(!isSelected && isCurrentMonth && !isToday ? " text-gray-900" : "") +
							(!isSelected && !isCurrentMonth && !isToday ? " text-gray-400" : "") +
							(isToday && !isSelected ? " text-accent" : "") +
							(dayIdx === 0 ? " rounded-tl-lg" : "") +
							(dayIdx === 6 ? " rounded-tr-lg" : "") +
							(dayIdx === dates.length - 7 ? " rounded-bl-lg" : "") +
							(dayIdx === dates.length - 1 ? " rounded-br-lg" : "")
						}
						onClick={() => { this.onDateClick(date) }}
					>
						<time
							dateTime={"time" + date.getTime()}
							className={
								"mx-auto flex h-8 w-8 items-center justify-center rounded-full mx-1 my-1" +
								(isSelected && isToday ? " bg-accent" : "") +
								(isSelected && !isToday ? " bg-accent" : "")
							}
						>
							{date.getDate()}
						</time>
					</button>
				})}
			</div>
		</div>
	}
}
export default withTranslation()(Calendar);
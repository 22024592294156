import { NavigateFunction } from "react-router-dom";
import { CompetitionBasketballMatches, MatchesBasketball } from "../../mods/common";
import CompetitionLinker from "../competitionLinker";
import MatchListItem from "./matchListItem";
import { Component, ReactNode } from "react";

type Props = {
    navigate: NavigateFunction
    league: CompetitionBasketballMatches,
}

class MatchList extends Component<Props> {
    render(): ReactNode {
        let league = this.props.league;
        return <div>
            <CompetitionLinker
                isButton={true}
                league={this.props.league}
                navigate={this.props.navigate}
            />

            {league.Matches.map((v: MatchesBasketball, index: number) => {
                return <div className="flex flex-col gap-y-3 mb-1" key={`$match-${index}`}>
                    <MatchListItem
                        navigate={this.props.navigate}
                        cid={league.CategoryName}
                        coid={league.LeagueName}
                        matchesBasketball={v} />
                </div>
            })}
        </div>
    }
}

export default MatchList
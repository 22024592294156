import { useTranslation } from "react-i18next";

type Props = {
  accented?: boolean;
  className?: string;
  first?: string;
  second?: string;
};

function MatchState(props: Props) {
  const { t } = useTranslation();
  let classes = `text-center flex flex-col mx-2`;
  if (props.className) {
    classes = classes.concat(" ", props.className);
  }

  return (
    <div className={classes}>
      {props.first && (
        <span
          className={`text-sm lg:text-xs mb-1${
            props.accented ? " text-accent" : ""
          }`}
        >
          {t(props.first, props.first)}
        </span>
      )}
      {props.second && (
        <span
          className={`text-base lg:text-sm${
            props.accented ? " text-accent" : ""
          }`}
        >
          {`${t(props.second, props.second)}`.replace('"', "")}
          {props.accented && !!parseInt(props.second.split("\\")[0]) && (
            <span className="blinking-time-live">"</span>
          )}
        </span>
      )}
    </div>
  );
}

export default MatchState;

import React, { memo, useEffect, useState } from "react";
import CompetitionLinker from "./competitionLinker";
import MatchListFootballItem from "./matchListFootballItem";

type Props = any;

const MatchListFootball = (props: Props) => {
  const InforMatch = props?.league?.InforMatch;
  return (
    <div>
      <CompetitionLinker
        round={props?.league?.Round}
        leagueIcon={props?.league?.LeagueIconSrc}
        leagueName={props.league?.LeagueName}
      />
      {InforMatch.map((e: any, index: number) => {
        return (
          <div className="flex flex-col gap-y-3 mb-1" key={`$match-${index}`}>
            <MatchListFootballItem inforMatch={e} />
          </div>
        );
      })}
    </div>
  );
};
export default memo(MatchListFootball);

import { useEffect, useState } from "react";

const SocialButtons = () => {
  const [darkTheme, setDarkTheme] = useState<boolean>(true);

  const changeTheme = () => {
    if (localStorage.theme !== "dark" || !("theme" in localStorage)) {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
      setDarkTheme(true);
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
      setDarkTheme(false);
    }
  };

  useEffect(() => {
    document.documentElement.classList.add(localStorage.theme);
  }, []);

  return (
    <div className="devvn_toolbar">
      <ul>
        <li>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.devvn_toolbar ul li a#devvn_contact_3 span:after {\nborder-right-color: #29b6f6;\n}\n",
            }}
          />
          <a
            href="https://t.me/scoreupdate_bot"
            target="_blank"
            id="devvn_contact_3"
            title="Telegram ScoreUpdate"
            style={{ backgroundColor: "#29b6f6" }}
            data-wpel-link="external"
            rel="nofollow external noopener noreferrer"
          >
            <img
              width={818}
              height={848}
              src="https://theanhlive.com/wp-content/uploads/2022/04/telegram.png"
              className="attachment-full size-full"
              alt=""
              decoding="async"
              loading="lazy"
              srcSet="https://theanhlive.com/wp-content/uploads/2022/04/telegram.png 818w, https://theanhlive.com/wp-content/uploads/2022/04/telegram-289x300.png 289w, https://theanhlive.com/wp-content/uploads/2022/04/telegram-768x796.png 768w"
              sizes="(max-width: 818px) 100vw, 818px"
            />{" "}
            <span style={{ color: "#ffffff", backgroundColor: "#29b6f6" }}>
              Telegram ScoreUpdate
            </span>
          </a>
        </li>
        <li>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.devvn_toolbar ul li a#devvn_contact_2 span:after {\nborder-right-color: rgb(60 87 158);\n}\n",
            }}
          />
          <a
            href="https://www.facebook.com/scoreupdate.official"
            target="_blank"
            id="devvn_contact_2"
            title="Facebook ScoreUpdate"
            style={{ backgroundColor: "rgb(60 87 158)" }}
            data-wpel-link="external"
            rel="nofollow external noopener noreferrer"
          >
            <img
              width={60}
              height={60}
              src="/assets/icons/fb-circle.svg"
              className="attachment-full size-full"
              alt=""
              decoding="async"
              loading="lazy"
            />{" "}
            <span
              style={{ color: "#ffffff", backgroundColor: "rgb(60 87 158)" }}
            >
              Facebook ScoreUpdate
            </span>
          </a>
        </li>
        <li>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.devvn_toolbar ul li a#devvn_contact_4 span:after {\nborder-right-color: rgb(84 172 238);\n}\n",
            }}
          />
          <a
            href="https://twitter.com/ScoreUpdate247"
            target="_blank"
            id="devvn_contact_4"
            title="Twitter ScoreUpdate"
            style={{ backgroundColor: "rgb(84 172 238)" }}
            data-wpel-link="external"
            rel="nofollow external noopener noreferrer"
          >
            <img
              width={543}
              height={543}
              src="/assets/icons/twitter-circle.svg"
              className="attachment-full size-full"
              alt=""
              decoding="async"
              loading="lazy"
              sizes="(max-width: 543px) 100vw, 543px"
            />{" "}
            <span
              style={{ color: "#ffffff", backgroundColor: "rgb(84 172 238)" }}
            >
              Twitter ScoreUpdate
            </span>
          </a>
        </li>
        <li>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.devvn_toolbar ul li a#devvn_contact_3 span:after {\nborder-right-color: rgb(60 87 158);\n}\n",
            }}
          />
          <a
            id="devvn_contact_3"
            onClick={changeTheme}
            title="Telegram ScoreUpdate"
            className="cursor-pointer"
            style={{ backgroundColor: `${darkTheme ? "#194daa" : "#ffffff"}` }}
            data-wpel-link="external"
          >
            <img
              width={818}
              height={848}
              src={`${
                darkTheme
                  ? "https://cdn-icons-png.flaticon.com/512/10991/10991061.png"
                  : "https://cdn-icons-png.flaticon.com/512/8637/8637690.png"
              } `}
              className="attachment-full size-full"
              decoding="async"
              loading="lazy"
              sizes="(max-width: 818px) 100vw, 818px"
            />{" "}
            <span style={{ color: "#fff", backgroundColor: "rgb(60 87 158)" }}>
              Dark/Light mode
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialButtons;

import { MatchHockeyDetail, TIME_HOCKEY_MODE, SummaryHockeyDetail } from "../../mods/common";
import NoData from "../shared/NoData";

type Props = {
  details: MatchHockeyDetail;
};

const summaryLayout = ({ details }: Props) => {
  if (!details.Summary || details.Summary.length === 0) {
    return <NoData text="There is no data available" />
  }
  return (
    <div className="tab-content p-0 overflow-hidden">
      {details.Summary.map((item: SummaryHockeyDetail, index: number) => (
        <div key={`SUMMARY_HOCKEY:${index}`}>
          {item.Time in TIME_HOCKEY_MODE ? (
            <div className="text-sm min-h-[38px] bg-main-bg-lighter dark:bg-slate-200 items-center border-b flex flex-nowrap justify-between relative border-primary-darker dark:border-zinc-200  dark:border-zinc-200">
              <span className="whitespace-nowrap flex items-center justify-center w-[50px]">
                {item.Time}
              </span>
              <span className="whitespace-nowrap flex-1 overflow-hidden text-ellipsis text-right"></span>
              <div className="flex  text-[#fdfdfd] dark:text-black flex-shrink-0 flex w-[80px] font-bold justify-center text-center">
                <span className="whitespace-nowrap">{item.Score}</span>
              </div>
              <span className="whitespace-nowrap flex-1 overflow-hidden text-ellipsis"></span>
            </div>
          ) : (
            <div className="text-sm items-center flex text-[#aaa] whitespace-nowrap justify-between relative border-b border-primary-darker dark:border-zinc-200  dark:border-zinc-200">
              <span className="items-center flex justify-center h-[50px] min-w-[50px] w-[50px]">
                {item.Time}
              </span>
              <div className="justify-end flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap">
                {item.HomePenalty && (
                  <>
                    <div className="text-right flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-right">
                        {item.HomePenalty}
                      </span>
                      {/* <span>{detail.homePlayerHr}</span> */}
                    </div>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      {/* {detail.isHomePowerPow && (
                        <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold">
                          PP
                        </div>
                      )} */}

                      <span className="relative w-[14px]">
                        <img
                          src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' name='HockeyGoalPow'%3e%3cpath fill='%23999' d='M31.16 22.28c-.19.15-.36.34-.61.48l-.77.48c-.28.16-.59.28-.9.42-.32.14-.65.3-1 .38l-1.05.3c-.35.12-.71.16-1.07.21l-1.04.15c-.34.04-.66.12-.98.1l-.88.03-1.8.04 1.8-.14.87-.08c.3 0 .62-.1.95-.16l1.03-.2c.34-.07.7-.14 1.03-.28l1-.34c.35-.1.64-.27.94-.42.3-.16.59-.29.85-.45l.68-.49c.23-.14.38-.33.54-.47.15-.14.28-.27.35-.38.09-.12.16-.18.2-.26.04-.07.29-.34.34-.4v-1.1c-.18.22-.52.5-.8.72-.29.23-.57.55-.97.77l-1.23.78c-.45.24-.95.43-1.46.66-.51.23-1.03.48-1.6.61l-1.68.48a8.9 8.9 0 01-1.72.34l-1.66.24c-.55.06-1.07.2-1.57.18l-1.41.03-2.91.08 2.9-.24 1.4-.12c.49 0 1-.17 1.53-.26l1.64-.33a8.6 8.6 0 001.65-.43l1.62-.56c.55-.15 1.03-.43 1.5-.67.47-.25.95-.46 1.36-.72l1.1-.8c.37-.21.6-.52.86-.74.24-.23.45-.43.57-.61.14-.19.25-.3.33-.43.07-.11.56-.68.56-.68v.2-6.92c0-3.52-7-6.38-15.65-6.38C7.36 4.9.35 7.75.35 11.28v9.44c0 3.52 7 6.37 15.65 6.37 7.35 0 13.51-2.06 15.2-4.84l-.04.03z'%3e%3c/path%3e%3cpath fill='%23020202' d='M9.45 17.46a23.3 23.3 0 01-5.48-1.76 11 11 0 01-1.96-1.23 4.56 4.56 0 01-.7-.66 3 3 0 01-.5-.65c-.16-.2-.22-.41-.28-.6l-.1-.25-.01-.21-.03-.4.08.38.04.21.12.23c.08.17.16.36.33.54a4.78 4.78 0 001.26 1.14c.55.37 1.23.69 1.97 1.02.76.3 1.6.6 2.51.83a32.84 32.84 0 006.04 1 39.34 39.34 0 0012.56-1 20.6 20.6 0 002.5-.82c.75-.34 1.44-.66 1.98-1.03a4.61 4.61 0 001.26-1.14c.17-.18.24-.37.33-.53l.12-.23.04-.21.08-.4-.03.4-.01.22-.1.25c-.06.18-.12.4-.28.6a4.87 4.87 0 01-1.21 1.31c-.53.45-1.2.85-1.96 1.23a22.95 22.95 0 01-5.47 1.77 31.79 31.79 0 01-6.55.64 41.34 41.34 0 01-3.34-.17c-1.1-.1-2.18-.26-3.22-.47'%3e%3c/path%3e%3c/svg%3e"
                          alt="logo-stadium"
                        ></img>
                      </span>
                    </div>
                  </>
                )}
                {/* {detail.isHomeMins && (
                  <>
                    <div className="text-left flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap">
                        {detail.homeMinsPlayer}
                      </span>
                    </div>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <span className="relative w-[14px] h-[14px] bg-[#FEE900] flex justify-center rounded font-bold text-[9px] items-center text-black">
                        {detail.homeMins}
                      </span>
                    </div>
                  </>
                )} */}
              </div>
              <div className="flex flex-col text-center w-[36px]  text-[#fdfdfd] dark:text-black font-bold">
                {item.Score && (
                  <span>
                    {item.Score}
                  </span>
                )}
              </div>
              <div className="flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap">
                {item.AwayPenalty && (
                  <>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      {/* {detail.isAwayPowerPow && (
                        <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold">
                          PP
                        </div>
                      )} */}

                      <span className="relative w-[14px]">
                        <img
                          src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' name='HockeyGoalPow'%3e%3cpath fill='%23999' d='M31.16 22.28c-.19.15-.36.34-.61.48l-.77.48c-.28.16-.59.28-.9.42-.32.14-.65.3-1 .38l-1.05.3c-.35.12-.71.16-1.07.21l-1.04.15c-.34.04-.66.12-.98.1l-.88.03-1.8.04 1.8-.14.87-.08c.3 0 .62-.1.95-.16l1.03-.2c.34-.07.7-.14 1.03-.28l1-.34c.35-.1.64-.27.94-.42.3-.16.59-.29.85-.45l.68-.49c.23-.14.38-.33.54-.47.15-.14.28-.27.35-.38.09-.12.16-.18.2-.26.04-.07.29-.34.34-.4v-1.1c-.18.22-.52.5-.8.72-.29.23-.57.55-.97.77l-1.23.78c-.45.24-.95.43-1.46.66-.51.23-1.03.48-1.6.61l-1.68.48a8.9 8.9 0 01-1.72.34l-1.66.24c-.55.06-1.07.2-1.57.18l-1.41.03-2.91.08 2.9-.24 1.4-.12c.49 0 1-.17 1.53-.26l1.64-.33a8.6 8.6 0 001.65-.43l1.62-.56c.55-.15 1.03-.43 1.5-.67.47-.25.95-.46 1.36-.72l1.1-.8c.37-.21.6-.52.86-.74.24-.23.45-.43.57-.61.14-.19.25-.3.33-.43.07-.11.56-.68.56-.68v.2-6.92c0-3.52-7-6.38-15.65-6.38C7.36 4.9.35 7.75.35 11.28v9.44c0 3.52 7 6.37 15.65 6.37 7.35 0 13.51-2.06 15.2-4.84l-.04.03z'%3e%3c/path%3e%3cpath fill='%23020202' d='M9.45 17.46a23.3 23.3 0 01-5.48-1.76 11 11 0 01-1.96-1.23 4.56 4.56 0 01-.7-.66 3 3 0 01-.5-.65c-.16-.2-.22-.41-.28-.6l-.1-.25-.01-.21-.03-.4.08.38.04.21.12.23c.08.17.16.36.33.54a4.78 4.78 0 001.26 1.14c.55.37 1.23.69 1.97 1.02.76.3 1.6.6 2.51.83a32.84 32.84 0 006.04 1 39.34 39.34 0 0012.56-1 20.6 20.6 0 002.5-.82c.75-.34 1.44-.66 1.98-1.03a4.61 4.61 0 001.26-1.14c.17-.18.24-.37.33-.53l.12-.23.04-.21.08-.4-.03.4-.01.22-.1.25c-.06.18-.12.4-.28.6a4.87 4.87 0 01-1.21 1.31c-.53.45-1.2.85-1.96 1.23a22.95 22.95 0 01-5.47 1.77 31.79 31.79 0 01-6.55.64 41.34 41.34 0 01-3.34-.17c-1.1-.1-2.18-.26-3.22-.47'%3e%3c/path%3e%3c/svg%3e"
                          alt="logo-stadium"
                        ></img>
                      </span>
                    </div>
                    <div className="text-left flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-left">
                        {item.AwayPenalty}
                      </span>
                      {/* <span>{detail.awayPlayerHr}</span> */}
                    </div>
                  </>
                )}
                {/* {detail.isAwayMins && (
                  <>
                    <div className="flex-col min-w-[34px] items-center flex justify-center">
                      <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold"></div>
                      <span className="relative w-[14px] h-[14px] bg-[#FEE900] flex justify-center rounded font-bold text-[9px] items-center text-black">
                        {detail.awayMins}
                      </span>
                    </div>
                    <div className="text-left flex flex-col">
                      <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap">
                        {detail.awayMinsPlayer}
                      </span>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          )}
          {/*           
          {item?.matchDetail &&
            item?.matchDetail.map((detail: any) => (
              <div className="text-sm items-center flex text-[#aaa] whitespace-nowrap justify-between relative border-b border-primary-darker dark:border-zinc-200 ">
                <span className="items-center flex justify-center h-[50px] min-w-[50px] w-[50px]">
                  {detail.time}
                </span>
                <div className="justify-end flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap">
                  {detail.isHomeScore && (
                    <>
                      <div className="text-right flex flex-col">
                        <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-right">
                          {detail.homePlayer}
                        </span>
                        <span>{detail.homePlayerHr}</span>
                      </div>
                      <div className="flex-col min-w-[34px] items-center flex justify-center">
                        {detail.isHomePowerPow && (
                          <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold">
                            PP
                          </div>
                        )}

                        <span className="relative w-[14px]">
                          <img
                            src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' name='HockeyGoalPow'%3e%3cpath fill='%23999' d='M31.16 22.28c-.19.15-.36.34-.61.48l-.77.48c-.28.16-.59.28-.9.42-.32.14-.65.3-1 .38l-1.05.3c-.35.12-.71.16-1.07.21l-1.04.15c-.34.04-.66.12-.98.1l-.88.03-1.8.04 1.8-.14.87-.08c.3 0 .62-.1.95-.16l1.03-.2c.34-.07.7-.14 1.03-.28l1-.34c.35-.1.64-.27.94-.42.3-.16.59-.29.85-.45l.68-.49c.23-.14.38-.33.54-.47.15-.14.28-.27.35-.38.09-.12.16-.18.2-.26.04-.07.29-.34.34-.4v-1.1c-.18.22-.52.5-.8.72-.29.23-.57.55-.97.77l-1.23.78c-.45.24-.95.43-1.46.66-.51.23-1.03.48-1.6.61l-1.68.48a8.9 8.9 0 01-1.72.34l-1.66.24c-.55.06-1.07.2-1.57.18l-1.41.03-2.91.08 2.9-.24 1.4-.12c.49 0 1-.17 1.53-.26l1.64-.33a8.6 8.6 0 001.65-.43l1.62-.56c.55-.15 1.03-.43 1.5-.67.47-.25.95-.46 1.36-.72l1.1-.8c.37-.21.6-.52.86-.74.24-.23.45-.43.57-.61.14-.19.25-.3.33-.43.07-.11.56-.68.56-.68v.2-6.92c0-3.52-7-6.38-15.65-6.38C7.36 4.9.35 7.75.35 11.28v9.44c0 3.52 7 6.37 15.65 6.37 7.35 0 13.51-2.06 15.2-4.84l-.04.03z'%3e%3c/path%3e%3cpath fill='%23020202' d='M9.45 17.46a23.3 23.3 0 01-5.48-1.76 11 11 0 01-1.96-1.23 4.56 4.56 0 01-.7-.66 3 3 0 01-.5-.65c-.16-.2-.22-.41-.28-.6l-.1-.25-.01-.21-.03-.4.08.38.04.21.12.23c.08.17.16.36.33.54a4.78 4.78 0 001.26 1.14c.55.37 1.23.69 1.97 1.02.76.3 1.6.6 2.51.83a32.84 32.84 0 006.04 1 39.34 39.34 0 0012.56-1 20.6 20.6 0 002.5-.82c.75-.34 1.44-.66 1.98-1.03a4.61 4.61 0 001.26-1.14c.17-.18.24-.37.33-.53l.12-.23.04-.21.08-.4-.03.4-.01.22-.1.25c-.06.18-.12.4-.28.6a4.87 4.87 0 01-1.21 1.31c-.53.45-1.2.85-1.96 1.23a22.95 22.95 0 01-5.47 1.77 31.79 31.79 0 01-6.55.64 41.34 41.34 0 01-3.34-.17c-1.1-.1-2.18-.26-3.22-.47'%3e%3c/path%3e%3c/svg%3e"
                            alt="logo-stadium"
                          ></img>
                        </span>
                      </div>
                    </>
                  )}
                  {detail.isHomeMins && (
                    <>
                      <div className="text-left flex flex-col">
                        <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap">
                          {detail.homeMinsPlayer}
                        </span>
                      </div>
                      <div className="flex-col min-w-[34px] items-center flex justify-center">
                        <span className="relative w-[14px] h-[14px] bg-[#FEE900] flex justify-center rounded font-bold text-[9px] items-center text-black">
                          {detail.homeMins}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-col text-center w-[36px]  text-[#fdfdfd] dark:text-black font-bold">
                  {detail.score && (
                    <span>
                      {detail.score[0]} - {detail.score[1]}
                    </span>
                  )}
                </div>
                <div className="flex-1 h-[50px] overflow-hidden text-ellipsis items-center flex whitespace-nowrap">
                  {detail.isAwayScore && (
                    <>
                      <div className="flex-col min-w-[34px] items-center flex justify-center">
                        {detail.isAwayPowerPow && (
                          <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold">
                            PP
                          </div>
                        )}

                        <span className="relative w-[14px]">
                          <img
                            src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' name='HockeyGoalPow'%3e%3cpath fill='%23999' d='M31.16 22.28c-.19.15-.36.34-.61.48l-.77.48c-.28.16-.59.28-.9.42-.32.14-.65.3-1 .38l-1.05.3c-.35.12-.71.16-1.07.21l-1.04.15c-.34.04-.66.12-.98.1l-.88.03-1.8.04 1.8-.14.87-.08c.3 0 .62-.1.95-.16l1.03-.2c.34-.07.7-.14 1.03-.28l1-.34c.35-.1.64-.27.94-.42.3-.16.59-.29.85-.45l.68-.49c.23-.14.38-.33.54-.47.15-.14.28-.27.35-.38.09-.12.16-.18.2-.26.04-.07.29-.34.34-.4v-1.1c-.18.22-.52.5-.8.72-.29.23-.57.55-.97.77l-1.23.78c-.45.24-.95.43-1.46.66-.51.23-1.03.48-1.6.61l-1.68.48a8.9 8.9 0 01-1.72.34l-1.66.24c-.55.06-1.07.2-1.57.18l-1.41.03-2.91.08 2.9-.24 1.4-.12c.49 0 1-.17 1.53-.26l1.64-.33a8.6 8.6 0 001.65-.43l1.62-.56c.55-.15 1.03-.43 1.5-.67.47-.25.95-.46 1.36-.72l1.1-.8c.37-.21.6-.52.86-.74.24-.23.45-.43.57-.61.14-.19.25-.3.33-.43.07-.11.56-.68.56-.68v.2-6.92c0-3.52-7-6.38-15.65-6.38C7.36 4.9.35 7.75.35 11.28v9.44c0 3.52 7 6.37 15.65 6.37 7.35 0 13.51-2.06 15.2-4.84l-.04.03z'%3e%3c/path%3e%3cpath fill='%23020202' d='M9.45 17.46a23.3 23.3 0 01-5.48-1.76 11 11 0 01-1.96-1.23 4.56 4.56 0 01-.7-.66 3 3 0 01-.5-.65c-.16-.2-.22-.41-.28-.6l-.1-.25-.01-.21-.03-.4.08.38.04.21.12.23c.08.17.16.36.33.54a4.78 4.78 0 001.26 1.14c.55.37 1.23.69 1.97 1.02.76.3 1.6.6 2.51.83a32.84 32.84 0 006.04 1 39.34 39.34 0 0012.56-1 20.6 20.6 0 002.5-.82c.75-.34 1.44-.66 1.98-1.03a4.61 4.61 0 001.26-1.14c.17-.18.24-.37.33-.53l.12-.23.04-.21.08-.4-.03.4-.01.22-.1.25c-.06.18-.12.4-.28.6a4.87 4.87 0 01-1.21 1.31c-.53.45-1.2.85-1.96 1.23a22.95 22.95 0 01-5.47 1.77 31.79 31.79 0 01-6.55.64 41.34 41.34 0 01-3.34-.17c-1.1-.1-2.18-.26-3.22-.47'%3e%3c/path%3e%3c/svg%3e"
                            alt="logo-stadium"
                          ></img>
                        </span>
                      </div>
                      <div className="text-left flex flex-col">
                        <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap text-left">
                          {detail.awayPlayer}
                        </span>
                        <span>{detail.awayPlayerHr}</span>
                      </div>
                    </>
                  )}
                  {detail.isAwayMins && (
                    <>
                      <div className="flex-col min-w-[34px] items-center flex justify-center">
                        <div className=" text-[#fdfdfd] dark:text-black text-[9px] font-bold"></div>
                        <span className="relative w-[14px] h-[14px] bg-[#FEE900] flex justify-center rounded font-bold text-[9px] items-center text-black">
                          {detail.awayMins}
                        </span>
                      </div>
                      <div className="text-left flex flex-col">
                        <span className=" text-[#fdfdfd] dark:text-black font-bold overflow-hidden text-ellipsis whitespace-pre-wrap">
                          {detail.awayMinsPlayer}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))} */}
        </div>
      ))}
    </div>
  );
};

export default summaryLayout;

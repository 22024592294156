export const newsCategories = [
  {
    id: 232,
    slug: 'highlight',
    name: 'All',
  },
  {
    id: 237,
    slug: 'england',
    name: 'England',
  },
  {
    id: 239,
    slug: 'spain',
    name: 'Spain',
  },
  {
    id: 241,
    slug: 'italy',
    name: 'Italy',
  },
  {
    id: 243,
    slug: 'germany',
    name: 'Germany',
  },
  {
    id: 245,
    slug: 'france',
    name: 'France',
  },
  {
    id: 247,
    slug: 'europe',
    name: 'Europe',
  }
]
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Semaphore = void 0;
class Semaphore {
    constructor(max) {
        this.max = max;
        this.running = 0;
        this.waiting = [];
        this.take = () => {
            if (this.waiting.length > 0 && this.running < this.max) {
                this.running++;
                // Get the next task from the queue
                const task = this.waiting.shift();
                // Resolve the promise to allow it to start, provide a release function
                task.resolve({ release: this.release });
            }
        };
        this.acquire = () => {
            if (this.running < this.max) {
                this.running++;
                return Promise.resolve({ release: this.release });
            }
            return new Promise((resolve, reject) => {
                this.waiting.push({ resolve, reject });
            });
        };
        this.release = () => {
            this.running--;
            this.take();
        };
        this.purge = () => {
            this.waiting.forEach(task => {
                task.reject(new Error('The semaphore was purged and as a result this task has been cancelled'));
            });
            this.running = 0;
            this.waiting = [];
        };
        if (max < 1) {
            throw new Error("max value cannot be less than 1");
        }
    }
}
exports.Semaphore = Semaphore;

import TextLinker from "../textLinker";
import { MatchHockeyDetail } from "../../mods/common";
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
  details: MatchHockeyDetail;
} & WithTranslation;

const matchInfoLayout = (props: Props) => {

  const videoData = [
    {
      time: "01:01",
      title: "Glazers to keep Man Utd if bids don't surpass £6 billion",
      img: "https://open.http.mp.streamamg.com/p/3001401/sp/300140100/thumbnail/entry_id/0_7aq2ea5w/width/640",
    },
    {
      time: "01:05",
      title: "Van Dijk mirrors Liverpool perfect storm with overhaul needed ",
      img: "https://open.http.mp.streamamg.com/p/3001401/sp/300140100/thumbnail/entry_id/0_dp6shfyr/width/640",
    },
    {
      time: "01:34",
      title: "Jurgen Klopp - Don't talk to me about transfers",
      img: "https://open.http.mp.streamamg.com/p/3001401/sp/300140100/thumbnail/entry_id/0_hclq44c5/width/640",
    },
    {
      time: "01:01",
      title: "Glazers to keep Man Utd if bids don't surpass £6 billion",
      img: "https://open.http.mp.streamamg.com/p/3001401/sp/300140100/thumbnail/entry_id/0_7aq2ea5w/width/640",
    },
  ];

  return (
    <>
      <TextLinker
        text="MATCH INFO"
        className="font-bold text-sm text-primary"
      />
      <div className="flex border border-primary-darker dark:border-zinc-200  rounded-[5px] px-[10px] py-[5px] justify-center flex-wrap">
        {
          props.details.StartTime && <button className="flex mx-2.5 items-center">
            <div className="p-2">
              <span className="inline-block overflow-hidden w-6 h-6 bg-transparent opacity-60 border-0 m-0 p-0 relative max-w-full">
                <span className="block w-full h-full bg-transparent opacity-100 border-0 m-0 p-0 max-w-full">
                  <img
                    className="absolute inset-0 w-full h-full filter-none"
                    src="/assets/icons/Calendar.svg"
                    alt="logo-calender"
                  ></img>
                </span>
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm text-[#AAAAAA]">
                {props.details.StartTime}
              </span>
            </div>
          </button>
        }
        {
          props.details.Venue && <button className="flex mx-2.5 items-center">
            <div className="p-2">
              <span className="inline-block overflow-hidden w-6 h-6 bg-transparent opacity-100 border-0 m-0 p-0 relative max-w-full">
                <span className="block w-full h-full bg-transparent opacity-100 border-0 m-0 p-0 max-w-full">
                  <img
                    className="absolute inset-0 w-full h-full filter-none"
                    src="/assets/icons/Stadium.svg"
                    alt="logo-stadium"
                  ></img>
                </span>
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm text-[#AAAAAA]">{props.details.Venue}</span>
            </div>
          </button>
        }
        {
            props.details.Spectators &&
              <button className="flex mx-2.5 items-center">
                <div className="p-2">
                  <span className="inline-block overflow-hidden w-6 h-6 bg-transparent opacity-100 border-0 m-0 p-0 relative max-w-full">
                    <span className="block w-full h-full bg-transparent opacity-100 border-0 m-0 p-0 max-w-full">
                      <img
                        className="absolute inset-0 w-full h-full filter-none"
                        src="/assets/icons/Person.svg"
                        alt="logo-person"
                      ></img>
                    </span>
                  </span>
                </div>
                <div className="flex flex-col justify-center">
                    <span className="text-sm text-[#AAAAAA]">{props.details.Spectators}</span>
                  </div>
              </button>
        }
      </div>
      <TextLinker text="VIDEO" className="hidden font-bold text-sm text-primary" />
      <div className="hidden overflow-hidden w-full relative">
        <div className="overflow-auto scroll-smooth scrollbar-hide">
          <div className="whitespace-nowrap">
            {videoData &&
              videoData.map((item, index) => (
                <div key={`VIDEO:${index}`} className="inline-block relative">
                  <div className="bg-bottom-shadow ml-0 cursor-pointer text-white dark:text-black relative font-bold text-center rounded-md">
                    <div className="text-xs absolute top-[17px] right-[12px] z-50 flex items-center justify-end p-[3px] rounded-[3px] bg-hsla-0-0-4-0.5">
                      {item.time}
                    </div>
                    <div className="font-bold z-50 absolute bottom-[5px] mx-0 text-left w-full text-white dark:text-black text-base px-5 whitespace-normal break-words">
                      {item.title}
                    </div>
                    <img
                      className="mx-[5px] w-[250px] h-[140px] box-border rounded-lg"
                      alt="altText"
                      src={item.img}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white dark:text-black sm:w-6 sm:h-6 dark:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
            <span className="sr-only">{props.t("Previous")}</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white dark:text-black sm:w-6 sm:h-6 dark:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
            <span className="sr-only">{props.t("Next")}</span>
          </span>
        </button>
      </div>
    </>
  );
};

export default withTranslation()(matchInfoLayout);

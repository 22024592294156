import { Component, ReactNode } from "react";

class ButtonDarkMode extends Component<{ darkMode: any, className?: string, svgClassName?: string }> {
    render(): ReactNode {
        return <button className={this.props.className} onClick={this.props.darkMode}>
            <img src="https://cdn-icons-png.flaticon.com/512/5460/5460846.png"
                height={30}
                width={30}
                alt="" />
        </button>
    }

}
export default ButtonDarkMode;
import { LeagueTennisDetail, MatchesTennis } from "@lib/models";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import NoData from "../shared/NoData";
import TabsComponent from "../tabs/tabComponent";
import TextLinker from "../textLinker";
import TennisMatchListItem from "./tennisMatchListItem";

export default function TennisCompetitionLayout(props: { fixtures?: boolean, results?: boolean }) {
    const [league, setLeague] = useState<LeagueTennisDetail | null>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const params = useParams();
    const nav = useNavigate();
    const link = new URL(window.location.href).searchParams.get('link');

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            setLeague(undefined)
            const tLeague = await Services.getTennisLeague(decodeURIComponent(params.coid || ""), link || "")
            tLeague && setLeague(tLeague)
            setIsLoading(false)
        })()
    }, [params.coid, link])


    if (league) {
        document.title = `Tennis - ${league.LeagueName} - ${props.fixtures ? "Fixtures" : props.results ? "Results" : "Overview"}`;

        return <>
            {!league && <GlobalSpinner />}
            <CompetitionLinker
                isButton={false}
                league={league}
                navigate={nav} />

            {renderTabsComponent()}
            {renderBody(league)}
        </>
    }

    return isLoading ? <GlobalSpinner /> : <NoData text="There is no data available" />

    function renderTabsComponent() {
        type IData = {
            name: string,
            path: string,
            childs?: IData[]
            active: boolean
        }

        const data: IData[] = [
            {
                name: 'Overview',
                path: '',
                active: !props.fixtures && !props.results
            },
            {
                name: 'Matches',
                path: 'fixtures',
                active: props.fixtures || props.results || false,
                childs: [
                    {
                        name: "FIXTURES",
                        path: "fixtures",
                        active: props.fixtures || false
                    },
                    {
                        name: "RESULTS",
                        path: "results",
                        active: props.results || false
                    },
                ],
            }
        ]

        return <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />
    }

    function handleNavigateTo(path: string) {
        nav(`/tennis/${params.cid}/${params.coid}/${path || 'overview'}`)
    }

    function renderBody(league: LeagueTennisDetail) {
        let fixtures = league.Fixtures;
        let results = league.Results;

        if (props.fixtures) {
            return renderMatchListItems(fixtures)

        }
        if (props.results) {
            return renderMatchListItems(results)
        }
        return <>
            <TextLinker
                text="FIXTURES"
                className="font-bold text-sm text-primary"
                link={"fixtures"}
                navigate={nav}
                isTextLink={true} />
            {renderMatchListItems(fixtures, 3)}

            <TextLinker
                text="RESULTS"
                className="font-bold text-sm text-primary"
                link={"results"}
                navigate={nav}
                isTextLink={true} />
            {renderMatchListItems(results, 3)}
        </>
    }

    function renderMatchListItems(matches: MatchesTennis[], max?: number) {
        if (matches.length === 0) {
            return <NoData text="There is no data available" />
        }
        if (max) {
            matches = matches.slice(0, max);
        }
        return matches.map((match: MatchesTennis, index: number) => {
            return <div className="flex flex-col gap-y-3 mb-1" key={`$match-${match.Id}-${index}`}>
                <TennisMatchListItem
                    navigate={nav}
                    match={match}
                    cid={params.cid}
                    coid={params.coid}
                />
            </div>
        })
    }
}
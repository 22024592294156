import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    if (document.referrer.includes(window.location.hostname)) {
      navigate(-1);
    } else {
      navigate('/'); 
    }
  };

  return (
    <div className="text-center m-auto p-2 mt-8">
      <img
        alt="error-page"
        className="w-[256px] w-[256px] m-auto"
        src="/assets/icons/Error.png"
      />
      <h2 className="mt-1 text-[20px] text-center">
        {t("We run into a problem")}
        <br />
        {t("The Developers have been notified of this issue")}
      </h2>
      <button
        className="rounded-lg bg-accent  text-primary-lighter dark:text-black text-lg font-semibold p-3 mt-2"
        onClick={handleGoBack}
      >
        {t("Go back")}
      </button>
    </div>
  );
};

export default ErrorPage;

import {
    CompetitionCricketMatches,
    MatchesCricket,
    LeagueCricketDetail,
    MatchCricketDetail,
    CompetitionTennisMatches,
    MatchTennisDetail,
    CompetitionItem,
    LeagueBasketballDetail,
    LeagueTennisDetail, 
    News,
    NewsPost,
    HighlightPost,
    MatchesFootball,
    MatchesFootballDetail
} from "@lib/models";
import {
    CompetitionBasketballMatches,
    CompetitionFixtureGroup,
    CompetitionGroup,
    CompetitionHockeyMatches,
    FixtureDetails,
    LeagueHockeyDetail,
    LineUpMatch,
    MatchBasketballDetail,
    MatchHockeyDetail,
    TeamDetailInfo,
    SoccerFixtureUpdaters
} from "./common";
import { MemCache, memCache } from "@lib/tools";

import { ApiClient } from "./apiClient";

interface CompetitionGroupMap {
    [key: number]: CompetitionGroup | undefined
}

interface ScheduleCache<T> {
    [key: number]: { cache: MemCache<T> | null }
}

function getScheduleCacheKey(year: number, month: number, date: number) {
    return (year * 10000) + (month * 100) + date
}

const loader = new ApiClient();

let competitionGroups: MemCache<CompetitionGroup[]> | null = null;
let competitionGroupMap: CompetitionGroupMap = {}

// let liveSchedule: MemCache<CompetitionFixtureGroup> | null = null;
let schedules: ScheduleCache<CompetitionFixtureGroup> = {}

let competitionSoccerGroups: MemCache<CompetitionGroup[]> | null = null;
let competitionBasketballGroups: MemCache<CompetitionItem[]> | null = null;
let competitionHockeyGroups: MemCache<CompetitionItem[]> | null = null;
let competitionTennisGroups: MemCache<CompetitionItem[]> | null = null;
let competitionCricketGroups: MemCache<CompetitionItem[]> | null = null;

let liveHockeySchedule: MemCache<CompetitionHockeyMatches[]> | null = null;
let hockeySchedules: ScheduleCache<CompetitionHockeyMatches[]> = {}

let liveBasketballSchedule: MemCache<CompetitionBasketballMatches[]> | null = null;
let basketballSchedules: ScheduleCache<CompetitionBasketballMatches[]> = {}

let liveCricketSchedule: MemCache<CompetitionCricketMatches[]> | null = null;
let cricketSchedules: ScheduleCache<CompetitionCricketMatches[]> = {}

let liveTennisSchedule: MemCache<CompetitionTennisMatches[]> | null = null;
let tennisSchedules: ScheduleCache<CompetitionTennisMatches[]> = {}

let soccerUpdaters: SoccerFixtureUpdaters = {}

const TTL_CACHE_COMPETITIONS = 24 * 3600 * 1000;
const TTL_CACHE_LIVE = 10 * 60 * 1000;
const TTL_CACHE_SCHEDULES = 3600 * 1000;

const Services = {


    // football 
    async getFootballList():Promise<MatchesFootball[]>{
        const resp = await loader.getfootballList()
        return resp
    },

    async getFootballListFromDate( year:any, month:any, date:any): Promise<MatchesFootball[]>{
        const reps = await loader.getfootballScheduleDataFromDate(year, month, date)
        return reps
    },

    async getDetailFootball(id:string  , link:string ): Promise <MatchesFootballDetail[]>{
        const resp = await loader.getDetailFootballMatch(id , link)
        return resp
    },
    getSoccerUpdaters(clear: boolean = false) {
        if (clear)
            soccerUpdaters = {};
        return soccerUpdaters;
    },

    async getNewsSportList(lang?: string, route?: string): Promise<NewsPost[]> {
        return await loader.getNewsSportList(lang, route);
    },

    async getNews(lang?: string, id?: number, page?: number, per_page?: number): Promise<News> {
        return await loader.getNews(lang, id, page, per_page);
    },

    async getNewsDetail(lang?: string, slug?: string): Promise<NewsPost> {
        return await loader.getNewsDetail(lang, slug);
    },

    async getNewsCategories(lang?: string): Promise<{id: number, name: string, slug: string}[]> {
        return await loader.getNewsCaterories(lang);
    },

    async getHighlightsList(lang?: string, id?: number, page?: number, per_page?: number): Promise<NewsPost[]> {
        return await loader.getHighlightsList(lang, id, page, per_page);
    },

    async getHighlightDetail(lang?: string, slug?: string): Promise<NewsPost> {
        return await loader.getHighlightDetail(lang, slug);
    },

    async getHighlightByTag(lang?: string, tag?: string): Promise<HighlightPost[]> {
        return await loader.getHighlightByTag(lang, tag);
    },

    getCompetitionGroup(coid: number): CompetitionGroup | undefined {
        return competitionGroupMap[coid];
    },

    getCompetitionSchedules: loader.getCompetitionSchedules,
    getCompetitionStandings: loader.getCompetitionStandings,

    async getTeamDetail(teamId: number): Promise<TeamDetailInfo> {
        return await loader.getTeamDetail(teamId);
    },

    async getTeamNews(teamName: string, lang?: string): Promise<NewsPost[]> {
        return await loader.getTeamNews(teamName, lang)
    },

    async getMatch(matchId: number): Promise<FixtureDetails | null> {
        return await loader.getMatch(matchId);
    },

    async getLineUpMatch(matchId: number): Promise<LineUpMatch> {
        return await loader.getLineUpMatch(matchId)
    },

    async getLiveSchedule(): Promise<CompetitionFixtureGroup> {
        return await loader.getLiveScheduleData();
    },

    async getBySchedule(year: number, month: number, date: number): Promise<CompetitionFixtureGroup> {
        const key = getScheduleCacheKey(year, month, date);
        let sc = schedules[key]
        if (!sc) {
            sc = { cache: null }
            schedules[key] = sc;
        }

        const c = sc.cache = memCache(sc.cache, TTL_CACHE_SCHEDULES, () => loader.getScheduleData(year, month, date));
        const result = await c.promise;

        if (result.serverTime) {
            // is live
            c.ttl = TTL_CACHE_LIVE;
        }

        return result;
    },

    async getCompetitionList(): Promise<CompetitionGroup[]> {
        const c = competitionGroups = memCache(competitionGroups, TTL_CACHE_COMPETITIONS, () => {
            const p = loader.getCompetitionList();

            p.then(cg => {
                cg.forEach(m => {
                    m.competitions.forEach(c => {
                        competitionGroupMap[c.id] = m;
                    })
                })
            })

            return p;
        })

        return await c.promise;
    },

    // Basketball
    async getBasketballMatch(matchId: string, link: string): Promise<MatchBasketballDetail> {
        return await loader.getBasketballMatch(matchId, link);
    },

    async getBasketballLiveSchedule(): Promise<CompetitionBasketballMatches[]> {
        const c = liveBasketballSchedule = memCache(liveBasketballSchedule, TTL_CACHE_LIVE, loader.getBasketballLiveScheduleData.bind(loader));
        return await c.promise;
    },

    async getBasketballSchedule(year: number, month: number, date: number): Promise<CompetitionBasketballMatches[]> {
        const key = getScheduleCacheKey(year, month, date);
        let sc = basketballSchedules[key]
        if (!sc) {
            sc = { cache: null }
            basketballSchedules[key] = sc;
        }

        const c = sc.cache = memCache(sc.cache, TTL_CACHE_SCHEDULES, () => loader.getBasketballScheduleData(year, month, date));
        const result = await c.promise;

        // TODO: check how to go live
        // if (result.serverTime) {
        //     // is live
        //     c.ttl = TTL_CACHE_LIVE;
        // }

        return result;
    },

    async getSoccerCompetitionList(): Promise<CompetitionGroup[]> {
        const c = competitionSoccerGroups = memCache(competitionSoccerGroups, TTL_CACHE_COMPETITIONS, () => {
            const p = loader.getSoccerCompetitionList()

            p.then(cg => {
                cg.forEach(m => {
                    m.competitions.forEach(c => {
                        competitionGroupMap[c.id] = m;
                    })
                })
            })

            return p;
        })
        
        return await c.promise;
    },
    async getBasketballCompetitionList(): Promise<CompetitionItem[]> {
        const c = competitionBasketballGroups = memCache(competitionBasketballGroups, TTL_CACHE_COMPETITIONS, () => loader.getBasketballCompetitionList())
        return await c.promise;
    },
    async getBasketballLeague(leagueId: string, link: string): Promise<LeagueBasketballDetail | undefined> {
        return await loader.getBasketballLeague(leagueId, link);
    },

    // Hockey
    async getHockeyMatch(matchId: string, link: string): Promise<MatchHockeyDetail> {
        return await loader.getHockeyMatch(matchId, link);
    },

    async getHockeyLiveSchedule(): Promise<CompetitionHockeyMatches[]> {
        const c = liveHockeySchedule = memCache(liveHockeySchedule, TTL_CACHE_LIVE, loader.getHockeyLiveScheduleData.bind(loader));
        return await c.promise;
    },

    async getHockeySchedule(year: number, month: number, date: number): Promise<CompetitionHockeyMatches[]> {
        const key = getScheduleCacheKey(year, month, date);
        let sc = hockeySchedules[key]
        if (!sc) {
            sc = { cache: null }
            hockeySchedules[key] = sc;
        }

        const c = sc.cache = memCache(sc.cache, TTL_CACHE_SCHEDULES, () => loader.getHockeyScheduleData(year, month, date));
        const result = await c.promise;

        // TODO: check how to go live
        // if (result.serverTime) {
        //     // is live
        //     c.ttl = TTL_CACHE_LIVE;
        // }

        return result;
    },

    async getHockeyCompetitionList(): Promise<CompetitionItem[]> {
        const c = competitionHockeyGroups = memCache(competitionHockeyGroups, TTL_CACHE_COMPETITIONS, () => loader.getHockeyCompetitionList())
        return await c.promise;
    },
    async getHockeyLeague(leagueId: string, link: string): Promise<LeagueHockeyDetail | undefined> {
        return await loader.getHockeyLeague(leagueId, link);
    },

    // Tennis
    async getTennisLiveSchedule(): Promise<CompetitionTennisMatches[]> {
        const c = liveTennisSchedule = memCache(liveTennisSchedule, TTL_CACHE_LIVE, loader.getTennisLiveScheduleData.bind(loader));
        return await c.promise;
    },

    async getTennisSchedule(year: number, month: number, date: number): Promise<CompetitionTennisMatches[]> {
        const key = getScheduleCacheKey(year, month, date);
        let sc = tennisSchedules[key]
        if (!sc) {
            sc = { cache: null }
            tennisSchedules[key] = sc;
        }

        const c = sc.cache = memCache(sc.cache, TTL_CACHE_SCHEDULES, () => loader.getTennisScheduleData(year, month, date));
        const result = await c.promise;

        // TODO: check how to go live
        // if (result.serverTime) {
        //     // is live
        //     c.ttl = TTL_CACHE_LIVE;
        // }

        return result;
    },
    async getTennisCompetitionList(): Promise<CompetitionItem[]> {
        const c = competitionTennisGroups = memCache(competitionTennisGroups, TTL_CACHE_COMPETITIONS, () => loader.getTennisCompetitionList())
        return await c.promise;
    },
    async getTennisLeague(leagueId: string, link: string): Promise<LeagueTennisDetail | null> {
        return await loader.getTennisLeague(leagueId, link);
    },
    async getTennisDetail(matchId: string, link: string): Promise<MatchTennisDetail> {
        return await loader.getTennisDetail(matchId, link);
    },

    // cricket
    async getCricketLiveSchedule(): Promise<CompetitionCricketMatches[]> {
        const c = liveCricketSchedule = memCache(liveCricketSchedule, TTL_CACHE_LIVE, loader.getCricketLiveScheduleData.bind(loader));
        return await c.promise;
    },
    async getCricketSchedule(year: number, month: number, date: number): Promise<CompetitionCricketMatches[]> {
        const key = getScheduleCacheKey(year, month, date);
        let sc = cricketSchedules[key]
        if (!sc) {
            sc = { cache: null }
            cricketSchedules[key] = sc;
        }

        const c = sc.cache = memCache(sc.cache, TTL_CACHE_SCHEDULES, () => loader.getCricketScheduleData(year, month, date));
        const result = await c.promise;

        // TODO: check how to go live
        // if (result.serverTime) {
        //     // is live
        //     c.ttl = TTL_CACHE_LIVE;
        // }

        return result;
    },
    async getCricketCompetitionList(): Promise<CompetitionItem[]> {
        const c = competitionCricketGroups = memCache(competitionCricketGroups, TTL_CACHE_COMPETITIONS, () => loader.getCricketCompetitionList())
        return await c.promise;
    },
    async getCricketLeague(leagueId: string, link: string): Promise<LeagueCricketDetail | null> {
        return await loader.getCricketLeague(leagueId, link);
    },
    async getCricketMatch(matchId: string): Promise<MatchesCricket | null> {
        return await loader.getCricketMatch(matchId);
    },
    async getCricketDetail(matchId: string, link: string): Promise<MatchCricketDetail> {
        return await loader.getCricketDetail(matchId, link);
    },
}

export default Services;
import { MatchTennisDetail } from "@lib/models";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { redirect, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import NoData from "../shared/NoData";
import TableTbodyRounded from "../shared/tableTbodyRounded";
import TabsComponent, { ITabData } from "../tabs/tabComponent";
import TextLinker from "../textLinker";

type Props = {
    info?: boolean,
    summary?: boolean,
} & WithTranslation



function TennisMatchDetailLayout(props: Props) {
    const [detail, setDetail] = useState<MatchTennisDetail | any>()
    const params = useParams<SportParam>();
    const link = new URL(window.location.href).searchParams.get('link');
    const [searchParams] = useSearchParams();
    const nav = useNavigate();
    const matchId = params.matchId;
    const coid = params.coid

    useEffect(() => {
        (async () => {

            if (!matchId || !link || !coid) {
                redirect(getNavLink())
            }
            else {
                const tDetail = matchId && link && await Services.getTennisDetail(matchId, link)
                tDetail && setDetail(tDetail)
            }
        })()
    }, [link, matchId])


    detail && (document.title = `Tennis - ${detail.HomeName} vs ${detail.AwayName} - ${(props.info ? "Info" : props.summary ? "Summary" : "")} `);

    return <>
        {!detail && <GlobalSpinner />}
        {detail && <CompetitionLinker
            isButton={true}
            league={detail}
            coid={detail.LeagueId}
            navigate={nav} />}

        {detail && renderSets(detail)}
        {detail && renderTabsComponent(detail)}
        {props.info && detail ? renderInfoTab(detail) : null}
        <table className="table table-fixed w-full border-separate border-spacing-0 ">
            <tbody>
                {props.summary && detail ? renderSummaryTab(detail) : null}
            </tbody>
        </table>
    </>


    function renderSets(detail: MatchTennisDetail) {
        return <div className={`flex flex-row text-white dark:text-black text-xl bg-main-bg-lighter dark:bg-slate-200 dark:text-black my-6 before:border-l-8 ${isLive() ? `before:border-orange-500 before:rounded-r-lg` : "before:border-transparent"}`}>
            <div className="px-4 w-20 text-center self-center">
                {props.t(detail?.Status, detail?.Status)}
            </div>
            <div className="flex flex-col grow">
                <div className={`flex flex-row`}>
                    <div className="px-4 self-center text-left grow relative py-4">{detail?.HomeName}</div>
                    <div className="px-4 self-center text-center w-16">{detail?.HomeScore}</div>
                </div>
                <div className={`flex flex-row`}>
                    <div className="px-4 self-center text-left grow relative py-4">{detail?.AwayName}</div>
                    <div className="px-4 self-center text-center w-16">{detail?.AwayScore}</div>
                </div>
            </div>
        </div>
    }

    function isLive() {
        return false;
    }

    function renderTabsComponent(detail: MatchTennisDetail) {
        const data: ITabData[] = [
            {
                name: 'Info',
                path: 'info',
                active: props.info
            },
            {
                name: 'Summary',
                path: 'summary',
                active: props.summary
            },
        ]

        return <TabsComponent data={data} handleNavigateTo={(path) => handleNavigateTo(path, detail)} />
    }

    function renderInfoTab(detail: MatchTennisDetail) {
        let scores = detail.HomeScores && detail.AwayScores && [detail.HomeScores, detail.AwayScores].map((arrTextScores: string[], index: number) => {
            return arrTextScores.map((textScores: string, indexTextScores: number) => {
                return textScores.split('\n').map((textScore: string, index: number) => {
                    return parseFloat(textScore);
                })
            })
        })
        return <>
            {scores ? <TableTbodyRounded
                classTH="h-12"
                classTD="h-12 text-center"
                thead={(detail.Headers || ["1", "2", "3", "4", "5", "PTS", "SETS"]).map((text: string, index: number) => {
                    return { child: text }
                })}
                tbody={scores.map((arrNumScores: number[][], indexNum: number) => {
                    return arrNumScores.map((colScores: number[], indexCol: number) => {
                        let indexOther = (indexNum + 1) % 2
                        let sumOther = (scores[indexOther][indexCol][0] || 0) + (scores[indexOther][indexCol][1] || 0)
                        let sumCurrent = (arrNumScores[indexCol][0] || 0) + (arrNumScores[indexCol][1] || 0);
                        let isHighlight = sumCurrent > sumOther || indexCol === arrNumScores.length - 1;
                        return {
                            child: <div className={`text-lg ${isHighlight ? "text-white dark:text-black" : "text-gray-300"}`}>
                                {colScores[0] || ""}
                                {colScores[1] ? <sup className="-top-3 text-sm">{colScores[1]}</sup> : <></>}
                            </div>
                        };
                    });
                })}
            /> : <NoData text="There is no data available" />}

            <TextLinker
                text="MATCH INFO"
                className="font-bold text-sm text-primary"
            />

            <div className="flex border border-primary-darker dark:border-zinc-200  rounded-[5px] px-[10px] py-[5px] justify-center flex-wrap">
                {renderCalendar(detail)}
                {renderCourt(detail)}
            </div>
        </>
    }

    function renderCalendar(detail: MatchTennisDetail) {
        return <div className="flex mx-2.5 items-center">
            <div className="p-2">
              <span className="inline-block overflow-hidden w-6 h-6 bg-transparent opacity-60 border-0 m-0 p-0 relative max-w-full">
                <span className="block w-full h-full bg-transparent opacity-100 border-0 m-0 p-0 max-w-full">
                  <img
                    className="absolute inset-0 w-full h-full filter-none"
                    src="/assets/icons/Calendar.svg"
                    alt="logo-calender"
                    ></img>
                </span>
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <span className="text-sm text-[#AAAAAA]">{detail.StartTime}</span>
            </div>
        </div>
    }

    function renderCourt(detail: MatchTennisDetail) {
        return detail.Venue ?
        <div className="flex mx-2.5 items-center">
        <div className="p-2">
          <span className="inline-block overflow-hidden w-6 h-6 bg-transparent opacity-100 border-0 m-0 p-0 relative max-w-full">
            <span className="block w-full h-full bg-transparent opacity-100 border-0 m-0 p-0 max-w-full">
              <img
                className="absolute inset-0 w-full h-full filter-none"
                src="/assets/icons/Stadium.svg"
                alt="logo-stadium"
                ></img>
            </span>
          </span>
        </div>
        <div className="flex flex-col justify-center">
          <span className="text-sm text-[#AAAAAA]">{detail.Venue}</span>
        </div>
    </div> : <></>
    }
    function renderSummaryTab(detail: MatchTennisDetail) {
        return detail.Summary
            ? detail.Summary.map((text: string, index: number) => {
                return <tr key={`Summary-${index}`} className="h-14">
                    <td className={`${index === 0 ? 'rounded-t-lg' : ""} ${ detail.Summary.length - 1 === index ? 'rounded-b-lg' : ""} px-10 py-2 border-b border-gray-700 px-4 border`}>
                        {text}
                    </td>
                </tr>
            })
            : <NoData text="There is no data available" />
    }

    function handleNavigateTo(path: string, detail: MatchTennisDetail) {
        let cid = encodeURIComponent(params.cid || "");
        let coid = encodeURIComponent(params.coid || "");
        let qs = new URLSearchParams([['link', searchParams.get("link") || ""]])

        nav(getNavLink(cid ? {
            cname: cid,
            coname: coid,
            team1: detail?.HomeName,
            team2: detail?.AwayName,
            matchId: params.matchId,
            tab: path,
        } : {
            matchId: params.matchId
        }, qs));
    }


}

export default withTranslation()(TennisMatchDetailLayout);
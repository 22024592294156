import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { MatchHockeyDetail, SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import { GlobalSpinner } from "../globalSpinner";
import TabsComponent, { ITabData } from "../tabs/tabComponent";
import MatchInfoLayout from "./matchInfoLayout";
import SummaryLayout from "./summaryLayout";

type IRouting = {
  info?: boolean;
  summary?: boolean;
  table?: boolean;
} & WithTranslation;

function MatchDetailLayout(props: IRouting) {
  const [details, setDetail] = useState<MatchHockeyDetail>();
  const link = new URL(window.location.href).searchParams.get("link");
  const nav = useNavigate();
  const params = useParams<SportParam>();
  const matchId = params.matchId;
  const coid = params.coid;

  useEffect(() => {
    (async () => {
      if (!matchId || !link || !coid) {
        redirect(getNavLink());
      } else {
        const tDetail = await Services.getHockeyMatch(matchId, link);
        tDetail && setDetail(tDetail);
      }
    })();
  }, [link, matchId]);

  const handleNavigateTo = (path: string) => {
    const qs = new URLSearchParams([["link", link || ""]]);
    const cname = params.cid?.toString();
    const coname = params.coid?.toString();

    nav(
      getNavLink(
        params.cid
          ? {
            cname,
            coname,
            team1: details?.HomeName,
            team2: details?.AwayName,
            matchId: params.matchId,
            tab: path,
          }
          : {
            matchId: params.matchId,
          },
        qs
      )
    );
  };

  const data: ITabData[] = [
    {
      name: "Info",
      path: "info",
      active: props.info,
    },
    {
      name: "Summary",
      path: "summary",
      active: props.summary,
    },
  ];

  details &&
    (document.title = `Hockey - ${details.HomeName} vs ${details.AwayName} - ${props.info ? "Info" : props.summary ? "Summary" : ""
      } `);

  return (
    <>
      {!details && <GlobalSpinner />}
      {details && (
        <CompetitionLinker
          isButton={true}
          league={details}
          coid={details.LeagueId}
          navigate={nav}
        />
      )}

      <video
        style={{ display: "none" }}
        className="rounded-lg w-full my-6"
        controls
      >
        <source src="/docs/videos/flowbite.mp4" type="video/mp4" />
        {props.t("Your browser does not support the video tag.")}
      </video>

      {details && (
        <div className="sp-item flex-col">
          <div className="flex flex-row justify-center items-center w-full h-auto relative pt-3 text-center">
            <div className="flex flex-col items-center w-full">
              <img
                src={details.HomeIcon}
                alt={details.HomeName}
                className="h-6 w-6 sm:h-8 sm:w-8"
              />
              <span className="font-semibold px-3 py-4">
                {details.HomeName}
              </span>
            </div>
            <div className="flex flex-col w-full">
              <div className="text-white dark:text-black font-bold text-3xl">
                {props.t(details.ScoreOrTime, details.ScoreOrTime)}
              </div>
              <div className="text-primary text-sm my-1">
                {details.ScoreAgg}
              </div>
              <div className="text-primary text-sm my-1">
                {props.t(details.Status)}
              </div>
            </div>
            <div className="flex flex-col items-center w-full">
              <img
                src={details.AwayIcon}
                alt={details.AwayName}
                className="h-6 w-6 sm:h-8 sm:w-8"
              />
              <span className="font-semibold px-3 py-4">
                {details.AwayName}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-screen-xl mx-auto mt-5" />
      {details && <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />}
      {props.info && details && <MatchInfoLayout details={details} />}
      {props.summary && details && <SummaryLayout details={details} />}
    </>
  );
}

export default withTranslation()(MatchDetailLayout);

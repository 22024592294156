import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from "react-i18next";

type Props = {
} & WithTranslation

const NotFound = (props: Props) => {
  return (
    <div className="w-full lg:col-span-12 my-28 flex items-center justify-center">
      <div className="max-w-md w-full text-center">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <p className="text-xl font-medium text-gray-500 mt-4">{props.t("Page not found")}</p>
        <Link to="/" className="mt-8 inline-block bg-blue-500 hover:bg-blue-700 text-white dark:text-black font-medium rounded-md px-4 py-2">
          {props.t("Go back to home")}
        </Link>
      </div>
    </div>
  );
};

export default withTranslation()(NotFound);

import { defer as dd, useAsyncValue as vv, useLoaderData } from "react-router-dom";
import { DeferredData } from "@remix-run/router/dist/utils";

var defDeferWaitTime = 0;

export function defaultDeferredTime() {
    return defDeferWaitTime;
}

export function setDefaultDeferredTime(tm: number) {
    defDeferWaitTime = tm;
}

export interface DeferredResult<T> {
    result: Promise<T>
}

export function useDeferredLoaderData<T>(): DeferredResult<T> {
    return useLoaderData() as DeferredResult<T>
}

export function useAsyncValue<T>(): T {
    return vv() as T
}

type AsyncFunc<T> = () => Promise<T>;

export async function deferHold<T>(arg1: number | AsyncFunc<T>, fn?: AsyncFunc<T>): Promise<DeferredData> {
    const timeout = (typeof (arg1) === "number") ? arg1 : defDeferWaitTime;
    const tFn = ((typeof (arg1) === "number") ? fn! : arg1)();

    if (timeout > 0) {
        const np = new Promise(res => {
            let tm: null | NodeJS.Timeout = setTimeout(() => {
                tm = null;
                res(null);
            }, timeout);

            tFn.finally(() => {
                if (tm) {
                    clearTimeout(tm)
                    tm = null;
                }

                res(null)
            });
        })

        await np;
    }

    return dd({ result: tFn });
}


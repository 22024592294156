import { LeagueCricketDetail, MatchesCricket } from "@lib/models";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { SportParam, getNavLink } from "../../mods/common";
import Services from "../../mods/services";
import CompetitionLinker from "../competitionLinker";
import NoData from "../shared/NoData";
import TabsComponent from "../tabs/tabComponent";
import TextLinker from "../textLinker";
import CricketCompetitionTables from "./cricketCompetitionTables";
import CricketMatchListItem from "./cricketMatchListItem";
import { GlobalSpinner } from "../globalSpinner";

type Props = {
    table?: boolean,
    fixtures?: boolean,
    results?: boolean
} & WithTranslation


function CricketCompetitionLayout(props: Props) {
    const [league, setLeague] = useState<LeagueCricketDetail | null>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const params = useParams<SportParam>();
    const nav = useNavigate();
    const link = new URL(window.location.href).searchParams.get('link');

    useEffect(() => {
        (async () => {
            if (!params.coid) {
                redirect(getNavLink())
            }
            else {
                setIsLoading(true)
                setLeague(undefined)
                const tLeague = await Services.getCricketLeague(params.coid || "", link || "")
                tLeague && setLeague(tLeague)
                setIsLoading(false)
            }
        })()
    }, [params.coid, link])


    if (league) {
        document.title = `Cricket - ${league.LeagueName} - ${props.table ? "Table" : props.fixtures ? "Fixtures" : props.results ? "Results" : "Overview"}`;

        return <>
            <CompetitionLinker
                isButton={false}
                league={league}
                navigate={nav} />

            {renderTabsComponent()}
            {renderBody(league)}
        </>
    }
    return isLoading ? <GlobalSpinner /> : <NoData text="There is no data available" />

    function renderTabsComponent() {
        type IData = {
            name: string,
            path: string,
            childs?: IData[]
            active: boolean
        }

        const data: IData[] = [
            {
                name: 'Overview',
                path: '',
                active: !props.fixtures && !props.results && !props.table
            },
            {
                name: 'Matches',
                path: 'fixtures',
                active: props.fixtures || props.results || false,
                childs: [
                    {
                        name: "FIXTURES",
                        path: "fixtures",
                        active: props.fixtures || false
                    },
                    {
                        name: "RESULTS",
                        path: "results",
                        active: props.results || false
                    },
                ],
            },
            {
                name: 'Table',
                path: 'table',
                active: props.table || false,
            }
        ]

        return <TabsComponent data={data} handleNavigateTo={handleNavigateTo} />
    }

    function handleNavigateTo(path: string) {
        nav(`/${window.location.pathname.split('/')[1]}/${params.cid}/${params.coid}/${path || 'overview'}`)
    }

    function renderBody(detail: LeagueCricketDetail) {
        let fixtures = detail.Fixtures;
        let results = detail.Results;
        let leagueTable = detail.Table;

        if (props.fixtures) {
            return renderMatchListItems(fixtures);
        }
        if (props.results) {
            return renderMatchListItems(results);
        }
        if (props.table) {
            return <CricketCompetitionTables tables={leagueTable} />;
        }
        return <>
            <TextLinker
                text="FIXTURES"
                className="font-bold text-sm text-primary"
                link={"fixtures"}
                navigate={nav}
                isTextLink={true} />
            {renderMatchListItems(fixtures, 2)}

            <TextLinker
                text="RESULTS"
                className="font-bold text-sm text-primary"
                link={"results"}
                navigate={nav}
                isTextLink={true} />
            {renderMatchListItems(results, 2)}

            <TextLinker
                text="TABLE"
                className="font-bold text-sm text-primary"
                link={"table"}
                navigate={nav}
                isTextLink={true} />
            <CricketCompetitionTables tables={leagueTable} />
        </>
    }

    function renderMatchListItems(matches: MatchesCricket[], limit?: number) {
        if (matches.length === 0) {
            return <NoData text="There is no data available" />
        }
        if (limit) {
            matches = matches.slice(0, limit);
        }
        return matches.map((match: MatchesCricket, index: number) => {
            return <CricketMatchListItem
                navigate={nav}
                match={match}
                cid={params.cid}
                coid={params.coid}
                key={`cricketCompetitionLayout.renderMatchListItems-${match.Id}-${index}`}
            />
        })
    }
}
export default withTranslation()(CricketCompetitionLayout);
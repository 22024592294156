import { useEffect, useState, useMemo } from "react";
import Services from "../../mods/services";
import { GlobalSpinner } from "../globalSpinner";
import { News } from "@lib/models";
import NoData from "../shared/NoData";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LIMIT_NUMBER } from "../../mods/common";
import { newsCategories } from "../../mods/mockHighlightsCat";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PageFetcher from "../pageFetcher";

const PAGE_SIZE = 15;
const KEY_STORAGE_LANG = "lang";
const LANG_VN = "vn";

function HighlightsLayout() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [data, setData] = useState<News | null>();
  const [categories, setCategories] = useState<
    { id: number; name: string; slug: string }[] | null
  >();
  const navigate = useNavigate();
  const query = useLocation();
  const usp = new URLSearchParams(query.search);
  const categoryQuery = usp.get("category") || "highlight";
  const page = parseInt(usp.get("page") || "1");

  const [lang, setLang] = useState<string>(
    localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "en"
  );

  const handleActiveCategory = (item: { id: number; slug: string }) => {
    navigate({
      search: `category=${item.slug}`,
    });
  };

  const onPageChanged = (pageIndex: number) => {
    navigate({
      search: `category=${categoryQuery}&page=${pageIndex + 1}`,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setCategories(() => [...newsCategories]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const lang =
      localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "en";

    (async () => {
      try {
        setDataIsLoading(true);
        if (categories && categories.length) {
          const temp = new URLSearchParams(query.search).get("category") || "highlight";
          const categoryId = categories.find((item) => item.slug === temp);
          const response: any = categoryId?.id && await Services.getHighlightsList(lang, categoryId.id, page, PAGE_SIZE);
          const result = {
            Categories: {},
            Tags: {},
            Posts: response.Posts.map((post: any) => {
              const imageUrls = post.ImageUrl.split(", ");
              return {
                ...post,
                ImageUrl: imageUrls,
              };
            }),
          };
          response && setData(result);
        }
        
      } catch (error) {
        console.log(error);
      } finally {
        setDataIsLoading(false);
      }
    })();
  }, [query.search, categories, localStorage.getItem(KEY_STORAGE_LANG)]);

  useEffect(() => {
    setLang(
      localStorage.getItem(KEY_STORAGE_LANG) === LANG_VN ? LANG_VN : "en"
    );
  }, [localStorage.getItem(KEY_STORAGE_LANG)]);

  document.title = `ScoreUpdate | The Highlights Video about Live Scores, Fixtures & Results`;

  const sliderArr = data?.Posts?.length
    ? data?.Posts.slice(0, LIMIT_NUMBER)
    : [];
  const leftItemsArr = data?.Posts?.length
    ? data?.Posts.slice(3, LIMIT_NUMBER + 3)
    : [];
  const highlightsArr = data?.Posts?.length
    ? data?.Posts.slice(6, data?.Posts?.length)
    : [];
  return (
    <div className="lg:col-span-full w-full">
      {isLoading ? (
        <GlobalSpinner />
      ) : (
        <>
          <div className="horizontal-navigation-container">
            <Swiper
              spaceBetween={5}
              slidesPerView="auto"
              navigation
              modules={[Navigation]}
              pagination={{ clickable: true }}
            >
              {categories?.length &&
                categories.map((item) => (
                  <SwiperSlide
                    onClick={() => handleActiveCategory(item)}
                    key={`NEWS_CATEGORIES:${item.id}`}
                    className={`border font-bold rounded-2xl !w-fit text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer ${
                      categoryQuery === item.slug
                        ? "bg-[#fdfdfd] text-[#111] border-[#fdfdfd] dark:text-accent dark:border-accent"
                        : "text-[#aaa] bg-[#181818] border-[#181818] dark:bg-slate-200 dark:border-zinc-300 dark:text-black"
                    }`}
                  >
                    <span>{item.name}</span>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          {dataIsLoading ? (
            <GlobalSpinner />
          ) : data && Object.keys(data.Posts).length > 0 ? (
            <div className="news-wrapper my-2 !p-0 tab-content !pb-3">
              <div className="lg:w-full lg:flex">
                <div className="featured-articles-wrapper p-2 mb-4 max-w-full lg:max-w-[65%]">
                  <Swiper
                    loop
                    spaceBetween={5}
                    slidesPerView={1}
                    autoplay={true}
                    navigation
                    breakpoints={{
                      375: {
                        slidesPerView: 1.2,
                      },
                      768: {
                        slidesPerView: 2.2,
                      },
                      1024: {
                        slidesPerView: 0.95,
                      },
                    }}
                    modules={[Navigation, Autoplay]}
                    pagination={{ clickable: true }}
                  >
                    {data &&
                      data.Posts &&
                      sliderArr.map((item: any, index: number) => {
                        if (index > LIMIT_NUMBER) return;
                        return (
                          <SwiperSlide
                            key={`NEWS_FEATURED:${index}`}
                            className={`font-bold rounded-2xl text-sm whitespace-nowrap py-1.5 px-2.5 !flex justify-center cursor-pointer`}
                          >
                            <SliderItem item={item} lang={lang} />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
                <div className="max-w-full lg:max-w-[34%]">
                  <div className="lg:flex lg:flex-col">
                    {leftItemsArr.map((item: any, index: number) => (
                      <HighlightLeftItem
                        key={`LEFT_NEWS_FEATURED:${index}`}
                        item={item}
                        lang={lang}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <hr className="max-w-screen-xl mx-auto border-primary-darker dark:border-zinc-200  dark:border-zinc-200 mb-5" />
              <div className="grid lg:grid-cols-3 gap-4 px-2 py-4">
                {!!highlightsArr.length &&
                  highlightsArr.map((item: any, index: number) => {
                    return <HighlightItem key={`HIGHLIGHTS:${index}`} item={item} lang={lang} />;
                  })}
              </div>
              <PageFetcher
                pageIndex={page - 1}
                next={data.Posts.length >= PAGE_SIZE}
                onPageChanged={onPageChanged} />
            </div>
          ) : (
            <NoData text="There are no featured news" />
          )}
        </>
      )}
    </div>
  );
}

export const SliderItem = ({ item, lang }: { item: any; lang: any }) => (
  <Link
    className="relative rounded-md overflow-hidden h-[300px] lg:h-[450px] w-full"
    to={`/highlights/detail/${localStorage.getItem(KEY_STORAGE_LANG) || "en"}/${
      item.Slug
    }`}
  >
    <span className="absolute left-2.5 top-2.5 bg-[#333]  text-[#fdfdfd]  text-base lg:text-[17px] rounded py-1 px-2 capitalize z-10">
      {item.Title.toString().split(":")[0]}
    </span>
    <div className="relative w-full h-full">
      <LazyLoadImage
        effect="blur"
        src={lang === LANG_VN && item.ImageUrl.length > 1 ? item.ImageUrl[2] : item.ImageUrl[0]}
        alt={item.Title}
        className="object-contain rounded-md min-w-[350px] min-h-[300px] max-w-[350px] max-h-[300px] lg:min-h-[450px] lg:max-h-[450px] lg:min-w-[800px] lg:max-w-[800px]"
      />
      {lang === LANG_VN && item.ImageUrl.length > 1 && (
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="flex absolute top-1/3 left-1/2"
        >
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[0]}
            alt={item.Title}
            className="object-contain rounded-xl h-full lg:min-h-[165px] p-2"
          />
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[1]}
            alt={item.Title}
            className="object-contain rounded-xl h-full lg:min-h-[165px] p-2"
          />
        </div>
      )}
    </div>
    <img
      className="absolute bottom-[56px] lg:bottom-[98px] left-0 w-[40px] lg:w-[70px] play-icon-animation"
      src="/assets/icons/Play.svg"
      alt="Play icon"
    />
    <span className="absolute bottom-0 px-3 py-3 lg:py-7 pl-[20px] z-10 whitespace-normal w-full bg-[rgb(0_0_0_/_50%)]">
      <p
        className="text-[#fdfdfd] font-bold text-lg lg:text-2xl truncate max-w-[90%]"
        dangerouslySetInnerHTML={{
          __html: item.Title,
        }}
      />
      <p className="text-[#aaa] text-base truncate">{item.PostTime}</p>
    </span>
  </Link>
);

export const HighlightItem = ({ item, lang }: { item: any; lang: any }) => (
  <Link
    className="relative rounded-md overflow-hidden h-[250px] w-full"
    to={`/highlights/detail/${localStorage.getItem(KEY_STORAGE_LANG) || "en"}/${
      item.Slug
    }`}
  >
    <span className="absolute left-2.5 top-2.5 bg-[#333]  text-[#fdfdfd] text-base rounded py-1 px-2 capitalize z-10">
      {item.Title.toString().split(":")[0]}
    </span>
    <div className="highlights-img relative w-full h-full">
      <LazyLoadImage
        effect="blur"
        src={lang === LANG_VN && !!item.ImageUrl[2] ? item.ImageUrl[2] : item.ImageUrl[0]}
        alt={item.Title}
        className="object-contain rounded-md h-full"
      />
      {lang === LANG_VN && !!item.ImageUrl[2] && (
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="flex absolute top-1/3 left-1/2"
        >
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[0]}
            alt={item.Title}
            width={100}
            height={70}
            className="object-contain rounded-xl h-full p-2"
          />
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[1]}
            alt={item.Title}
            width={100}
            height={70}
            className="object-contain rounded-xl h-full p-2"
          />
        </div>
      )}
    </div>
    <img
      className="absolute bottom-[65px] lg:bottom-[75px] left-0 w-[40px] play-icon-animation"
      src="/assets/icons/Play.svg"
      alt="Play icon"
    />
    <span className="absolute bottom-0 px-2 py-1 min-h-[65px] lg:min-h-[77px] pl-[20px] z-10 whitespace-normal w-full bg-[rgb(0_0_0_/_50%)]">
      <p
        className=" text-[#fdfdfd] dark:text-white font-bold ellipse-content text-lg"
        dangerouslySetInnerHTML={{
          __html: item.Title,
        }}
      />
      <p className="text-[#aaa] text-base truncate">{item.PostTime}</p>
    </span>
  </Link>
);

export const HighlightLeftItem = ({ item, lang }: { item: any; lang: any }) => (
  <Link
    className="flex flex-cols w-full text-left p-2.5 border-t border-primary-darker dark:border-zinc-200"
    to={`/highlights/detail/${localStorage.getItem(KEY_STORAGE_LANG) || "en"}/${
      item.Slug
    }`}
  >
    <div className={`max-w-[170px] min-w-[170px] w-[170px] h-[140px] max-h-[140px] min-h-[140px] relative`}>
      <LazyLoadImage
        effect="blur"
        src={lang === LANG_VN && !!item.ImageUrl[2] ? item.ImageUrl[2] : item.ImageUrl[0]}
        alt={item.Title}
        height={140}
        className="object-cover rounded-md h-full min-h-[140px]"
      />
      {lang === LANG_VN && !!item.ImageUrl[2] && (
        <div
          style={{
            transform: "translate(-50%, -50%)",
          }}
          className="flex absolute top-1/3 left-1/2"
        >
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[0]}
            alt={item.Title}
            width={60}
            className="object-contain rounded-xl h-full p-2"
          />
          <LazyLoadImage
            effect="blur"
            src={item.ImageUrl[1]}
            alt={item.Title}
            width={60}
            className="object-contain rounded-xl h-full p-2"
          />
        </div>
      )}

      <img
        className="absolute bottom-0 left-0 w-[30px] play-icon-animation rounded-bl-lg"
        src="/assets/icons/Play.svg"
        alt="Play icon"
      />
    </div>
    <div className="flex-auto pl-4 relative">
      <div
        dangerouslySetInnerHTML={{
          __html: item.Title,
        }}
        className=" text-[#fdfdfd] dark:text-black font-bold ellipse-content text-base"
      />
      <div className="grid-cols-2">
        <div> 
          {!!item.Tags?.length && item.Tags.filter((item: string) => item.trim()).slice(0, 3).map((tag: string, index: number) => (
            <span key={`TAGS_HIGHLIGHTS:${index}`} className="bg-[#333] text-[#fdfdfd] dark:text-black dark:bg-[#ccc] rounded py-1 px-2 capitalize mt-2 mr-2 inline-block text-[11px]">
              {tag}
            </span>
          ))}
        </div>
      </div>
      
      
      <span className="absolute bottom-0 px-3 z-10 whitespace-normal right-0">
        <div className="text-[#aaa] dark:text-black text-base max-w-[172px] truncate">
          {item.PostTime}
        </div>
      </span>
    </div>
  </Link>
);

export default withTranslation()(HighlightsLayout);

"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _ResourceAwaiter_curr, _ResourceAwaiter_promises;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceAwaiter = void 0;
class ResourceAwaiter {
    constructor() {
        _ResourceAwaiter_curr.set(this, null);
        _ResourceAwaiter_promises.set(this, []);
    }
    waitFor(starter) {
        const pr = new Promise((resolve, reject) => {
            __classPrivateFieldGet(this, _ResourceAwaiter_promises, "f").push({ resolve, reject });
        });
        if (!__classPrivateFieldGet(this, _ResourceAwaiter_curr, "f")) {
            const self = this;
            const st = starter();
            __classPrivateFieldSet(this, _ResourceAwaiter_curr, st, "f");
            st.then(t => {
                for (const ppr of __classPrivateFieldGet(this, _ResourceAwaiter_promises, "f")) {
                    ppr.resolve(t);
                }
            }, err => {
                for (const ppr of __classPrivateFieldGet(this, _ResourceAwaiter_promises, "f")) {
                    ppr.reject(err);
                }
            }).finally(() => {
                __classPrivateFieldSet(self, _ResourceAwaiter_curr, null, "f");
                __classPrivateFieldSet(self, _ResourceAwaiter_promises, [], "f");
            });
        }
        return pr;
    }
}
exports.ResourceAwaiter = ResourceAwaiter;
_ResourceAwaiter_curr = new WeakMap(), _ResourceAwaiter_promises = new WeakMap();
